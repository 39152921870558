import React from "react";
import { Route } from "react-router-dom";
import ExpertautoCompleteProcess from "views/Expert/expertComponent/ExpertautoCompleteProcess";
import MyProfile from "views/Expert/expertComponent/SettingsComponents/MyProfile";
import MyBussinessProfile from "views/Expert/expertComponent/SettingsComponents/MyBussinessProfile";
import Ratings from "views/Expert/expertComponent/SettingsComponents/Ratings";
import VarifyProfile from "views/Expert/expertComponent/SettingsComponents/VerifyProfile/index";
import MySerevices from "views/Expert/expertComponent/SettingsComponents/MyServices";
import MyLocations from "views/Expert/expertComponent/SettingsComponents/MyLocations";
import MyCredits from "views/Expert/expertComponent/SettingsComponents/MyCredits";
import PaymentMethods from "views/Expert/expertComponent/SettingsComponents/PaymentMethods";
import EmailNotification from "views/Expert/expertComponent/SettingsComponents/EmailNotification";

function genericExpertRoutes() {
  return (
    <>
      <Route path="/expert/post-service">
        <ExpertautoCompleteProcess />
      </Route>
      <Route path="/expert/location">
        <ExpertautoCompleteProcess isLocation="true" />
      </Route>
      <Route path="/expert/my-profile">
        <MyProfile />
      </Route>
      <Route path="/expert/my-bussiness-profile">
        <MyBussinessProfile />
      </Route>
      <Route path="/expert/ratings">
        <Ratings />
      </Route>
      <Route path="/expert/verify-profile">
        <VarifyProfile />
      </Route>
      <Route path="/expert/my-services">
        <MySerevices />
      </Route>
      <Route path="/expert/my-locations">
        <MyLocations />
      </Route>
      <Route path="/expert/my-credits">
        <MyCredits />
      </Route>
      <Route path="/expert/payment-methods">
        <PaymentMethods />
      </Route>
      <Route path="/expert/notifications">
        <EmailNotification />
      </Route>
    </>
  );
}

export default genericExpertRoutes;
