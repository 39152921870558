import { ActionTypes } from "redux/constants/action-types";

// Action Creators
export const setToken = (token) => ({
  type: ActionTypes.SET_TOKEN,
  payload: token,
});
export const resetToken = () => ({
  type: ActionTypes.RESET,
});

export const setQuestions = (questions) => ({
  type: ActionTypes.SET_QUESTION,
  payload: questions,
});

export const setPublicQuestions = (public_questions) => ({
  type: ActionTypes.SET_PUBLIC_QUESTION,
  payload: public_questions,
});

export const setModal = (bool) => ({
  type: ActionTypes.SET_MODAL,
  payload: bool,
});

export const leadProcess = (bool) => ({
  type: ActionTypes.SET_LEAD_PROCESS,
  payload: bool,
});

export const setLeadDetail = (leadDetail) => ({
  type: ActionTypes.SET_LEAD_DETAIL,
  payload: leadDetail,
});

export const setError = (err) => ({
  type: ActionTypes.SET_ERROR,
  payload: err,
});

export const setPackageId = (packageId) => ({
  type: ActionTypes.SET_PACKAGEID,
  payload: packageId,
});

export const setPackageName = (packageSelected) => ({
  type: ActionTypes.SET_PACKAGESELECTED,
  payload: packageSelected,
});

export const setPaymentMethodId = (paymentMethodID) => ({
  type: ActionTypes.SET_PAYMENT_METHOD_ID,
  payload: paymentMethodID,
});

export const removePaymentMethodId = () => ({
  type: ActionTypes.REMOVE_PAYMENT_METHOD_ID,
});

export const removePackageId = () => ({
  type: ActionTypes.REMOVE_PACKAGEID,
});

export const setCredits = (val) => ({
  type: ActionTypes.SET_CREDITS,
  payload: val,
});

export const removeCredits = () => ({
  type: ActionTypes.REMOVE_CREDITS,
});

export const setSessionType = (type) => ({
  type: ActionTypes.SET_SESSION_TYPE,
  payload: type,
});

export const paymentMethodChecker = (type) => ({
  type: ActionTypes.SET_PAYMENT_CHECKER,
  payload: type,
});

export const setPackageidCreditcs = (type) => ({
  type: ActionTypes.SET_PACKAGEID_CREDITS,
  payload: type,
});

export const setIsFilterSelected = (bool) => ({
  type: ActionTypes.SET_IS_FILTER_SELECTED,
  payload: bool,
});

export const setFilters = (filterDetail) => ({
  type: ActionTypes.SET_FILTERS,
  payload: filterDetail,
});
