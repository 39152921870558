import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import MDTypography from "components/MDTypography";
import creditIcon from "assets/imgs/icons/creditIcon.png";

const ConfirmationModal = ({ credits, selectLeadData }) => {
  const packageCredits = useSelector((state) => state?.userLeadDetail?.packageIdCredits);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <img
        src={creditIcon}
        style={{
          marginRight: "10px",
          justifyContent: "center",
          marginTop: "5px",
          marginBottom: "5px",
        }}
        alt="img"
      />
      {packageCredits ? (
        <MDTypography variant="h6">
          {/* {`-${selectLeadData.credits} credits, ${credits - selectLeadData.credits + packageCredits}
           credits will remain after lead is unlocked.`} */}
          {`${
            credits - selectLeadData.credits + packageCredits
          } credits will remain after lead is unlocked.`}
        </MDTypography>
      ) : (
        <MDTypography variant="h6">
          {/* {`-${selectLeadData.credits} credits, ${
            credits - selectLeadData.credits
          } credits will remain after lead is unlocked.`} */}
          {`${credits - selectLeadData.credits} credits will remain after lead is unlocked.`}
        </MDTypography>
      )}
    </div>
  );
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  selectLeadData: PropTypes.any,
  credits: PropTypes.any,
};
