import * as React from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { getSignOut, getSignIn, getSignUp } from "hooks/auth/B2CActions";
// import MDAvatar from "components/MDAvatar";
// import { makeStyles } from "@mui/styles";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import MDTypography from "components/MDTypography";
import synergeHorizontal from "../../assets/images/horizontal logo.png";

const pages = [
  { link: "/auth/expert-landing-page", name: "Find Work" },
  { link: "/auth/client-landing-page", name: "Find Talent" },
  { link: "https://www.synerge.io/why-synerge", name: "Why Synerge" },
];

const settings = ["Logout"];

// const styles = {
//   img: {
//     height: "100px",
//     width: "100px",
//   },
// };

// const useStyles = makeStyles(styles);

const ResponsiveAppBar = ({ routes }) => {
  const data = useSelector((state) => state.setTokenReducer?.data);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { pathname } = useLocation();
  // const clientExpertPageRoutes = [];

  // const menuRoutes = () => {
  //   if (routes && routes.length > 0) {
  //     for (let i = 0; i < routes.length; i++) {
  //       clientExpertPageRoutes.push({ link: routes[i].path, name: routes[i].name });
  //     }
  //     debugger;
  //     console.log(clientExpertPageRoutes);
  //   } else {
  //     console.log("");
  //   }
  // };

  // const classes = useStyles();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const getSignOut = () => {
  //   localStorage.clear();
  //   window.open(
  //     // `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/b2c_1_signup_client/oauth2/v2.0/logout?post_logout_redirect_uri=${
  //     //   window.location.hostname === "localhost"
  //     //     ? "https://localhost:3000"
  //     //     : "https://app-develop.synerge.io"
  //     // }`,
  //     `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/logout?p=b2c_1a_displaycontrol_sendgrid_signin&post_logout_redirect_uri=${
  //       window.location.hostname === "localhost"
  //         ? "https://localhost:3000"
  //         : "https://app-develop.synerge.io"
  //     }`,
  //     "_self"
  //   );
  // };

  // const getSignIn = () => {
  //   localStorage.setItem("actionKey", "login");

  //   window.open(
  //     // `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_signin&response_type=code&client_id=c553df61-0e32-435b-b3c5-3a5afc614a1e&scope=openid https://synergeplatform.onmicrosoft.com/api/access_as_user&redirect_uri=${
  //     //   window.location.hostname === "localhost"
  //     //     ? "https://localhost:3000"
  //     //     : "https://app-develop.synerge.io"
  //     // }/auth/callback&code_challenge=1XxXWLiufpAcOhrZPGGOO3g5Vz5MZaZJF0VXLySd6i0&code_verifier=T~xwgKlCOUUuKz3SNMtfp4E7glHdTs.Sl6sL~_sTq53GVZh-HyVsQMpnIa~j7ETrto5sw1Rm79RCCJnVabaudgK.WoBOBwuo9tUVc4mHeA9RFCTXogP5Vu_xI9bVtog~&code_challenge_method=S256`,
  //     `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_DISPLAYCONTROL_SENDGRID_SIGNIN&response_type=code&client_id=c553df61-0e32-435b-b3c5-3a5afc614a1e&scope=openid https://synergeplatform.onmicrosoft.com/api/access_as_user&redirect_uri=${
  //       window.location.hostname === "localhost"
  //         ? "https://localhost:3000"
  //         : "https://app-develop.synerge.io"
  //     }/auth/callback&code_challenge=1XxXWLiufpAcOhrZPGGOO3g5Vz5MZaZJF0VXLySd6i0&code_verifier=T~xwgKlCOUUuKz3SNMtfp4E7glHdTs.Sl6sL~_sTq53GVZh-HyVsQMpnIa~j7ETrto5sw1Rm79RCCJnVabaudgK.WoBOBwuo9tUVc4mHeA9RFCTXogP5Vu_xI9bVtog~&code_challenge_method=S256`,
  //     "_self"
  //   );
  // };

  const onSignUpClick = () => {
    let role = "";
    if (pathname.indexOf("client") !== -1) {
      role = "client_signup";
    } else if (pathname.indexOf("expert") !== -1) {
      role = "expert_signup";
    }
    getSignUp("", role, "NormalFlow", "");
  };

  const onSignInClick = () => {
    let role = "";
    if (pathname.indexOf("client") !== -1) {
      role = "client_login";
    } else if (pathname.indexOf("expert") !== -1) {
      role = "expert_login";
    }
    getSignIn(role);
  };

  const onLogOutClick = () => {
    let role = "";
    if (pathname.indexOf("client") !== -1) {
      role = "client_logout";
    } else if (pathname.indexOf("expert") !== -1) {
      role = "expert_logout";
    }
    getSignOut(role);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // React.useEffect(async () => {
  //   await menuRoutes();
  // }, []);

  return (
    <AppBar position="static">
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          <MDBox
            component={NavLink}
            to="/"
            display="flex"
            alignItems="center"
            color="white"
            bgColor="white"
          >
            {/* <MDBox
              component="img"
              src={synergeLogo}
              alt="Brand"
              width="150px"
              // width="2rem"
              color="white"
              bgColor="white"
            /> */}
            <MDBox
              component="img"
              src={synergeHorizontal}
              alt="Brand"
              // width="5rem"
              width="150px"
              color="white"
              bgColor="white"
            />
          </MDBox>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, ml: 3, height: 70 }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routes && routes.length > 0 ? (
                <>
                  {routes.map((page) => (
                    <MenuItem onClick={handleCloseNavMenu}>
                      {page.name === "Why Synerge" ? (
                        <a
                          href="https://www.synerge.io/why-synerge"
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {page.name}
                        </a>
                      ) : (
                        <NavLink
                          to={`${page.layout}${page.path}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography variant="h6" noWrap textAlign="center" textTransform="capi">
                            {page.name}
                          </Typography>
                        </NavLink>
                      )}
                    </MenuItem>
                  ))}
                </>
              ) : (
                pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    {page.name === "Why Synerge" ? (
                      <a
                        href="https://www.synerge.io/why-synerge"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {page.name}
                      </a>
                    ) : (
                      <NavLink to={page.link} style={{ textDecoration: "none" }}>
                        <Typography variant="h6" noWrap textAlign="center" textTransform="capi">
                          {page.name}
                        </Typography>
                      </NavLink>
                    )}
                  </MenuItem>
                ))
              )}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            {/* <MDAvatar
              src="https://synergeplatform.blob.core.windows.net/customstore/icon-synerge.svg"
              className={classes.img}
              size="xl"
              alt="imageHere"
            />
            <MDAvatar
              src="https://synergeplatform.blob.core.windows.net/customstore/synerge.png"
              className="w-full h-auto"
              alt="imageHere"
            /> */}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              ml: 10,
              marginLeft: "30px",
            }}
          >
            {data
              ? routes.map((route, key) => (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={key}
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      px: { lg: 4 },
                      color: "white",
                      display: "block",
                      cursor: "default",
                      textTransform: "capitalize",
                      "& .MuiTouchRipple-root": {
                        display: "none",
                      },
                    }}
                  >
                    {/* <Link style={{ textDecoration: "none" }} to={`${route.layout + route.path}`}>
                      <MDTypography fontWeight="bold" variant="body1" color="error">
                        {route.name}
                      </MDTypography>
                    </Link> */}
                    <NavLink
                      style={{
                        textDecoration: "none",
                        color: "#051931",
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "23px",
                        marginRight: { md: "18px" },
                        // fontSize: "1rem",
                        // fontWeight: "bold",
                      }}
                      to={`${route.layout + route.path}`}
                      activeStyle={{
                        color: "red",
                      }}
                    >
                      {/* <MDTypography> */}
                      {route.name}
                      {/* </MDTypography> */}
                    </NavLink>
                  </Button>
                ))
              : pages.map((page) => (
                  <Button
                    // eslint-disable-next-line react/no-array-index-key
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                      textTransform: "capitalize",
                      "& .MuiTouchRipple-root": {
                        display: "none",
                      },
                    }}
                  >
                    {page.name === "Why Synerge" ? (
                      <a
                        href="https://www.synerge.io/why-synerge"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontSize: "18px",
                          fontWeight: 600,
                          // fontSize: "1rem",
                          // fontWeight: "bold",
                        }}
                        to={`${page.link}`}
                        activeStyle={{
                          color: "red",
                        }}
                      >
                        {page.name}
                      </a>
                    ) : (
                      <NavLink
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontSize: "18px",
                          fontWeight: 600,
                          // fontSize: "1rem",
                          // fontWeight: "bold",
                        }}
                        to={`${page.link}`}
                        activeStyle={{
                          color: "red",
                        }}
                      >
                        {page.name}
                      </NavLink>
                    )}
                  </Button>
                ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            {data ? (
              <>
                <Tooltip title="Sign Out">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 5 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={data && data.profile_Picture_Url}
                      sx={{ bgcolor: "#f44335" }}
                    >
                      {data && data.profile_Picture_Url
                        ? ""
                        : data && data.name && data.name !== null
                        ? data.name.charAt(0).toUpperCase()
                        : data.given_Name && data.given_Name !== null
                        ? data.given_Name.charAt(0).toUpperCase()
                        : "U"}
                    </Avatar>
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      paddingTop: "40px",
                      paddingBottom: "40px",
                      paddingRight: "60px",
                      borderRadius: "10px",
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {/* <MenuItem>
                    <Avatar /> Profile
                  </MenuItem> */}
                  <MenuItem
                    onClick={onLogOutClick}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#ffffff",
                      },
                    }}
                  >
                    <ListItemIcon>
                      <MDBox
                        sx={{
                          borderRadius: "30px",
                          background: "#051931",
                          display: "flex",
                          height: " 40px",
                          width: "40px",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: 3,
                        }}
                      >
                        <Logout color="white" fontSize="small" />
                      </MDBox>
                    </ListItemIcon>
                    <MDTypography fontWeight="bold" variant="h6">
                      Sign Out
                    </MDTypography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <MDButton
                  color="error"
                  onClick={onSignUpClick}
                  sx={{
                    px: 4,
                    textTransform: "capitalize",
                    width: "9rem",
                    boxShadow: "0px 0px",
                    marginRight: "15px",
                  }}
                  size="large"
                  circular
                >
                  Sign Up
                </MDButton>

                <MDButton
                  color="dark"
                  onClick={onSignInClick}
                  sx={{ px: 4, textTransform: "capitalize", width: "9rem", boxShadow: "0px 0px" }}
                  size="large"
                  circular
                >
                  Sign In
                </MDButton>
              </>
            )}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {data &&
                settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
ResponsiveAppBar.propTypes = {
  routes: PropTypes.array,
};
