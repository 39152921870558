import React from "react";
import { Box, Tab, Container } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { makeStyles } from "@mui/styles";
// import colors from "assets/theme/base/colors";
import BreadCrumb from "views/Common/BreadCrumb";
import IdentityVerification from "./IdentityVerification";
import LanguageVerification from "./LanguageVerification";
import SkillVerification from "./SkillVerification";
import ContactInformation from "./ContactInformation";
import ReferenceCheck from "./ReferenceCheck";

const styles = {
  tabs: {
    background: "none",
    borderRadius: "unset",
    // "& button": {
    //   background: "red",
    //   backgroundColor: "red",
    //   color: "green",
    // },
    "& .MuiButtonBase-root-MuiTab-root.Mui-selected": {
      backgroundColor: "red",
    },
  },
  tab: {
    fontFamily: "Hurme Geometric Sans 3",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "152.2%",
  },
};

const useStyles = makeStyles(styles);

const index = () => {
  const classes = useStyles();

  const [selectedTab, setTab] = React.useState("languageVerification");

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  // const { error } = colors;

  return (
    <Container maxWidth="xxl" sx={{ width: { md: "95%" }, margin: "auto" }}>
      <BreadCrumb childRoute="Verify Profile" />
      <TabContext value={selectedTab}>
        {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}> */}
        <Box>
          <TabList
            sx={{
              "& .Mui-selected": {
                color: "#DE1921 !important",
                // background: "red",
              },
              "& .MuiButtonBase-root-MuiTab-root": {
                display: "none",
              },
            }}
            className={classes.tabs}
            onChange={handleChange}
            aria-label="expert settings"
            // textColor="inherit"
            // indicatorColor="secondary"
            // indicatorColor={{ style: { color: error.main } }}
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <Tab
              label="Identity Verification"
              value="identityVerification"
              disableRipple
              className={classes.tab}
            />
            <Tab
              label="Language Verification"
              value="languageVerification"
              disableRipple
              className={classes.tab}
            />
            <Tab
              label="Skill Verification"
              value="skillVerification"
              disableRipple
              className={classes.tab}
            />
            <Tab
              label="Contact Information"
              value="contactInformation"
              disableRipple
              className={classes.tab}
            />
            <Tab
              label="Reference Check"
              value="referenceCheck"
              disableRipple
              className={classes.tab}
            />
          </TabList>
        </Box>
        <TabPanel value="identityVerification">
          <IdentityVerification />
        </TabPanel>
        <TabPanel value="languageVerification">
          <LanguageVerification />
        </TabPanel>
        <TabPanel value="skillVerification">
          <SkillVerification />
        </TabPanel>
        <TabPanel value="contactInformation">
          <ContactInformation />
        </TabPanel>
        <TabPanel value="referenceCheck">
          <ReferenceCheck />
        </TabPanel>
      </TabContext>
    </Container>
  );
};

export default index;
