import React from "react";
import PropTypes from "prop-types";
import { get } from "axios-setup";
import { makeStyles } from "@mui/styles";
import { Grid, Divider } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LeadDetail from "views/Common/LeadDetail";
import LeadDetailStatic from "views/Common/LeadDetailStatic";
import EmailTemplate from "views/Common/EmailTemplate";
import MDBox from "components/MDBox";
import SimpleBar from "simplebar-react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import Portal from "@mui/material/Portal";
// import SendIcon from "@mui/icons-material/Send";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MenuItem from "@mui/material/MenuItem";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import GroupRemoveRoundedIcon from "@mui/icons-material/GroupRemoveRounded";
import CircleIcon from "@mui/icons-material/Circle";
import Loader from "views/Common/Loader";
import { submitRefundRequest, postEmailOrCallActivity, postNotes } from "constants/apiCalls";
import MDInputDatePicker from "components/MDDatePicker";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import EmailedClient from "./EmailedClient";
import CalledClient from "./CalledClient";
import PurchasedLeadNotes from "./PurchasedLeadNotes";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/MDDialog";

const PurchasedLeadDetails = ({ purchased_lead_id }) => {
  const [detailedLoading, setDetailedLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [miniloading, setMiniloading] = React.useState(false);
  const [isActive, setActive] = React.useState(false);
  const [isActive2, setActive2] = React.useState(true);
  const [isActive3, setActive3] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [showHideEmail, setShowHideEmail] = React.useState(false);
  const [showCall, setShowCall] = React.useState(false);
  const [showEmaill, setShowEmaill] = React.useState(false);
  const [filter, setShowFilter] = React.useState(false);
  const [reason1, setReason1] = React.useState(false);
  const [reason2, setReason2] = React.useState(false);
  const [reason3, setReason3] = React.useState(false);
  const [callAvtivityLoader, setCallAvtivityLoader] = React.useState(false);
  const [notesActivityLoader, setNotesActivityLoader] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [notesValue, setNotesValue] = React.useState("");
  const [dateValue, setDateValue] = React.useState("");
  const [timeValue, setTimeValue] = React.useState("");
  const [statas, setStatus] = React.useState("Available");
  const [commentValue, setCommentValue] = React.useState("");
  const [snackMessage, setSnackMessage] = React.useState("");
  const [purchasedLeadDetail, setPurchasedLeadDetail] = React.useState([]);
  const [purchasedActivity, setPurchasedActivity] = React.useState([]);
  const [purchasedNotes, setPurchasedNotes] = React.useState([]);
  const [staticQuestions, setStaticQuestions] = React.useState({
    budgetType: "",
    fixedPriceBudget: 0,
    hourlyBudget: Object(),
    projectDuration: "",
    projectType: "",
    startTime: "",
  });

  const statuses = [
    {
      value: "Available",
      label: "Available",
    },
    {
      value: "Unavailable",
      label: "Unavailable",
    },
  ];

  const handleEmailShow = () => {
    setShowHideEmail(!showHideEmail);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCallOpen = () => {
    setShowCall(true);
  };

  const handleCallClose = () => {
    setShowCall(false);
  };

  const handleEmailOpen = () => {
    setShowEmaill(true);
  };

  const handleEmailClose = () => {
    setShowEmaill(false);
  };

  const handleFilterOpen = () => {
    setShowFilter(true);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
    setReason1(false);
    setReason2(false);
    setReason3(false);
    setReason("");
  };

  const clearAllFields = () => {
    setDateValue("");
    setTimeValue("");
    setCommentValue("");
    setNotesValue("");
    setReason1(false);
    setReason2(false);
    setReason3(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const getPurchasedLeadsDetails = async () => {
    setLoading(true);
    setDetailedLoading(true);
    const requestOptions = {
      isAuth: true,
      headers: {
        "Content-Type": "application/json",
      },
      endPoint: `users/me/purchased_leads/${
        purchased_lead_id && purchased_lead_id.length > 0 ? purchased_lead_id : ""
      }`,
    };
    try {
      const res = await get(requestOptions);
      setPurchasedLeadDetail(res.data);
      setStaticQuestions({
        budgetType: res.data.budget_type,
        fixedPriceBudget: res.data.fixed_price_budget,
        hourlyBudget: res.data.hourly_budget,
        projectDuration: res.data.project_duration,
        projectType: res.data.project_type,
        startTime: res.data.start_time,
      });
    } catch (error) {
      console.log("client error", error);
    }
    setLoading(false);
    setDetailedLoading(false);
  };

  const postCallActivity = async () => {
    const dateTime = new Date();
    setCallAvtivityLoader(true);

    const year = new Date(dateValue).getFullYear();
    const month = new Date(dateValue).getMonth();
    const datee = new Date(dateValue).getDate();
    const hour = parseInt(timeValue.split(":")[0], 10);
    const minutes = parseInt(timeValue.split(":")[1], 10);
    const dateTimeString = new Date(
      new Date(
        new Date(
          new Date(new Date(dateTime.setDate(datee)).setFullYear(year)).setMonth(month)
        ).setHours(hour)
      ).setMinutes(minutes)
    ).toISOString();
    const activity_type = "call";
    const email_details = {
      email_time: dateTimeString,
      comments: "N/A",
    };
    const call_details = {
      call_time: dateTimeString,
      status: statas,
      comments: commentValue,
    };
    const titleObject = `Called ${
      purchasedLeadDetail.posted_by.name ? purchasedLeadDetail.posted_by.name : "N/A"
    }`;

    const res = await postEmailOrCallActivity(
      purchased_lead_id,
      activity_type,
      email_details,
      call_details,
      titleObject
    );
    if (res.status === 200) {
      setCallAvtivityLoader(false);
      setSnackMessage("Submitted successfully");
    } else {
      setCallAvtivityLoader(false);
      setSnackMessage(res.title);
    }
  };

  const postEmailActivity = async () => {
    const dateTime = new Date();
    setCallAvtivityLoader(true);
    const year = new Date(dateValue).getFullYear();
    const month = new Date(dateValue).getMonth();
    const datee = new Date(dateValue).getDate();
    const hour = parseInt(timeValue.split(":")[0], 10);
    const minutes = parseInt(timeValue.split(":")[1], 10);
    const dateTimeString = new Date(
      new Date(
        new Date(
          new Date(new Date(dateTime.setDate(datee)).setFullYear(year)).setMonth(month)
        ).setHours(hour)
      ).setMinutes(minutes)
    ).toISOString();
    const activity_type = "email";
    const email_details = {
      email_time: dateTimeString,
      comments: commentValue,
    };
    const call_details = {
      call_time: dateTimeString,
      status: "N/A",
      comments: "N/A",
    };
    const titleObject = `Emailed ${
      purchasedLeadDetail.posted_by.name ? purchasedLeadDetail.posted_by.name : "N/A"
    }`;

    const res = await postEmailOrCallActivity(
      purchased_lead_id,
      activity_type,
      email_details,
      call_details,
      titleObject
    );
    if (res.status === 200) {
      setCallAvtivityLoader(false);
      setSnackMessage("Submitted successfully");
    } else {
      setCallAvtivityLoader(false);
      setSnackMessage(res.title);
    }
    // try {
    //   await post(
    //     apiParamsFunction(
    //       true,
    //       "application/json",
    //       `users/me/purchased_leads/${purchased_lead_id}/activities`,
    //       {
    //         activity_type: "email",
    //         email_details: {
    //           email_time: dateTime,
    //           comments: commentValue,
    //         },
    //         call_details: {
    //           call_time: dateTime,
    //           status: "N/A",
    //           comments: "N/A",
    //         },
    //         title: `Emailed ${
    //           purchasedLeadDetail.posted_by.name ? purchasedLeadDetail.posted_by.name : "N/A"
    //         }`,
    //         description: "Description",
    //       },
    //       ""
    //     )
    //   );

    //   setCallAvtivityLoader(false);
    //   setSnackMessage("Submitted successfully");
    // } catch (error) {
    //   console.error(error);
    //   setSnackMessage("Error submitting activity");
    //   setCallAvtivityLoader(false);
    // }
  };

  const getPurchasedLeadActivity = async () => {
    const requestOptions = {
      isAuth: true,
      headers: {
        "Content-Type": "application/json",
      },
      endPoint: `users/me/purchased_leads/${
        purchased_lead_id && purchased_lead_id.length > 0 ? purchased_lead_id : ""
      }/activities`,
      body: "",
      params: {
        page_size: 50,
        continuation_token: "",
      },
    };
    try {
      const res = await get(requestOptions);
      setPurchasedActivity(res.data.leadActivity);
    } catch (error) {
      console.log("client error", error);
    }
  };

  const getNotes = async () => {
    const requestOptions = {
      isAuth: true,
      headers: {
        "Content-Type": "application/json",
      },
      endPoint: `users/me/purchased_leads/${
        purchased_lead_id && purchased_lead_id.length > 0 ? purchased_lead_id : ""
      }/notes`,
      body: "",
      params: {
        page_size: 100,
        continuation_token: "",
      },
    };
    try {
      const res = await get(requestOptions);
      setPurchasedNotes(res.data.leadNote);
    } catch (error) {
      console.log("client error", error);
    }
  };

  const useStyles = makeStyles(() => ({
    buttonProperties: {
      color: "#4285F4",
      textTransform: "capitalize",
      fontSize: 14,
    },
    buttonProperties2: {
      textTransform: "capitalize",
      fontSize: 18,
      borderRadius: "25px",
      "@media (min-width:768px) and (max-width: 900px)": {
        padding: "0.75rem 3rem",
      },
    },
    buttonProperties4: {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "lighter",
      borderRadius: "25px",
    },
    buttonProperties3: {
      color: "black",
      textTransform: "capitalize",
      fontSize: 16,
    },
    inlineFlex: {
      display: "flex",
      alignItems: "center",
    },
    colorRed: {
      color: "red",
    },
    bgRed: {
      backgroundColor: "red",
      height: "100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "0px 10px 10px 0px",
    },
    dash_dash: {
      height: 1,
      display: "block",
      backgroundColor: "#e4e4e4",
      width: "91%",
      margin: "auto",
    },
    image: {
      borderRadius: "60%",
      marginTop: 10,
      height: 70,
      width: 70,
    },
    profileBox: {
      borderBottom: "1px solid #dddddd",
      borderRight: "1px solid #dddddd",
      padding: 15,
      borderRadius: 10,
      marginBottom: 2,
    },
    marginBot: {
      marginBottom: 20,
    },
    customReasonButton: {
      padding: 15,
      borderRadius: "10px",
      width: "80%",
      cursor: "pointer",
    },
    skipBtn: {
      width: "32%",
      textTransform: "capitalize",
      borderRadius: "30px",
      fontSize: "14px",
      letterSpacing: "1px",
      marginLeft: "30%",
      marginTop: 35,
    },
    isActive: {
      border: "3px solid red",
    },
    inputPlaceholderOther: {
      input: {
        "&::placeholder": {
          fontWeight: "bold",
          color: "red",
        },
      },
    },
  }));

  const handleDateChange = (e) => {
    setDateValue(e[0].toISOString());
  };
  const classes = useStyles();

  React.useEffect(() => {
    if (purchased_lead_id && purchased_lead_id.length > 0) {
      getPurchasedLeadsDetails();
      // getPurchasedLeadActivity();
      // getNotes();
    }
    return () => handleCloseSnack;
    // }, []);
  }, [purchased_lead_id]);
  React.useEffect(() => {
    handleCloseSnack();
  }, [purchased_lead_id]);

  if (purchasedLeadDetail.length === 0 && !detailedLoading) {
    return (
      <Grid
        item
        xs={12}
        md={6}
        xl={8}
        sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}
      >
        {/* <MDTypography textAlign="center" sx={{ pt: 15 }}>
          <b>Click any lead to see its details.. </b>
        </MDTypography> */}
        <Divider orientation="horizontal" sx={{ mx: 0 }} />
      </Grid>
    );
  }
  return (
    <>
      {showHideEmail ? (
        <Grid item xs={12}>
          <EmailTemplate
            setShowEmail={handleEmailShow}
            purchased_lead_id={purchased_lead_id}
            name={
              purchasedLeadDetail.posted_by &&
              purchasedLeadDetail.posted_by.name &&
              purchasedLeadDetail.posted_by.name.length > 0
                ? purchasedLeadDetail.posted_by.name
                : "N/A"
            }
            user_id={
              purchasedLeadDetail.posted_by &&
              purchasedLeadDetail.posted_by.id &&
              purchasedLeadDetail.posted_by.id.length > 0
                ? purchasedLeadDetail.posted_by.id
                : "N/A"
            }
          />
        </Grid>
      ) : (
        <>
          {detailedLoading ? (
            <Grid item xs={12} md={6} xl={12}>
              <Loader />
            </Grid>
          ) : (
            <>
              <Grid
                className={classes.marginBot}
                item
                xs={12}
                md={4.5}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Divider orientation="vertical" sx={{ m: 0 }} />
                {loading ? (
                  <Loader />
                ) : (
                  <MDBox
                    display="flex-column"
                    justifyContent="space-between"
                    alignItems="center"
                    px={2}
                    sx={{ pt: 6, width: "100%" }}
                    // m={{ width: "120%" }}
                  >
                    <MDTypography variant="h3" sx={{ pb: 2 }}>
                      {purchasedLeadDetail.posted_by &&
                      purchasedLeadDetail.posted_by.name &&
                      purchasedLeadDetail.posted_by.name.length > 0
                        ? purchasedLeadDetail.posted_by.name
                        : "N/A"}
                    </MDTypography>
                    <MDTypography sx={{ pb: 1, fontSize: "18px" }}>
                      {purchasedLeadDetail.service &&
                      purchasedLeadDetail.service.name &&
                      purchasedLeadDetail.service.name.length > 0
                        ? purchasedLeadDetail.service.name
                        : "N/A"}
                    </MDTypography>
                    <MDTypography sx={{ pb: 1, fontSize: "18px" }}>
                      {purchasedLeadDetail.posted_by &&
                      purchasedLeadDetail.posted_by.country &&
                      purchasedLeadDetail.posted_by.country.length > 0 &&
                      purchasedLeadDetail.posted_by.city &&
                      purchasedLeadDetail.posted_by.city.length > 0
                        ? `${purchasedLeadDetail.posted_by.city},  
                      ${purchasedLeadDetail.posted_by.country}`
                        : "N/A"}
                    </MDTypography>
                    <MDBox sx={{ pt: 7 }}>
                      <MDTypography
                        // className={classes.inlineFlex}
                        variant="h5"
                        fontWeight="light"
                        sx={{ pb: 2 }}
                      >
                        <PhoneIcon />
                        &nbsp;&nbsp;
                        {/* <a
                            target="_blank"
                            href={`tel:${purchasedLeadDetail.posted_by.phone}`}
                            rel="noopener noreferrer"
                            style={{ color: "black", textDecoration: "none" }}
                          > */}
                        {purchasedLeadDetail.posted_by &&
                        purchasedLeadDetail.posted_by.phone &&
                        purchasedLeadDetail.posted_by.phone.length > 0
                          ? formatPhoneNumberIntl(purchasedLeadDetail.posted_by.phone)
                          : "N/A"}
                        {/* </a> */}
                      </MDTypography>
                      <MDTypography
                        // className={classes.inlineFlex}
                        variant="h5"
                        fontWeight="light"
                        sx={{ pb: 2 }}
                      >
                        <EmailIcon />
                        &nbsp;&nbsp;
                        <a
                          target="_blank"
                          href={`mailto:${purchasedLeadDetail.posted_by.email}`}
                          rel="noopener noreferrer"
                          style={{ color: "red", textDecoration: "underline" }}
                        >
                          {purchasedLeadDetail.posted_by &&
                          purchasedLeadDetail.posted_by.email &&
                          purchasedLeadDetail.posted_by.email.length > 0
                            ? purchasedLeadDetail.posted_by.email
                            : "N/A"}
                        </a>
                      </MDTypography>
                      {/* {purchasedLeadDetail.lead_status === "pending" ? (
                    <MDTypography
                      className={`${classes.inlineFlex}`}
                      variant="h6"
                      fontWeight="light"
                      sx={{ pb: 2 }}
                    >
                      <SendIcon />
                      <MDButton className={`${classes.buttonProperties}`}>Send Estimate</MDButton>
                    </MDTypography>
                  ) : (
                    ""
                  )} */}
                    </MDBox>
                    <MDBox sx={{ pt: 5, display: "flex", justifyContent: "space-evenly" }}>
                      <MDButton
                        className={`${classes.buttonProperties2}`}
                        color="dark"
                        size="large"
                        sx={{ mr: { lg: 5 } }}
                        onClick={() => handleEmailOpen()}
                      >
                        Email
                      </MDButton>
                      <MDButton
                        onClick={() => {
                          handleCallOpen();
                        }}
                        className={`${classes.buttonProperties2}`}
                        color="error"
                        size="large"
                      >
                        Call
                      </MDButton>

                      <BootstrapDialog
                        onClose={handleCallClose}
                        aria-labelledby="customized-dialog-title"
                        open={showCall}
                      >
                        <MDBox sx={{ width: { md: "545px", xs: "100%" } }}>
                          <BootstrapDialogTitle
                            id="customized-dialog-title"
                            onClose={handleCallClose}
                          />
                          <DialogContent
                            dividers
                            sx={{
                              borderTop: "transparent",
                              borderBottom: "transparent",
                            }}
                          >
                            <MDBox sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                              <PhoneIcon
                                sx={{
                                  width: "3rem",
                                  height: "3rem",
                                  margin: "auto",
                                }}
                              />
                            </MDBox>

                            <MDTypography variant="h4" sx={{ pb: 2, textAlign: "center" }}>
                              Call&nbsp;
                              {purchasedLeadDetail.posted_by &&
                              purchasedLeadDetail.posted_by.name &&
                              purchasedLeadDetail.posted_by.name.length > 0
                                ? purchasedLeadDetail.posted_by.name
                                : "N/A"}
                            </MDTypography>
                            <MDTypography
                              variant="h5"
                              sx={{ pb: 3, textAlign: "center" }}
                              fontWeight="light"
                            >
                              {purchasedLeadDetail.posted_by &&
                              purchasedLeadDetail.posted_by.phone &&
                              purchasedLeadDetail.posted_by.phone.length > 0
                                ? formatPhoneNumberIntl(purchasedLeadDetail.posted_by.phone)
                                : "N/A"}
                            </MDTypography>
                            <MDTypography variant="h6" sx={{ pb: 3, textAlign: "center" }}>
                              If you have called, please enter details
                            </MDTypography>
                            {callAvtivityLoader ? (
                              <MDBox>
                                <Loader />
                              </MDBox>
                            ) : (
                              <>
                                <MDBox
                                  display="flex"
                                  justifyContent="space-between"
                                  sx={{
                                    width: { xs: "85%", md: "85%" },
                                    flexDirection: { xs: "column", md: "row" },
                                    alignItems: { xs: "center" },
                                    margin: "auto",
                                  }}
                                >
                                  <MDBox sx={{ mr: 2, mt: 1, width: { xs: "82%", md: "auto" } }}>
                                    <MDTypography
                                      sx={{
                                        // mt: { xs: 1, sm: 1 },
                                        mb: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                      variant="h6"
                                    >
                                      <CalendarTodayIcon />
                                      &nbsp;Date
                                    </MDTypography>
                                    {/* <TextField
                                    type="date"
                                    sx={{ width: { xs: "100%" }, mt: 1 }}
                                    onChange={(e) => {
                                      setDateValue(e.target.value);
                                    }}
                                  /> */}
                                    <MDInputDatePicker
                                      input={{ placeholder: "Select a date" }}
                                      options={{
                                        maxDate: "today",
                                        static: "true",
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        dateFormat: "Y-m-d",
                                      }}
                                      sx={{ width: { xs: "100%" }, mt: 2 }}
                                      onChange={(e) => handleDateChange(e)}
                                    />
                                  </MDBox>
                                  <MDBox sx={{ mr: 1, width: { xs: "82%", md: "auto" } }}>
                                    <MDTypography
                                      sx={{
                                        mt: { xs: 1, sm: 1 },
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        margin: "auto",
                                      }}
                                      variant="h6"
                                    >
                                      <AccessTimeFilledIcon />
                                      &nbsp;Time
                                    </MDTypography>
                                    <TextField
                                      sx={{ width: { xs: "100%" }, mt: 1 }}
                                      type="time"
                                      color="error"
                                      onChange={(e) => {
                                        setTimeValue(e.target.value);
                                      }}
                                      onClick={(e) => {
                                        setTimeValue(e.target.value);
                                      }}
                                    />
                                  </MDBox>
                                  <MDBox>
                                    <MDTypography
                                      sx={{
                                        mt: { xs: 1, sm: 1 },
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        margin: "auto",
                                      }}
                                      variant="h6"
                                    >
                                      <CircleIcon />
                                      &nbsp;Status
                                    </MDTypography>
                                    <TextField
                                      select
                                      value={statas}
                                      onChange={handleChange}
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      sx={{
                                        mt: 1,
                                        padding: "0.75rem",
                                        position: "relative",
                                        height: "1rem",
                                        border: "1px solid lightgray",
                                        borderRadius: "0.375rem",
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          border:
                                            statas === "Unavailable"
                                              ? "2px solid red !important"
                                              : "2px solid green !important",
                                          "&.Mui-selected": {
                                            outline: "none",
                                          },
                                        },
                                      }}
                                    >
                                      {statuses.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          <span>{option.label}</span>
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </MDBox>
                                </MDBox>
                                <MDBox sx={{ width: "85%", margin: "auto" }}>
                                  <MDTypography
                                    sx={{ display: "flex", alignItems: "center", mb: 1, mt: 2 }}
                                    variant="h6"
                                  >
                                    <ChatBubbleIcon />
                                    &nbsp;Comments
                                  </MDTypography>
                                  <TextField
                                    sx={{ width: "100%", mt: 1, margin: "auto" }}
                                    label="Comments"
                                    multiline
                                    rows={4}
                                    onChange={(e) => {
                                      setCommentValue(e.target.value);
                                    }}
                                  />
                                </MDBox>
                              </>
                            )}
                          </DialogContent>
                          <DialogActions sx={{ justifyContent: "center" }}>
                            <MDBox
                              sx={{
                                display: { xs: "flex", md: "flex" },
                                justifyContent: { md: "space-evenly", xs: "center" },
                                flexDirection: { xs: "column", md: "row" },
                                my: 3,
                              }}
                            >
                              <MDButton
                                className={`${classes.buttonProperties4}`}
                                color="dark"
                                size="large"
                                sx={{
                                  marginTop: { xs: "10px", md: 0 },
                                  order: { xs: 2, md: 1 },
                                }}
                                onClick={handleCallClose}
                              >
                                Cancel
                              </MDButton>
                              <MDButton
                                className={`${classes.buttonProperties4}`}
                                sx={{
                                  ml: { xs: 0, md: 4, sm: 4 },
                                  width: { xs: "100%", md: "auto" },
                                  order: { xs: 1, md: 1 },
                                }}
                                color="error"
                                size="large"
                                onClick={async () => {
                                  if (
                                    dateValue &&
                                    timeValue &&
                                    statas &&
                                    // commentValue &&
                                    dateValue.length > 0 &&
                                    timeValue.length > 0
                                    // commentValue.length > 0
                                  ) {
                                    await postCallActivity();
                                    getPurchasedLeadActivity();
                                    handleCallClose();
                                    handleClickSnack();
                                    setActive(true);
                                    setActive2(false);
                                    setActive3(false);
                                    clearAllFields();
                                  } else {
                                    setSnackMessage("Please fill all fields");
                                    handleClickSnack();
                                  }
                                }}
                              >
                                Done
                              </MDButton>
                            </MDBox>
                          </DialogActions>
                        </MDBox>

                        <Snackbar
                          open={openSnack}
                          autoHideDuration={2000}
                          action={action}
                          anchorOrigin={{ vertical: "top", horizontal: "center" }}
                          onClose={handleCloseSnack}
                        >
                          <Alert
                            onClose={handleCloseSnack}
                            severity={
                              snackMessage === "Submitted successfully" ? "success" : "error"
                            }
                            sx={{ width: "100%" }}
                          >
                            {snackMessage}
                          </Alert>
                        </Snackbar>
                      </BootstrapDialog>

                      <BootstrapDialog
                        onClose={handleEmailClose}
                        aria-labelledby="customized-dialog-title"
                        open={showEmaill}
                      >
                        <MDBox sx={{ width: { md: "545px", xs: "100%" } }}>
                          <BootstrapDialogTitle
                            id="customized-dialog-title"
                            onClose={handleEmailClose}
                          />
                          <DialogContent
                            dividers
                            sx={{
                              borderTop: "transparent",
                              borderBottom: "transparent",
                            }}
                          >
                            <MDBox sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                              <EmailIcon
                                sx={{
                                  width: "3rem",
                                  height: "3rem",
                                  margin: "auto",
                                }}
                              />
                            </MDBox>

                            <MDTypography variant="h4" sx={{ pb: 2, textAlign: "center" }}>
                              Email&nbsp;
                              {purchasedLeadDetail.posted_by &&
                              purchasedLeadDetail.posted_by.name &&
                              purchasedLeadDetail.posted_by.name.length > 0
                                ? purchasedLeadDetail.posted_by.name
                                : "N/A"}
                            </MDTypography>
                            <MDTypography
                              variant="h5"
                              sx={{ pb: 3, textAlign: "center" }}
                              fontWeight="light"
                            >
                              {purchasedLeadDetail.posted_by &&
                              purchasedLeadDetail.posted_by.email &&
                              purchasedLeadDetail.posted_by.email.length > 0
                                ? purchasedLeadDetail.posted_by.email
                                : "N/A"}
                            </MDTypography>
                            <MDTypography variant="h6" sx={{ pb: 3, textAlign: "center" }}>
                              If you have emailed, please enter details
                            </MDTypography>
                            {callAvtivityLoader ? (
                              <MDBox>
                                <Loader />
                              </MDBox>
                            ) : (
                              <>
                                <MDBox
                                  display="flex"
                                  justifyContent="space-between"
                                  sx={{
                                    width: { xs: "85%", md: "85%" },
                                    flexDirection: { xs: "column", md: "row" },
                                    alignItems: { xs: "center" },
                                    margin: "auto",
                                  }}
                                >
                                  <MDBox sx={{ mr: 2, mt: 1, width: { xs: "82%", md: "auto" } }}>
                                    <MDTypography
                                      sx={{
                                        // mt: { xs: 1, sm: 1 },
                                        mb: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                      variant="h6"
                                    >
                                      <CalendarTodayIcon />
                                      &nbsp;Date
                                    </MDTypography>
                                    {/* <TextField
                                    type="date"
                                    sx={{ width: { xs: "100%" }, mt: 1 }}
                                    onChange={(e) => {
                                      setDateValue(e.target.value);
                                    }}
                                  /> */}
                                    <MDInputDatePicker
                                      input={{ placeholder: "Select a date" }}
                                      options={{
                                        maxDate: "today",
                                        static: "true",
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        dateFormat: "Y-m-d",
                                      }}
                                      sx={{ width: { xs: "100%" }, mt: 2 }}
                                      onChange={(e) => handleDateChange(e)}
                                    />
                                  </MDBox>
                                  <MDBox sx={{ mr: 1, width: { xs: "82%", md: "auto" } }}>
                                    <MDTypography
                                      sx={{
                                        mt: { xs: 1, sm: 1 },
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        margin: "auto",
                                      }}
                                      variant="h6"
                                    >
                                      <AccessTimeFilledIcon />
                                      &nbsp;Time
                                    </MDTypography>
                                    <TextField
                                      sx={{ width: { xs: "100%" }, mt: 1 }}
                                      type="time"
                                      color="error"
                                      onChange={(e) => {
                                        setTimeValue(e.target.value);
                                      }}
                                    />
                                  </MDBox>
                                </MDBox>
                                <MDBox sx={{ width: "85%", margin: "auto" }}>
                                  <MDTypography
                                    sx={{ display: "flex", alignItems: "center", mb: 1, mt: 2 }}
                                    variant="h6"
                                  >
                                    <ChatBubbleIcon />
                                    &nbsp;Comments
                                  </MDTypography>
                                  <TextField
                                    sx={{ width: "100%", mt: 1, margin: "auto" }}
                                    label="Comments"
                                    multiline
                                    rows={4}
                                    onChange={(e) => {
                                      setCommentValue(e.target.value);
                                    }}
                                  />
                                </MDBox>
                              </>
                            )}
                          </DialogContent>
                          <DialogActions sx={{ justifyContent: "center" }}>
                            <MDBox
                              sx={{
                                display: { xs: "flex", md: "flex" },
                                justifyContent: { md: "space-evenly", xs: "center" },
                                flexDirection: { xs: "column", md: "row" },
                                my: 3,
                              }}
                            >
                              <MDButton
                                className={`${classes.buttonProperties4}`}
                                color="dark"
                                size="large"
                                sx={{
                                  marginTop: { xs: "10px", md: 0 },
                                  order: { xs: 2, md: 1 },
                                }}
                                onClick={handleEmailClose}
                              >
                                Cancel
                              </MDButton>
                              <MDButton
                                className={`${classes.buttonProperties4}`}
                                sx={{
                                  ml: { xs: 0, md: 4, sm: 4 },
                                  width: { xs: "100%", md: "auto" },
                                  order: { xs: 1, md: 1 },
                                }}
                                color="error"
                                size="large"
                                onClick={async () => {
                                  if (
                                    dateValue &&
                                    timeValue &&
                                    statas &&
                                    // commentValue &&
                                    dateValue.length > 0 &&
                                    timeValue.length > 0
                                  ) {
                                    await postEmailActivity();
                                    getPurchasedLeadActivity();
                                    handleEmailClose();
                                    handleClickSnack();
                                    setActive(true);
                                    setActive2(false);
                                    setActive3(false);
                                    clearAllFields();
                                  } else {
                                    setSnackMessage("Please fill all fields");
                                    handleClickSnack();
                                  }
                                }}
                              >
                                Done
                              </MDButton>
                            </MDBox>
                          </DialogActions>
                        </MDBox>

                        <Snackbar
                          open={openSnack}
                          autoHideDuration={6000}
                          action={action}
                          anchorOrigin={{ vertical: "top", horizontal: "center" }}
                          onClose={handleCloseSnack}
                        >
                          <Alert
                            onClose={handleCloseSnack}
                            severity={
                              snackMessage === "Submitted successfully" ? "success" : "error"
                            }
                            sx={{ width: "100%" }}
                          >
                            {snackMessage}
                          </Alert>
                        </Snackbar>
                      </BootstrapDialog>

                      <Snackbar
                        open={openSnack}
                        autoHideDuration={6000}
                        action={action}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        onClose={handleCloseSnack}
                      >
                        <Alert
                          onClose={handleCloseSnack}
                          severity={snackMessage === "Submitted successfully" ? "success" : "error"}
                          sx={{ width: "100%" }}
                        >
                          {snackMessage}
                        </Alert>
                      </Snackbar>
                    </MDBox>
                  </MDBox>
                )}
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} md={4} pr={4}>
                <MDBox
                  className={classes.marginBot}
                  display="flex"
                  alignItems="start"
                  alignContent="start"
                  justifyContent="end"
                  sx={{ pt: 3 }}
                >
                  <MDButton
                    sx={{
                      cursor: "pointer",
                      color: "#4285F4",
                      textTransform: "capitalize",
                      fontSize: 14,
                      padding: 0,
                      marginTop: "2%",
                    }}
                    onClick={handleFilterOpen}
                  >
                    Request Refund
                  </MDButton>
                  {/* <Grid xs={12}>
                      <MDTypography
                        className={classes.buttonProperties}
                        sx={{
                          padding: "15px",
                          textAlign: "end",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={handleFilterOpen}
                      >
                        Request Refund
                      </MDTypography>
                    </Grid> */}
                  <BootstrapDialog
                    onClose={handleFilterClose}
                    aria-labelledby="customized-dialog-title"
                    open={filter}
                  >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleFilterClose}>
                      <MDTypography
                        textAlign="center"
                        fontWeight="800"
                        variant="h3"
                        sx={{ mt: 2, px: { md: 6, xs: 1 } }}
                      >
                        Reason for requesting refund?
                      </MDTypography>
                      <MDTypography
                        textAlign="center"
                        fontWeight="bold"
                        variant="h6"
                        sx={{ color: "darkGray", mt: 2 }}
                      >
                        This helps us to know the reason for refund
                      </MDTypography>
                      {miniloading ? (
                        <Loader />
                      ) : (
                        <MDBox sx={{ pl: 8, py: 3 }}>
                          <MDBox
                            display="flex"
                            alignItems="center"
                            className={`${classes.customReasonButton} ${
                              reason1 ? classes.isActive : ""
                            }`}
                            onClick={() => {
                              setReason1(true);
                              setReason2(false);
                              setReason3(false);
                              setReason("Lead is not genuine");
                            }}
                          >
                            <DoNotDisturbOnIcon sx={{ mr: 2, fontSize: 100 }} />
                            <MDTypography variant="h6" fontWeight="normal">
                              Lead is not genuine
                            </MDTypography>
                          </MDBox>
                          <MDBox
                            display="flex"
                            alignItems="center"
                            className={`${classes.customReasonButton} ${
                              reason2 ? classes.isActive : ""
                            }`}
                            onClick={() => {
                              setReason1(false);
                              setReason2(true);
                              setReason3(false);
                              setReason("Email is not valid");
                            }}
                          >
                            <EmailIcon sx={{ mr: 2, fontSize: 100 }} />
                            <MDTypography variant="h6" fontWeight="normal">
                              Email is invalid
                            </MDTypography>
                          </MDBox>
                          <MDBox
                            display="flex"
                            alignItems="center"
                            className={`${classes.customReasonButton} ${
                              reason3 ? classes.isActive : ""
                            }`}
                            onClick={() => {
                              setReason1(false);
                              setReason2(false);
                              setReason3(true);
                              setReason("Phone number is not valid");
                            }}
                          >
                            <GroupRemoveRoundedIcon sx={{ mr: 2, fontSize: 100 }} />
                            <MDTypography variant="h6" fontWeight="normal">
                              Phone number is not valid
                            </MDTypography>
                          </MDBox>
                          <MDBox sx={{ pl: 5, mt: 3 }}>
                            <MDTypography variant="h6" sx={{ mb: 2 }} fontWeight="bold">
                              Other
                            </MDTypography>
                            <MDInput
                              sx={{
                                width: "75%",

                                label: { color: "blue", fontWeight: "bold", fontSize: "17px" },
                              }}
                              placeholder="Other (please specify)"
                              onChange={(e) => setReason(e.target.value)}
                              onClick={() => {
                                setReason1(false);
                                setReason2(false);
                                setReason3(false);
                              }}
                            />
                          </MDBox>
                          <MDButton
                            className={classes.skipBtn}
                            color="error"
                            onClick={async () => {
                              if (reason === "") {
                                setOpenSnack(true);
                                setSnackMessage("Please select a reason");
                              } else {
                                setMiniloading(true);
                                const re = await submitRefundRequest(
                                  purchased_lead_id,
                                  reason,
                                  commentValue
                                );
                                if (re.status === 200) {
                                  setSnackMessage("Submitted successfully");
                                } else {
                                  setSnackMessage(re.messageDetail);
                                }
                                setMiniloading(false);
                                handleFilterClose();
                                handleClickSnack();

                                // leadUpdating();
                              }
                            }}
                          >
                            Submit
                          </MDButton>
                        </MDBox>
                      )}
                    </BootstrapDialogTitle>
                  </BootstrapDialog>
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="start"
                  alignContent="start"
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <MDButton
                    className={`${classes.buttonProperties3} ${isActive2 ? classes.colorRed : ""}`}
                    onClick={() => {
                      setActive(false);
                      setActive2(true);
                      setActive3(false);
                    }}
                  >
                    Lead Details
                  </MDButton>
                  <MDButton
                    className={`${classes.buttonProperties3} ${isActive ? classes.colorRed : ""}`}
                    onClick={() => {
                      setActive(true);
                      setActive2(false);
                      setActive3(false);
                      getPurchasedLeadActivity();
                    }}
                  >
                    Activity
                  </MDButton>
                  <MDButton
                    className={`${classes.buttonProperties3} ${isActive3 ? classes.colorRed : ""}`}
                    onClick={() => {
                      setActive(false);
                      setActive2(false);
                      setActive3(true);
                      getNotes();
                    }}
                  >
                    Notes
                  </MDButton>
                </MDBox>
                <span className={`${classes.dash_dash} ${classes.marginBot}`} />
                {isActive ? (
                  <MDBox
                    // <SimpleBar style={{ maxHeight: "80vh" }} className="scroll" autoHide={false}>
                    sx={{
                      position: "relative",
                      maxHeight: "auto",
                    }}
                  >
                    {purchasedActivity &&
                    purchasedActivity.length > 0 &&
                    purchasedActivity.sort((a, b) => new Date(b.created) - new Date(a.created)) ? (
                      <MDBox sx={{ p: 1 }}>
                        {purchasedActivity.map((x) => (
                          <>
                            {x && x.activity_Type && x.activity_Type === "call" ? (
                              <MDBox>
                                <CalledClient callActivity={x} clientPhone={purchasedLeadDetail} />
                              </MDBox>
                            ) : (
                              <MDBox>
                                <EmailedClient
                                  emailActivity={x}
                                  clientEmail={purchasedLeadDetail}
                                />
                              </MDBox>
                            )}
                          </>
                        ))}
                      </MDBox>
                    ) : (
                      <MDBox display="flex" justifyContent="center" sx={{ p: 2 }}>
                        <MDTypography variant="h6">No activities found</MDTypography>
                      </MDBox>
                    )}
                    {/* </SimpleBar> */}
                  </MDBox>
                ) : (
                  ""
                )}
                {isActive2 ? (
                  <MDBox sx={{ p: 1 }}>
                    <SimpleBar style={{ maxHeight: "80vh" }} autoHide={false} />
                    <LeadDetail
                      title="Lead Details"
                      service_questions_and_answers={
                        purchasedLeadDetail.service_questions_and_answers &&
                        purchasedLeadDetail.service_questions_and_answers.length >= 0
                          ? purchasedLeadDetail.service_questions_and_answers
                          : ""
                      }
                      shadow={false}
                      lead_description={
                        purchasedLeadDetail.description &&
                        purchasedLeadDetail.description.length > 0
                          ? purchasedLeadDetail.description
                          : ""
                      }
                    />

                    <LeadDetailStatic staticQuestions={staticQuestions} />
                  </MDBox>
                ) : (
                  ""
                )}
                {isActive3 ? (
                  <MDBox direction="column" sx={{ p: 1 }}>
                    {/* <SimpleBar style={{ maxHeight: "80vh" }} className="scroll" autoHide={false}> */}
                    <MDBox
                      sx={{
                        position: "relative",
                        maxHeight: "79vh",
                      }}
                    >
                      <MDBox display="flex" mb={2}>
                        {/* <ChatBubbleIcon sx={{ pr: 1 }}  /> */}
                        <svg
                          width="17"
                          height="20"
                          viewBox="0 0 17 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.61538 0H14.3846C15.8231 0 17 1.125 17 2.5V11.25C17 12.625 15.8231 13.75 14.3846 13.75H11.7692L5.23077 20V13.75H2.61538C1.17692 13.75 0 12.625 0 11.25V2.5C0 1.125 1.17692 0 2.61538 0Z"
                            fill="#051931"
                          />
                        </svg>
                        <MDTypography
                          variant="h6"
                          sx={{
                            pl: 1,
                            fontSize: "16px",
                            fontWeight: "600",
                            lineHeight: "20px",
                            color: "#051931",
                          }}
                        >
                          Notes
                        </MDTypography>
                      </MDBox>
                      {notesActivityLoader ? (
                        <Loader />
                      ) : (
                        <TextField
                          sx={{ width: "100%", pb: 2, mb: 3 }}
                          id="outlined-multiline-static"
                          label="Add Notes"
                          multiline
                          rows={5}
                          onChange={(e) => {
                            setNotesValue(e.target.value);
                          }}
                        />
                      )}
                      <MDBox display="flex" justifyContent="end">
                        <MDButton
                          color="error"
                          sx={{
                            textTransform: "capitalize",
                            fontSize: 15,
                            mb: 3,
                            borderRadius: "30px",
                          }}
                          onClick={async () => {
                            if (notesValue && notesValue.length > 0) {
                              setNotesActivityLoader(true);
                              const res = await postNotes(purchased_lead_id, notesValue);
                              if (res.status === 200) {
                                setNotesActivityLoader(false);
                                setSnackMessage("Submitted successfully");
                              } else {
                                setSnackMessage(res.title);
                                setNotesActivityLoader(false);
                              }
                            } else {
                              setSnackMessage("Please fill the field");
                            }
                            handleClickSnack();
                            getNotes();
                            clearAllFields();
                          }}
                        >
                          Submit
                        </MDButton>
                      </MDBox>
                      <MDBox sx={{ mt: 2 }}>
                        <SimpleBar
                          style={{ xs: { maxHeight: "100vh" }, maxHeight: "30vh" }}
                          className="scroll"
                          autoHide={false}
                        >
                          {purchasedNotes &&
                          purchasedNotes.length > 0 &&
                          purchasedNotes.sort((a, b) => new Date(b.created) - new Date(a.created))
                            ? purchasedNotes.map((x) => <PurchasedLeadNotes leadNotes={x} />)
                            : ""}
                        </SimpleBar>
                      </MDBox>
                    </MDBox>
                    {/* <SimpleBar style={{ maxHeight: "80vh" }} className="scroll" autoHide={false}> */}
                  </MDBox>
                ) : (
                  ""
                )}
              </Grid>
              <Portal>
                <Snackbar
                  open={openSnack}
                  autoHideDuration={2000}
                  onDismiss={() => setOpenSnack(false)}
                  action={action}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  onClose={handleCloseSnack}
                >
                  <Alert
                    onClose={handleCloseSnack}
                    severity={snackMessage === "Submitted successfully" ? "success" : "error"}
                    sx={{ width: "100%" }}
                  >
                    {snackMessage}
                  </Alert>
                </Snackbar>
              </Portal>
            </>
          )}
        </>
      )}
    </>
  );
};

PurchasedLeadDetails.propTypes = {
  purchased_lead_id: PropTypes.string,
};

export default PurchasedLeadDetails;
