import React from "react";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Container, Grid, Paper, TextareaAutosize, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PaidIcon from "@mui/icons-material/Paid";
import Rating from "@mui/material/Rating";
import CircularProgress from "@mui/material/CircularProgress";
import BreadCrumb from "views/Common/BreadCrumb";
// import CircularProgres from "./CircularProgress";

const Ratings = () => {
  // const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState([
    {
      name: "communication",
      star: 0,
    },
    {
      name: "responsiveness",
      star: 0,
    },
    {
      name: "timely_delivery",
      star: 0,
    },
    {
      name: "quality_of_work",
      star: 0,
    },
    {
      name: "rating_count",
      star: 0,
    },
  ]);
  const updateValue = (e, index) => {
    const updatedAreas = [...values];
    values[index].star = e.target.value;
    setValues(updatedAreas);
  };
  // const handleChange = (index) => (e) => {
  //   // debugger;
  //   const newArr = [...values];
  //   newArr[index] = e.target.value;

  //   console.log("val:", newArr[index]);
  //   console.log("index:", index);
  //   // setValues(newArr);
  //   setValues(newArr);
  // };

  return (
    <Container>
      <BreadCrumb childRoute="Ratings" />
      {/* <CircularProgres /> */}
      <Grid container justifyContent="center" mt={4} mb={5}>
        <Grid item xs={12}>
          <Paper
            elevation={5}
            sx={{ padding: { xs: "1rem", sm: "4rem 2rem" }, borderRadius: "15px" }}
          >
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={7}>
                <MDTypography mb={1} variant="h5" fontWeight="bold">
                  Tehana
                </MDTypography>
                <MDTypography mb={1} variant="h6" fontWeight="light">
                  UI/UX | answers of few key questions
                </MDTypography>
                <MDTypography mb={1} variant="h6" fontWeight="light">
                  <LocationOnIcon />
                  &nbsp;New York, United States
                </MDTypography>
                <MDTypography mb={1} variant="h6" fontWeight="light">
                  <PaidIcon />
                  &nbsp;20
                </MDTypography>
                <Grid container mb={1}>
                  {values.map((val, index) => (
                    <>
                      <Grid item xs={6}>
                        <MDTypography variant="h6" fontWeight="bold">
                          {/* Communication */}
                          {val.name}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={5}>
                        <Rating
                          name="half-rating"
                          precision={0.5}
                          value={val.star}
                          onChange={(e) => updateValue(e, index)}
                          // onChange={(event, newValue) => {
                          //   setValue(newValue);
                          // }}
                        />
                      </Grid>
                      <Grid item xs={1} sx={{ lineHeight: "30px" }}>
                        <span>{val.star}</span>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} md={5} sx={{ display: "flex", justifyContent: "center" }}>
                <Grid justifyContent="center">
                  <Grid item xs={12}>
                    <MDBox sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        variant="determinate"
                        value={90}
                        size={160}
                        color="success"
                      />
                      <MDBox
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MDTypography variant="h4" component="div" color="text.secondary">
                          90%
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item mt={1} xs={12} sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontWeight: "600", fontSize: "22px", lineHeight: "28px" }}>
                      Success Rate
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container mt={2}>
              <Grid item xs={12}>
                <Typography
                  fontWeight="bold"
                  sx={{ fontSize: "18px", lineHeight: "23px", color: "#000000" }}
                >
                  Comments
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7} mt={1}>
                <TextareaAutosize
                  minRows={7}
                  placeholder=" Good Work!"
                  style={{
                    width: "100%",
                    resize: "none",
                    background: "#DBDBDB",
                    border: "none",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Ratings;
