import React from "react";
import { get } from "axios-setup/index";
import { apiParamsFunction } from "constants/apiParamsFunction";
import { CalendarToday, FormatQuote } from "@mui/icons-material";
import { setLeadDetail } from "redux/actions/action";
import { useDispatch } from "react-redux";
// import settings from "assets/imgs/icons/settings.png";
// import filterIcon from "assets/imgs/icons/filterIcon1.png";
import MDTypography from "components/MDTypography";
import Loader from "views/Common/Loader";
// import { FormatQuote } from "@mui/icons-material";
import { Grid, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SimpleBar from "simplebar-react";
import MDBox from "components/MDBox";
import { stringIntoMin } from "constants/getTime";
import UserDetails from "./clientComponents/UserDetails";
import SearchLoader from "../Common/Loader";
import "simplebar/dist/simplebar.min.css";

function MyProjects() {
  const [lead, setLead] = React.useState([]);
  const dispatch = useDispatch();
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [detailViewLoading, setDetailViewLoading] = React.useState(false);
  const [isId, setId] = React.useState(false);
  let leadsArray = [];
  const useStyles = makeStyles(() => ({
    fontSize: {
      fontSize: 40,
      alignSelf: "center",
      paddingRight: 10,
    },
    paraColor: {
      color: "#4F4F4F",
      fontStyle: "normal",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "113.5%",
    },
    dash_dash: {
      height: 1,
      display: "block",
      backgroundColor: "#d8d8d8",
      width: "95%",
      margin: "auto",
    },
    active: {
      borderLeft: "5px solid red",
      transition: "0.2s ease all",
      backgroundColor: "#F8F8F8",
    },
  }));
  const classes = useStyles();

  // DATE FORMAT IN: DD-MM-YY (02-05-22)
  // function getDate(date) {
  //   try {
  //     const yy = new Date(date).getFullYear().toString().slice(2, 4);
  //     const MM = (new Date(date).getMonth() + 1).toString().padStart(2, 0);
  //     const dd = new Date(date).getDate().toString().padStart(2, 0);

  //     return `${dd}-${MM}-${yy}`;
  //   } catch (error) {
  //     return "date format not correct";
  //   }
  // }

  const selectUserID = async (id) => {
    setDetailViewLoading(true);
    try {
      const leadResponseselectUserID = await get(
        apiParamsFunction(true, "application/json", "leads", "", `/${id}`)
      );
      dispatch(setLeadDetail(leadResponseselectUserID.data));
      setDetailViewLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getLeadList = async () => {
    setSearchLoading(true);
    try {
      const requestOptions = {
        isAuth: true,
        headers: {
          "Content-Type": "application/json",
        },
        endPoint: `users/me/submitted_leads`,
        params: {
          page_size: 50,
          continuation_token: "",
          lead_status: "pending",
        },
      };
      const res = await get(requestOptions);
      leadsArray = res.data;
      if (leadsArray && leadsArray.length > 0) {
        leadsArray.sort((a, b) => new Date(b.posted_on) - new Date(a.posted_on));
        setLead(leadsArray);
        selectUserID(leadsArray[0].id);
        setId(leadsArray[0].id);
      } else {
        setLead([]);
      }
      setSearchLoading(false);
    } catch (error) {
      console.log("client error", error);
    }
  };

  React.useEffect(() => {
    getLeadList();
    // dispatch(setLeadDetail());
  }, []);

  return (
    <>
      {searchLoading ? (
        <SearchLoader />
      ) : (
        <Grid container>
          <Grid item xs={12} md={3.5}>
            <MDBox px={2} bgColor="dark" display="flex-column">
              <MDBox display="flex" justifyContent="flex-end" bgColor="dark">
                <MDBox bgColor="dark" display="flex" alignItems="center" sx={{ height: 50 }}>
                  <MDTypography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ position: "absolute", left: "10px", color: "#ffffff" }}
                    display="flex"
                  >
                    Pending Projects
                  </MDTypography>
                  {/* <MDBox
                    component="img"
                    src={settings}
                    alt="Brand"
                    color="white"
                    bgColor="dark"
                    mr={2}
                  />
                  <MDBox
                    component="img"
                    src={filterIcon}
                    alt="Brand"
                    color="white"
                    bgColor="dark"
                    mr={2}
                  /> */}
                </MDBox>
              </MDBox>
            </MDBox>
            <SimpleBar style={{ maxHeight: "90vh" }} className="scroll" autoHide={false}>
              <MDBox
                sx={{
                  position: "relative",
                  maxHeight: "90vh",
                }}
              >
                <MDBox
                  component="ul"
                  display="flex"
                  style={{ maxHeight: "89vh" }}
                  flexDirection="column"
                  p={0}
                  m={0}
                >
                  {lead && lead.length > 0 ? (
                    lead.map((items) => {
                      const time = stringIntoMin(items.posted_on);
                      const date = new Date(items.posted_on).toString();
                      return (
                        <>
                          <MDBox
                            className={isId === items.id ? classes.active : ""}
                            p={2}
                            key={items.id}
                            display="flex-column"
                            alignItems="center"
                            onClick={() => {
                              selectUserID(items.id);
                              setId(items.id);
                            }}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <MDBox
                              display="flex"
                              justifyContent="space-between"
                              flexWrap="wrap"
                              sx={{ paddingBottom: 2 }}
                            >
                              <MDBox display="flex">
                                <MDTypography>
                                  <b
                                    sx={{
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      lineHeight: "18px",
                                      fontStyle: "normal",
                                      color: "#051931",
                                    }}
                                  >
                                    {" "}
                                    {items.service.name} &nbsp;&nbsp;{" "}
                                  </b>
                                </MDTypography>
                                {/* <ArrowCircleUp className={classes.fontSize} /> */}
                              </MDBox>
                              <MDTypography
                                variant="caption"
                                sx={{ color: "gray" }}
                                fontWeight="light"
                              >
                                {time}
                              </MDTypography>
                            </MDBox>
                            <MDTypography className={classes.paraColor} sx={{ paddingBottom: 2 }}>
                              {items.description.length > 60
                                ? `${items.description.substring(0, 60)}...`
                                : items.description}
                            </MDTypography>
                            <MDBox display="flex">
                              <MDBox
                                display="flex"
                                justifyContent="flex-start"
                                sx={{ marginRight: 5 }}
                                flexWrap="wrap"
                              >
                                <FormatQuote className={classes.fontSize} />
                                <MDTypography variant="h6" fontWeight="light">
                                  {items.experts_responded}
                                </MDTypography>
                                {/* {items.experts_responded > 0 ? (
                                  <MDTypography variant="h6" fontWeight="light">
                                    {items.experts_responded}
                                  </MDTypography>
                                ) : (
                                  <MDTypography variant="h6" fontWeight="light">
                                    N/A
                                  </MDTypography>
                                )} */}
                              </MDBox>
                              <MDBox display="flex" justifyContent="flex-start" flexWrap="wrap">
                                <CalendarToday className={classes.fontSize} />
                                <MDTypography
                                  variant="h6"
                                  sx={{ fontSize: "14px", paddingTop: "1px" }}
                                  fontWeight="light"
                                >
                                  {`${date.split(" ")[0]} ${date.split(" ")[1]} ${
                                    date.split(" ")[2]
                                  }, ${date.split(" ")[3]}`}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                          <Divider sx={{ height: 3, m: 0.5 }} orientation="horizontal" />
                        </>
                      );
                    })
                  ) : (
                    <MDTypography variant="h6" sx={{ pt: 5 }} textAlign="center">
                      No results found..
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </SimpleBar>
          </Grid>
          {detailViewLoading ? (
            <Grid
              item
              xs={12}
              md={6}
              xl={8}
              sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}
            >
              <Loader />
            </Grid>
          ) : (
            <UserDetails />
          )}
        </Grid>
      )}
    </>
  );
}
export default MyProjects;
