export const ActionTypes = {
  SET_TOKEN: "SET_TOKEN",
  RESET: "RESET",
  SET_QUESTION: "SET_QUESTION",
  SET_MODAL: "SET_MODAL",
  SET_LEAD_PROCESS: "SET_LEAD_PROCESS",
  SET_LEAD_DETAIL: "SET_LEAD_DETAIL",
  SET_ERROR: "SET_ERROR",
  SET_PACKAGEID: "SET_PACKAGEID",
  SET_PACKAGESELECTED: "SET_PACKAGESELECTED",
  SET_PAYMENT_METHOD_ID: "SET_PAYMENT_METHOD_ID",
  REMOVE_PAYMENT_METHOD_ID: "REMOVE_PAYMENT_METHOD_ID",
  REMOVE_PACKAGEID: "REMOVE_PACKAGEID",
  SET_CREDITS: "SET_CREDITS",
  REMOVE_CREDITS: "REMOVE_CREDITS",
  SET_SESSION_TYPE: "SET_SESSION_TYPE",
  SET_PAYMENT_CHECKER: "SET_PAYMENT_CHECKER",
  SET_PACKAGEID_CREDITS: "SET_PACKAGEID_CREDITS",
  SET_PUBLIC_QUESTION: "SET_PUBLIC_QUESTION",
  SET_IS_FILTER_SELECTED: "SET_IS_FILTER_SELECTED",
  SET_FILTERS: "SET_FILTERS",
};
