const develop = {
  apiGateway: {
    API_URL: "https://api-develop.synerge.io/v1/",
    REDIRECT_URL: "https://app-develop.synerge.io",
  },
};

const staging = {
  apiGateway: {
    API_URL: "https://api-staging.synerge.io/v1/",
    REDIRECT_URL: "https://app-staging.synerge.io",
  },
};

const master = {
  apiGateway: {
    API_URL: "https://api-master.synerge.io/v1/",
    REDIRECT_URL: "https://app-master.synerge.io",
  },
};
const production = {
  apiGateway: {
    API_URL: "https://api.synerge.io/v1/",
    REDIRECT_URL: "https://app.synerge.io",
  },
};

export const Config = {
  // Add common config values here

  // Default to develop if not set

  ...(process.env.REACT_APP_STAGE === "develop"
    ? develop
    : process.env.REACT_APP_STAGE === "staging"
    ? staging
    : process.env.REACT_APP_STAGE === "master"
    ? master
    : process.env.REACT_APP_STAGE === "production"
    ? production
    : develop),
};
