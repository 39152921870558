import React, { useEffect, useState } from "react";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import { setToken } from "redux/actions/action";
import { useDispatch } from "react-redux";
import {
  Grid,
  Container,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Person, Payment } from "@mui/icons-material";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MDBox from "components/MDBox";
import { useHistory } from "react-router-dom";
import colors from "assets/theme/base/colors";
import BreadCrumb from "views/Common/BreadCrumb";
import { getCurrentUserData, updateCurrentUserProfile } from "../../constants/userSettings";

const { error } = colors;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",

      "& + .MuiSwitch-track": {
        height: 23,
        width: 38,
        // backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        backgroundColor: "#2ECA45 !important",

        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      border: "6px solid #33cf4d",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    height: 22,
    width: 38,
    borderRadius: 46 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#65C466",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const styles = {
  root: {
    backgroundColor: "#f9f9fa",
    width: "60%",
    "@media (max-width:780px)": {
      width: "100%",
    },
    "&::before": {
      height: 0,
    },
  },
  settingsTitle: {
    fontFamily: "Hurme Geometric Sans 3",
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "28px",
    color: " #051931",
    margin: "4px 0 0 27px",
    padding: "0 25px 0 0",
    // {1} mt={1} pr={1.5}
  },
  settingsSubtitle: {
    fontFamily: "Hurme Geometric Sans 3",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "23px",
    cursor: "pointer",
    "&:active": {
      color: error.main,
    },
    "&:hover": {
      color: error.main,
    },
  },
  settingsBody: {
    fontFamily: "Hurme Geometric Sans 3",
    fontstyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "15px",
    marginTop: "8px",
    color: "#000000",
  },
  notifications: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "32px",
    color: " #051931",
  },
};

const useStyles = makeStyles(styles);

const Settings = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState("profile");
  const [userData, setUserData] = useState();
  const [checked, setChecked] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const classes = useStyles();

  // const rootClass = expanded ? classes.expand : classes.expanded;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  const handleCheckboxChange = async (event) => {
    setChecked(event.target.checked);
    const request = {
      ...userData,
      send_Email_Notifications: event.target.checked,
    };
    const res = await updateCurrentUserProfile(request);
    if (res && res.status && res.status === 200) {
      if (event.target.checked) {
        setSnackMessage("Email notifications enabled");
      } else {
        setSnackMessage(
          "Email Notifications Disabled. You will still get important emails from us."
        );
      }
    } else {
      setSnackMessage(res.message);
    }

    setOpenSnack(true);

    // console.log("response:", res);
  };

  const getData = async () => {
    try {
      const res = await getCurrentUserData();
      dispatch(setToken(res));
      localStorage.setItem("data", JSON.stringify(res));
      setUserData(res);
      setChecked(res.send_Email_Notifications);
    } catch (err) {
      console.error("Error: ", err);
    }
  };
  // const rootClass = expanded ? classes.expand : classes.expanded;

  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ background: "#f9f9fa", height: { xs: "50vh", sm: "95vh", md: "95vh" } }}>
      <Container maxWidth="xxl" sx={{ width: { md: "95%" }, margin: "auto" }}>
        <BreadCrumb />
        <Grid sx={{ padding: { md: "2rem" } }}>
          <Accordion
            expanded={expanded === "profile"}
            onChange={handleChange("profile")}
            elevation={0}
            className={classes.root}
            // sx={{ width: "70%" }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropUpIcon fontSize="large" color="black" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ width: "fit-content", height: 0 }}
            >
              <Person fontSize="large" />
              <Typography className={classes.settingsTitle}>Profile</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: "0px" }}>
              <MDBox pl={8}>
                <Typography
                  className={classes.settingsSubtitle}
                  onClick={() => {
                    const path = `/expert/my-profile`;
                    history.push(path);
                  }}
                >
                  My Profile
                </Typography>
                <Typography className={classes.settingsBody}>
                  Update your profile such as name and contact info.
                </Typography>
              </MDBox>
              <MDBox pl={8} mt={2}>
                <Typography
                  className={classes.settingsSubtitle}
                  onClick={() => {
                    const path = `/expert/my-bussiness-profile`;
                    history.push(path);
                  }}
                >
                  My Business Profile
                </Typography>
                <Typography className={classes.settingsBody}>
                  Your business profile is key to attracting customers on Synerge. Use it to explain
                  what makes you different from your competition & why people should work with you.
                </Typography>
              </MDBox>
              {/* <MDBox pl={8} mt={2}>
              <Typography
                variant="h6"
                className={classes.settingsSubtitle}
                onClick={() => {
                  const path = `/expert/ratings`;
                  history.push(path);
                }}
              >
                Ratings
              </Typography>
                <Typography className={classes.settingsBody}>
                Your profile is key to attracting customers on Synerge. Use it to explain what makes
                you different from your competition & why people should work with you.
              </Typography>
            </MDBox> */}
              {/* <MDBox pl={8} mt={2}>
                <Typography
                  className={classes.settingsSubtitle}
                  onClick={() => {
                    const path = `/expert/verify-profile`;
                    history.push(path);
                  }}
                >
                  Verify Profile
                </Typography>
                <Typography className={classes.settingsBody}>
                  Your profile is key to attracting customers on Synerge. Use it to explain what
                  makes you different from your competition & why people should work with you.
                </Typography>
              </MDBox> */}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "leadreferences"}
            onChange={handleChange("leadreferences")}
            elevation={0}
            className={classes.root}
            // sx={{ width: "70%" }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropUpIcon fontSize="large" color="black" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ width: "fit-content" }}
            >
              <InsertChartIcon fontSize="large" />
              <Typography className={classes.settingsTitle}>Lead Preferences</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: "0px" }}>
              <MDBox pl={8}>
                <Typography
                  variant="h6"
                  className={classes.settingsSubtitle}
                  onClick={() => {
                    const path = `/expert/my-services`;
                    history.push(path);
                  }}
                >
                  My Services
                </Typography>
                <Typography className={classes.settingsBody}>
                  Manage your services. You&apos;ll get lead notifications based on services
                  selected here.
                </Typography>
              </MDBox>
            </AccordionDetails>
            <AccordionDetails sx={{ paddingTop: "0px" }}>
              <MDBox pl={8}>
                <Typography
                  variant="h6"
                  className={classes.settingsSubtitle}
                  onClick={() => {
                    const path = `/expert/my-locations`;
                    history.push(path);
                  }}
                >
                  My Locations
                </Typography>
                <Typography className={classes.settingsBody}>
                  Manage the locations you want to get leads from. You&apos;ll get notifications
                  based on locations selected here.
                </Typography>
              </MDBox>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "paymentInfo"}
            onChange={handleChange("paymentInfo")}
            elevation={0}
            className={classes.root}
            // sx={{ width: "70%" }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropUpIcon fontSize="large" color="black" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ width: "fit-content" }}
            >
              <Payment fontSize="large" />
              <Typography className={classes.settingsTitle}>Payment Info</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: "0px" }}>
              <MDBox pl={8}>
                <Typography
                  variant="h6"
                  className={classes.settingsSubtitle}
                  onClick={() => {
                    const path = `/expert/my-credits`;
                    history.push(path);
                  }}
                >
                  My Credits
                </Typography>
                <Typography className={classes.settingsBody}>
                  View your credits purchase history and purchase credit packs.
                </Typography>
              </MDBox>
              <MDBox pl={8} mt={2}>
                <Typography
                  variant="h6"
                  className={classes.settingsSubtitle}
                  onClick={() => {
                    const path = `/expert/payment-methods`;
                    history.push(path);
                  }}
                >
                  Payment Methods
                </Typography>
                <Typography className={classes.settingsBody}>
                  Manage your payment methods to securely purchase credit packs.
                </Typography>
              </MDBox>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "notifications"}
            onChange={handleChange("notifications")}
            elevation={0}
            className={classes.root}
            // sx={{ width: "70%" }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropUpIcon fontSize="large" color="black" />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ width: "fit-content" }}
            >
              <NotificationsActiveIcon fontSize="large" />
              <Typography className={classes.settingsTitle}>Notifications</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: "0px" }}>
              <MDBox pl={8}>
                <MDBox sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    variant="h6"
                    // onClick={() => {
                    //   const path = `/client/notifications`;
                    //   history.push(path);
                    // }}
                  >
                    Email
                  </Typography>
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        sx={{ margin: "0 0 0 30px" }}
                        checked={checked}
                        onChange={(e) => handleCheckboxChange(e)}
                      />
                    }
                    label=""
                  />
                </MDBox>
                <Typography className={classes.settingsBody}>
                  Send me email notifications. You will get important emails even when this settings
                  is off.
                </Typography>
              </MDBox>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={
              snackMessage === "Email notifications enabled" ||
              snackMessage ===
                "Email Notifications Disabled. You will still get important emails from us."
                ? "success"
                : "error"
            }
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default Settings;
