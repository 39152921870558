import React from "react";
import Paper from "@mui/material/Paper";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Card, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import MDAvatar from "components/MDAvatar";
import { getSignUpWithGoggle } from "hooks/auth/B2CActions";
// const LandingPageCard = ({ title, description, getSignUp, onInputChange }) => (

let theme = createTheme();
theme = responsiveFontSizes(theme);

const LandingPageCard = ({ title, description, getSignUp, onInputChange, otherSignOptions }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Card
    variant="outlined"
    sx={{
      backgroundColor: "white",
      // padding: "1px",
      // boxShadow: 5,
      borderRadius: 5,
      overflow: "hidden",
      boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
      width: { md: "60%", sx: "100%" },
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: 13,
      marginBottom: 13,
    }}
  >
    <Grid my={10} md={12} sx={12} direction="row" justifyContent="center" alignItems="center">
      <MDBox pt={2} mx={3} display="flex" justifyContent="center" color="white" bgColor="white">
        <MDBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor="white"
          color="white"
          borderRadius="lg"
          variant="gradient"
        >
          <ThemeProvider theme={theme}>
            <Typography
              // variant="h3"
              // color="black"
              // fontWeight="medium"
              // textTransform="capitalize"
              // textAlign="center"
              sx={{
                fontFamily: "hurmeGeometeric-Semi-Bold",
                marginBottom: 5,
                fontWeight: "600",
                fontSize: "40px",
                lineHeight: "100%",
                textAlign: "center",
                color: "#051931",
              }}
            >
              {title}
            </Typography>
          </ThemeProvider>
        </MDBox>
      </MDBox>
      <MDBox
        // pb={2}
        px={2}
        textAlign="center"
        lineHeight={1.25}
        color="white"
        bgColor="white"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <MDBox sx={{ minWidth: "20rem", maxWidth: "40rem", width: "60%" }}>
          <ThemeProvider theme={theme}>
            <MDTypography
              fontWeight="regular"
              // variant="h6"
              sx={{
                fontFamily: "hurmeGeometeric",
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "100%",
                textAlign: "center",
                color: "#4F4F4F",
              }}
            >
              {description}
            </MDTypography>
          </ThemeProvider>
        </MDBox>

        <MDBox
          justifyContent="center"
          px={2}
          mt={4}
          lineHeight={1.25}
          color="white"
          bgColor="white"
          width="100%"
        >
          <Paper
            component="form"
            elevation={0}
            sx={{
              p: "0px 0px",
              margin: "auto",
              width: "auto",
              backgroundColor: "transparent",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <MDBox
              component="form"
              sx={{
                p: -1,
                display: "flex",
                border: "1px solid gray",
                borderRadius: "1rem",
                overflow: "hidden",
                height: "5rem",
                width: "80%",
              }}
            >
              <InputBase
                variant="outlined"
                sx={{ p: 1, ml: 1, flex: 1, fontSize: "1rem", fontWeight: "bold" }}
                placeholder="Enter Your Work Email"
                inputProps={{ "aria-label": "Enter Your Work Email" }}
                onChange={onInputChange}
              />
              <MDButton
                color="error"
                onClick={getSignUp}
                sx={{
                  p: 3,
                  ml: 1,
                  borderRadius: 0,
                  zIndex: "1",
                  width: "30%",
                  fontSize: "1rem",
                  textTransform: "capitalize",
                }}
              >
                Get Started
              </MDButton>
            </MDBox>
            {/* OR and other sign in method */}
          </Paper>
          <MDTypography
            fontWeight="bold"
            sx={{ marginTop: "2rem", fontFamily: "hurmeGeometeric-Bold" }}
          >
            OR
          </MDTypography>
          <Divider />
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
          >
            {otherSignOptions.map((options) => (
              <OtherSignUp
                logo={options.logo}
                title={options.title}
                hint={options.hint}
                actionUser={options.actionUser}
              />
            ))}
          </MDBox>
        </MDBox>
      </MDBox>
    </Grid>
  </Card>
);
export default LandingPageCard;

LandingPageCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  getSignUp: PropTypes.func,
  onInputChange: PropTypes.func,
  otherSignOptions: PropTypes.func,
};

const OtherSignUp = ({ logo, title, hint, actionUser }) => (
  <MDButton
    onClick={() => getSignUpWithGoggle(hint, actionUser)}
    variant="outlined"
    sx={{
      margin: "1rem 5rem 0rem",
      padding: "5px",
      height: 60,
      border: "solid 1px gray",
      width: "auto",
      minWidth: "16rem",
      borderRadius: "1.25rem",
    }}
  >
    <MDAvatar src={logo} size="xs" variant="square" />
    <MDTypography fontWeight="bold" sx={{ color: "#383838" }} ml={1}>
      {title}
    </MDTypography>
  </MDButton>
);

OtherSignUp.propTypes = {
  logo: PropTypes.string,
  title: PropTypes.string,
  hint: PropTypes.string,
  actionUser: PropTypes.string,
};
