import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import { makeStyles } from "@mui/styles";

const { dark, error, white } = colors;

const styles = {
  title: {
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "28px",
    color: dark.main,
    marginTop: "26px",
  },
  description: {
    fontSize: "18px",
    lineHeight: "23px",
    color: "#9F9F9F",
    margin: "10px 0 26px",
  },
  subtitle: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: dark.main,
    margin: "23px 0 10px",
  },
  inputlabel: {
    fontWeight: "600",
    fontSize: " 16px",
    lineHeight: "20px",
    color: dark.main,
    margin: "20px 0 0",
    "& span": {
      fontSize: "12px",
      lineHeight: " 15px",
      color: "#818182",
    },
  },
  btn: {
    background: error.main,
    borderRadius: "30px",
    fontSize: " 16px",
    color: white.main,
    padding: "0.8rem 1.625rem",
    margin: "50px 0",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: error.main,
    },
  },
};
const useStyles = makeStyles(styles);

const Invoices = () => {
  const classes = useStyles();

  return (
    <Container>
      <Typography className={classes.title}>Billing Address</Typography>
      <Typography className={classes.description}>
        Your business address for billing & invoicing
      </Typography>
      <Paper
        sx={{
          padding: { xs: "0 15px 25px 15px", md: "0 185px 36px 48px" },
          width: { md: "50%" },
          borderRadius: "1rem",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>Please enter required information.</Typography>
            <Typography className={classes.inputlabel}>Contact Name</Typography>
            <MDInput
              fullWidth
              required
              variant="outlined"
              color="primary"
              margin="dense"
              placeholder="Contact Name"
            />
            <Typography className={classes.inputlabel}>Address Line 1 * </Typography>
            <MDInput
              fullWidth
              required
              variant="outlined"
              // label="Name"
              color="primary"
              margin="dense"
              placeholder="Address Line 1 *"
            />
            <Typography className={classes.inputlabel}>
              Address Line 2<span> (Optional)</span>
            </Typography>
            <MDInput
              fullWidth
              required
              variant="outlined"
              // label="Name"
              color="primary"
              margin="dense"
              // label="Multiline Placeholder"
              placeholder=" Address Line 2 (Optional)"
            />
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sm={5}>
                <Typography className={classes.inputlabel}>City *</Typography>
                <MDInput
                  fullWidth
                  required
                  variant="outlined"
                  // label="Name"
                  color="primary"
                  margin="dense"
                  placeholder="City *"
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Typography className={classes.inputlabel}>Postal Code </Typography>
                <MDInput
                  fullWidth
                  required
                  variant="outlined"
                  // label="Name"
                  color="primary"
                  margin="dense"
                  placeholder="Postal Code"
                />
              </Grid>
            </Grid>
            <Typography className={classes.inputlabel}>Phone Number *</Typography>
            <MDInput
              fullWidth
              required
              variant="outlined"
              // label="Name"
              color="primary"
              margin="dense"
              placeholder="Phone Number *"
            />
          </Grid>
        </Grid>
      </Paper>
      <MDButton className={classes.btn}>Confirm Changes</MDButton>
    </Container>
  );
};

export default Invoices;
