import axios from "axios";

export const getRefreshToken = async () => {
  const code = localStorage.getItem("refreshToken");
  const actionPolicy = "B2C_1A_DISPLAYCONTROL_SENDGRID_SIGNIN";
  // const clientId = process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID;
  const clientId = "c553df61-0e32-435b-b3c5-3a5afc614a1e";

  const res = await axios.post(
    `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/${actionPolicy}/oauth2/v2.0/token?grant_type=refresh_token&client_id=${clientId}&redirect_uri=https://jwt.ms&code_verifier=3d1NCzH6pAsZ.xIO__AnS5hrL-ax.vPqFD-ONmIge.4dWCfJk~~aIupCSXZjX36up7CC.u1Ru6RROC46Gqw-LZ2_BOCILjNcSXSgAaVSYCPQLS3Acn2j1MAo3eEY3dky&scope=openid profile email https://synergeplatform.onmicrosoft.com/api/access_as_user&refresh_token=${code}`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  try {
    localStorage.setItem("accessToken", res.data.access_token);
    localStorage.setItem("refreshToken", res.data.refresh_token);
    return res;
  } catch (err) {
    console.error("error:", err);
  }
};
