import * as React from "react";
import Modal from "@mui/material/Modal";
import { Card, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
// import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { setModal, leadProcess } from "redux/actions/action";
import { useHistory, NavLink } from "react-router-dom";
import success from "../../assets/images/success.gif";

export default function BasicModal({ title, description, label }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setModal(false));
    setOpen(false);
    localStorage.setItem("leadProcess", false);
    dispatch(leadProcess(false));
    history.push("/client/dashboard");
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          my={10}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          <Card id="delete-account">
            <MDBox
              p={2}
              mx={3}
              display="flex"
              justifyContent="center"
              color="white"
              bgColor="white"
            >
              <MDBox
                p={3}
                display="flex-column"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                bgColor="white"
              >
                <MDBox>
                  <img src={success} alt="" />
                </MDBox>
                <MDBox pb={3} lineHeight={1}>
                  <MDBox mb={1} justifyContent="center" alignItem="center">
                    <MDTypography variant="h3" sx={{ fontFamily: "hurmeGeometeric-Bold" }}>
                      {title}
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="h5" fontWeight="light" sx={{ mt: 2, mb: 2 }}>
                    {description}
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-around"
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                  {/* window.location.href = "/client/dashboard"; */}
                  <NavLink style={{ textDecoration: "none" }} to="/client/dashboard">
                    <MDButton
                      variant="gradient"
                      color="error"
                      sx={{ height: "100%", borderRadius: "30px", textTransform: "capitalize" }}
                      onClick={handleClose}
                    >
                      {label}
                    </MDButton>
                  </NavLink>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Modal>
    </div>
  );
}

BasicModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
};
