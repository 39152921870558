import React, { useState, useEffect } from "react";
import { Container, Paper, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setToken } from "redux/actions/action";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import {
  getCurrentUserData,
  updateCurrentUserServicesAndCountries,
  getServicesList,
} from "constants/userSettings";
import BreadCrumb from "views/Common/BreadCrumb";
import colors from "assets/theme/base/colors";
// import MDAvatar from "components/MDAvatar";
import SearchLoader from "../../../Common/Loader";

const { dark, white, error } = colors;

const styles = {
  // root: {
  //   "& .MuiButton-root": {
  //     color: "red",
  //     backgroundColor: "green",
  //     marginTop: "2rem",
  //   },
  // },
  title: {
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "28px",
    color: " #051931",
  },
  addservice: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#051931",
    paddingLeft: "5px",
  },
  service: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#051931",
    marginLeft: "10px",
  },
  remove: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "#DE1921",
    background: "#F9F9FA",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F9F9FA",
    },
    textTransform: "capitalize",
  },
  btn: {
    background: "#DE1921",
    borderRadius: "30px",
    fontSize: " 16px",
    color: "#FFFFFF",
    padding: "0.8rem 1.625rem",
    margin: "3rem 0",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#DE0921",
    },
  },
  dialogTitle: {
    fontWeight: "600",
    lineHeight: "121.2%",
    textAlign: "center",
    fontSize: "28px",
    textTransform: "capitalize",
    color: dark.main,
    marginTop: "50px",
  },
  dialogDesc: {
    fontWeight: "600",
    lineHeight: " 121.2%",
    textAlign: "center",
    fontSize: "16px",
    color: "#9F9F9F",
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    marginBottom: "30px",
  },
  dialogSubtitle: {
    fontWeight: "600",
    lineHeight: " 121.2%",
    fontSize: "16px",
    color: dark.main,
    marginBottom: "15px",
    marginLeft: "2rem",
  },
  dialogBtn: {
    width: "152px",
    height: "47px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
    color: white.main,
    borderRadius: "30px",
    margin: "25px 0 50px",
  },
};
const useStyles = makeStyles(styles);

const MyServices = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loader, setLoader] = React.useState(true);

  // const [serviceValue, setServiceValue] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [userCountries, setUserCountries] = useState([]);
  const [allServicesList, setAllServicesList] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnackDialog = () => {
    setOpenSnack(false);
  };
  const handleClickSnackDialog = () => {
    setOpenSnack(true);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleNewData = async () => {
    let existingServices = [];
    if (userServices && userServices.length > 0) {
      existingServices = userServices;
    }
    if (selectedValue.length === 0) {
      setSnackMessage("Please select service");
    } else {
      selectedValue.forEach((element) => {
        const filterdData1 = existingServices.filter((x) => x.id === element.id);
        if (filterdData1.length > 0) {
          // setSnackMessage("Service already added");
          setSnackMessage("Service already added");
        } else {
          const newService1 = { id: element.id, name: element.name, icon: element.icon };
          existingServices.push(newService1);
          setSnackMessage("Service added");
          setOpen(false);
        }
      });
    }

    setUserServices(existingServices);
    setSelectedValue([]);
  };

  const handleRemoveData = (name) => {
    const updatedArr = userServices.filter((x) => x.name !== name);
    setUserServices(updatedArr);
  };

  const getSelectedServices = async () => {
    try {
      const res = await getCurrentUserData();
      dispatch(setToken(res));
      localStorage.setItem("data", JSON.stringify(res));
      setUserServices(res.user_Services);
      setUserCountries(res.user_Countries);
      setLoader(false);
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  // getServicesList
  const getAllServicesList = async () => {
    try {
      const res = await getServicesList();
      setAllServicesList(res.data);
      setLoader(false);
    } catch (err) {
      console.error(err);
    }
  };

  // API will fetch data after entering 3 character in search dropdown
  // const handleSearchServices = (value) => {
  //   setSelectedValue(value);
  //   if (selectedValue && selectedValue.length >= 2) {
  //     getAllServicesList();
  //   }
  // };

  const updateServices = async () => {
    try {
      await updateCurrentUserServicesAndCountries(userServices, userCountries);
      setSnackMessage("Services updated successfully");
    } catch (err) {
      console.error(err);
    }
  };

  const updateUserInRedux = async () => {
    const res = await getCurrentUserData();
    dispatch(setToken(res));
    localStorage.setItem("data", JSON.stringify(res));
  };

  useEffect(() => {
    getSelectedServices();
    // getAllServicesList();
  }, []);

  return (
    <div style={{ backgroundColor: "#f9f9fa" }}>
      <Container maxWidth="xxl" sx={{ width: { md: "95%" }, margin: "auto" }}>
        <BreadCrumb childRoute="My Services" />
        <MDBox sx={{ px: { xs: 0, md: "5%" } }}>
          <Paper
            sx={{
              padding: { xs: "25px 0", sm: "25px 48px 84px" },
              borderRadius: "15px",
              marginTop: "5%",
            }}
          >
            <Grid container mb={3}>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "center", sm: "start" }}
              >
                <Typography className={classes.title}>My Services</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "center", sm: "end" }}
                sx={{ marginTop: { xs: "1rem" } }}
              >
                <AddIcon fontSize="large" sx={{ color: error.main }} />
                <MDButton
                  className={classes.addservice}
                  onClick={() => {
                    handleOpen();
                    getAllServicesList();
                  }}
                  sx={{ textTransform: "capitalize" }}
                >
                  Add Services
                </MDButton>
              </Grid>
            </Grid>
            {loader ? (
              <SearchLoader />
            ) : (
              <div>
                {userServices && userServices.length > 0 ? (
                  userServices.map((item) => (
                    <Grid
                      key={item.id}
                      container
                      sx={{
                        background: "#F9F9FA",
                        borderRadius: "5px",
                        marginTop: "48px",
                        padding: { xs: "30px 10px", sm: "30px" },
                      }}
                    >
                      <Grid
                        item
                        xs={9}
                        sx={{
                          margin: "auto",
                        }}
                      >
                        <MDBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <MDAvatar src={item.icon} alt="Avatar" size="sm" variant="square" />
                          <Typography className={classes.service}>{item.name}</Typography>
                        </MDBox>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
                      >
                        <MDButton
                          id={item.name}
                          className={classes.remove}
                          onClick={() => handleRemoveData(item.name)}
                        >
                          Remove
                        </MDButton>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Typography mt={2} display="flex" justifyContent="center" alignItems="center">
                    No services added yet
                  </Typography>
                )}
              </div>
            )}
          </Paper>
          <MDButton
            sx={{ textTransform: "capitalize" }}
            color="error"
            className={classes.btn}
            onClick={async () => {
              if (userServices && userServices.length > 0) {
                await updateServices();
                await updateUserInRedux();
              } else {
                setSnackMessage("Please select service");
              }
              handleClickSnack();
              // const respons = await updateCurrentUserServicesAndCountries(userServices, userCountries);
              // respons && alert("success");
            }}
          >
            Confirm Changes
          </MDButton>
        </MDBox>
        {/* Add services modal */}
        <>
          <Dialog open={open} onClose={handleClose}>
            <CloseIcon
              fontSize="medium"
              sx={{
                color: "red",
                position: "absolute",
                top: "12px",
                right: "12px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <DialogTitle sx={{ padding: 0 }} className={classes.dialogTitle}>
              Add Services
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.dialogDesc}>
                Type the name of your service to start searching our thousands of available
                services.
              </DialogContentText>
              <Typography className={classes.dialogSubtitle}>Service</Typography>
              <Stack spacing={3} sx={{ width: "90%", margin: "auto" }}>
                <Autocomplete
                  sx={{
                    "& .MuiAutocomplete-tag": {
                      backgroundColor: "gray",
                    },
                    "& .MuiAutocomplete-hasClearIcon": {
                      color: "red",
                    },
                  }}
                  multiple
                  id="tags-outlined"
                  options={allServicesList}
                  getOptionLabel={(option) => option.name}
                  // defaultValue={[allServicesList[0]]}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Select Service" placeholder="" />
                  )}
                  // isOptionEqualToValue={(option, value) => option.value === value}
                  // onInputChange={(e, value) => handleSearchServices(value)}
                  onChange={(e, value) => setSelectedValue(value)}
                />
              </Stack>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <MDButton
                color="dark"
                className={classes.dialogBtn}
                onClick={handleClose}
                sx={{ textTransform: "capitalize" }}
              >
                Cancel
              </MDButton>
              <MDButton
                sx={{ textTransform: "capitalize" }}
                color="error"
                className={classes.dialogBtn}
                onClick={() => {
                  handleNewData();
                  handleClickSnackDialog();
                }}
              >
                Add Service
              </MDButton>
            </DialogActions>
            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              action={action}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleCloseSnackDialog}
            >
              <Alert
                onClose={handleCloseSnackDialog}
                severity={snackMessage === "Service added" ? "success" : "error"}
                sx={{ width: "100%" }}
              >
                {snackMessage}
              </Alert>
            </Snackbar>
          </Dialog>
        </>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={
              snackMessage === "Services updated successfully" || snackMessage === "Service added"
                ? "success"
                : "error"
            }
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default MyServices;
