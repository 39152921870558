import React from "react";
import colors from "assets/theme/base/colors";
import {
  Container,
  Grid,
  ListItem,
  Paper,
  Stack,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";

const { error, white } = colors;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        height: 23,
        width: 38,
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    height: 22,
    width: 38,
    borderRadius: 46 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const styles = {
  paper: {
    display: "flex",
    flrxDirection: "row",
    alignItems: "center",
    padding: "15px 15px 15px 0",
    marginTop: "26px",
  },
  title: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: " 23px",
    color: "#051931",
    margin: "47px 0 26px",
  },
  warning: {
    margin: "0 16px",
    "& span": {
      backgroundColor: error.main,
      color: white.main,
      padding: "4px 10px",
      borderRadius: "50%",
    },
  },
  description: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "#9F9F9F",
  },

  notifications: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "32px",
    color: " #051931",
  },
};
const useStyles = makeStyles(styles);

const EmailNotification = () => {
  const classes = useStyles();

  return (
    <Container>
      <Grid container mb={15}>
        <Grid item xs={12} md={10}>
          <Paper className={classes.paper}>
            <MDBox className={classes.warning}>
              <span>i</span>
            </MDBox>
            <MDBox>
              <Typography className={classes.description}>
                We can send notifications straight to your web browser. Choose what you’d like to be
                notified about. Note: If you’d like to control what notifications you receive in our
                mobile apps, please head to the settings within your app.
              </Typography>
            </MDBox>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography className={classes.title}>Notify me about</Typography>
          <Stack direction="row">
            <ListItem>
              <Typography className={classes.notifications}>New leads I receive</Typography>
            </ListItem>
            <ListItem>
              <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} label="" />
            </ListItem>
          </Stack>
          <Stack direction="row">
            <ListItem>
              <Typography className={classes.notifications}>
                Customers sending me a message
              </Typography>
            </ListItem>
            <ListItem>
              <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} label="" />
            </ListItem>
          </Stack>
          <Stack direction="row">
            <ListItem>
              <Typography className={classes.notifications}>New reviews on my profile</Typography>
            </ListItem>
            <ListItem>
              <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} label="" />
            </ListItem>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EmailNotification;
