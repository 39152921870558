import React from "react";

import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import CancelIcon from "@mui/icons-material/Cancel";

const styles = {
  title: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: " #051931",
    textShadow: "0px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  description: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#051931",
    margin: "13px 0 31px",
  },
  status: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#051931",
    "& span": {
      color: "red",
    },
  },
  btn: {
    background: "#DE1921",
    borderRadius: "30px",
    fontSize: " 16px",
    color: "#FFFFFF",
    padding: "14px 56px",
    marginTop: "100px",
    "&:hover": {
      backgroundColor: "#DE0921",
    },
  },
};

const useStyles = makeStyles(styles);

const ContactInformation = () => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" mt={3}>
      <Grid item xs={6}>
        <Typography className={classes.title}>OTP Verification</Typography>
        <Typography className={classes.description}>
          We will send you one time password on your mobile number.
        </Typography>
        <MDInput label="Enter Mobile Number" sx={{ width: "50%" }} />
        <MDBox>
          <MDButton className={classes.btn}>Get OTP</MDButton>
        </MDBox>
      </Grid>
      <Grid item xs={6} textAlign="end">
        <Typography className={classes.status}>
          Status
          <CancelIcon sx={{ margin: "0 5px" }} />
          <span>Not Verified</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ContactInformation;
