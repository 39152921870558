import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import filledIcon from "../../assets/imgs/icons/filledIcon.png";
import unfilledIcon from "../../assets/imgs/icons/unfilledIcon.png";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

export default function MDCustomizedRating({ experts_responded }) {
  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      <StyledRating
        name="customized-color"
        defaultValue={experts_responded}
        getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
        precision={0.5}
        icon={
          <MDBox
            component="img"
            src={filledIcon}
            alt="Brand"
            // width="2rem"
            color="white"
            bgColor="white"
            mr={1}
          />
        }
        emptyIcon={
          <MDBox
            component="img"
            src={unfilledIcon}
            alt="Brand"
            // width="2rem"
            color="white"
            bgColor="white"
            mr={1}
          />
        }
        readOnly
      />
    </Box>
  );
}

MDCustomizedRating.propTypes = {
  experts_responded: PropTypes.number,
};
