import { post, get } from "axios-setup";
import { apiParamsFunction } from "constants/apiParamsFunction";

const manageResponse = (response) => {
  const res = {
    status: response.status,
    statusText: response.statusText,
    data: response.data,
    message: `${response.data.title} , ${response.data.detail}`,
    messageDetail: response.data.detail,
  };
  return res;
};

export const submitRefundRequest = async (purchased_lead_id, refund_reason, _comments) => {
  const body = {
    refundReason: refund_reason,
    comments: _comments,
  };
  const res = await post(
    apiParamsFunction(
      true,
      "application/json",
      `users/me/purchased_leads/${purchased_lead_id}/refund_requests`,
      body,
      ""
    )
  );
  return manageResponse(res);
};

export const getRequest = async () => {
  const requestOptions = {
    page_size: 50,
    continuation_token: "",
    active: true,
    request_status: "Pending",
  };
  const res = await get(
    apiParamsFunction(true, "application/json", "/users/me/requests", "", requestOptions)
  );
  return manageResponse(res);
};

export const postEmailOrCallActivity = (
  purchased_lead_id,
  activity_type,
  email_details,
  call_details,
  titleObject
) => {
  const body = {
    activity_type,
    email_details,
    call_details,
    title: titleObject,
    description: "Description",
  };
  return post(
    apiParamsFunction(
      true,
      "application/json",
      `users/me/purchased_leads/${purchased_lead_id}/activities`,
      body,
      ""
    )
  );
};

export const postNotes = async (purchased_lead_id, notesValue) => {
  const res = await post(
    apiParamsFunction(
      true,
      "application/json",
      `users/me/purchased_leads/${purchased_lead_id}/notes`,
      {
        note_description: notesValue,
      },
      ""
    )
  );
  return manageResponse(res);
};
