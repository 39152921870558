import { Config } from "../../config";

export const getSignUpWithGoggle = async (hint, actionKey) => {
  // localStorage.setItem("actionKey", "client_signup");
  // localStorage.setItem("actionKey", "expert_signup");
  localStorage.setItem("actionKey", actionKey);
  window.open(
    `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_DISPLAYCONTROL_SENDGRID_SIGNIN&domain_hint=${hint}&response_type=code&client_id=c553df61-0e32-435b-b3c5-3a5afc614a1e&scope=openid https://synergeplatform.onmicrosoft.com/api/access_as_user&redirect_uri=${
      window.location.hostname === "localhost"
        ? "https://localhost:3000"
        : // : "https://app-develop.synerge.io"
          Config.apiGateway.REDIRECT_URL
    }/auth/callback&code_challenge=1XxXWLiufpAcOhrZPGGOO3g5Vz5MZaZJF0VXLySd6i0&code_verifier=T~xwgKlCOUUuKz3SNMtfp4E7glHdTs.Sl6sL~_sTq53GVZh-HyVsQMpnIa~j7ETrto5sw1Rm79RCCJnVabaudgK.WoBOBwuo9tUVc4mHeA9RFCTXogP5Vu_xI9bVtog~&code_challenge_method=S256`,
    "_self"
  );
};
export const changePasswordRedirect = (role) => {
  // localStorage.clear();
  window.open(
    `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_DISPLAYCONTROL_SENDGRID_PASSWORDRESET&response_type=code&client_id=c553df61-0e32-435b-b3c5-3a5afc614a1e&scope=openid https://synergeplatform.onmicrosoft.com/api/access_as_user&redirect_uri=${
      window.location.hostname === "localhost"
        ? "https://localhost:3000"
        : // : "https://app-develop.synerge.io"
          Config.apiGateway.REDIRECT_URL
    }/${role}/my-profile&code_challenge=1XxXWLiufpAcOhrZPGGOO3g5Vz5MZaZJF0VXLySd6i0&code_verifier=T~xwgKlCOUUuKz3SNMtfp4E7glHdTs.Sl6sL~_sTq53GVZh-HyVsQMpnIa~j7ETrto5sw1Rm79RCCJnVabaudgK.WoBOBwuo9tUVc4mHeA9RFCTXogP5Vu_xI9bVtog~&code_challenge_method=S256`,
    "_self"
  );
};
export const getSignOut = (role) => {
  localStorage.clear();
  localStorage.setItem("logoutType", role);

  window.open(
    // `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/b2c_1_signup_client/oauth2/v2.0/logout?post_logout_redirect_uri=${
    //   window.location.hostname === "localhost"
    //     ? "https://localhost:3000"
    //     : "https://app-develop.synerge.io"
    // }`,
    `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/logout?p=b2c_1a_displaycontrol_sendgrid_signin&post_logout_redirect_uri=${
      window.location.hostname === "localhost"
        ? "https://localhost:3000"
        : // : "https://app-develop.synerge.io"
          Config.apiGateway.REDIRECT_URL
    }`,
    "_self"
  );
};

export const getSignIn = (actionKey) => {
  localStorage.setItem("actionKey", actionKey);
  window.open(
    // `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_signin&response_type=code&client_id=c553df61-0e32-435b-b3c5-3a5afc614a1e&scope=openid https://synergeplatform.onmicrosoft.com/api/access_as_user&redirect_uri=${
    //   window.location.hostname === "localhost"
    //     ? "https://localhost:3000"
    //     : "https://app-develop.synerge.io"
    // }/auth/callback&code_challenge=1XxXWLiufpAcOhrZPGGOO3g5Vz5MZaZJF0VXLySd6i0&code_verifier=T~xwgKlCOUUuKz3SNMtfp4E7glHdTs.Sl6sL~_sTq53GVZh-HyVsQMpnIa~j7ETrto5sw1Rm79RCCJnVabaudgK.WoBOBwuo9tUVc4mHeA9RFCTXogP5Vu_xI9bVtog~&code_challenge_method=S256`,
    `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_DISPLAYCONTROL_SENDGRID_SIGNIN&response_type=code&client_id=c553df61-0e32-435b-b3c5-3a5afc614a1e&scope=openid https://synergeplatform.onmicrosoft.com/api/access_as_user&redirect_uri=${
      window.location.hostname === "localhost"
        ? "https://localhost:3000"
        : // : "https://app-develop.synerge.io"
          Config.apiGateway.REDIRECT_URL
    }/auth/callback&code_challenge=1XxXWLiufpAcOhrZPGGOO3g5Vz5MZaZJF0VXLySd6i0&code_verifier=T~xwgKlCOUUuKz3SNMtfp4E7glHdTs.Sl6sL~_sTq53GVZh-HyVsQMpnIa~j7ETrto5sw1Rm79RCCJnVabaudgK.WoBOBwuo9tUVc4mHeA9RFCTXogP5Vu_xI9bVtog~&code_challenge_method=S256`,
    "_self"
  );
};

export const getSignUp = async (hint, actionKey, flowType, LeadId) => {
  // localStorage.setItem("actionKey", "client_signup");
  // localStorage.setItem("actionKey", "expert_signup");
  localStorage.setItem("actionKey", actionKey);
  localStorage.setItem("flowType", flowType);
  if (flowType === "PublicLeadFlow" && actionKey === "client_signup") {
    window.open(
      // `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_signup&signup_hint=${hint}&response_type=code&client_id=c553df61-0e32-435b-b3c5-3a5afc614a1e&scope=openid https://synergeplatform.onmicrosoft.com/api/access_as_user&redirect_uri=${
      //   window.location.hostname === "localhost"
      //     ? "https://localhost:3000"
      //     : "https://app-develop.synerge.io"
      // }/auth/callback&code_challenge=1XxXWLiufpAcOhrZPGGOO3g5Vz5MZaZJF0VXLySd6i0&code_verifier=T~xwgKlCOUUuKz3SNMtfp4E7glHdTs.Sl6sL~_sTq53GVZh-HyVsQMpnIa~j7ETrto5sw1Rm79RCCJnVabaudgK.WoBOBwuo9tUVc4mHeA9RFCTXogP5Vu_xI9bVtog~&code_challenge_method=S256`,
      `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_DISPLAYCONTROL_SENDGRID_SIGNIN&option=signup&signup_hint=${hint}&state=${LeadId}&response_type=code&client_id=c553df61-0e32-435b-b3c5-3a5afc614a1e&scope=openid https://synergeplatform.onmicrosoft.com/api/access_as_user&redirect_uri=${
        window.location.hostname === "localhost"
          ? "https://localhost:3000"
          : // : "https://app-develop.synerge.io"
            Config.apiGateway.REDIRECT_URL
      }/auth/callback&code_challenge=1XxXWLiufpAcOhrZPGGOO3g5Vz5MZaZJF0VXLySd6i0&code_verifier=T~xwgKlCOUUuKz3SNMtfp4E7glHdTs.Sl6sL~_sTq53GVZh-HyVsQMpnIa~j7ETrto5sw1Rm79RCCJnVabaudgK.WoBOBwuo9tUVc4mHeA9RFCTXogP5Vu_xI9bVtog~&code_challenge_method=S256`,
      "_self"
    );
  } else {
    window.open(
      // `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_signup&signup_hint=${hint}&response_type=code&client_id=c553df61-0e32-435b-b3c5-3a5afc614a1e&scope=openid https://synergeplatform.onmicrosoft.com/api/access_as_user&redirect_uri=${
      //   window.location.hostname === "localhost"
      //     ? "https://localhost:3000"
      //     : "https://app-develop.synerge.io"
      // }/auth/callback&code_challenge=1XxXWLiufpAcOhrZPGGOO3g5Vz5MZaZJF0VXLySd6i0&code_verifier=T~xwgKlCOUUuKz3SNMtfp4E7glHdTs.Sl6sL~_sTq53GVZh-HyVsQMpnIa~j7ETrto5sw1Rm79RCCJnVabaudgK.WoBOBwuo9tUVc4mHeA9RFCTXogP5Vu_xI9bVtog~&code_challenge_method=S256`,
      `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_DISPLAYCONTROL_SENDGRID_SIGNIN&option=signup&signup_hint=${hint}&response_type=code&client_id=c553df61-0e32-435b-b3c5-3a5afc614a1e&scope=openid https://synergeplatform.onmicrosoft.com/api/access_as_user&redirect_uri=${
        window.location.hostname === "localhost"
          ? "https://localhost:3000"
          : // : "https://app-develop.synerge.io"
            Config.apiGateway.REDIRECT_URL
      }/auth/callback&code_challenge=1XxXWLiufpAcOhrZPGGOO3g5Vz5MZaZJF0VXLySd6i0&code_verifier=T~xwgKlCOUUuKz3SNMtfp4E7glHdTs.Sl6sL~_sTq53GVZh-HyVsQMpnIa~j7ETrto5sw1Rm79RCCJnVabaudgK.WoBOBwuo9tUVc4mHeA9RFCTXogP5Vu_xI9bVtog~&code_challenge_method=S256`,
      "_self"
    );
  }
};
