import * as React from "react";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { Card, Grid, CircularProgress, styled } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import {
  removeCredits,
  removePaymentMethodId,
  setPackageidCreditcs,
  setPackageId,
  setPackageName,
} from "redux/actions/action";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Portal from "@mui/material/Portal";
import SimpleBar from "simplebar-react";
import successTick from "../../../assets/imgs/icons/successTick.png";
import "simplebar/dist/simplebar.min.css";

const CustomButton = styled(MDButton)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginTop: "10px",
  },
}));

export default function ExpertModal({
  details,
  component: Component,
  setModalFlag,
  setShowModal,
  buttonLoader,
  ifModalFlag,
  refreshScreen,
}) {
  const dispatch = useDispatch();
  const [open] = React.useState(true);
  const { title, description, label, secondButton, secondLabel, errorMessage, ThumbIcon } = details;
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackMessage("");
    setOpenSnack(false);
  };

  // const handleClickSnack = () => {
  //   setOpenSnack(true);
  // };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  // onclose button
  const onClose = () => {
    dispatch(removeCredits());
    dispatch(removePaymentMethodId());
    if (ifModalFlag === "fourth" || ifModalFlag === "fifth") {
      refreshScreen();
    }
    setShowModal(false);
    setOpenSnack(false);
    setModalFlag("first");
    dispatch(setPackageidCreditcs(""));
    dispatch(setPackageId(""));
    dispatch(setPackageName(""));
  };

  // clickHandler
  const clickHandler = () => {
    const successfunction = details.successfunc();
    // if (details.nextRoute === "fourth") {
    //   dispatch(setPackageidCreditcs(""));
    // }
    if (successfunction === null) {
      setOpenSnack(true);
      setSnackMessage(errorMessage);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setShowModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SimpleBar style={{ maxHeight: "100vh" }} className="scroll" autoHide={false}>
          <Grid
            my={10}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ textAlign: "center", width: "100%" }}
          >
            <Card
              id="delete-account"
              // sx={{ xs: { maxWidth: "100%" }, margin: "10px",maxWidth: "60%" }}
              sx={{ maxWidth: "96%" }}
            >
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "red",
                  // hover: "#f44360",
                  margin: "10px",
                  "&:hover": {
                    color: "red",
                  },
                }}
                color="white"
              >
                <CloseIcon />
              </IconButton>
              <MDBox
                p={2}
                mx={3}
                display="flex"
                justifyContent="center"
                color="white"
                bgColor="white"
                sx={{ width: "auto", padding: { xs: "0" } }}
                // sx={{ md: { width: "33rem" },sm:{ width: "33rem"}, xs: { width: "13rem" } }}
              >
                <MDBox
                  p={2}
                  display="flex-column"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  bgColor="white"
                  width="33rem"
                >
                  {title === "Please Confirm" ? (
                    <img
                      style={{ marginTop: 4, marginBottom: 20 }}
                      src={successTick}
                      alt="Success"
                    />
                  ) : (
                    ""
                  )}
                  <MDBox pb={2} lineHeight={1}>
                    <MDBox mb={2} mt={2} justifyContent="center" alignItem="center">
                      {ThumbIcon ? (
                        <MDTypography variant="h6" color="dark" fontWeight="light">
                          <ThumbUpIcon sx={{ height: "70px", width: "auto" }} />
                        </MDTypography>
                      ) : (
                        ""
                      )}
                      {label === "Buy " ? (
                        <MDTypography
                          variant="h3"
                          sx={{ fontFamily: "hurmeGeometeric-Bold", paddingTop: "2px" }}
                        >
                          {title}
                        </MDTypography>
                      ) : (
                        <MDTypography
                          variant="h2"
                          sx={{ fontFamily: "hurmeGeometeric-Bold", paddingTop: "2px" }}
                        >
                          {title}
                        </MDTypography>
                      )}
                    </MDBox>
                    <MDTypography variant="h6" color="dark" fontWeight="light">
                      {description}
                    </MDTypography>
                  </MDBox>
                  <Component />
                  {details.successfunc}
                  <MDBox
                    display="flex"
                    justifyContent="space-around"
                    mt={5}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    {secondButton && (
                      <CustomButton
                        // variant="gradient"
                        size="large"
                        color="dark"
                        mt={3}
                        sx={{
                          height: "100%",
                          borderRadius: "30px",
                          textTransform: "capitalize",
                          fontSize: "15px",
                          order: { xs: 2, sm: 1, md: 1 },
                        }}
                        onClick={() => {
                          dispatch(removeCredits());
                          dispatch(removePaymentMethodId());
                          console.log("button pressed");
                          setShowModal(false);
                          setModalFlag("first");
                        }}
                      >
                        {secondLabel}
                      </CustomButton>
                    )}
                    {buttonLoader ? (
                      <MDButton
                        // variant="gradient"
                        size="large"
                        color="error"
                        sx={{
                          height: "100%",
                          borderRadius: "30px",
                          textTransform: "capitalize",
                          fontSize: "15px",
                          order: { xs: 1, sm: 1, md: 1 },
                        }}
                        mt={3}
                        onClick={details.successfunc}
                      >
                        <CircularProgress color="white" size="1.3rem" />
                      </MDButton>
                    ) : (
                      <MDButton
                        // variant="gradient"
                        size="large"
                        color="error"
                        sx={{
                          height: "100%",
                          borderRadius: "30px",
                          textTransform: "capitalize",
                          fontSize: "15px",
                          order: { xs: 1, sm: 1, md: 2 },
                        }}
                        // onClick={details.successfunc}
                        onClick={clickHandler}
                      >
                        {label}
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </SimpleBar>
      </Modal>
      <Portal>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={snackMessage === "Profile updated successfully" ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>{" "}
      </Portal>
    </>
  );
}

ExpertModal.propTypes = {
  details: PropTypes.any,
  component: PropTypes.func,
  setModalFlag: PropTypes.func,
  setShowModal: PropTypes.func,
  buttonLoader: PropTypes.bool,
  ifModalFlag: PropTypes.string,
  refreshScreen: PropTypes.func,
};
