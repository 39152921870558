import { getFilteredLeadList } from "constants/getData";

export const filterList = async (leadSubmittedTimeValue, checkStates, selectedCountry) => {
  let filterLeadByServiceIds = null;
  let filterLeadByCountries = null;
  // let filterLeadByCountries = [];
  let filterLeadByTime = null;
  if (leadSubmittedTimeValue && leadSubmittedTimeValue.length > 0) {
    filterLeadByTime = leadSubmittedTimeValue;
  }
  if (checkStates && checkStates.length > 0) {
    let servicesAreChecked = [];
    let filterLeadByServices = "";
    servicesAreChecked = checkStates.filter((x) => x.isChecked);
    if (servicesAreChecked && servicesAreChecked.length > 0) {
      const service = "&services=";
      for (let i = 0; i < servicesAreChecked.length; i++) {
        if (i === 0) {
          filterLeadByServices += servicesAreChecked[i].id;
        } else {
          filterLeadByServices += service + servicesAreChecked[i].id;
        }
      }
      filterLeadByServiceIds = filterLeadByServices;
    }
  }
  // if (checkCountries && checkCountries.length > 0) {
  if (selectedCountry && selectedCountry.length > 0) {
    const countriesAreChecked = [];
    let filterLeadByCountryID = "";
    // countriesAreChecked = checkCountries.filter((x) => x.isChecked);
    // countriesAreChecked = selectedCountry.filter((x) => x.isChecked);
    // countriesAreChecked = () => Object.entries(selectedCountry).map((x) => x[1]);
    selectedCountry.map((x) => countriesAreChecked.push({ name: x.name, id: x.id }));
    if (countriesAreChecked && countriesAreChecked.length > 0) {
      const country = "&countries=";
      for (let i = 0; i < countriesAreChecked.length; i++) {
        if (i === 0) {
          filterLeadByCountryID += countriesAreChecked[i].name;
        } else {
          filterLeadByCountryID += country + countriesAreChecked[i].name;
        }
      }
      filterLeadByCountries = filterLeadByCountryID;
    }
  }
  // if (selectedCountry) {
  //   filterLeadByCountries = selectedCountry;
  // }
  const response = await getFilteredLeadList(
    filterLeadByServiceIds,
    filterLeadByCountries,
    filterLeadByTime
  );

  return response;
};
