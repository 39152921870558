import { get, post } from "axios-setup";

const manageResponse = (response) => {
  const res = {
    status: response.status,
    statusText: response.statusText,
    data: response.data,
    message: `${response.data.title} , ${response.data.detail}`,
  };
  return res;
};

export const getPublicServiceAreasList = async () => {
  const request_params = {
    page_size: 50,
    continuation_token: "",
    active: true,
  };
  const requestOptions = {
    isAuth: false,
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "sy_33a3953d-b5c4-4f85-9ba6-94a656ec8a31",
    },
    endPoint: `public/service_areas`,
    params: request_params,
  };
  const res = await get(requestOptions);
  return manageResponse(res);
};

export const getPublicServiceList = async (searchText) => {
  const request_params = {
    page_size: 50,
    continuation_token: "",
    search_string: searchText,
    active: true,
  };
  const requestOptions = {
    isAuth: false,
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "sy_33a3953d-b5c4-4f85-9ba6-94a656ec8a31",
    },
    endPoint: `public/services`,
    params: request_params,
  };
  const res = await get(requestOptions);
  return manageResponse(res);
};

export const getSelectedServiceQuestionList = async (serviceId) => {
  const request_params = {
    page_size: 50,
    continuation_token: "",
    active: true,
  };
  const requestOptions = {
    isAuth: false,
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "sy_33a3953d-b5c4-4f85-9ba6-94a656ec8a31",
    },
    endPoint: `public/services/${serviceId}/questions`,
    params: request_params,
  };
  const res = await get(requestOptions);
  return manageResponse(res);
};

export const submitPublicQuestionsForLeadPosting = async (_body) => {
  const requestOptions = {
    isAuth: false,
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": "sy_33a3953d-b5c4-4f85-9ba6-94a656ec8a31",
    },
    endPoint: `public/leads`,
    body: _body,
  };
  const res = await post(requestOptions);
  return manageResponse(res);
};
