import React from "react";
import { Checkbox, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import CancelIcon from "@mui/icons-material/Cancel";
import { makeStyles } from "@mui/styles";

const styles = {
  title: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: " #051931",
    textShadow: "0px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  description: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#051931",
    marginLeft: "12px",
    "& span": {
      fontStyle: "italic",
      fontSize: "12px",
      color: "gray",
    },
  },
  status: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#051931",
    "& span": {
      color: "red",
    },
  },
};

const useStyles = makeStyles(styles);

const SkillVerification = () => {
  const classes = useStyles();

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Grid container justifyContent="space-between" mt={3}>
      <Grid item xs={6}>
        <Typography className={classes.title}>
          Choose any method for Language Verification
        </Typography>
        <MDBox display="flex" flexDirection="row" mt={2}>
          <MDBox>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.description}>
              Proof of study in a specific skill set
              <span>(diploma, certificate, degree)*</span>
            </Typography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection="row" mt={2}>
          <MDBox>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.description}>
              Proof of employment in a specific role
              <span>(experience certificate, appointment letter etc) *</span>
            </Typography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection="row" mt={2}>
          <MDBox>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          </MDBox>
          <MDBox sx={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.description}>
              Proof of previous work, projects*
            </Typography>
          </MDBox>
        </MDBox>
      </Grid>
      <Grid item xs={6} textAlign="end">
        <Typography className={classes.status}>
          Status
          <CancelIcon sx={{ margin: "0 5px" }} />
          <span>Not Verified</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SkillVerification;
