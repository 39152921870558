import React from "react";
import PropTypes from "prop-types";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";
import { useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  AccessTime,
  DateRange,
  PersonSearch,
  EventNote,
  Timer,
  MonetizationOn,
} from "@mui/icons-material";
// import DateRangeIcon from "@mui/icons-material/DateRange";
// import PersonSearchIcon from "@mui/icons-material/PersonSearch";
// import EventNoteIcon from "@mui/icons-material/EventNote";
// import TimerIcon from "@mui/icons-material/Timer";
// import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const styles = {
  staticQuestion: {
    position: "relative",
    padding: ".8rem",
    margin: "auto",
    marginBottom: "1rem",
    marginTop: "1rem",
  },
};
const useStyles = makeStyles(styles);

const BpStaticIcon = styled("span")(({ theme }) => ({
  position: "absolute",
  top: 2,
  left: 95,
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : " #f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
  },
}));

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : " #f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedStaticIcon = styled(BpIcon)({
  position: "absolute",
  top: 2,
  left: 95,
  backgroundColor: "#137cbd",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

export function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export function BpStaticRadio(props) {
  return (
    <Radio
      sx={{
        display: "none",
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedStaticIcon />}
      icon={<BpStaticIcon />}
      {...props}
    />
  );
}

const MDCustomInputPublic = ({
  inputType,
  options,
  answer,
  changeHandler,
  currentQuestion,
  onhandlerCheckbox,
  checkbox,
  budgetType,
  startTime,
  minRate,
  maxRate,
  // fixedPriceBudget,
}) => {
  const questions = useSelector((state) => state.setTokenReducer?.public_questions);
  // const [budget, setBudget] = React.useState({ min: "", max: "", fixed: "" });
  const [fixed, setFixed] = React.useState("");

  const classes = useStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setBudget((prevState) => ({
    //   ...prevState,
    //   [name]: value,
    // }));

    if (name === "min") {
      questions[currentQuestion].budgetReference = "min";
      changeHandler(questions[currentQuestion], value);
    } else if (name === "max") {
      questions[currentQuestion].budgetReference = "max";
      changeHandler(questions[currentQuestion], value);
    } else {
      setFixed(value);
      questions[currentQuestion].budgetReference = "fixed";
      changeHandler(questions[currentQuestion], value);
    }
  };
  return (
    <>
      {/* Static Questions */}
      {questions &&
        inputType === "radioStatic" &&
        options?.map((val) => (
          <MDBox
            mb={5}
            sx={{
              width: `${230}px`,
              borderColor:
                answer?.[questions[currentQuestion]?.id]?.answer === val.optionvalue && "#DE1921",
              border:
                answer?.[questions[currentQuestion]?.id]?.answer === val.optionvalue &&
                "0.1rem solid #DE1921",
            }}
            display="flex"
            // alignItems={{ xs: "center" }}
            borderRadius={5}
            border={1}
            className={classes.staticQuestion}
          >
            <FormControl>
              <RadioGroup
                defaultValue=""
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                sx={{ alignItems: "flex-start" }}
              >
                <FormControlLabel
                  sx={{ margin: 0 }}
                  value={val.optionvalue}
                  control={<BpStaticRadio />}
                  label={
                    <MDBox>
                      <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                        {inputType === "radioStatic" && val.iconURL === "shortterm" && (
                          <AccessTime fontSize="large" sx={{ marginBottom: "1rem" }} />
                        )}
                        {inputType === "radioStatic" && val.iconURL === "longerterm" && (
                          <DateRange fontSize="large" sx={{ marginBottom: "1rem" }} />
                        )}
                        {inputType === "radioStatic" && val.iconURL === "Onetime" && (
                          <PersonSearch fontSize="large" sx={{ marginBottom: "1rem" }} />
                        )}
                        {inputType === "radioStatic" && val.iconURL === "Ongoing" && (
                          <EventNote fontSize="large" sx={{ marginBottom: "1rem" }} />
                        )}
                        {inputType === "radioStatic" && val.iconURL === "hourly" && (
                          <Timer fontSize="large" sx={{ marginBottom: "1rem" }} />
                        )}
                        {inputType === "radioStatic" && val.iconURL === "fixed" && (
                          <MonetizationOn fontSize="large" sx={{ marginBottom: "1rem" }} />
                        )}
                        {inputType === "radioStatic" && val.iconURL === "Immediately" && (
                          <AccessTime fontSize="large" sx={{ marginBottom: "1rem" }} />
                        )}
                        {inputType === "radioStatic" && val.iconURL === "aftersometime" && (
                          <DateRange fontSize="large" sx={{ marginBottom: "1rem" }} />
                        )}
                      </MDBox>
                      <MDTypography
                        variant="h6"
                        fontWeight="bold"
                        style={{ fontFamily: "hurmeGeometeric-Semi-Bold" }}
                      >
                        {val.optiontext}
                      </MDTypography>

                      <MDTypography
                        variant="body2"
                        fontWeight="bold"
                        // color="text"
                        mt={2}
                        // sx={{ width: "80%", margin: "auto" }}
                      >
                        {val.description}
                      </MDTypography>
                      <MDTypography
                        variant="body2"
                        fontWeight="bold"
                        // sx={{ width: "80%", margin: "auto" }}
                      >
                        {val.description1}
                      </MDTypography>
                    </MDBox>
                  }
                  checked={answer?.[questions[currentQuestion]?.id]?.answer === val.optionvalue}
                  onChange={(e) => changeHandler(questions[currentQuestion], e.target.value)}
                />
              </RadioGroup>
            </FormControl>
          </MDBox>
        ))}

      {inputType === "budgetStaic" && budgetType === "hourly" && (
        <MDBox
          sx={{
            justifyContent: "center",
            display: { md: "flex" },
            width: { xs: "90%", md: "80%" },
            margin: "auto",
            mb: 2,
          }}
        >
          <MDBox display="flex" flexDirection="row">
            <MDInput
              fullWidth
              InputProps={{ inputProps: { min: 1 } }}
              type="number"
              required
              variant="outlined"
              label="$Min"
              // sx={{ mb: 2, ml: 1, flex: 1 }}
              // sx={{ width: { xs: "-webkit-fill-available", md: "50px" } }}
              // value={budget.min}
              value={minRate}
              color="primary"
              name="min"
              onChange={handleChange}
              // onChange={(e) => changeHandler(questions[currentQuestion], e.target.value)}
              onKeyDown={(evt) => (evt.key === "e" || evt.key === "E") && evt.preventDefault()}
            />
            <MDTypography
              variant="body2"
              pr={2}
              margin="auto"
              marginLeft="4% !important"
              color="text"
            >
              /hr
            </MDTypography>
          </MDBox>
          <MDTypography
            pr={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            color="text"
            variant="body2"
            margin="auto"
            marginLeft="0px !important"
            marginRight="0px !important"
          >
            to
          </MDTypography>
          <MDBox display="flex" flexDirection="row">
            <MDInput
              fullWidth
              InputProps={{ inputProps: { min: 1 } }}
              type="number"
              required
              variant="outlined"
              label="$Max"
              // sx={{ mb: 2, ml: 1, flex: 1 }}
              value={maxRate}
              color="primary"
              name="max"
              onChange={handleChange}
              // onChange={(e) => changeHandler(questions[currentQuestion], e.target.value)}
              onKeyDown={(evt) => (evt.key === "e" || evt.key === "E") && evt.preventDefault()}
            />
            <MDTypography
              pr={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              color="text"
              margin="auto"
              marginLeft="4% !important"
              variant="body2"
            >
              /hr
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
      {inputType === "budgetStaic" && budgetType === "fixed" && (
        <MDBox sx={{ width: { xs: "100%", sm: "70%" }, marginLeft: { xs: "0", sm: "15%" } }}>
          <MDInput
            InputProps={{ inputProps: { min: 1 } }}
            type="number"
            fullWidth
            variant="outlined"
            label="$"
            sx={{ mb: 2, flex: 1 }}
            // value={answer?.[questions[currentQuestion]?.id]?.answer || ""}
            value={fixed}
            color="primary"
            name="fixed"
            onChange={handleChange}
            onKeyDown={(evt) => (evt.key === "e" || evt.key === "E") && evt.preventDefault()}
          />
        </MDBox>
      )}
      {inputType === "datePicker" && startTime === "aftersometime" && (
        <MDBox sx={{ width: { xs: "100%", sm: "70%" }, marginLeft: { xs: "0", sm: "15%" } }}>
          <MDDatePicker
            input={{ placeholder: "Select a date" }}
            onChange={(e) => changeHandler(questions[currentQuestion], e)}
            value={answer?.[questions[currentQuestion]?.id]?.answer || ""}
            options={{
              minDate: "today",
              static: "true",
              altInput: true,
              altFormat: "F j, Y",
              dateFormat: "Y-m-d",
            }}
          />
        </MDBox>
      )}

      {/* Static Questions */}

      {questions && inputType === "radio" && (
        <MDBox sx={{ paddingLeft: { xs: "10%", sm: 0, md: 0, lg: 0 } }}>
          <FormControl>
            <RadioGroup
              defaultValue=""
              aria-labelledby="demo-customized-radios"
              name="customized-radios"
              sx={{ alignItems: "flex-start", marginLeft: { xs: 0, sm: "3rem" } }}
            >
              {options?.map((val) => (
                <FormControlLabel
                  sx={{ display: "flex" }}
                  value={val.optionvalue}
                  control={<BpRadio />}
                  label={
                    <MDTypography
                      lineHeight={1}
                      fontWeight="bold"
                      color="text"
                      sx={{ fontSize: { xs: "14px", sm: "16px", md: "16px" }, textAlign: "left" }}
                    >
                      {/* val.optiontext */}
                      {val.optiontext}
                    </MDTypography>
                  }
                  checked={answer?.[questions[currentQuestion]?.id]?.answer === val.optionvalue}
                  onChange={(e) => changeHandler(questions[currentQuestion], e.target.value)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </MDBox>
      )}
      {inputType === "textbox" && (
        <MDInput
          variant="outlined"
          label="Type here..."
          sx={{ width: { xs: "100%", sm: "80%" }, marginLeft: { xs: "0", sm: "10%" } }}
          // sx={{ mb: 2, ml: 1, flex: 1 }}
          value={answer?.[questions[currentQuestion]?.id]?.answer || ""}
          color="primary"
          onChange={(e) => changeHandler(questions[currentQuestion], e.target.value)}
        />
      )}
      {inputType === "textarea" && (
        <MDInput
          variant="outlined"
          label="Answer"
          multiline
          rows={5}
          sx={{ width: { xs: "80%", sm: "60%" }, margin: "auto" }}
          // sx={{ mb: 2, ml: 1, flex: 1 }}
          value={answer?.[questions[currentQuestion]?.id]?.answer || ""}
          color="primary"
          onChange={(e) => changeHandler(questions[currentQuestion], e.target.value)}
        />
      )}
      {questions && inputType === "checkbox" && (
        <FormGroup sx={{ alignItems: "flex-start", marginLeft: { xs: 0, sm: "3rem" } }}>
          {options?.map((val, i) => (
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label={
                <MDTypography lineHeight={0} variant="body2" fontWeight="bold" color="text">
                  {val.optiontext}
                </MDTypography>
              }
              value={val.optionvalue}
              checked={checkbox.includes(val.optionvalue)}
              onChange={(e) => onhandlerCheckbox(questions[currentQuestion], e, i)}
            />
          ))}
        </FormGroup>
      )}
    </>
  );
};

export default MDCustomInputPublic;

MDCustomInputPublic.propTypes = {
  inputType: PropTypes.string,
  options: PropTypes.array,
  answer: PropTypes.object,
  changeHandler: PropTypes.func,
  currentQuestion: PropTypes.number,
  checkbox: PropTypes.array,
  onhandlerCheckbox: PropTypes.func,
  budgetType: PropTypes.string,
  startTime: PropTypes.string,
  minRate: PropTypes.number,
  maxRate: PropTypes.number,
  // fixedPriceBudget: PropTypes.string,
};
