import React from "react";
import Footer from "components/Footer";
import ResponsiveAppBar from "components/MDHeaderBar";
import Divider from "@mui/material/Divider";
// import LandingPageCard from "views/Common/LandingPageCard";
// import googleIcon from "assets/imgs/icons/googleIcon.png";
// import { getSignUp } from "hooks/auth/B2CActions";
import AutoCompleteProcessPublic from "../PublicView/AutoCompleteProcessPublic";

function ClientLandingPage() {
  // const [val, setVal] = React.useState("");

  // const onInputChange = (e) => {
  //   setVal(e.target.value);
  // };
  // const otherSignUpOptions = [
  //   {
  //     logo: googleIcon,
  //     title: "Sign Up with Google",
  //     hint: "google.com",
  //     actionUser: "client_signup",
  //   },

  // { logo: microsoftLogo, title: "Sign Up with Microsoft" },
  // { logo: appleLogoIcon, title: "Sign Up with Apple" },
  // ];

  // localStorage.setItem("actionKey", "client_login");
  return (
    <>
      <ResponsiveAppBar />
      <Divider sx={{ height: 2, m: 0.5 }} orientation="horizontal" />
      <div style={{ minHeight: `calc(100%  - ${350}px)` }}>
        {/* <LandingPageCard
          title="Find Freelancers"
          description="Hire freelance talent with years of professional experience and get custom solutions tailored to your unique needs."
          getSignUp={() => getSignUp(val, "client_signup", "NormalFlow", "")}
          onInputChange={onInputChange}
          otherSignOptions={otherSignUpOptions}
        /> */}
        <AutoCompleteProcessPublic />
      </div>
      <Footer />
    </>
  );
}
export default ClientLandingPage;
