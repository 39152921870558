import React from "react";
import { Route } from "react-router-dom";
import MyProfile from "views/Client/clientComponents/SettingsComponents/MyProfile";
import EmailNotification from "views/Client/clientComponents/SettingsComponents/EmailNotification";
import Invoices from "views/Client/clientComponents/SettingsComponents/Invoices";
import PaymentMethods from "views/Client/clientComponents/SettingsComponents/PaymentMethods";
import VerifyProfile from "views/Client/clientComponents/SettingsComponents/VerifyProfile";
import AutoCompleteProcess from "views/Common/AutoCompleteProcess";

function GenericClientRoutes() {
  return (
    <>
      <Route path="/client/postleads">
        <AutoCompleteProcess />
      </Route>
      <Route path="/client/my-profile">
        <MyProfile />
      </Route>
      <Route path="/client/notifications">
        <EmailNotification />
      </Route>
      <Route path="/client/invoices">
        <Invoices />
      </Route>
      <Route path="/client/payment-methods">
        <PaymentMethods />
      </Route>
      <Route path="/client/verify-profile">
        <VerifyProfile />
      </Route>
    </>
  );
}
export default GenericClientRoutes;
