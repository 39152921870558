import React from "react";
// import { get } from "axios-setup";
// import { getPublicServiceAreasList } from "constants/publicApiCalls";
import QuestionsFormPublic from "./QuestionFormPublic";
import RequiredServiceCardPublic from "./RequiredServiceCardPublic";

const AutoCompleteProcessPublic = () => {
  const [toggle, setToggle] = React.useState(true);
  const [backButton, setBackButton] = React.useState(false);
  const [currentService, setCurrentService] = React.useState("");
  // const [loader, setLoader] = React.useState(true);
  // const [service, setService] = React.useState([]);
  //   const getServiceList = async () => {
  //     const requestOptions = {
  //       isAuth: true,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       endPoint: `service_areas`,
  //       params: {
  //         page_size: 50,
  //         continuation_token: "",
  //         active: true,
  //       },
  //     };
  //     const res = await get(requestOptions);
  //     try {
  //       setService(res.data.data);

  //       setLoader(false);
  //     } catch (error) {
  //       console.log("client error", error);
  //     }
  //   };

  // const getServiceList = async () => {
  //   const res = await getPublicServiceAreasList();
  //   try {
  //     setService(res.data.data);
  //     setLoader(false);
  //   } catch (error) {
  //     console.log("client error", error);
  //   }
  // };

  const updateStates = (value) => {
    setToggle(value);
    setBackButton(value);
  };

  React.useEffect(() => {
    // getServiceList();
  }, []);
  return (
    <>
      {toggle ? (
        <RequiredServiceCardPublic
          title="Required Service"
          description="Choose services that you need."
          placeholderText="What service do you need?"
          // services={service}
          isBackButtonClick={backButton}
          setCurrentService={setCurrentService}
          setToggle={setToggle}
          setBackButton={setBackButton}
        />
      ) : (
        <QuestionsFormPublic currentService={currentService} setToggle={updateStates} />
      )}
    </>
  );
};

export default AutoCompleteProcessPublic;
