import { Paper } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

const NotFoundPage = () => (
  <Paper sx={{ height: "80vh", width: "50vw", margin: "auto" }} elevation={5} square={false}>
    <MDBox
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
      display="flex"
    >
      <MDTypography variant="h1" color="error" sx={{ fontSize: "8rem" }}>
        404
      </MDTypography>
      <MDTypography variant="h4">Page Not Not</MDTypography>
    </MDBox>
  </Paper>
);

export default NotFoundPage;
