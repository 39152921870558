import Dashboard from "../views/Client/Dashboard";
import MyProjects from "../views/Client/MyProjects";
// import MyRequests from "../views/Client/MyRequests";
import Settings from "../views/Client/Settings";

export const clientRoutes = [
  // DASHBOARD ROUTES
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/client",
  },
  {
    path: "/my-projects",
    name: "My Projects",
    component: MyProjects,
    layout: "/client",
  },
  // {
  //   path: "/my-requests",
  //   name: "My Requests",
  //   component: MyRequests,
  //   layout: "/client",
  // },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: "/client",
  },
];
