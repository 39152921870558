import React, { useState, useEffect } from "react";
import { Container, Paper, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setToken } from "redux/actions/action";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import SearchLoader from "views/Common/Loader";
import AllCountries from "assets/imgs/icons/allCountries.png";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Stack from "@mui/material/Stack";
// import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import {
  getCurrentUserData,
  updateCurrentUserServicesAndCountries,
  // getServicesList,
  getCountriesList,
} from "constants/userSettings";
// import { getServiceList, getCountriesList } from "constants/getData";

import BreadCrumb from "views/Common/BreadCrumb";
import colors from "assets/theme/base/colors";
// import MDAvatar from "components/MDAvatar";

const { dark, white, error } = colors;

const styles = {
  // root: {
  //   "& .MuiButton-root": {
  //     color: "red",
  //     backgroundColor: "green",
  //     marginTop: "2rem",
  //   },
  // },
  title: {
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "28px",
    color: " #051931",
  },
  addservice: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#051931",
    paddingLeft: "5px",
  },
  service: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#051931",
    marginLeft: "10px",
  },
  remove: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "#DE1921",
    background: "#F9F9FA",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F9F9FA",
    },
    textTransform: "capitalize",
  },
  btn: {
    background: "#DE1921",
    borderRadius: "30px",
    fontSize: " 16px",
    color: "#FFFFFF",
    padding: "0.8rem 1.625rem",
    margin: "3rem 0",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#DE0921",
    },
  },
  dialogTitle: {
    fontWeight: "600",
    lineHeight: "121.2%",
    textAlign: "center",
    fontSize: "28px",
    textTransform: "capitalize",
    color: dark.main,
    marginTop: "50px",
  },
  dialogDesc: {
    fontWeight: "600",
    lineHeight: " 121.2%",
    textAlign: "center",
    fontSize: "16px",
    color: "#9F9F9F",
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    marginBottom: "30px",
  },
  dialogSubtitle: {
    fontWeight: "600",
    lineHeight: " 121.2%",
    fontSize: "16px",
    color: dark.main,
    marginBottom: "15px",
    marginLeft: "2rem",
  },
  dialogBtn: {
    width: "180px",
    height: "47px",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "21px",
    textAlign: "center",
    color: white.main,
    borderRadius: "30px",
    margin: "25px 0 50px",
  },
  isActive: {
    border: "2px solid red",
    "&:hover": {
      border: "2px solid red",
    },
  },
};
const useStyles = makeStyles(styles);

const MyLocations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loader, setLoader] = useState(false);

  // const [serviceValue, setServiceValue] = useState("");
  // const [selectedValue, setSelectedValue] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [userCountries, setUserCountries] = useState([]);
  // const [allServicesList, setAllServicesList] = useState([]);
  const [allCountriesList, setAllCountriesList] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleSelectedCountryAdd = async () => {
    setUserCountries(selectedCountries);
    setSnackMessage("Location added");
  };

  const handleCountrySelection = async (counntryId, countryName, countryIcon) => {
    let indexVal;
    if (selectedCountries && selectedCountries.length > 0) {
      indexVal = selectedCountries.findIndex((x) => x.id === counntryId);

      if (indexVal !== -1) {
        const temp = [...selectedCountries];
        temp.splice(indexVal, 1);
        setSelectedCountries(temp);
      } else {
        setSelectedCountries([
          ...selectedCountries,
          {
            id: counntryId,
            name: countryName,
            icon: countryIcon,
          },
        ]);
      }
      if (counntryId === "any") {
        setSelectedCountries([
          {
            id: counntryId,
            name: countryName,
            icon: countryIcon,
          },
        ]);
      } else {
        const allCountryIndex = selectedCountries.findIndex((x) => x.id === "any");
        if (allCountryIndex !== -1) {
          const temp = [
            ...selectedCountries,
            {
              id: counntryId,
              name: countryName,
              icon: countryIcon,
            },
          ];
          temp.splice(allCountryIndex, 1);
          setSelectedCountries(temp);
        }
      }
    } else {
      setSelectedCountries([
        ...selectedCountries,
        {
          id: counntryId,
          name: countryName,
          icon: countryIcon,
        },
      ]);
    }
  };

  const countrySelection = (item) => (
    <>
      <MDBox
        // pb={2}
        // px={2}
        lineHeight={1.25}
        bgColor="white"
        display="flex"
      >
        <MDAvatar
          // src={item.icon}
          src={item.id === "any" ? AllCountries : item.icon}
          alt="Avatar"
          size="sm"
          variant="square"
        />

        <Typography color="dark" varient="body1" fontWeight="bold" ml={2}>
          {item.name}
        </Typography>
      </MDBox>
    </>
  );

  const handleRemoveData = (name) => {
    const updatedArr = userCountries.filter((x) => x.name !== name);
    setUserCountries(updatedArr);
  };

  const getSelectedCountries = async () => {
    try {
      setLoader(true);
      const res = await getCurrentUserData();
      dispatch(setToken(res));
      localStorage.setItem("data", JSON.stringify(res));
      setUserServices(res.user_Services);
      setUserCountries(res.user_Countries);
      setLoader(false);
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const getAllCountriesList = async () => {
    try {
      setLoader(true);
      const res = await getCountriesList();
      const countries = res.data.data;
      if (countries && countries.length > 0) {
        countries.splice(0, 0, {
          active: true,
          icon: "",
          id: "any",
          name: "All Countries",
        });
      }
      setAllCountriesList(countries);
      setSelectedCountries(userCountries);
      setLoader(false);
    } catch (err) {
      console.error(err);
    }
  };

  const updateServices = async () => {
    try {
      await updateCurrentUserServicesAndCountries(userServices, userCountries);
      setSnackMessage("Locations updated successfully");
    } catch (err) {
      console.error(err);
    }
  };

  const updateUserInRedux = async () => {
    const res = await getCurrentUserData();
    dispatch(setToken(res));
    localStorage.setItem("data", JSON.stringify(res));
  };

  useEffect(() => {
    getSelectedCountries();
  }, []);

  return (
    <div style={{ backgroundColor: "#f9f9fa" }}>
      <Container maxWidth="xxl" sx={{ width: { md: "95%" }, margin: "auto" }}>
        <BreadCrumb childRoute="My Locations" />
        <MDBox sx={{ px: { xs: 0, md: "5%" } }}>
          <Paper
            sx={{
              padding: { xs: "25px 0", sm: "25px 48px 84px" },
              borderRadius: "15px",
              marginTop: "5%",
            }}
          >
            <Grid container mb={3}>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "center", sm: "start" }}
              >
                <Typography className={classes.title}>My Locations</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
                justifyContent={{ xs: "center", sm: "end" }}
                sx={{ marginTop: { xs: "1rem" } }}
              >
                <AddIcon fontSize="large" sx={{ color: error.main }} />
                <MDButton
                  className={classes.addservice}
                  onClick={() => {
                    getAllCountriesList();
                    handleOpen();
                  }}
                  sx={{ textTransform: "capitalize" }}
                >
                  Add Locations
                </MDButton>
              </Grid>
            </Grid>
            {loader ? (
              <SearchLoader />
            ) : (
              <MDBox>
                {userCountries && userCountries.length > 0 ? (
                  userCountries.map((item) => (
                    <Grid
                      key={item.id}
                      container
                      sx={{
                        background: "#F9F9FA",
                        borderRadius: "5px",
                        marginTop: "48px",
                        padding: { xs: "30px 10px", sm: "30px" },
                      }}
                    >
                      <Grid
                        item
                        xs={9}
                        sx={{
                          margin: "auto",
                        }}
                      >
                        <MDBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          <MDAvatar src={item.id === "any" ? AllCountries : item.icon} />
                          <Typography className={classes.service}>{item.name}</Typography>
                        </MDBox>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}
                      >
                        <MDButton
                          id={item.name}
                          className={classes.remove}
                          onClick={() => handleRemoveData(item.name)}
                        >
                          Remove
                        </MDButton>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Typography mt={2} display="flex" justifyContent="center" alignItems="center">
                    No locations added yet
                  </Typography>
                )}
              </MDBox>
            )}
          </Paper>
          <MDButton
            sx={{ textTransform: "capitalize" }}
            color="error"
            className={classes.btn}
            onClick={async () => {
              if (userCountries && userCountries.length > 0) {
                await updateServices();
                await updateUserInRedux();
              } else {
                setSnackMessage("Please select service");
              }
              handleClickSnack();
              // const respons = await updateCurrentUserServicesAndCountries(userServices, userCountries);
              // respons && alert("success");
            }}
          >
            Confirm Changes
          </MDButton>
        </MDBox>
        {/* Add services modal */}
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            sx={{ "& .MuiDialog-paper": { maxWidth: "700px" } }}
          >
            <CloseIcon
              fontSize="medium"
              sx={{
                color: "red",
                position: "absolute",
                top: "12px",
                right: "12px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
            <DialogTitle sx={{ padding: 0 }} className={classes.dialogTitle}>
              Add Locations
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.dialogDesc}>
                Select the country for which you want to see the thousands of available leads.
              </DialogContentText>
              {loader ? (
                <SearchLoader />
              ) : (
                <MDBox textAlign="center">
                  {(allCountriesList || [])?.map((item) => (
                    <MDButton
                      className={`${
                        selectedCountries &&
                        selectedCountries.length > 0 &&
                        selectedCountries.find((x) => x.name === item.name)
                          ? classes.isActive
                          : ""
                      }`}
                      key={item.id}
                      variant="outlined"
                      color="black"
                      padding={0}
                      sx={{
                        marginBottom: 3,
                        marginRight: { xs: 0, sm: 2, md: 2 },
                        marginLeft: { xs: 0, sm: 2, md: 2 },
                        borderRadius: "10px !important",
                        width: "256px",
                        height: "53px",
                        justifyContent: "flex-start",
                        textTransform: "capitalize",
                      }}
                      gradient
                      onClick={() => {
                        handleCountrySelection(item.id, item.name, item.icon);
                      }}
                    >
                      <Typography>{countrySelection(item)}</Typography>
                    </MDButton>
                  ))}
                </MDBox>
              )}
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <MDButton
                color="dark"
                className={classes.dialogBtn}
                onClick={handleClose}
                sx={{ textTransform: "capitalize" }}
              >
                Cancel
              </MDButton>
              <MDButton
                sx={{ textTransform: "capitalize" }}
                color="error"
                className={classes.dialogBtn}
                onClick={() => {
                  handleSelectedCountryAdd();
                  handleClickSnack();
                  handleClose();
                }}
              >
                Add Locations
              </MDButton>
            </DialogActions>
            {/* <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              action={action}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleCloseSnackDialog}
            >
              <Alert
                onClose={handleCloseSnackDialog}
                severity={snackMessage !== "Location added" ? "error" : "success"}
                sx={{ width: "100%" }}
              >
                {snackMessage}
              </Alert>
            </Snackbar> */}
          </Dialog>
        </>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={
              snackMessage === "Locations updated successfully" || snackMessage === "Location added"
                ? "success"
                : "error"
            }
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default MyLocations;
