import React from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import SimpleBar from "simplebar-react";
import Radio from "@mui/material/Radio";
// import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { setPackageId, setPackageidCreditcs, setPackageName } from "redux/actions/action";
import { useDispatch } from "react-redux";
import Loader from "views/Common/Loader";
import PropTypes from "prop-types";

const styles = {
  isActive: {
    border: "2px solid red",
  },
};
const useStyle = makeStyles(styles);

const UserInfo = ({ loading, packageList }) => {
  const classes = useStyle();
  const [ID, setID] = React.useState("");
  const dispatch = useDispatch();

  const handleChange = (item) => {
    const value = item.id;
    const credits = item.offered_Credits;
    dispatch(setPackageidCreditcs(credits));
    dispatch(setPackageId(value));
    dispatch(setPackageName(item.name));
  };

  if (loading) {
    return <Loader />;
  }
  // item for lead selection
  const leadSelection = (item) => (
    <span style={{ width: "100%" }}>
      <Grid container sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <Grid item xs={12} md={6} sx={{ textAlign: { xs: "center", md: "left" } }}>
          <MDBox sx={{ width: "100%" }}>
            <MDBox ml={5}>
              <MDTypography variant="h6" fontWeight="medium">
                {item.name}
              </MDTypography>
              <MDBox mt={0}>
                <MDTypography
                  display="block"
                  variant="caption"
                  color="secondary"
                  fontWeight="bold"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    // justifyContent: "space-between",
                    justifyContent: { md: "space-between", xs: "center" },
                  }}
                >
                  {item.description}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>

        {/* right grid  */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            textAlign: { xs: "center", md: "right" },
          }}
        >
          <MDTypography variant="h6" fontWeight="medium" sx={{ marginLeft: { xs: "40px", sm: 0 } }}>
            {`${item.offered_Credits} Credits / Price $${item.package_Price}`}
          </MDTypography>
          <MDBox mt={0}>
            <MDTypography
              display="block"
              variant="body2"
              color="secondary"
              fontWeight="bold"
              sx={{ marginLeft: { xs: "40px", sm: 0 } }}
            >
              {`${item.offered_Discount}% OFF`}
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </span>
  );

  return (
    <MDBox>
      <MDBox lineHeight={1}>
        <SimpleBar style={{ maxHeight: "25vh" }} className="scroll" autoHide={false}>
          <Grid container sx={{ width: "90%", margin: "auto" }}>
            <FormControl>
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="pkg">
                {packageList?.data?.data.map((item) => (
                  <MDButton
                    className={`${item.id === ID ? classes.isActive : ""}`}
                    key={item.id}
                    variant="outlined"
                    circular
                    color="dark"
                    padding={0}
                    sx={{
                      width: "100%",
                      marginBottom: 3,
                    }}
                    gradient
                    onClick={() => {
                      setID(item.id);
                      handleChange(item);
                    }}
                  >
                    <FormControlLabel
                      value={item.id}
                      control={<Radio sx={{ display: "none" }} />}
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        "& .MuiFormControlLabel-label": { width: "100%" },
                      }}
                      label={leadSelection(item)}
                      // onChange={handleChange}
                      // onClick={() => handleChange(item)}
                    />
                  </MDButton>
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </SimpleBar>
      </MDBox>
      <MDBox
        container
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bgColor="#F0F0F0"
        sx={{
          borderRadius: 40,
          padding: "10px",
          overflow: "hidden",
        }}
        mt={2}
      >
        <Grid container>
          <Grid item xs={12} sm={3} display="flex" justifyContent="center">
            <MDBox bgColor="#F0F0F0" display="flex" alignItems="center">
              <MDBox
                component="img"
                src="https://synergeplatform.blob.core.windows.net/customstore/icon-synerge.svg"
                alt="Brand"
                width="2rem"
                color="white"
                bgColor="#F0F0F0"
              />
              <MDBox
                component="img"
                src="https://synergeplatform.blob.core.windows.net/customstore/synerge.png"
                alt="Brand"
                width="5rem"
                color="white"
                bgColor="#F0F0F0"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={9}>
            <MDBox sx={{ backgroundColor: "#F0F0F0" }}>
              <MDTypography
                textAlign="left"
                fontWeight="bold"
                fontSize="13px"
                sx={{ lineHeight: "20px", marginLeft: "15px", padding: "10px" }}
              >
                Your purchases with us are covered under our Refund Policy.{" "}
                <a
                  href="https://www.synerge.io/refund-policy/"
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: "red" }}
                >
                  Click here
                </a>{" "}
                to read it.
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default UserInfo;
UserInfo.propTypes = {
  loading: PropTypes.bool,
  packageList: PropTypes.any,
};
