import { useSelector } from "react-redux";
import { expertRoutes } from "./expertRoutes";
import { authRoutes } from "./authRoutes";
import { clientRoutes } from "./clientRoutes";

export const AppRoutes = () => {
  const data = useSelector((state) => state.setTokenReducer?.data);

  const appRoutes = [];

  // eslint-disable-next-line no-unused-expressions
  !data && authRoutes.map((route) => appRoutes.push(route));

  if (data) {
    if (data?.role?.toLowerCase() === "expert") {
      expertRoutes.map((route) => appRoutes.push(route));
    }
    if (data?.role?.toLowerCase() === "client") {
      clientRoutes.map((route) => appRoutes.push(route));
    }
  }

  // eslint-disable-next-line no-unused-expressions
  return appRoutes;
};
