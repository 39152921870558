import React from "react";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
// import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
// import CardMedia from "@mui/material/CardMedia";
// import MDButton from "components/MDButton";
import colors from "assets/theme/base/colors";
import PropTypes from "prop-types";
import { BlobServiceClient } from "@azure/storage-blob";
// import FileUploadIcon from "@mui/icons-material/FileUpload";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { getSASToken } from "../../constants/userSettings";

const { dark } = colors;
const styles = {
  mdbox: {
    display: "flex",
    flexDrection: "row",
    // margin: "15px 0",
  },
  update: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#4285F4",
    alignItems: "center",
    textTransform: "capitalize",
  },
  avatar: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "96px",
    lineHeight: "123px",
    color: "#FFFFFF",
    bgcolor: "blue",
    padding: "5rem",
    // width: "200px",
    // height: "200px",
    textShadow: " 0px 4px 7px rgba(0, 0, 0, 0.25)",
  },
  avatar2: {
    width: "200px",
    height: "200px",
  },
  btn: {
    textTransform: "capitalize",
    borderRadius: "30px",
    fontSize: " 16px",
    color: "#FFFFFF",
    padding: "0.8rem 1.625rem",
    "&:hover": {
      backgroundColor: "#DE0921",
    },
  },
  file_input__input: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },

  file_input__label: {
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: 25,
    fontWeight: 600,
    color: "#fff",
    fontSize: 14,
    fontFamily: "hurmeGeometeric",
    padding: "15px 17px",
    backgroundColor: "#de1921",
  },
  guideImage: {
    borderRadius: "50%",
  },
  inputlabel: {
    fontWeight: "600",
    fontSize: " 16px",
    lineHeight: "20px",
    color: dark.main,
    margin: "20px 0 0",
    "& span": {
      fontSize: "12px",
      lineHeight: " 15px",
      color: "#818182",
    },
  },
};
const useStyles = makeStyles(styles);

const UserProfileImage = ({ naam }) => {
  const classes = useStyles();
  const data = useSelector((state) => state?.setTokenReducer?.data);

  // const [selectedFile, setSelectedFile] = React.useState();
  // const [isFilePicked, setIsFilePicked] = React.useState(false);

  const uploadProfileImage = async (file, extention) => {
    const blobSasUrl = await getSASToken("user_skill_docs");

    // Create a new BlobServiceClient
    const blobServiceClient = new BlobServiceClient(blobSasUrl);

    // Create a unique name for the container by
    // appending the current time to the file name
    // const containerName = `user_profile/${data.id}`;
    const containerName = "user-profile";
    // Get a container client from the BlobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const fileName = `user_profile_${data.id}.${extention}`;
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    await blockBlobClient.uploadBrowserData(file);
  };
  // const changeHandler = (event) => {
  //   setSelectedFile(event.target.files[0]);
  // };

  // const onSubmit = () => {
  //   console.log(selectedFile);
  //   setIsFilePicked(true);
  //   const i = selectedFile.name.lastIndexOf(".");
  //   const fileExtention = i > 0 ? selectedFile.name.substring(i + 1) : "";
  //   uploadProfileImage(selectedFile, fileExtention);
  // };

  return (
    <>
      <Grid
        item
        // xs={12}
        // md={8}
        color="#9F9F9F"
        sx={{ textAlign: "center" }}
        order={{ xs: 1, md: 2 }}
      >
        <div>
          <IconButton>
            <Avatar
              className={data.profile_Picture_Url ? classes.avatar2 : classes.avatar}
              sx={{ bgcolor: "#DE1921" }}
              onChange={uploadProfileImage}
              src={data && data.profile_Picture_Url}
            >
              {naam.charAt(0).toUpperCase()}
            </Avatar>
            {/* {!isFilePicked ? (
              <label htmlFor="file-input">
                <input
                  sx={{ my: 4 }}
                  type="file"
                  name="file-input"
                  accept="image/*"
                  id="file-input"
                  className={classes.file_input__input}
                  onChange={changeHandler}
                />
                <Avatar className={classes.avatar} sx={{ bgcolor: "#DE1921" }}>
                  {naam.charAt(0).toUpperCase()}
                </Avatar>
              </label>
            ) : (
              <Avatar className={`${classes.avatar}`} sx={{ bgcolor: "#DE1921", maxWidth: 345, boxShadow: "none", margin: "auto" }} src={selectedFile} />

              <Card>
                <CardActionArea sx={{ maxWidth: 345, boxShadow: "none", margin: "auto" }}>
                  <CardMedia
                    className={classes.guideImage}
                    component="img"
                    height="200"
                    image={selectedFile}
                    alt="picture"
                  />
                </CardActionArea>
              </Card>
            )} */}
          </IconButton>
        </div>
        {/* {/ <input className={classes.Inputfile} type="file" id="file" onChange={changeHandler} /> /} */}

        {/* <MDButton
          className={`file-input ${classes.update}`}
          style={{ marginTop: "18px" }}
          onClick={onSubmit}
        >
          {" "}
          Upload File
        </MDButton> */}
      </Grid>
    </>
  );
};

export default UserProfileImage;

UserProfileImage.propTypes = {
  naam: PropTypes.string,
};
