import * as React from "react";
import Modal from "@mui/material/Modal";
import { Card, Grid } from "@mui/material";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
// import ThumbUpIcon from "@mui/icons-material/ThumbUp";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SimpleBar from "simplebar-react";
import { makeStyles } from "@mui/styles";

// import successTick from "../../../assets/imgs/icons/successTick.png";
import "simplebar/dist/simplebar.min.css";
import { createUserRequest } from "../../../constants/userSettings";
import requestModalCategory from "../../../assets/images/requestModalCategory.png";
import requestModalDescription from "../../../assets/images/requestModalDescription.png";
import requestModalTitle from "../../../assets/images/requestModalTitle.png";

const styles = makeStyles(() => ({
  rootCategory: {
    width: "100%",
    mt: 1,
    margin: "auto",
    textAlign: "left",
    height: "43px !important",
    //  MuiInputBase-colorPrimary
    "& .MuiOutlinedInput-root, & .MuiInputBase-root ,  & .MuiAutocomplete-input": {
      height: "40px !important",
      padding: "0.5px 1px 0.5px 5px  !important",
    },
  },
  categoryLabel: {
    alignItems: "center",
    textAlign: "left",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#051931",
    fontStyle: "normal",
    position: "relative",
    bottom: "3px",
  },
  titleLabel: {
    alignItems: "center",
    textAlign: "left",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#051931",
    fontStyle: "normal",
    position: "relative",
    bottom: "3px",
  },
  descriptionLabel: {
    alignItems: "center",
    textAlign: "left",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#051931",
    fontStyle: "normal",
    position: "relative",
    bottom: "2px",
  },
  placeholderLabel: {
    fontFamily: "inherit",
    fontStyle: "normal",
    fontWeight: "100",
    fontSize: "13px",
    lineHeight: "20px",
    // color: "currentColor",
    color: "#999",
  },
  textField: {
    width: "100%",
    mt: 1,
    margin: "auto",
    "& ::placeholder": { color: "#999999", opacity: "1" },
  },
  btnCancel: {
    height: "100%",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontSize: "15px",
    order: { xs: 2, sm: 1, md: 1 },
  },
  btnSubmit: {
    height: "100%",
    borderRadius: "30px",
    textTransform: "capitalize",
    fontSize: "15px",
    order: { xs: 1, sm: 1, md: 2 },
  },
}));

export default function RequestModal({ setCreateRequest, setOpenSnack, setSnackMessage }) {
  const [open] = React.useState(true);
  const [formData, setFormData] = React.useState({
    title: "",
    request_type: "",
    description: "",
  });
  const classes = styles();

  const options = ["General Inquiry", "Billing", "Technical Support"];

  const titleChange = (e) => setFormData({ ...formData, title: e.target.value });
  const request_typeChange = (event, value) => {
    if (value) {
      setFormData({ ...formData, request_type: value });
    }
  };
  const descriptionChange = (e) => setFormData({ ...formData, description: e.target.value });

  // onclose button
  const onClose = () => {
    setCreateRequest(false);
    setCreateRequest(false);
    setOpenSnack(false);
  };

  // clickHandler
  const submitHandler = async () => {
    if (
      !formData.title ||
      formData.title.length < 1 ||
      !formData.description ||
      !formData.request_type ||
      formData.description.length < 1
    ) {
      setOpenSnack(true);
      setSnackMessage("Please Fill all fields");
    } else {
      const res = await createUserRequest(formData);
      if (res.status === 200) {
        setOpenSnack(true);
        setSnackMessage("Your request has been submitted successfully.");
        setCreateRequest(false);
      } else {
        setSnackMessage("There was an error submitting your request. Please try again.");
      }
    }
  };
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setCreateRequest(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SimpleBar style={{ maxHeight: "100vh" }} className="scroll" autoHide={false}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ textAlign: "center", width: "96%", margin: "80px auto" }}
          >
            <Card id="delete-account" sx={{ maxWidth: "550px" }} p={5}>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "red",
                  // hover: "#f44360",

                  "&:hover": {
                    color: "red",
                  },
                }}
                color="white"
              >
                <CloseIcon />
              </IconButton>
              <MDBox
                p={2}
                mx={3}
                display="flex"
                justifyContent="center"
                color="white"
                bgColor="white"
                sx={{ width: "auto", padding: { xs: "0" } }}
              >
                <MDBox
                  p={2}
                  display="flex-column"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  bgColor="white"
                  width="33rem"
                >
                  <MDBox pb={2} lineHeight={1}>
                    <MDBox mb={1} mt={2} justifyContent="center" alignItem="center">
                      <MDTypography
                        variant="h3"
                        sx={{ fontFamily: "hurmeGeometeric-Bold", paddingTop: "2px" }}
                      >
                        New Request
                      </MDTypography>
                    </MDBox>
                    <MDTypography variant="h6" color="dark" fontWeight="light" mb={3}>
                      Please Fill out the request details below
                    </MDTypography>
                    <MDBox display="flex" alignItems="baseline">
                      <MDBox>
                        <img
                          pt={2}
                          src={requestModalDescription}
                          alt="Icon Category  "
                          sx={{
                            width: "19px",
                            height: "14px",
                          }}
                        />
                      </MDBox>
                      <MDTypography ml={1} className={classes.categoryLabel} mt={2} mb={1}>
                        Category <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                    </MDBox>
                    <Autocomplete
                      value={formData.request_type && formData.request_type}
                      disableClearable
                      className={classes.rootCategory}
                      onInputChange={(event) => {
                        request_typeChange(event);
                      }}
                      onChange={(event, value) => {
                        request_typeChange(event, value);
                      }}
                      options={options}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "& .MuiInputBase-input": {
                              height: "1.5rem",
                            },
                          }}
                          {...params}
                          onChange={(event) => {
                            request_typeChange(event);
                          }}
                          placeholder="Select Category"
                          size="medium"
                        />
                      )}
                    />

                    <MDBox display="flex" alignItems="baseline">
                      <MDBox>
                        <img
                          pt={2}
                          src={requestModalTitle}
                          alt="Icon Category  "
                          sx={{
                            width: "19px",
                            height: "14px",
                          }}
                        />
                      </MDBox>
                      <MDTypography ml={1} className={classes.titleLabel} mt={2} mb={1}>
                        Title <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                    </MDBox>
                    <TextField
                      className={classes.textField}
                      placeholder="Title"
                      value={formData.title}
                      onChange={(e) => {
                        titleChange(e);
                      }}
                    />
                    <MDBox display="flex" alignItems="baseline">
                      <MDBox>
                        <img
                          pt={2}
                          src={requestModalCategory}
                          alt="Icon Category  "
                          sx={{
                            width: "19px",
                            height: "14px",
                          }}
                        />
                      </MDBox>
                      <MDTypography ml={1} className={classes.descriptionLabel} mt={2} mb={1}>
                        Description <span style={{ color: "red" }}>*</span>
                      </MDTypography>
                    </MDBox>
                    <TextField
                      className={classes.textField}
                      placeholder="Description"
                      value={formData.description}
                      multiline
                      rows={3}
                      onChange={(e) => {
                        descriptionChange(e);
                      }}
                    />
                  </MDBox>

                  <MDBox
                    display="flex"
                    justifyContent="space-around"
                    mt={2}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <MDButton
                      // variant="gradient"
                      size="large"
                      color="dark"
                      mt={3}
                      className={classes.btnCancel}
                      onClick={() => {
                        setCreateRequest(false);
                      }}
                      sx={{ mb: { xs: 1, md: 0 } }}
                    >
                      Cancel
                    </MDButton>
                    {/* circular progress  */}

                    <MDButton
                      // variant="gradient"
                      size="large"
                      color="error"
                      className={classes.btnSubmit}
                      // onClick={details.successfunc}
                      pt={{ xs: 9, md: 1 }}
                      onClick={submitHandler}
                    >
                      Submit
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </SimpleBar>
      </Modal>
    </>
  );
}

RequestModal.propTypes = {
  setCreateRequest: PropTypes.func,
  setOpenSnack: PropTypes.func,
  setSnackMessage: PropTypes.func,
};
