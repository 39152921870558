import React, { useEffect, useState } from "react";
// import { get, put } from "axios-setup/index";
// import { apiParamsFunction } from "constants/apiParamsFunction";
import { makeStyles } from "@mui/styles";
import { Container, Grid, Paper, Typography } from "@mui/material";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
// import FileUploadIcon from "@mui/icons-material/FileUpload";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
// import Card from "@mui/material/Card";
import Snackbar from "@mui/material/Snackbar";
// import Avatar from "@mui/material/Avatar";
// import CardMedia from "@mui/material/CardMedia";
import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import MDBox from "components/MDBox";
import { getCurrentUserData, updateCurrentUserProfile } from "constants/userSettings";
import BreadCrumb from "views/Common/BreadCrumb";
import colors from "assets/theme/base/colors";
import { changePasswordRedirect } from "hooks/auth/B2CActions";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "redux/actions/action";
import UserProfileImage from "views/Common/UserProfileImage";
import SearchLoader from "../../../Common/Loader";
import { stringIntoMin } from "../../../../constants/getTime";

const { dark } = colors;

const styles = {
  mdbox: {
    display: "flex",
    flexDrection: "row",
    // margin: "15px 0",
  },
  update: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#4285F4",
    alignItems: "center",
    textTransform: "capitalize",
    margin: "40px 0",
    padding: 0,
  },
  avatar: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "96px",
    lineHeight: "123px",
    color: "#FFFFFF",
    bgcolor: "blue",
    padding: "5rem",
    // width: "200px",
    // height: "200px",
    textShadow: " 0px 4px 7px rgba(0, 0, 0, 0.25)",
  },
  btn: {
    textTransform: "capitalize",
    borderRadius: "30px",
    fontSize: " 16px",
    color: "#FFFFFF",
    padding: "0.8rem 1.625rem",
    "&:hover": {
      backgroundColor: "#DE0921",
    },
  },
  file_input__input: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },

  file_input__label: {
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: 25,
    fontWeight: 600,
    color: "#fff",
    fontSize: 14,
    fontFamily: "hurmeGeometeric",
    padding: "15px 17px",
    backgroundColor: "#de1921",
  },
  guideImage: {
    borderRadius: "50%",
  },
  inputlabel: {
    fontWeight: "600",
    fontSize: " 16px",
    lineHeight: "20px",
    color: dark.main,
    margin: "20px 0 0",
    "& span": {
      fontSize: "12px",
      lineHeight: " 15px",
      color: "#818182",
    },
  },
  phoneNumber: {
    fontFamily: "hurmeGeometeric",
    color: "#495057",
    fontSize: "0.875rem",
    borderRadius: "0.375rem",
    border: "0.01rem solid lightgray",
    height: "1.4375em",
    padding: "0.75rem",
    marginTop: "8px",
  },
};
const useStyles = makeStyles(styles);

const MyProfile = () => {
  const classes = useStyles();
  // let isValidPn = true;
  // const isValidE = true;
  const [openSnack, setOpenSnack] = React.useState(false);
  const [naam, setName] = React.useState("");
  const [snackMessage, setSnackMessage] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState([]);
  // const [selectedFile, setSelectedFile] = React.useState();
  // const [isFilePicked, setIsFilePicked] = React.useState(false);
  const [updateProfLoader, setUpdateProfLoader] = React.useState(false);
  // const [dataGet, setDataGet] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");

  const userData = useSelector((state) => state.setTokenReducer?.data);

  const dispatch = useDispatch();

  // const changeHandler = (event) => {
  //   setSelectedFile(event.target.files[0]);
  //   setIsFilePicked(true);
  //   const file = event.target.files[0];
  //   console.log("UPLOADED FILE: ", file);
  //   const reader = new FileReader();
  //   reader.readAsBinaryString(file);

  //   reader.onload = () => {
  //     handle reader success
  //     console.log("RESULT", reader.result);
  //   };
  // };

  const handleNumberChange = (number) => {
    setPhoneNumber(number);
    console.log();
  };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  // const phoneValidation = () => {
  //   const pattern = new RegExp(/^[+]*[(]{0,1}[0-9]{12,14}[)]{0,1}[-\s\./0-9]*$/); // eslint-disable-line
  //   return !(!phoneNumber || pattern.test(phoneNumber) === false);
  // };

  const isValidData = () => {
    let isValid = false;
    if (naam) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid;
  };

  // const emailValidation = () => {
  //   const pattern = new RegExp(
  //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  //   );
  //   return !(!emailAddress || pattern.test(emailAddress) === false);
  // };

  // const onSubmit = () => {
  //   const isPhoneValid = phoneValidation();
  //   if (isPhoneValid) {
  //     isValidPn = isPhoneValid;
  //   } else {
  //     isValidPn = isPhoneValid;
  //   }
  // };
  const getData = async () => {
    try {
      // const requestOptions = {
      //   isAuth: true,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   endPoint: `users/me`,
      //   params: {
      //     page_size: 50,
      //     continuation_token: "",
      //     active: true,
      //   },
      // };
      // const res = await get(requestOptions);

      const res = await getCurrentUserData();

      // setDataGet(res.data);
      // setName(res.data.name);
      // setPhoneNumber(res.data.phone);
      // setLastUpdated(stringIntoMin(res.data.last_Updated_On));
      if (res) {
        dispatch(setToken(res));
        localStorage.setItem("data", JSON.stringify(res));
        setName(res.name ? res.name : res.given_Name ? res.given_Name : "");
        setPhoneNumber(res.phone);
        setEmailAddress(res.emails[0]);
        setLastUpdated(stringIntoMin(res.last_Updated_On));
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  const updateProfile = async () => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber) && isValidData()) {
      setUpdateProfLoader(true);
      try {
        // if (!isValidData()) {
        //   setSnackMessage("please enter required input");
        //   setUpdateProfLoader(false);
        //   return;
        // }
        const request = {
          ...userData,
          name: naam,
          phone: phoneNumber,
        };
        const res = await updateCurrentUserProfile(request);
        if (res && res.status && res.status === 200) {
          setSnackMessage("Profile updated successfully");
          getData();
        } else {
          setSnackMessage(res.message);
        }
      } catch (error) {
        console.error("Error: ", error);
        setSnackMessage("Error updating profile");
      }
    } else {
      if (!naam && !phoneNumber) {
        setSnackMessage("Both inputs are null");
        return;
      }
      if (!naam) {
        if (!isValidPhoneNumber(phoneNumber)) {
          setSnackMessage("Please enter name and correct phone number");
          return;
        }
        setSnackMessage("Please enter name");
      } else setSnackMessage("Please enter correct Phone number");
    }
    setUpdateProfLoader(false);
    // if (!isValidPn) {
    //   setSnackMessage("Please enter details");
    // } else if (!isValidPn) {
    //   setSnackMessage("Please enter correct Phone number");
    // }
    // else if (!isValidE) {
    //   setSnackMessage("Please enter correct Email");
    // }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ backgroundColor: "#f9f9fa" }}>
      <Container maxWidth="xxl" sx={{ width: { md: "95%" }, margin: "auto" }}>
        <BreadCrumb childRoute="My Profile" />
        <MDBox sx={{ px: { xs: 0, md: "5%" } }}>
          <Paper
            sx={{
              padding: { xs: 1, md: "2rem" },
              margin: "3.25rem 0 2rem",
              borderRadius: "1rem",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid container mb={4}>
              <Grid item xs={4}>
                <Typography variant="h5">My Profile</Typography>
              </Grid>
              <Grid item xs={8} sx={{ textAlign: "end" }}>
                <Typography variant="body2" color="#9F9F9F">
                  Updated {lastUpdated}
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={5} order={{ xs: 2, md: 1 }}>
                <Grid container>
                  <Grid item xs={12} md={10}>
                    {updateProfLoader ? (
                      <SearchLoader />
                    ) : (
                      <div>
                        <Typography className={classes.inputlabel}> Name *</Typography>
                        <MDBox className={classes.mdbox}>
                          <TextField
                            fullWidth
                            required
                            variant="outlined"
                            // label="Name"
                            color="primary"
                            margin="dense"
                            value={naam}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </MDBox>
                        {/* <Typography className={classes.inputlabel}>Phone Number *</Typography>
                    <MDBox className={classes.mdbox}>
                      <Tooltip title="ex: +447911651780" placement="left-start">
                        <TextField
                          type="phone"
                          fullWidth
                          required
                          variant="outlined"
                          // label="Phone Number"
                          color="primary"
                          margin="dense"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </Tooltip>
                    </MDBox> */}
                        <Typography className={classes.inputlabel}>Phone Number *</Typography>
                        <MDBox style={{ display: "flex" }}>
                          <MDBox className={classes.phoneNumber}>
                            <PhoneInput
                              international
                              // initialValueFormat="national"
                              countryCallingCodeEditable={false}
                              defaultCountry="US"
                              placeholder="Enter phone number"
                              value={phoneNumber}
                              onChange={(e) => handleNumberChange(e)}
                              // onChange={setValue}
                              // error={phoneNumber && isValidPhoneNumber(phoneNumber) ? "true" : "false"}
                            />
                          </MDBox>
                          <MDBox
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%",
                              paddingTop: "7px",
                              alignItems: "center",
                            }}
                          >
                            {userData.phone_Verified ? (
                              <>
                                &nbsp;{" "}
                                <VerifiedUserIcon
                                  fontSize="medium"
                                  style={{ paddingTop: "2px", paddingLeft: "2px" }}
                                />{" "}
                                <span style={{ color: "#4285F4", fontFamily: "hurmeGeometeric" }}>
                                  Verified
                                </span>
                              </>
                            ) : (
                              <>
                                &nbsp;
                                <svg
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.34821 0.000201799C3.72326 0.00325997 0.390815 3.0287 0.0488183 6.64773C-0.318172 9.73013 1.41375 12.9519 4.2405 14.2612C6.86563 15.5463 10.2351 15.1396 12.4123 13.1608C14.6275 11.2408 15.5955 7.96818 14.6224 5.17616C13.6971 2.32769 10.9661 0.14697 7.95835 0.0149816C7.75524 0.00167814 7.55169 -0.000812183 7.34821 0.000201799ZM7.4967 2.35047C10.1663 2.2727 12.6137 4.63102 12.6451 7.30599C12.7605 9.75311 10.925 12.1561 8.49022 12.5664C6.13417 13.0391 3.52108 11.6753 2.6964 9.38426C1.8086 7.1187 2.71436 4.25369 4.87535 3.0646C5.66547 2.59992 6.58022 2.34933 7.4967 2.35047ZM5.64276 4.19005L4.19125 5.6439L6.05908 7.51476L4.22499 9.35182L5.65346 10.7826L7.48759 8.94553L9.35651 10.8175L10.808 9.36362L8.93909 7.49169L10.7733 5.65456L9.34478 4.22379C8.73339 4.83616 8.122 5.44853 7.51062 6.0609L5.64276 4.19006V4.19005Z"
                                    fill="#DE1921"
                                  />
                                </svg>
                                &nbsp;
                                <span style={{ color: "#DE1921", fontFamily: "hurmeGeometeric" }}>
                                  Not Verified
                                </span>
                              </>
                            )}
                          </MDBox>
                        </MDBox>
                        {/* <MDBox className={classes.mdbox}>
                  <Tooltip title="ex: mailto:someone@emample.com" placement="left-start">
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      label="Email Address"
                      color="primary"
                      margin="dense"
                      onChange={(e) => setEmailAddress(e.target.value)}
                    />
                  </Tooltip>
                </MDBox> */}
                        <Typography className={classes.inputlabel}>Email Address </Typography>
                        <MDBox className={classes.mdbox}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            // label="Email Address"
                            color="primary"
                            margin="dense"
                            readonly
                            value={emailAddress}
                            // onChange={(e) => setEmailAddress(e.target.value)}
                          />
                        </MDBox>
                        <MDButton
                          className={classes.update}
                          onClick={() => {
                            changePasswordRedirect("expert");
                          }}
                        >
                          Change Password
                        </MDButton>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={0} md={2} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={7} order={{ xs: 1, md: 2 }}>
                <UserProfileImage naam={naam} />
              </Grid>
              {/* <Grid item xs={0} md={1} /> */}
              {/* <Grid
              item
              xs={12}
              md={8}
              color="#9F9F9F"
              sx={{ textAlign: "center" }}
              order={{ xs: 1, md: 2 }}
            >
              {!isFilePicked ? (
                <div>
                  <IconButton>
                    <Avatar className={classes.avatar} sx={{ bgcolor: "#DE1921" }}>
                      {naam.charAt(0).toUpperCase()}
                    </Avatar>
                  </IconButton>
                </div>
              ) : (
                <div>
                  <Card sx={{ maxWidth: 345, boxShadow: "none", margin: "auto" }}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.guideImage}
                        component="img"
                        height="200"
                        image={selectedFile}
                        alt="picture"
                      />
                    </CardActionArea>
                  </Card>
                </div>
              )} */}
              {/* {/ <input className={classes.Inputfile} type="file" id="file" onChange={changeHandler} /> /} */}
              {/* <div className="file-input" style={{ marginTop: 20 }}>
                  <input
                    type="file"
                    name="file-input"
                    id="file-input"
                    className={classes.file_input__input}
                    onChange={changeHandler}
                  />
                  <label className={classes.file_input__label} htmlFor="file-input">
                <FileUploadIcon sx={{ mr: 1 }} />
                Upload File
              </label>
                </div>
              </Grid> */}
            </Grid>
          </Paper>

          <MDButton
            sx={{ mb: 9 }}
            className={classes.btn}
            color="error"
            onClick={async () => {
              // onSubmit();
              await updateProfile();
              handleClickSnack();
            }}
          >
            Confirm Changes
          </MDButton>
        </MDBox>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            severity={snackMessage === "Profile updated successfully" ? "success" : "error"}
            sx={{ width: "100%" }}
            onClose={handleCloseSnack}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default MyProfile;
