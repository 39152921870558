import React from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";

function PurchasedLeadNotes({ leadNotes }) {
  const date = new Date(leadNotes.created);
  return (
    <>
      <Card sx={{ boxShadow: "none", backgroundColor: "#eeeeee", pr: 2, pl: 2, mb: 3 }}>
        <MDBox sx={{ p: 2 }}>
          <MDTypography variant="h6" fontWeight="light" sx={{ mb: 2 }}>
            {leadNotes.description}
          </MDTypography>
          <MDTypography variant="h6" textAlign="end" sx={{ fontSize: "12px" }}>
            {`${date.toString().split(" ")[0]}, ${
              date.toString().split(" ")[1]
            } ${date.getDate()}, ${date.getFullYear()}`}
          </MDTypography>
        </MDBox>
      </Card>
    </>
  );
}

PurchasedLeadNotes.propTypes = {
  leadNotes: PropTypes.object,
};

export default PurchasedLeadNotes;
