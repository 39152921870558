// @mui material components
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import synergeLogo from "../../assets/images/Synerge.png";
// import footerlogo from "assets/imgs/icons/";
// Material Dashboard 2 PRO React components
function Footer() {
  return (
    <MDBox component="footer" py={2.5} bgColor="dark">
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8} mb={3} sx={{ textAlign: "center" }}>
          <MDBox
            component={NavLink}
            to="/"
            display="flex"
            justifyContent="center"
            color="transparent"
            bgColor="transparent"
          >
            <MDBox
              component="img"
              src={synergeLogo}
              alt="Brand"
              width="5rem"
              color="white"
              bgColor="transparent"
              sx={{ objectFit: "contain" }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={8} mb={2} sx={{ textAlign: "center" }}>
          <MDTypography variant="body2" color="white">
            Copyright &copy; 2022 Synerge Technologies Inc.– All rights reserved.
            <MDTypography
              component="a"
              href="https://www.synerge.io/terms"
              target="_blank"
              rel="noreferrer"
              variant="body2"
              color="white"
              sx={{ fontWeight: "600", lineHeight: "18px" }}
            >
              &nbsp;Terms & Conditions
            </MDTypography>
          </MDTypography>
        </Grid>
        {/* <Grid item xs={10} lg={8}>
          <MDBox
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            mb={1}
            bgColor="dark"
            alignItems="center"
          >
            <MDBox mr={{ xs: 2, lg: 3, xl: 6 }} bgColor="dark">
              <MDTypography component="a" href="#" variant="body2" color="white">
                Contact Us
              </MDTypography>
            </MDBox>
            <MDBox mr={{ xs: 2, lg: 3, xl: 6 }} bgColor="dark">
              <MDTypography component="a" href="#" variant="body2" color="white">
                Privacy Policy
              </MDTypography>
            </MDBox>
            <MDBox mr={{ xs: 0, lg: 0, xl: 0 }} bgColor="dark">
              <MDTypography
                component="a"
                href="https://www.synerge.io/terms"
                variant="body2"
                color="white"
                sx={{ fontWeight: "600", lineHeight: "18px" }}
              >
                Terms & Conditions
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={8}>
          <MDBox
            display="flex"
            justifyContent="center"
            flexWrap="wrap"
            mt={0}
            mb={0}
            bgColor="dark"
          >
            <MDBox mr={3} color="secondary" bgColor="dark">
              <MDTypography component="a" href="#" variant="body2" color="white">
                Email: team@synerge.io
              </MDTypography>
            </MDBox>

            <MDBox color="secondary" bgColor="dark">
              <MDTypography component="a" href="#" variant="body2" color="white">
                Contact Number: 0331264254
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid> */}
      </Grid>
    </MDBox>
  );
}

export default Footer;
