import React from "react";
import { useSelector } from "react-redux";
// import { get } from "axios-setup/index";
import LeadDetail from "views/Common/LeadDetail";
import { Grid, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SimpleBar from "simplebar-react";
import MDBox from "components/MDBox";
import LeadDetailStatic from "views/Common/LeadDetailStatic";
import MDTypography from "components/MDTypography";
import { stringIntoMin } from "constants/getTime";
// import Rating from "@mui/material/Rating";
// import { ArrowCircleUp } from "@mui/icons-material";
// import EmailIcon from "@mui/icons-material/Email";
// import PhoneIcon from "@mui/icons-material/Phone";
// import MDButton from "components/MDButton";
// import PurchasedLeadNotes from "views/Expert/expertComponent/PurchasedLeadNotes";

const UserDetails = () => {
  // const [isActive, setActive] = React.useState(true);
  // const [isActive2, setActive2] = React.useState(false);
  // const [purchasedNotes, setPurchasedNotes] = React.useState([]);
  const [DummyData, setDummyData] = React.useState(true);
  const userLeadDetail = useSelector((state) => state.userLeadDetail.leadDetail);
  // const [interestedExperts, setInterestedExperts] = React.useState([]);
  const isEmptyObject = (x) => JSON.stringify(x) === "{}";
  const {
    service_questions_and_answers,
    service,
    budget_type,
    hourly_budget,
    fixed_price_budget,
    project_duration,
    project_type,
    start_time,
    description,
    posted_on,
    // id,
  } = userLeadDetail;
  const [staticQuestions] = React.useState({
    budgetType: budget_type,
    fixedPriceBudget: fixed_price_budget,
    hourlyBudget: hourly_budget,
    projectDuration: project_duration,
    projectType: project_type,
    startTime: start_time,
  });
  const postedDate = stringIntoMin(posted_on);
  const useStyles = makeStyles(() => ({
    // buttonProperties: {
    //   color: "#4285F4",
    //   textTransform: "capitalize",
    //   fontSize: 15,
    // },
    // buttonProperties2: {
    //   color: "black",
    //   textTransform: "capitalize",
    //   fontSize: 20,
    // },
    // colorRed: {
    //   color: "red",
    // },
    // bgRed: {
    //   backgroundColor: "red",
    //   height: "100px",
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   borderRadius: "0px 10px 10px 0px",
    // },
    // dash_dash: {
    //   height: 1,
    //   display: "block",
    //   backgroundColor: "#e4e4e4",
    //   width: "91%",
    //   margin: "auto",
    // },
    // image: {
    //   borderRadius: "60%",
    //   marginTop: 10,
    //   height: 70,
    //   width: 70,
    // },
    // profileBox: {
    //   borderBottom: "1px solid #dddddd",
    //   borderRight: "1px solid #dddddd",
    //   padding: 15,
    //   borderRadius: 10,
    //   marginBottom: 2,
    // },
    leadTitle: {
      fontfamily: "Hurme Geometric Sans 3",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "31px",
      color: " #051931",
    },
    leadDesc: {
      fontFamily: "Hurme Geometric Sans 3",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "113.5%",
      color: " #4F4F4F",
      margin: "15px 0 25px 0",
    },
  }));
  const classes = useStyles();

  // const getInterestedExperts = async () => {
  //   try {
  //     const requestOptionsBody = {
  //       isAuth: true,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       endPoint: `users/me/submitted_leads/${id}/interested_experts`,
  //       params: {
  //         page_size: 50,
  //         continuation_token: "",
  //         purchased_lead_status: "pendingHireConfirmation",
  //         active: true,
  //       },
  //     };
  //     const res = await get(requestOptionsBody);
  //     console.log(res.data);
  //     setInterestedExperts(res.data);
  //     console.log("interested exp: ", interestedExperts);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // const getNotes = async () => {
  //   const requestOptions = {
  //     isAuth: true,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     endPoint: `users/me/purchased_leads/${id && id.length > 0 ? id : ""}/notes`,
  //     body: "",
  //     params: {
  //       page_size: 100,
  //       continuation_token: "",
  //     },
  //   };
  //   try {
  //     const res = await get(requestOptions);
  //     setPurchasedNotes(res.data.leadNote);
  //   } catch (error) {
  //     console.log("client error", error);
  //   }
  // };

  React.useEffect(() => {
    setDummyData(isEmptyObject(userLeadDetail));
    // getNotes();
    // getInterestedExperts();
  }, []);

  if (DummyData) {
    return (
      <Grid
        item
        xs={12}
        md={6}
        xl={8}
        sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}
      >
        {/* <MDTypography textAlign="center" alignSelf="center">
          <b>Click any lead to see its details.. </b>
        </MDTypography> */}
        <Divider orientation="horizontal" sx={{ mx: 0 }} />
      </Grid>
    );
  }
  return (
    <>
      <Grid
        item
        xs={12}
        md={6}
        xl={8}
        sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}
      >
        <Divider orientation="vertical" sx={{ ml: 0, mr: 0 }} />
        <MDBox sx={{ padding: "3rem 1rem 3rem 3rem" }}>
          <MDBox display="flex" justifyContent="space-between">
            <MDTypography className={classes.leadTitle}>{service.name}</MDTypography>
            <MDTypography
              variant="h6"
              sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "18px", color: "#9F9F9F" }}
              mt={2}
            >
              Posted {postedDate}
            </MDTypography>
          </MDBox>
          <MDTypography className={classes.leadDesc}>
            {description && description.length > 0 ? description : "N/A"}
          </MDTypography>

          {service_questions_and_answers && staticQuestions ? (
            <MDBox>
              <SimpleBar style={{ maxHeight: "100vh" }} className="scroll" autoHide={false}>
                {service_questions_and_answers ? (
                  <MDBox>
                    <MDBox
                      sx={{
                        position: "relative",
                        maxHeight: "69vh",
                      }}
                    >
                      <LeadDetail
                        title="Lead Details"
                        service_questions_and_answers={service_questions_and_answers}
                        shadow={false}
                      />
                    </MDBox>
                  </MDBox>
                ) : (
                  ""
                )}
                {staticQuestions ? <LeadDetailStatic staticQuestions={staticQuestions} /> : ""}
              </SimpleBar>
            </MDBox>
          ) : (
            <MDTypography variant="h5" fontWeight="light" textAlign="center" sx={{ pt: 2 }}>
              No lead details found
            </MDTypography>
          )}
        </MDBox>
        <Divider orientation="vertical" sx={{ mx: 0 }} />
      </Grid>

      {/* <Grid item xs={12} md={12} xl={4} sx={{ p: 4 }}>
        <MDBox sx={{ pb: 4 }}>
          <MDBox display="flex" justifyContent="end" alignItems="center" sx={{ pb: 15 }}>
            <ArrowCircleUp />
            <MDButton className={classes.buttonProperties}>Upgrade to recruiter</MDButton>
          </MDBox>
          <MDBox sx={{ pb: 3 }}>
            <MDButton
              className={`${classes.buttonProperties2} ${isActive ? classes.colorRed : ""}`}
              onClick={() => {
                setActive(true);
                setActive2(false);
              }}
            >
              Experts
            </MDButton>
            <MDButton
              className={`${classes.buttonProperties2} ${isActive2 ? classes.colorRed : ""}`}
              onClick={() => {
                setActive(false);
                setActive2(true);
              }}
            >
              Notes
            </MDButton>
          </MDBox>
          <span className={classes.dash_dash} />
        </MDBox>
        <SimpleBar style={{ maxHeight: "90vh" }} className="scroll" autoHide={false}>
          <MDBox
            sx={{
              position: "relative",
              maxHeight: "80vh",
            }}
          >
            {isActive ? (
              <MDBox className={classes.profileBox}>
                <MDBox sx={{ display: "flex" }}>
                  <Grid item md={3}>
                    <CardMedia
                      className={classes.image}
                      component="img"
                      image="https://www.linkpicture.com/q/as_5.jpg"
                      alt="Profile Picture"
                    />
                  </Grid>
                  <Grid item md={7}>
                    <MDBox sx={{ pl: 2 }}>
                      <MDTypography variant="h5">Alexa</MDTypography>
                      <MDTypography variant="h6" sx={{ color: "gray", pb: 1 }}>
                        Development and IT
                      </MDTypography>
                      <MDTypography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
                        Ratings &nbsp;
                        <Rating name="read-only" value={5} readOnly />
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        pt: 4,
                      }}
                    >
                      <MDTypography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <EmailIcon />
                        &nbsp; <span color="red">Email</span>
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <PhoneIcon />
                        &nbsp; <span color="red">Call</span>
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item md={2} className={classes.bgRed} textAlign="center">
                    <MDTypography variant="h5" color="white">
                      5.0
                    </MDTypography>
                  </Grid>
                </MDBox>
              </MDBox>
            ) : (
              <MDBox direction="column" sx={{ p: 2 }}>
                {purchasedNotes &&
                purchasedNotes.length > 0 &&
                purchasedNotes.sort((a, b) => new Date(b.created) - new Date(a.created)) ? (
                  purchasedNotes.map((x) => <PurchasedLeadNotes leadNotes={x} />)
                ) : (
                  <MDTypography variant="h6" textAlign="center">
                    {" "}
                    No notes found..
                  </MDTypography>
                )}
              </MDBox>
            )}
          </MDBox>
        </SimpleBar>
      </Grid> */}
    </>
  );
};

export default UserDetails;
