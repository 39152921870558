import React, { useState, useEffect } from "react";
import colors from "assets/theme/base/colors";
import MDButton from "components/MDButton";
import { useSelector, useDispatch } from "react-redux";
import { paymentMethodChecker } from "redux/actions/action";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import Radio from "@mui/material/Radio";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CreditDetails from "views/Expert/expertComponent/FourTypeOfModals/CreditDetails";
import SimpleBar from "simplebar-react";
import Loader from "views/Common/Loader";
import VisaCard from "assets/imgs/icons/Visa.png";
import AmericanExpressCard from "assets/imgs/icons/American Express.png";
import DiscoverCard from "assets/imgs/icons/Discover.png";
import MasterCard from "assets/imgs/icons/MasterCard.png";
import JCB from "assets/imgs/icons/jcb.png";
import AllCards from "assets/imgs/icons/allCards.png";
import BreadCrumb from "views/Common/BreadCrumb";
import { validateCreditCardExpiry, validateYourCard } from "../../../../constants/helper";
import { getCardTypeByValue } from "../../../Expert/expertComponent/FourTypeOfModals/CreditCardUtils";
import {
  getPaymentMethods,
  deletePaymentMethod,
  createPaymentMethod,
} from "../../../../constants/userSettings";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../../components/MDDialog";

const {
  dark,
  // , white
} = colors;

const styles = {
  title: {
    fontWeight: "600",
    fontSize: "22px",
    lineHeight: "28px",
    color: dark.main,
    marginTop: "50px",
  },
  buttonProperties4: {
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: "lighter",
    borderRadius: "25px",
  },
  description: {
    fontSize: "18px",
    lineHeight: "23px",
    color: "#9F9F9F",
    margin: "10px 0 50px",
  },
  subtitle: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: dark.main,
    margin: "23px 0 10px",
  },
  inputlabel: {
    display: "flex",
    flexDirection: "row",
    "& .MuiTypography-root": {
      fontWeight: "600",
      fontSize: " 16px",
      lineHeight: "20px",
      color: dark.main,
      margin: "20px 0 0",
    },
    "& span": {
      fontSize: "12px",
      lineHeight: " 15px",
      color: "#818182",
    },
  },
  payment: {
    display: "flex",
    alignItems: "center",
    // flexDirection: "row",
    verticalAlign: "center",
  },

  btn: {
    // background: "#F44335",
    width: "185px",
    borderRadius: "30px",
    fontSize: " 16px",
    // color: white.main,
    padding: "0.8rem 1.625rem",
    marginTop: "40px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#F44335",
    },
  },
};
const useStyles = makeStyles(styles);

const Payment = () => {
  const classes = useStyles();

  // const [selectedCardValue, setSelectedCardValue] = useState();
  const [SavedCards, setSavedCardsValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [miniLoading, setMiniLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [filter, setShowFilter] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [itemID, setItemID] = React.useState("");
  const credit = useSelector((state) => state?.userLeadDetail?.credits);
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      const res = await getPaymentMethods();
      setSavedCardsValue(res.data);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleDeleteCard = async () => {
    setMiniLoading(true);
    if (itemID && itemID.length > 0) {
      try {
        const res = await deletePaymentMethod(itemID);
        console.log("res:", res);
        getData();
      } catch (error) {
        console.error("Error: ", error);
        setSnackMessage("Error while deleting");
        setMiniLoading(false);
      }
      setSnackMessage("Card deleted successfully");
      setMiniLoading(false);
    } else {
      setSnackMessage("Error while deleting");
    }
  };

  const getPaymentCardLogoUrl = (cardType) => {
    let brand = VisaCard;
    if (cardType.toLowerCase() === "visa") {
      brand = VisaCard;
    } else if (cardType.toLowerCase() === "mastercard") {
      brand = MasterCard;
    } else if (cardType.toLowerCase() === "discover") {
      brand = DiscoverCard;
    } else if (cardType.toLowerCase() === "american express") {
      brand = AmericanExpressCard;
    } else if (cardType.toLowerCase() === "jcb") {
      brand = JCB;
    } else {
      brand = AllCards;
    }
    return brand;
  };

  const addNewCardDetails = async () => {
    setLoading(true);
    try {
      if (credit.cardNumber && credit.cvc && credit.expiry) {
        const cardType = getCardTypeByValue(credit.cardNumber);
        if (cardType && cardType.type) {
          if (validateCreditCardExpiry(credit.expiry)) {
            const expiry = credit.expiry.split("/");
            if (
              validateYourCard(
                credit.cardNumber.replace(/\s/g, ""),
                expiry,
                credit.cvc,
                cardType.type
              )
            ) {
              const response = await createPaymentMethod(
                parseInt(expiry[0], 10),
                parseInt(expiry[1], 10),
                credit.cardNumber,
                credit.cvc,
                // commented this line as we need to set the isDefault value true for this.
                // credit.isDefaultCard
                true
              );
              if (response.status === 200) {
                getData();
                setSnackMessage("Card added successfully");
                dispatch(paymentMethodChecker(true));
                credit.expiry = "";
                credit.cardNumber = "";
                credit.cvc = "";
                credit.isDefaultCard = false;
              } else if (response.status === 409) {
                setSnackMessage(response.data.detail);
                // dispatch(paymentMethodChecker(false));
              } else if (response.status !== 200 && response.data) {
                // getData();
                setSnackMessage(response.data.detail);
                dispatch(paymentMethodChecker(false));
              } else {
                setSnackMessage("server error, please contact to synerge support team.");
                dispatch(paymentMethodChecker(false));
              }
            } else {
              setSnackMessage("Please enter correct card details");
              dispatch(paymentMethodChecker(false));
            }
          } else {
            setSnackMessage("Please enter correct card expiry. MM/YY");
            dispatch(paymentMethodChecker(false));
          }
        } else {
          setSnackMessage("Please enter correct card details");
          dispatch(paymentMethodChecker(false));
        }
      } else {
        setSnackMessage("Please fill all fields with correct information");
        dispatch(paymentMethodChecker(false));
      }
    } catch (error) {
      console.error("Error: ", error);
      setSnackMessage(error);
    }
    // credit.expiry = "";
    // credit.cardNumber = "";
    // credit.cvc = "";
    // credit.isDefaultCard = false;
    setLoading(false);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleFilterOpen = () => {
    setShowFilter(true);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ backgroundColor: "#f9f9fa" }}>
      <Container maxWidth="xxl" sx={{ width: { md: "95%" }, margin: "auto", paddingBottom: "10%" }}>
        <BreadCrumb childRoute="Payment Methods" />
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={`${
              snackMessage === "Card added successfully" ||
              snackMessage === "Card deleted successfully"
                ? "success"
                : "error"
            }`}
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
        <MDBox sx={{ px: { xs: 0, md: "5%" } }}>
          <Typography className={classes.title}>Payment Methods</Typography>
          <Typography className={classes.description}>Choose your payment method</Typography>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Paper
                sx={{
                  // padding: { xs: "0 15px 25px 15px", md: "0 185px 36px 48px" },
                  padding: { xs: "0 15px 25px 15px", md: "50px" },
                  // width: { md: "50%" },
                  borderRadius: "1rem",
                }}
              >
                {loading ? <Loader /> : <CreditDetails />}
                <MDButton
                  className={classes.btn}
                  color="error"
                  sx={{ mt: 4 }}
                  onClick={async () => {
                    await addNewCardDetails();
                    handleClickSnack();
                  }}
                >
                  Save Card
                </MDButton>
              </Paper>
            </Grid>
            <Grid item xs={1} />
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              sx={{ paddingTop: { xs: "10%", sm: "10%", md: 0, lg: 0 } }}
            >
              <SimpleBar style={{ maxHeight: "475px" }} className="scroll" autoHide={false}>
                <MDBox
                  sx={{
                    position: "relative",
                    maxHeight: "94vh",
                    paddingRight: "35px",
                  }}
                >
                  {SavedCards &&
                  SavedCards.length > 0 &&
                  SavedCards.sort((a, b) => new Date(b.created) - new Date(a.created)) ? (
                    SavedCards.map((item) => (
                      <Grid
                        container
                        sx={{
                          mb: 4,
                          width: "100%",
                          marginRight: "auto",
                          marginLeft: "auto",
                          paddingBottom: "5%",
                          // maxWidth: "100%",
                          overflowX: "hidden !important",
                        }}
                      >
                        <Paper sx={{ width: "100%", padding: "2rem", borderRadius: "1rem" }}>
                          <MDBox display="flex" justifyContent="space-between">
                            <div>
                              <MDBox className={classes.payment}>
                                <img
                                  className={classes.imageSize}
                                  src={getPaymentCardLogoUrl(item.card.brand)}
                                  alt="icon"
                                  width="fit-content"
                                  height="fit-content"
                                />
                                <MDTypography sx={{ paddingLeft: "1rem" }} variant="h6">
                                  {item.card.brand}
                                </MDTypography>
                              </MDBox>
                              <div style={{ display: "flex" }}>
                                <MDTypography
                                  sx={{ ml: 2, marginTop: "10px" }}
                                  variant="h6"
                                  fontWeight="light"
                                >
                                  **** **** **** {item.card.number}
                                </MDTypography>
                              </div>
                            </div>
                            <IconButton
                              size="small"
                              aria-label="close"
                              color="inherit"
                              onClick={() => {
                                handleFilterOpen();
                                setItemID(item.id);
                              }}
                            >
                              <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                          </MDBox>
                          <BootstrapDialog
                            onClose={handleFilterClose}
                            aria-labelledby="customized-dialog-title"
                            open={filter}
                          >
                            <MDBox sx={{ width: "576px" }}>
                              <BootstrapDialogTitle
                                id="customized-dialog-title"
                                onClose={handleFilterClose}
                              >
                                <MDBox textAlign="center" sx={{ mt: 2 }}>
                                  <MDTypography variant="h3" fontWeight="bold">
                                    Please confirm
                                  </MDTypography>
                                  <MDTypography variant="h6" fontWeight="light" sx={{ mt: 2 }}>
                                    Are you sure you want to remove card ending with:{" "}
                                    {item.card.number}?
                                  </MDTypography>
                                </MDBox>
                              </BootstrapDialogTitle>
                              <DialogContent>
                                <MDTypography sx={{ my: 3 }} color="grey" textAlign="center">
                                  Make sure you have at least one card added to your profile so that
                                  you are able to buy credits
                                </MDTypography>
                              </DialogContent>
                              <DialogActions sx={{ justifyContent: "center", my: 3 }}>
                                {miniLoading ? (
                                  <Loader />
                                ) : (
                                  <div>
                                    <MDButton
                                      sx={{ mr: 5 }}
                                      className={`${classes.buttonProperties4}`}
                                      color="dark"
                                      size="large"
                                      onClick={() => {
                                        handleFilterClose();
                                      }}
                                    >
                                      Cancel
                                    </MDButton>
                                    <MDButton
                                      className={`${classes.buttonProperties4}`}
                                      color="error"
                                      size="large"
                                      onClick={async () => {
                                        await handleDeleteCard();
                                        handleFilterClose();
                                        handleClickSnack();
                                      }}
                                    >
                                      Remove
                                    </MDButton>
                                  </div>
                                )}
                              </DialogActions>
                            </MDBox>
                          </BootstrapDialog>
                        </Paper>
                      </Grid>
                    ))
                  ) : (
                    <MDTypography sx={{ paddingTop: { xs: "10%" }, textAlign: "center" }}>
                      No cards found..
                    </MDTypography>
                  )}
                </MDBox>
              </SimpleBar>
            </Grid>
          </Grid>
        </MDBox>
      </Container>
    </div>
  );
};

export default Payment;
