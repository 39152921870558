import { CircularProgress, Grid } from "@mui/material";
import React from "react";

const index = () => (
  <div>
    <Grid my={10} container direction="row" justifyContent="center" alignItems="center">
      <CircularProgress color="error" />
    </Grid>
  </div>
);

export default index;
