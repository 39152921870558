import React, { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EmailIcon from "@mui/icons-material/Email";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MDButton from "components/MDButton";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { makeStyles } from "@mui/styles";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { formatAMPM, displayInDateFormat } from "constants/getTime";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/MDDialog";

const useStyles = makeStyles({
  cardBg: {
    cursor: "pointer",
    backgroundColor: "#F8FAFE",
    borderRadius: " 2px 15px 15px 2px",
  },
  emailTitle: {
    fontFamily: "Hurme Geometric Sans 3",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    color: " #051931",
    marginTop: "5px",
  },
});

function EmailedClient({ emailActivity, clientEmail }) {
  const [showDetail, setShowDetail] = useState(false);
  const date = new Date(emailActivity.email_Details.email_Time);
  const classes = useStyles();

  return (
    <>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        className={classes.cardBg}
        sx={{ marginBottom: "21px", borderLeft: "5px solid black", pl: 3, pr: 3, pt: 1, pb: 1 }}
        onClick={() => {
          setShowDetail(true);
        }}
      >
        <div>
          <EmailIcon fontSize="medium" />
          <MDTypography className={classes.emailTitle}>{emailActivity.title}</MDTypography>
        </div>
        <div>
          <MDTypography
            variant="h6"
            textAlign="end"
            sx={{
              mt: 2,
              fontStyle: "normal",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "15px",
              color: "#9F9F9F",
            }}
          >
            {/* {`${date.toString().split(" ")[4].toString().split(":")[0]}:${
              date.toString().split(" ")[4].toString().split(":")[1]
            }`} */}
            {formatAMPM(date)}
          </MDTypography>
          <MDTypography
            variant="h6"
            sx={{
              mt: 2,
              fontStyle: "normal",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "15px",
              color: "#9F9F9F",
              alignItems: "center",
            }}
          >
            {/* {`${date.toString().split(" ")[0]}, ${
              date.toString().split(" ")[1]
            } ${date.getDate()}, ${date.getFullYear()}`} */}
            {displayInDateFormat(date)}
          </MDTypography>
        </div>
      </MDBox>
      <BootstrapDialog
        onClose={() => setShowDetail(false)}
        aria-labelledby="customized-dialog-detail"
        open={showDetail}
      >
        <MDBox sx={{ width: { md: "545px", xs: "100%" } }}>
          <BootstrapDialogTitle
            id="customized-dialog-detail"
            // id="customized-dialog-title"
            onClose={() => setShowDetail(false)}
          />
          <DialogContent
            dividers
            sx={{
              borderTop: "transparent",
              borderBottom: "transparent",
            }}
          >
            <MDBox
              sx={{ display: "flex", justifyContent: "center", my: 2 }}
              // onClose={() => setShowDetail(false)}
            >
              <EmailIcon
                sx={{
                  width: "3rem",
                  height: "3rem",
                  margin: "auto",
                }}
              />
            </MDBox>

            <MDTypography variant="h4" sx={{ pb: 2, textAlign: "center" }}>
              {emailActivity.title}
            </MDTypography>
            <MDTypography variant="h5" sx={{ pb: 3, textAlign: "center" }} fontWeight="light">
              {clientEmail?.posted_by.email}
            </MDTypography>
            <>
              <MDBox
                display="flex"
                justifyContent="space-between"
                sx={{
                  width: { xs: "85%", md: "85%" },
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "center" },
                  margin: "auto",
                }}
              >
                <MDBox sx={{ mr: 2, mt: 1, width: { xs: "82%", md: "auto" } }}>
                  <MDTypography
                    sx={{
                      fontFamily: "Hurme Geometric Sans 3 !important",
                      // mt: { xs: 1, sm: 1 },
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      fontSize: "18px",
                    }}
                    variant="h6"
                  >
                    <CalendarTodayIcon />
                    &nbsp;Date
                  </MDTypography>
                  <MDTypography
                    sx={{ fontFamily: "Hurme Geometric Sans 3 !important", fontSize: "16px" }}
                  >
                    {displayInDateFormat(date)}
                  </MDTypography>
                </MDBox>
                <MDBox
                  sx={{
                    mr: 1,
                    width: { xs: "82%", md: "auto" },
                  }}
                >
                  <MDTypography
                    sx={{
                      fontFamily: "Hurme Geometric Sans 3 !important",
                      // mt: { xs: 1, sm: 1 },
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      // margin: "auto",
                      fontSize: "18px",
                    }}
                    variant="h6"
                  >
                    <AccessTimeFilledIcon />
                    &nbsp;Time
                  </MDTypography>
                  <MDTypography
                    sx={{ fontFamily: "Hurme Geometric Sans 3 !important", fontSize: "16px" }}
                  >
                    {formatAMPM(date)}
                  </MDTypography>
                </MDBox>
              </MDBox>
              {emailActivity.email_Details.comments !== "N/A" &&
                emailActivity.email_Details.comments.length > 0 && (
                  <MDBox sx={{ width: "85%", margin: "auto" }}>
                    <MDTypography
                      sx={{ display: "flex", alignItems: "center", mb: 1, mt: 2 }}
                      variant="h6"
                    >
                      <ChatBubbleIcon />
                      &nbsp;Comments
                    </MDTypography>
                    <MDTypography
                      sx={{
                        fontFamily: "Hurme Geometric Sans 3 !important",
                        fontSize: "16px",
                        paddingLeft: "2px",
                      }}
                    >
                      {emailActivity.email_Details.comments}
                    </MDTypography>
                  </MDBox>
                )}
            </>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <MDBox
              sx={{
                display: { xs: "flex", md: "flex" },
                justifyContent: { md: "space-evenly", xs: "center" },
                flexDirection: { xs: "column", md: "row" },
                my: 3,
              }}
            >
              <MDButton
                color="error"
                size="large"
                sx={{
                  marginTop: { xs: "10px", md: 0 },
                  order: { xs: 2, md: 1 },
                }}
                onClick={() => setShowDetail(false)}
              >
                Close
              </MDButton>
            </MDBox>
          </DialogActions>
        </MDBox>
      </BootstrapDialog>
    </>
  );
}

EmailedClient.propTypes = {
  emailActivity: PropTypes.object,
  clientEmail: PropTypes.object,
};

export default EmailedClient;
