import React, { useState, useEffect } from "react";
// import { get } from "axios-setup/index";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import { setToken } from "redux/actions/action";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Container, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  sendOTPVerificationCode,
  submitOTPCode,
  getCurrentUserData,
  updateCurrentUserProfile,
} from "../../../../constants/userSettings";
import BreadCrumb from "../../../Common/BreadCrumb";

const styles = {
  resentotp: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: " #051931",
    "& span": {
      color: "#4285F4",
    },
  },
  or: {
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: "100%",
    margin: "35px 0 25px 33px",
    "@media (max-width:780px)": {
      margin: "1.875rem 0 1rem 33px",
    },
  },
  btn: {
    background: "#DE1921",
    borderRadius: "30px",
    fontSize: " 16px",
    color: "#FFFFFF",
    padding: "0.813rem 3.313rem",
    textTransform: "capitalize",
    margin: "4rem 0 1rem",
    "&:hover": {
      backgroundColor: "#DE0921",
    },
  },
  title: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: " #051931",
    textShadow: "0px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  description: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#051931",
    margin: "13px 0 31px",
  },
  phoneNumber: {
    fontFamily: "hurmeGeometeric",
    color: "#495057",
    fontSize: "0.875rem",
    borderRadius: "0.375rem",
    border: "0.01rem solid lightgray",
    height: "1.4375em",
    padding: "0.75rem",
    marginTop: "8px",
    width: "fit-content",
    "& .PhoneInputInput": {
      background: "rgb(249, 249, 250)",
    },
  },
};
const useStyles = makeStyles(styles);

const VerifyProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // let isValidPn = true;

  const [userData, setUserData] = useState();
  const [OTP, setOTP] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [resendTimmer, setResendTimmerValue] = useState(60);
  const [isGetCode, setIsGetCode] = React.useState(false);

  const [isPhoneNumber, setIsPhoneNumber] = useState(false);
  const renderTime = () => React.Fragment;

  const handleResendTimmer = () => {
    setResendTimmerValue(parseInt(resendTimmer, 10) + 60);
  };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleNumberChange = (number) => {
    setPhoneNumber(number);
  };

  // const phoneValidation = () => {
  //   /* eslint-disable no-useless-escape */
  //   const pattern = new RegExp(/^[+]*[(]{0,1}[0-9]{11,14}[)]{0,1}[-\s\./0-9]*$/);
  //   return !(!phoneNumber || pattern.test(phoneNumber) === false);
  // };

  // const onSubmit = () => {
  //   const isPhoneValid = phoneValidation();
  //   // if (isPhoneValid) {
  //   //   isValidPn = isPhoneValid;
  //   // } else {
  //   //   isValidPn = isPhoneValid;
  //   // }
  //   isValidPn = isPhoneValid;
  // };
  const getData = async () => {
    try {
      const res = await getCurrentUserData();
      if (res) {
        dispatch(setToken(res));
        localStorage.setItem("data", JSON.stringify(res));
        setUserData(res);
        if (res.phone) {
          // setPhoneNumber(res.phone.slice(2).replace(/.(?=...)/g, "*"));
          setPhoneNumber(res.phone);
          // sendOTPVerificationCode();
        }
      }
    } catch (err) {
      console.error("Error: ", err);
    }
  };
  const updatPhoneNumber = async () => {
    // if (isValidPn) {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      const request = {
        ...userData,
        phone: phoneNumber,
      };
      const res = await updateCurrentUserProfile(request);
      if (res && res.status && res.status === 200) {
        if (res.data.phone) {
          setUserData(res.data);
        }
        setSnackMessage("Mobile number updated");
        setIsPhoneNumber(false);
      } else {
        setSnackMessage(res.message);
      }
      // if (res.phone) {
      //   setUserData(res);
      // }
      // setSnackMessage("Mobile number updated");
      // setIsPhoneNumber(false);
    } else {
      setSnackMessage("Please enter correct Phone number");
    }
    setOpenSnack(true);
  };
  const renderButton = (buttonProps) => (
    <MDButton sx={{ backgroundColor: "#f9f9fa !important", color: "#de1921" }} {...buttonProps}>
      {buttonProps.remainingTime !== 0 ? (
        // ? `Please wait for ${buttonProps.remainingTime} sec for resend`
        <div style={{ color: "red" }}>{buttonProps.remainingTime} sec</div>
      ) : (
        "Resend"
      )}
    </MDButton>
  );

  // setIsGetCode(false);
  // const response = await submitOTPCode(OTP);
  // if (response) {
  //   if (response.data.contact_Verification_Code_Verified === true) {
  //     setOpenSnack(true);
  //     setSnackMessage("OTP Verified");
  //     await getData();
  //     // dispatch(setToken(userData));
  //     setIsGetCode(false);
  //   } else {
  //     setOpenSnack(true);
  //     setSnackMessage("Invalid OTP");
  //   }
  // }
  const sendOTPCode = async () => {
    try {
      if (OTP) {
        const response = await submitOTPCode(OTP);
        // setOTPResponse(response.contact_Verification_Code_Verified);
        if (response && response.status === 200) {
          if (response.data.contact_Verification_Code_Verified === true) {
            setOpenSnack(true);
            setSnackMessage("OTP Verified");
            await getData();
            setIsGetCode(false);
          } else {
            setOpenSnack(true);
            setSnackMessage("Internal error! Please contact synerge support team");
          }
        }
        if (response && response.status === 400) {
          setOpenSnack(true);
          setSnackMessage(response.message);
        }
      }
    } catch (err) {
      console.error("Error:", err);
      setOpenSnack(true);
      setSnackMessage("Internal error! Please contact synerge support team");
    }
  };

  useEffect(async () => {
    await getData();
    // setResendTimmerValue(parseInt(resendTimmer, 10) + 60);
  }, []);

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  return (
    <div style={{ backgroundColor: "#f9f9fa" }}>
      <Container
        maxWidth="xxl"
        sx={{ width: { md: "95%" }, margin: "auto", minHeight: { xs: "65vh !important" } }}
      >
        <BreadCrumb childRoute="Verification" />
        {userData ? (
          userData.phone && !isPhoneNumber ? (
            userData.phone_Verified ? (
              <MDBox sx={{ px: { xs: 0, md: "5%" }, height: "50vh" }}>
                <div style={{ display: "flex", paddingTop: "3%" }}>
                  <CheckCircleIcon sx={{ fontSize: "40px !important" }} />
                  <MDTypography style={{ margin: "auto", marginLeft: "1%" }}>
                    User profile verified
                  </MDTypography>
                </div>
              </MDBox>
            ) : isGetCode ? (
              <MDBox sx={{ px: { xs: 0, md: "5%" } }}>
                <MDTypography
                  sx={{
                    fontFamily: "Hurme Geometric Sans 3",
                    fontWeight: "600",
                    fontSize: "22px",
                    lineHeight: "100%",
                    color: "#051931",
                    marginTop: "44px",
                  }}
                >
                  Verification
                </MDTypography>
                <MDTypography
                  sx={{
                    fontFamily: "Hurme Geometric Sans 3",
                    fontWeight: "400",
                    fontSize: "18px",
                    lineHeight: " 100%",
                    color: "#9F9F9F",
                    marginTop: "10px",
                  }}
                >
                  Please enter verification code sent to your number{" **** *** "}
                  {phoneNumber.substr(phoneNumber.length - 3)}
                </MDTypography>

                <MDBox mt={4}>
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    // autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    // secure
                    inputStyles={{
                      borderLeft: "none",
                      borderTop: "none",
                      borderRight: "none",
                      broderBottom: "3px solid #051931",
                      marginRight: "4rem",
                      backgroundColor: "#f9f9fa",
                      fontWeight: "600",
                      fontSize: "24px",
                      lineHeight: "31px",
                    }}
                  />
                  {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                </MDBox>

                <MDTypography mt={4} className={classes.resentotp}>
                  <MDBox display="flex" flexDrection="row">
                    <MDBox
                      sx={{
                        marginRight: "2rem",
                        display: "flex",
                        alignItems: "center",
                        fontFamily: "Hurme Geometric Sans 3",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#051931",
                      }}
                    >
                      Didn’t receive the code?
                    </MDBox>
                    <ResendOTP
                      renderButton={renderButton}
                      renderTime={renderTime}
                      onResendClick={async () => {
                        const res = await sendOTPVerificationCode();
                        if (res.status === 200) await handleResendTimmer();
                        else {
                          setOpenSnack(true);
                          setSnackMessage(`${res.data.title} , ${res.data.detail}`);
                          setResendTimmerValue(0);
                        }
                      }}
                      maxTime={resendTimmer}
                    />
                  </MDBox>
                </MDTypography>
                <MDButton
                  color="error"
                  className={classes.btn}
                  onClick={async () => {
                    if (OTP && OTP.length === 4) {
                      await sendOTPCode();
                    } else {
                      setOpenSnack(true);
                      setSnackMessage("Please enter valid OTP");
                    }
                  }}
                >
                  Verify
                </MDButton>
                <MDTypography className={classes.or}>OR</MDTypography>
                {/* <MDTypography className={classes.resentotp}> */}
                <MDBox sx={{ paddingBottom: "5%" }}>
                  <MDButton
                    sx={{
                      cursor: "pointer",
                      color: "#4285F4",
                      backgroundColor: "#f9f9fa",
                      textTransform: "capitalize",
                      fontSize: 16,
                      padding: 0,
                      marginTop: "1%",
                      margin: "1rem 1.2rem 0rem",
                      "&:hover": {
                        backgroundColor: "#f9f9fa",
                      },
                    }}
                    onClick={() => setIsPhoneNumber(true)}
                  >
                    Change Number
                  </MDButton>
                </MDBox>
                {/* </MDTypography> */}
              </MDBox>
            ) : (
              <MDBox sx={{ px: { xs: 0, md: "5%" } }}>
                <MDTypography
                  sx={{
                    fontFamily: "Hurme Geometric Sans 3",
                    fontWeight: "600",
                    fontSize: "22px",
                    lineHeight: "100%",
                    color: "#051931",
                    marginTop: "44px",
                  }}
                >
                  Verification
                </MDTypography>
                <MDTypography
                  sx={{
                    fontFamily: "Hurme Geometric Sans 3",
                    fontWeight: "400",
                    fontSize: "18px",
                    lineHeight: " 100%",
                    color: "#9F9F9F",
                    marginTop: "10px",
                  }}
                >
                  Please verify your number{" **** *** "}
                  {phoneNumber.substr(phoneNumber.length - 3)}
                </MDTypography>
                <br />
                <MDButton
                  color="error"
                  sx={{ borderRadius: "30px", mt: 3 }}
                  // className={classes.btn}
                  onClick={async () => {
                    setIsGetCode(true);
                    const res = await sendOTPVerificationCode();
                    if (res.status !== 200) {
                      setOpenSnack(true);
                      setSnackMessage(res.message);
                    }
                  }}
                >
                  Get Code
                </MDButton>

                {/* <MDBox mt={4}>
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    // autoFocus
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    // secure
                    inputStyles={{
                      borderLeft: "none",
                      borderTop: "none",
                      borderRight: "none",
                      broderBottom: "3px solid #051931",
                      marginRight: "4rem",
                      backgroundColor: "#f9f9fa",
                    }}
                  /> */}
                {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                {/* </MDBox> */}
                <MDTypography className={classes.or}>OR</MDTypography>
                {/* <MDTypography className={classes.resentotp}> */}
                <MDBox sx={{ paddingBottom: "5%" }}>
                  <MDButton
                    sx={{
                      cursor: "pointer",
                      color: "#4285F4",
                      backgroundColor: "#f9f9fa",
                      textTransform: "capitalize",
                      fontSize: 16,
                      padding: 0,
                      // marginTop: "2%",
                      "&:hover": {
                        backgroundColor: "#f9f9fa",
                      },
                    }}
                    onClick={() => setIsPhoneNumber(true)}
                  >
                    Change Number
                  </MDButton>
                </MDBox>
                {/* </MDTypography> */}
              </MDBox>
            )
          ) : (
            <>
              <MDBox sx={{ px: { xs: 0, md: "5%" } }}>
                <Grid container justifyContent="space-between" mt={3}>
                  <Grid item xs={12}>
                    <Typography className={classes.title}>Update Mobile Number</Typography>
                    <Typography className={classes.description}>
                      We will update your mobile number in your profile.
                    </Typography>
                    {/* <Tooltip title="ex: +447911651780" placement="left-start">
                      <MDInput
                        label="Enter Mobile Number"
                        sx={{ width: { md: "30%", xs: "90%" } }}
                        fullWidth
                        required
                        variant="outlined"
                        color="primary"
                        margin="dense"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Tooltip> */}
                    <MDBox className={classes.phoneNumber}>
                      <PhoneInput
                        international
                        // initialValueFormat="national"
                        countryCallingCodeEditable={false}
                        defaultCountry="US"
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={(e) => handleNumberChange(e)}
                      />
                    </MDBox>
                    <MDBox>
                      <MDButton
                        className={classes.btn}
                        color="error"
                        onClick={() => {
                          // onSubmit();
                          updatPhoneNumber();
                          setIsGetCode(false);
                        }}
                      >
                        Update
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </>
          )
        ) : (
          <> </>
        )}

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={
              snackMessage === "OTP Verified" || snackMessage === "Mobile number updated"
                ? "success"
                : "error"
            }
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default VerifyProfile;
