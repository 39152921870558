import React from "react";
import PropTypes from "prop-types";
import { postIgnoredLead } from "constants/getData";
// import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import creditIcon from "assets/imgs/icons/creditIcon.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
// import { setPackageidCreditcs } from "redux/actions/action";
// import { useDispatch } from "react-redux";
import numberIcon from "assets/imgs/icons/numberIcon.png";
import messageIcon from "assets/imgs/icons/messageIcon.png";
import instructionIcon from "assets/imgs/icons/instructionIcon.png";
import respondedIcon from "assets/imgs/icons/respondedIcon.png";
import Snackbar from "@mui/material/Snackbar";
import MDCustomizedRating from "components/MDCustomizedRating";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import GroupRemoveRoundedIcon from "@mui/icons-material/GroupRemoveRounded";
import Tooltip from "@mui/material/Tooltip";
import Portal from "@mui/material/Portal";
import Loader from "views/Common/Loader";
import { makeStyles } from "@mui/styles";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/MDDialog";

function UserInfoCard({
  service,
  credits,
  experts_responded,
  posted_by,
  city,
  country,
  shadow,
  setShowModal,
  leadId,
  leadUpdating,
}) {
  const [filter, setShowFilter] = React.useState(false);
  const [reason1, setReason1] = React.useState(false);
  const [reason2, setReason2] = React.useState(false);
  const [reason3, setReason3] = React.useState(false);
  const [reason4, setReason4] = React.useState(false);
  const [reason5, setReason5] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [miniloading, setMiniloading] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [snackMessage, setSnackMessage] = React.useState("");
  // const dispatch = useDispatch();

  const handleFilterOpen = () => {
    setShowFilter(true);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  const postIgnoreLead = () => {
    setMiniloading(true);
    setSnackMessage("Lead skipped");
    postIgnoredLead(leadId, reason);
    // dispatch(setLeadDetail());
    setMiniloading(false);
  };

  const handleCloseSnack = (rsn) => {
    if (rsn === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const useStyles = makeStyles(() => ({
    customReasonButton: {
      padding: 15,
      borderRadius: "10px",
      width: "80%",
      cursor: "pointer",
    },
    skipBtn: {
      width: "32%",
      textTransform: "capitalize",
      borderRadius: "30px",
      fontSize: "14px",
      letterSpacing: "1px",
      marginLeft: "30%",
      marginTop: 35,
    },
    isActive: {
      border: "3px solid red",
    },
    headerModal: {
      fontFamily: "Hurme Geometric Sans 3",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "28px",
      lineHeight: "121.2%",
      textAlign: "center",
      color: "#051931",
    },
    MuiDialogDialog: {
      xs: {
        paddingLeft: "22px",
      },
      md: {
        paddingLeft: "32px",
      },
    },
    exportTextHeader: {
      color: "#051931",
      fontSize: "24px",
      fontFamily: "Hurme Geometric Sans 3",
      fontWeight: "600",
      lineHeight: "31px",
      fontStyle: "normal",
    },
    expertInfoText: {
      fontFamily: "Hurme Geometric Sans 3",
      fontStyle: " normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: " 23px",
    },
    expertResponse: {
      fontFamily: "Hurme Geometric Sans 3",
      fontStyle: " normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: " 20px",
    },
    notInterestBtn: {
      fontFamily: "Hurme Geometric Sans 3",
      fontStyle: " normal",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: " 20px",
      color: "#4285F4",
    },
    contactBtn: {
      fontFamily: "Hurme Geometric Sans 3",
      fontStyle: " normal",
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: " 21px",
      textAlign: "center",
      color: "#FFFFFF",
    },
  }));

  const classes = useStyles();

  return (
    <MDBox sx={{ boxShadow: !shadow && "none", pt: 4, width: "100%" }} m={{ width: "120%" }}>
      {" "}
      {/* <MDBox display="flex-column" justifyContent="space-between" alignItems="center" pt={2} px={2}> */}
      <MDBox display="flex-column" justifyContent="space-between" alignItems="center" px={2}>
        <MDTypography
          className={classes.exportTextHeader}
          // variant="h5"
        >
          {posted_by?.name}
        </MDTypography>
        <MDTypography className={classes.expertInfoText} sx={{ pb: 1 }}>
          {service?.name}
        </MDTypography>
        <MDTypography sx={{ pb: 1 }} className={classes.expertInfoText}>
          {`${city}, ${country}` || "N/A"}
        </MDTypography>
        {/* <MDTypography variant="h5" sx={{ pb: 2 }} fontWeight="light">
          <b> Lead Description: </b>
          {description && description.length > 0 ? description : "N/A"}
        </MDTypography> */}
        <MDBox mt={6} mb={7}>
          <MDBox display="flex" justifyContent="flex-start" flexWrap="wrap" sx={{ mb: 3 }}>
            <MDBox
              component="img"
              src={creditIcon}
              alt="Brand"
              color="white"
              bgColor="white"
              mr={2}
              width="1.5rem"
            />

            <MDTypography className={classes.expertInfoText}>{credits} Credits</MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-start" flexWrap="wrap" sx={{ mb: 3 }}>
            <MDBox
              component="img"
              src={numberIcon}
              alt="Brand"
              color="white"
              bgColor="white"
              mr={2}
              width="1.5rem"
            />

            <MDTypography>
              {posted_by?.phone || "N/A"}
              {posted_by?.phone_verified ? (
                <>
                  &nbsp;
                  <Tooltip title="Phone Number Verified" placement="top">
                    <span>
                      <VerifiedUserIcon
                        fontSize="small"
                        style={{ paddingTop: "1px", paddingLeft: "2px" }}
                      />{" "}
                    </span>
                  </Tooltip>
                  {/* <span style={{ color: "#4285F4", paddingBottom: "2px" }}>Verified</span> */}
                </>
              ) : (
                <>
                  &nbsp;
                  <Tooltip title="Phone Number Not Verified" placement="top">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.34821 0.000201799C3.72326 0.00325997 0.390815 3.0287 0.0488183 6.64773C-0.318172 9.73013 1.41375 12.9519 4.2405 14.2612C6.86563 15.5463 10.2351 15.1396 12.4123 13.1608C14.6275 11.2408 15.5955 7.96818 14.6224 5.17616C13.6971 2.32769 10.9661 0.14697 7.95835 0.0149816C7.75524 0.00167814 7.55169 -0.000812183 7.34821 0.000201799ZM7.4967 2.35047C10.1663 2.2727 12.6137 4.63102 12.6451 7.30599C12.7605 9.75311 10.925 12.1561 8.49022 12.5664C6.13417 13.0391 3.52108 11.6753 2.6964 9.38426C1.8086 7.1187 2.71436 4.25369 4.87535 3.0646C5.66547 2.59992 6.58022 2.34933 7.4967 2.35047ZM5.64276 4.19005L4.19125 5.6439L6.05908 7.51476L4.22499 9.35182L5.65346 10.7826L7.48759 8.94553L9.35651 10.8175L10.808 9.36362L8.93909 7.49169L10.7733 5.65456L9.34478 4.22379C8.73339 4.83616 8.122 5.44853 7.51062 6.0609L5.64276 4.19006V4.19005Z"
                        fill="#DE1921"
                      />
                    </svg>
                  </Tooltip>
                  &nbsp;
                  {/* <span style={{ color: "#DE1921" }}>Not Verified</span> */}
                </>
              )}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-start" flexWrap="wrap" sx={{ mb: 3 }}>
            <MDBox
              component="img"
              src={messageIcon}
              alt="Brand"
              color="white"
              bgColor="white"
              mr={2}
              width="1.5rem"
              height="1.3rem"
            />

            <MDTypography className={classes.expertInfoText}>
              {posted_by?.email}
              {posted_by?.email_verified ? (
                <>
                  <Tooltip title="Email Address Verified" placement="top">
                    <span>
                      <VerifiedUserIcon
                        fontSize="small"
                        style={{ paddingTop: "1px", paddingLeft: "2px" }}
                      />{" "}
                    </span>
                  </Tooltip>
                  {/* &nbsp; <VerifiedUserIcon fontSize="medium" style={{ paddingLeft: "2px" }} />{" "}
                  <span style={{ color: "#4285F4" }}>Verified</span> */}
                </>
              ) : (
                <>
                  &nbsp;
                  <Tooltip title="Email Address Not Verified" placement="top">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.34821 0.000201799C3.72326 0.00325997 0.390815 3.0287 0.0488183 6.64773C-0.318172 9.73013 1.41375 12.9519 4.2405 14.2612C6.86563 15.5463 10.2351 15.1396 12.4123 13.1608C14.6275 11.2408 15.5955 7.96818 14.6224 5.17616C13.6971 2.32769 10.9661 0.14697 7.95835 0.0149816C7.75524 0.00167814 7.55169 -0.000812183 7.34821 0.000201799ZM7.4967 2.35047C10.1663 2.2727 12.6137 4.63102 12.6451 7.30599C12.7605 9.75311 10.925 12.1561 8.49022 12.5664C6.13417 13.0391 3.52108 11.6753 2.6964 9.38426C1.8086 7.1187 2.71436 4.25369 4.87535 3.0646C5.66547 2.59992 6.58022 2.34933 7.4967 2.35047ZM5.64276 4.19005L4.19125 5.6439L6.05908 7.51476L4.22499 9.35182L5.65346 10.7826L7.48759 8.94553L9.35651 10.8175L10.808 9.36362L8.93909 7.49169L10.7733 5.65456L9.34478 4.22379C8.73339 4.83616 8.122 5.44853 7.51062 6.0609L5.64276 4.19006V4.19005Z"
                        fill="#DE1921"
                      />
                    </svg>
                  </Tooltip>
                </>
              )}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-start" flexWrap="wrap" sx={{ mb: 3 }}>
            <MDBox
              component="img"
              src={respondedIcon}
              alt="Brand"
              color="white"
              bgColor="white"
              mr={2}
              width="1.5rem"
            />
            <MDTypography className={classes.expertInfoText}>
              Number of experts responded
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-start" flexWrap="wrap" mb={3} mt={4}>
            <MDCustomizedRating experts_responded={experts_responded} />
            <MDTypography className={classes.expertResponse}>
              {experts_responded}/5 experts have responded
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <MDBox mb={3} lineHeight={1}>
          <MDButton
            variant="gradient"
            color="error"
            sx={{
              height: "100%",
              borderRadius: "30px",
              textTransform: "capitalize",
              width: "50%",
            }}
            onClick={async () => {
              await setShowModal(true);
              // dispatch(setPackageidCreditcs(""));
            }}
          >
            <MDTypography className={classes.contactBtn}>
              Contact{" "}
              {posted_by?.name && posted_by?.name.length > 10
                ? `${posted_by?.name.split(" ")[0]} ...`
                : posted_by?.name}{" "}
            </MDTypography>
          </MDButton>
          <MDButton variant="text" color="info" onClick={handleFilterOpen} sx={{ width: "50%" }}>
            <MDTypography className={classes.notInterestBtn}>Not Interested</MDTypography>
          </MDButton>
          <BootstrapDialog
            onClose={handleFilterClose}
            aria-labelledby="customized-dialog-title"
            open={filter}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleFilterClose}>
              <MDTypography className={classes.headerModal} sx={{ mt: 2, px: 5 }}>
                Why are you not interested?
              </MDTypography>
              <MDTypography
                textAlign="center"
                fontWeight="bold"
                variant="h6"
                sx={{ color: "darkGray", mt: 2, fontSize: "16px" }}
              >
                This helps us to find you the best leads
              </MDTypography>
              {miniloading ? (
                <Loader />
              ) : (
                <MDBox sx={{ mt: 3, pl: 4, py: 2 }}>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    sx={{ ml: "10px" }}
                    className={`${classes.customReasonButton} ${reason1 ? classes.isActive : ""}`}
                    onClick={() => {
                      setReason1(true);
                      setReason2(false);
                      setReason3(false);
                      setReason4(false);
                      setReason5(false);
                      setReason("I do not provide this service");
                    }}
                  >
                    <DoNotDisturbOnIcon sx={{ mr: 2, fontSize: 100 }} />
                    <MDTypography variant="h6" fontWeight="bold">
                      I do not provide this service
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    className={`${classes.customReasonButton} ${reason2 ? classes.isActive : ""}`}
                    onClick={() => {
                      setReason1(false);
                      setReason2(true);
                      setReason3(false);
                      setReason4(false);
                      setReason5(false);
                      setReason("Job is too small");
                    }}
                  >
                    <KeyboardArrowDownIcon sx={{ mr: 2, fontSize: 300 }} fontSize="large" />
                    <MDTypography variant="h6" fontWeight="bold">
                      Job is too small
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    className={`${classes.customReasonButton} ${reason3 ? classes.isActive : ""}`}
                    sx={{ ml: "10px" }}
                    onClick={() => {
                      setReason1(false);
                      setReason2(false);
                      setReason3(true);
                      setReason4(false);
                      setReason5(false);
                      setReason("I`m not available");
                    }}
                  >
                    <GroupRemoveRoundedIcon sx={{ mr: 2, fontSize: 100 }} />
                    <MDTypography
                      variant="h6"
                      fontWeight="bold"
                      fontFamily="Hurme Geometric Sans 3"
                    >
                      I`m not available
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    className={`${classes.customReasonButton} ${reason4 ? classes.isActive : ""}`}
                    sx={{ ml: "10px" }}
                    onClick={() => {
                      setReason1(false);
                      setReason2(false);
                      setReason3(false);
                      setReason4(true);
                      setReason5(false);
                      setReason("Not enough information provided");
                    }}
                  >
                    <MDBox component="img" src={instructionIcon} color="white" mr={2} />
                    <MDTypography variant="h6" fontWeight="bold">
                      Not enough information provided
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    className={`${classes.customReasonButton} ${reason5 ? classes.isActive : ""}`}
                    sx={{ ml: "10px" }}
                    onClick={() => {
                      setReason1(false);
                      setReason2(false);
                      setReason3(false);
                      setReason4(false);
                      setReason5(true);
                      setReason("Lead is too expensive");
                    }}
                  >
                    <MDBox component="img" src={creditIcon} color="white" mr={2} />
                    <MDTypography variant="h6" fontWeight="bold">
                      Lead is too expensive
                    </MDTypography>
                  </MDBox>
                  <MDBox sx={{ pl: 5, mt: 2 }}>
                    <MDTypography variant="h6" sx={{ mb: 1 }}>
                      Other
                    </MDTypography>
                    <MDInput
                      sx={{ width: "75%" }}
                      placeholder="Other (please specify)"
                      onChange={(e) => setReason(e.target.value)}
                      onClick={() => {
                        setReason1(false);
                        setReason2(false);
                        setReason3(false);
                        setReason4(false);
                        setReason5(false);
                      }}
                    />
                  </MDBox>
                  <MDButton
                    className={classes.skipBtn}
                    color="error"
                    onClick={() => {
                      if (reason === "") {
                        // alert("Please select a reason");
                        setSnackMessage("Please select a reason");
                        handleClickSnack();
                      } else {
                        postIgnoreLead();
                        handleFilterClose();
                        handleClickSnack();
                        leadUpdating();
                      }
                    }}
                  >
                    Skip
                  </MDButton>
                </MDBox>
              )}
            </BootstrapDialogTitle>
          </BootstrapDialog>
        </MDBox>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
      </MDBox>
      <Portal>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            elevation={6}
            severity={snackMessage === "Lead skipped" ? "success" : "error"}
            sx={{ width: "100%", zIndex: 9 }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Portal>
    </MDBox>
  );
}

// Setting default props for the ProfileInfoCard
UserInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
UserInfoCard.propTypes = {
  credits: PropTypes.string.isRequired,
  posted_by: PropTypes.object,
  city: PropTypes.string,
  country: PropTypes.string,
  service: PropTypes.object,
  experts_responded: PropTypes.string,
  setShowModal: PropTypes.func,
  shadow: PropTypes.bool,
  leadId: PropTypes.string,
  leadUpdating: PropTypes.func,
};

export default UserInfoCard;
