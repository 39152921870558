// import { CreditCard as validators } from "credit-card-validate";

const capatalizeFisrtLetterOFWord = (text) => text.charAt(0).toUpperCase() + text.slice(1);

export const validateCreditCardExpiry = (expiry) => {
  const date_regex = /^\d{2}\/\d{2,4}$/;
  return date_regex.test(expiry);
};

export const validateYourCard = (number, expiry, cvc, cardType) => {
  /* eslint-disable global-require */
  const validators = require("credit-card-validate");
  let cardTypeUsed;
  if (cardType === "amex") {
    cardTypeUsed = "AmericanExpress";
  } else if (cardType === "mastercard") {
    cardTypeUsed = "MasterCard";
  } else if (cardType === "dinersclub") {
    cardTypeUsed = "DinersClub";
  } else if (cardType === "jcb") {
    cardTypeUsed = "JCB";
  } else {
    cardTypeUsed = capatalizeFisrtLetterOFWord(cardType);
  }
  let cardValidator = [];
  cardValidator = new validators.cards[cardTypeUsed](
    number?.toString(),
    new Date(`20${expiry[1]}`, expiry[0]),
    cvc?.toString()
  );
  return cardValidator.isValid();
};

export const countriesArrayToQueryString = (selectedCountry) => {
  const countriesAreChecked = [];
  let filterLeadByCountryID = "";
  if (selectedCountry && selectedCountry.length > 0) {
    selectedCountry.map((x) => countriesAreChecked.push({ name: x.name, id: x.id }));
    if (countriesAreChecked && countriesAreChecked.length > 0) {
      const country = "&countries=";
      for (let i = 0; i < countriesAreChecked.length; i++) {
        if (i === 0) {
          filterLeadByCountryID += countriesAreChecked[i].name;
        } else {
          filterLeadByCountryID += country + countriesAreChecked[i].name;
        }
      }
    }
  }
  return filterLeadByCountryID;
};
