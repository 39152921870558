import React from "react";
import EmailNotification from "views/Client/clientComponents/SettingsComponents/EmailNotification";
import BreadCrumb from "views/Common/BreadCrumb";

const EmailNotifications = () => (
  <>
    <BreadCrumb childRoute="Notification" />
    <EmailNotification />
  </>
);

export default EmailNotifications;
