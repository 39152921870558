import React from "react";
import colors from "assets/theme/base/colors";
import { makeStyles } from "@mui/styles";
import { Divider, Container, Grid, Paper, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// import Radio from "@mui/material/Radio";
import { useSelector, useDispatch } from "react-redux";
// import { getPakagesList, postPurchaseCreditsPackage, getuserUpdate } from "constants/getData";
import { getPakagesList, postPurchaseCreditsPackage } from "constants/getData";
import { validateYourCard, validateCreditCardExpiry } from "constants/helper";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Loader from "views/Common/Loader";
import CloseIcon from "@mui/icons-material/Close";
import SimpleBar from "simplebar-react";
import creditIcon from "assets/imgs/icons/creditIcon.png";
// import MDButton from "components/MDButton";`
import {
  removePackageId,
  removePaymentMethodId,
  removeCredits,
  setToken,
} from "redux/actions/action";
import Snackbar from "@mui/material/Snackbar";
import store from "redux/store";
import BreadCrumb from "views/Common/BreadCrumb";
import { BootstrapDialog, BootstrapDialogTitle } from "components/MDDialog";
import {
  getPaymentMethods,
  getUserPurchaseHistory,
  // createPaymentMethod,
  getCurrentUserData,
} from "constants/userSettings";
import PurchasedCreditsHistory from "./PurchasedCreditsHistory";
import UserInfo from "../FourTypeOfModals/UserInfo";
import MyCards from "./MyCards";
import { getCardTypeByValue } from "../FourTypeOfModals/CreditCardUtils";

const { error, white, dark } = colors;

const styles = {
  isActive: {
    border: "2px solid red",
  },
  paper: {
    display: "flex",
    flrxDirection: "row",
    alignItems: "center",
    padding: "15px 15px 15px 0",
    marginTop: "26px",
  },
  semiboldFont: {
    fontFamily: "hurmeGeometeric-Semi-Bold",
  },
  warning: {
    margin: "0 16px",
    "& span": {
      backgroundColor: error.main,
      color: white.main,
      padding: "4px 10px",
      borderRadius: "50%",
    },
  },
  description: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "#9F9F9F",
  },
  title: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: " 25px",
    color: dark.main,
    marginTop: "40px",
  },
  discount: {
    background:
      " linear-gradient(91.53deg, rgba(5, 25, 49, 0.8) 23.39%, rgba(222, 25, 33, 0.8) 104.35%)",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: "3px",
    textAlign: "center",
    padding: "14px 30px",
    marginTop: "42px",
  },
  btn: {
    background: "#DE1921",
    borderRadius: "30px",
    fontSize: " 16px",
    color: "#FFFFFF",
    padding: "0.8rem 1.625rem",
    margin: "45px 0 27px",
    "&:hover": {
      backgroundColor: "#DE0921",
    },
  },
  button: {
    borderRadius: "30px",
    textTransform: "Capitalize",
    marginTop: "25px",
  },
  buttonProperties4: {
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: "lighter",
    borderRadius: "25px",
  },
};

const useStyle = makeStyles(styles);

const MyCredits = () => {
  const dispatch = useDispatch();

  const classes = useStyle();
  const data = useSelector((state) => state?.setTokenReducer?.data);
  const credit = useSelector((state) => state?.userLeadDetail?.credits);
  const credits = data?.credit_Balance;
  const [packageList, setPackageList] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [filter, setShowFilter] = React.useState(false);
  const [miniLoading, setMiniLoading] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [SavedCards, setSavedCardsValue] = React.useState([]);
  const [cardPurchaseHistory, setCardPurchaseHistory] = React.useState([]);
  const packageCredits = useSelector((state) => state?.userLeadDetail?.packageIdCredits);
  // const [cardDetails, setCardDetails] = React.useState([]);
  // const [ID, setID] = React.useState("");
  // const [creditCardDetail, setCreditCardDetail] = React.useState();
  const creditCardDetail = {
    brand: "",
    exp_Year: null,
    exp_month: null,
    number: "",
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleFilterOpen = () => {
    setShowFilter(true);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  const getPackageId = () => {
    const stat = store.getState((state) => state?.userLeadDetail);
    return stat?.userLeadDetail?.packageID;
  };

  const getPaymentMethodID = () => {
    const stat = store.getState((state) => state?.userLeadDetail);
    return stat?.userLeadDetail?.paymentMethodID;
  };

  const getUserPurchaseHistoryData = async () => {
    try {
      const response = await getUserPurchaseHistory();
      setCardPurchaseHistory(response.purchaseHistory);
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const getCardData = async () => {
    try {
      await getUserPurchaseHistoryData();
      // const response = await getUserPurchaseHistory();
      const res = await getPaymentMethods();
      setSavedCardsValue(res.data);
      // setCardPurchaseHistory(response.purchaseHistory);.
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const getCurrentUserInfo = async () => {
    // const updatedUser = await getuserUpdate();
    // dispatch(setToken(updatedUser.data));
    // localStorage.setItem("data", JSON.stringify(updatedUser.data));
    const userData = await getCurrentUserData();
    dispatch(setToken(userData));
    localStorage.setItem("data", JSON.stringify(userData));
  };

  const buyCreditsWithPaymentID = async () => {
    setMiniLoading(true);
    const packageID = getPackageId();
    const paymentMethID = getPaymentMethodID();
    const res = await postPurchaseCreditsPackage(packageID, paymentMethID, creditCardDetail, false);
    const { status } = res;
    if (status >= 200 && status <= 299) {
      setSnackMessage("Successfully bought credits");
      await getCurrentUserInfo();
    } else {
      setSnackMessage("Error buying credits, try again");
    }
    setMiniLoading(false);
  };

  const buyCreditsWithCreditCard = async () => {
    setMiniLoading(true);
    const packageID = getPackageId();
    const monthAndYear = credit.expiry.split("/");
    const cardDetails = {
      expMonth: monthAndYear[0].trim(),
      expYear: monthAndYear[1].trim(),
      Number: credit.cardNumber,
      CVC: credit.cvc,
    };
    // const response = await createPaymentMethod(
    //   monthAndYear[0].trim(),
    //   monthAndYear[1].trim(),
    //   credit.cardNumber,
    //   credit.cvc,
    //   credit.isDefaultCard
    // );
    // if (response.status === 200) {
    const res = await postPurchaseCreditsPackage(
      packageID,
      "",
      cardDetails,
      credit.isDefaultCard === undefined ? true : credit.isDefaultCard
    );
    const { status } = res;
    if (status >= 200 && status <= 299) {
      setSnackMessage("Successfully bought credits");
      await getCurrentUserInfo();
    } else if (res === []) {
      setSnackMessage("Error buying credits, try again");
    } else {
      setSnackMessage("Error buying credits, try again");
    }
    // } else if (response.status !== 200 && response.data) {
    //   setSnackMessage(response.data.detail);
    // } else {
    //   setSnackMessage("server error, please contact to synerge support team.");
    // }

    setMiniLoading(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  React.useEffect(async () => {
    setLoading(true);
    getCardData();
    dispatch(removePackageId());
    try {
      const offerList = await getPakagesList();
      setPackageList(offerList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  }, []);

  return (
    <div style={{ backgroundColor: "#f9f9fa", height: { xs: "50vh", sm: "100vh", md: "100vh" } }}>
      <Container maxWidth="xxl" sx={{ width: { md: "95%" }, margin: "auto", paddingBottom: "5%" }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={`${
              snackMessage === "Submitted successfully" ||
              snackMessage === "Successfully bought credits"
                ? "success"
                : "error"
            }`}
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>

        <BreadCrumb childRoute="My Credits" />
        <MDBox sx={{ px: { xs: 0, md: "5%" } }}>
          <Grid container mb={6}>
            <Grid item xs={12} md={7}>
              <Paper className={classes.paper}>
                <MDBox className={classes.warning}>
                  <span>i</span>
                </MDBox>
                <MDBox>
                  <Typography className={classes.description}>
                    Credits are used to contact customers on Synerge. You can find out more about
                    credits and how we charge for our service in the{" "}
                    <a
                      href="https://www.synerge.io/resources-freelancers/"
                      target="_blank"
                      rel="noreferrer noopener"
                      style={{ color: "red" }}
                    >
                      Help Centre
                    </a>
                    .
                  </Typography>
                </MDBox>
              </Paper>
              <Typography className={`${classes.title} ${classes.semiboldFont}`}>
                Credit Balance
              </Typography>
              <Grid container mt={3}>
                <img src={creditIcon} alt="credit icon" />
                <Typography ml={1} sx={{ fontSize: "18px", lineHeight: "23px", color: dark.main }}>
                  {credits} Credits
                </Typography>
              </Grid>
              <Typography
                className={classes.semiboldFont}
                mt={4}
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: " 25px",
                  color: dark.main,
                  marginBottom: 7,
                }}
              >
                Offers
              </Typography>
              <Paper>
                <Grid p={{ md: 5, xs: "40px 0" }}>
                  <UserInfo packageList={packageList} loading={loading} />
                </Grid>
              </Paper>
              <MDButton
                color="error"
                size="large"
                className={classes.button}
                onClick={async () => {
                  const p = await getPackageId();
                  dispatch(removePaymentMethodId());
                  dispatch(removeCredits());
                  if (p && p.length > 0) {
                    getCardData();
                    handleFilterOpen();
                  } else {
                    setSnackMessage("Please select a package");
                    handleClickSnack();
                  }
                }}
              >
                Buy Package
              </MDButton>
            </Grid>
            <Grid
              item
              xs={0}
              md={1}
              sx={{
                display: { xs: "none", md: "flex" },
              }}
            >
              <BootstrapDialog
                onClose={handleFilterClose}
                aria-labelledby="customized-dialog-title"
                open={filter}
                sx={{ overflowX: "hidden", margin: 0, padding: 0 }}
              >
                <MDBox sx={{ width: { xs: "300px", sm: "576px" }, padding: 0, overflow: "hidden" }}>
                  <BootstrapDialogTitle id="customized-dialog-title" onClose={handleFilterClose}>
                    <MDBox textAlign="center" sx={{ mt: 2 }}>
                      <MDTypography variant="h3" fontWeight="bold">
                        Please select a card.
                      </MDTypography>
                      <MDTypography variant="h6" fontWeight="light" sx={{ mt: 2 }}>
                        Select or add a card you want to buy credits from.
                      </MDTypography>
                    </MDBox>
                  </BootstrapDialogTitle>
                  {miniLoading ? (
                    <Loader />
                  ) : (
                    <DialogContent>
                      <SimpleBar style={{ maxHeight: "50vh" }} className="scroll" autoHide={false}>
                        <MDBox
                          sx={{
                            position: "relative",
                            maxHeight: "45vh",
                          }}
                        >
                          <MyCards SavedCards={SavedCards} />
                        </MDBox>
                      </SimpleBar>
                    </DialogContent>
                  )}
                  <DialogActions sx={{ justifyContent: "center", my: 1 }}>
                    <div>
                      <MDButton
                        className={`${classes.buttonProperties4}`}
                        color="dark"
                        size="large"
                        sx={{ mr: 5, minWidth: "205px", boxSizing: "revert" }}
                        onClick={() => {
                          handleFilterClose();
                        }}
                      >
                        Close
                      </MDButton>
                      <MDButton
                        className={`${classes.buttonProperties4}`}
                        color="error"
                        size="large"
                        sx={{ minWidth: "205px" }}
                        onClick={async () => {
                          const IDEE = getPaymentMethodID();
                          if (
                            IDEE &&
                            IDEE.length > 0 &&
                            !credit.cardNumber &&
                            !credit.cvc &&
                            !credit.expiry
                          ) {
                            await buyCreditsWithPaymentID();
                            handleClickSnack();
                            handleFilterClose();
                            await getUserPurchaseHistoryData();
                          } else if (credit.cardNumber && credit.cvc && credit.expiry) {
                            const cardType = getCardTypeByValue(credit.cardNumber);
                            if (cardType && cardType.type) {
                              if (validateCreditCardExpiry(credit.expiry)) {
                                const validate = validateYourCard(
                                  credit.cardNumber.replace(/\s/g, ""),
                                  credit.expiry.split("/"),
                                  credit.cvc,
                                  cardType.type
                                );
                                if (validate) {
                                  await buyCreditsWithCreditCard();
                                  handleClickSnack();
                                  handleFilterClose();
                                  await getUserPurchaseHistoryData();
                                } else {
                                  setSnackMessage("Please Provide Correct Card Details");
                                  handleClickSnack();
                                }
                              } else {
                                setSnackMessage("Please Provide Correct Card Expiry");
                                handleClickSnack();
                              }
                            } else {
                              setSnackMessage("Please provide card details");
                              handleClickSnack();
                            }
                          } else {
                            setSnackMessage("Please provide card details");
                            handleClickSnack();
                          }
                        }}
                      >
                        Buy {packageCredits} Credits
                      </MDButton>
                    </div>
                  </DialogActions>
                </MDBox>
                <Snackbar
                  open={openSnack}
                  autoHideDuration={6000}
                  action={action}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  onClose={handleCloseSnack}
                >
                  <Alert
                    onClose={handleCloseSnack}
                    severity={`${
                      snackMessage === "Submitted successfully" || snackMessage === "Successful"
                        ? "success"
                        : "error"
                    }`}
                    sx={{ width: "100%" }}
                  >
                    {snackMessage}
                  </Alert>
                </Snackbar>
              </BootstrapDialog>
              <Divider
                sx={{ minHeight: "100vh", width: 3, margin: "0 3rem" }}
                orientation="vertical"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography mb={3} className={classes.title}>
                Purchase History
              </Typography>
              {cardPurchaseHistory &&
              cardPurchaseHistory.length > 0 &&
              cardPurchaseHistory.sort((a, b) => new Date(b.created) - new Date(a.created)) ? (
                <SimpleBar style={{ maxHeight: "60vh" }} className="scroll" autoHide={false}>
                  <MDBox
                    sx={{
                      position: "relative",
                      maxHeight: "59vh",
                    }}
                  >
                    {cardPurchaseHistory.map((x) => (
                      <>
                        <MDBox sx={{ pr: 4 }}>
                          <PurchasedCreditsHistory purchaseHistory={x} />
                        </MDBox>
                      </>
                    ))}
                  </MDBox>
                </SimpleBar>
              ) : (
                <MDTypography variant="h6" sx={{ my: 3 }}>
                  No purchase history found
                </MDTypography>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Container>
    </div>
  );
};

export default MyCredits;
