import React, { useState } from "react";
// import { get } from "axios-setup";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { useDispatch } from "react-redux";
import { setPublicQuestions } from "redux/actions/action";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Divider from "@mui/material/Divider";
import { Card, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import MDTypography from "components/MDTypography";
import { getPublicServiceAreasList } from "constants/publicApiCalls";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import MDAvatar from "components/MDAvatar";
import TimlineImage from "assets/imgs/component/RequiredServicesImages/Timeline.png";
import SearchLoader from "views/Common/Loader";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import {
  getSelectedServiceQuestionList,
  getPublicServiceList,
} from "../../constants/publicApiCalls";
// import icon from "assets/imgs/icons/card.png";

const RequiredServiceCardPublic = ({
  title,
  description,
  placeholderText,
  // getSignUp,
  // loader,
  // services,
  isBackButtonClick,
  setCurrentService,
  setToggle,
  setBackButton,
}) => {
  const { search } = useLocation();
  const { service_id } = queryString.parse(search);
  const [searchDropdown, setSearchDropdown] = useState([]);
  const [getActivity, setActivity] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loader, setLoader] = useState(true);
  const [services, setServices] = useState("");
  const [mainLoader, setMainLoader] = useState(service_id);

  // const userData = useSelector((state) => state.setTokenReducer?.data);

  const dispatch = useDispatch();

  // console.log("Services:", services);
  const searchActivity = async (event) => {
    if (event.target.value && event.target.value.length > 2) {
      setSearchLoading(true);
      const obj = event.target.value;
      //   const requestOptions = {
      //     isAuth: true,
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     endPoint: `services`,
      //     params: {
      //       page_size: 20,
      //       continuation_token: "",
      //       search_string: obj,
      //       active: true,
      //     },
      //   };
      //   const res = await get(requestOptions);
      const res = await getPublicServiceList(obj);
      setSearchLoading(false);
      try {
        if (res.data) {
          if (res.data.data && res.data.data.length > 0) {
            setSearchDropdown(res.data.data);
          } else {
            // passing N/A from front end
            setSearchDropdown([{ id: "0", name: "N/A" }]);
          }
        } else {
          // passing N/A from front end
          setSearchDropdown([{ id: "0", name: "N/A" }]);
        }
      } catch (error) {
        console.error("client error", error);
      }
    } else {
      setSearchDropdown([]);
    }
  };
  const debouncedChangeHandler = React.useCallback(debounce(searchActivity, 200), []);

  const SetCurrentSelectedService = (serviceId, serviceName, setToggleValue) => {
    setCurrentService({
      id: serviceId,
      name: serviceName,
    });
    if (setToggleValue) {
      setToggle(false);
      setMainLoader(false);
      setBackButton(false);
    }
  };

  const getServicesQuestions = async (serviceId, serviceName) => {
    SetCurrentSelectedService(serviceId, serviceName, false);

    // const requestOptions = {
    //   isAuth: true,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   endPoint: `services/${serviceId}/questions`,
    //   params: {
    //     page_size: 50,
    //     continuation_token: "",
    //     active: true,
    //   },
    // };
    // const data = await get(requestOptions);

    const data = await getSelectedServiceQuestionList(serviceId);
    // console.log("Dynamic Questions: ", data);
    // console.log("PREVIOUS: ", data.data.serviceQuestion);
    if (data && data.data && data.data.serviceQuestion && data.data.serviceQuestion.length > 0) {
      // data.data.serviceQuestion = data.data.serviceQuestion.filter((q) => q.required === true);
      data.data.serviceQuestion.forEach((element) => {
        element.questionoptions.forEach((element1) => {
          element1.optionvalue = element1.optiontext;
        });
      });
      // console.log("NEW: ", data.data.serviceQuestion);
    }
    try {
      const staticQuestion = [
        {
          id: "projectDuration",
          questionType: "radioStatic",
          questionoptions: [
            {
              optiontext: "Short term or part-time work",
              optionvalue: "short",
              // description: "Less than 30 hrs/week less than 3 months",
              description: "Less than 30 hrs/week",
              description1: "Less than 3 months",

              iconURL: "shortterm",
            },
            {
              optiontext: "Designated, longer term work",
              optionvalue: "long",
              description: "More than 30 hrs/week",
              description1: "3+ months",
              iconURL: "longerterm",
            },
          ],
          required: true,
          title: "Please select expected duration of this project",
        },
        {
          id: "projectType",
          questionType: "radioStatic",
          questionoptions: [
            {
              optiontext: "One-time project",
              optionvalue: "one-time",
              description: "Find the right skills for short term need",
              iconURL: "Onetime",
            },
            {
              optiontext: "Ongoing project",
              optionvalue: "ongoing",
              description: " Find a skilled resource for an extended engagement.",
              iconURL: "Ongoing",
            },
          ],
          required: true,
          title: "What type of project do you have?",
        },
        {
          id: "budgetType",
          questionType: "radioStatic",
          questionoptions: [
            {
              optiontext: "Pay by the hour",
              optionvalue: "hourly",
              description: "Pay hourly to easily scale up and down.       ",
              iconURL: "hourly",
            },
            {
              optiontext: "Pay a fixed price",
              optionvalue: "fixed",
              description: "Define payment before work begins and pay only when work is delivered",
              iconURL: "fixed",
            },
          ],
          required: true,
          title: "How would you like to pay your freelancer or agency?",
        },
        {
          id: "budgetStaicID",
          questionType: "budgetStaic",
          budgetReference: "",
          questionoptions: [
            {
              optiontext: "budget",
              optionvalue: 0,
            },
          ],
          required: true,
          title: "Do you have a specific budget? (in US Dollars)",
        },
        {
          id: "startTime",
          questionType: "radioStatic",
          questionoptions: [
            {
              optiontext: "Immediately",
              optionvalue: "Immediately",
              description: "I want to get started right away",
              iconURL: "Immediately",
            },
            {
              optiontext: "After sometime",
              optionvalue: "aftersometime",
              description: "I need sometime before i initiate the project",
              iconURL: "aftersometime",
            },
          ],
          required: true,
          title: "When do you want to start your project?",
        },
        {
          id: "startDate",
          questionType: "datePicker",
          questionoptions: [
            {
              optiontext: "Pick a date",
              optionvalue: "",
            },
          ],
          required: true,
          title: "Expected start date?",
        },
      ];

      const leadDescription = {
        id: "leadDesc",
        questionType: "textarea",
        questionoptions: [
          {
            optiontext: "Lead description",
            optionvalue: "",
            description: "Less than 30 hrs/week less than 3 months",
          },
        ],
        required: true,
        title: "Project Description",
        titleDescription: "Please explain your project in detail.",
      };
      data.data.serviceQuestion.push(...staticQuestion);
      data.data.serviceQuestion.unshift(leadDescription);
      dispatch(setPublicQuestions(data.data.serviceQuestion));
      setToggle(false);
      setBackButton(false);
    } catch (error) {
      console.error("client error", error);
      dispatch(setPublicQuestions([]));
    }
  };
  const setValueInInput = (e) => {
    setActivity(e.target.value);
    debouncedChangeHandler(e);
  };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleClickSnack = () => {
    setOpenSnack(true);
  };
  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const handleSubmitButton = () => {
    for (let index = 0; index < services.length; index++) {
      const element = services[index].services;

      const searchData = element.filter((x) => x.name.toLowerCase() === getActivity.toLowerCase());
      if (searchData.length > 0) {
        // if (userData && userData.role === "expert") {
        //   SetCurrentSelectedService(searchData[0].id, searchData[0].name, true);
        // } else {
        //   getServicesQuestions(searchData[0].id, searchData[0].name);
        // }
        getServicesQuestions(searchData[0].id, searchData[0].name);
        return;
      }
    }
    handleClickSnack();
    setSnackMessage("Select your service");
  };
  const getServiceList = async () => {
    const res = await getPublicServiceAreasList();
    try {
      setServices(res.data.data);
      setLoader(false);
    } catch (error) {
      console.log("client error", error);
    }
    if (!isBackButtonClick) {
      if (service_id) {
        let filterData;
        res.data.data.forEach((element) => {
          const selectedService = element.services.find((x) => x.id === service_id);
          if (selectedService) {
            filterData = {
              id: selectedService.id,
              name: selectedService.name,
            };
          }
        });
        if (filterData) {
          getServicesQuestions(filterData.id, filterData.name);
        } else {
          setMainLoader(false);
          setOpenSnack(true);
          setSnackMessage("Service Data Not Found");
        }
      }
    } else {
      setMainLoader(false);
    }
  };
  React.useEffect(() => {
    getServiceList();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-filename-extension

    <Grid
      style={{ backgroundColor: "white" }}
      my={10}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {/* card to given width */}
      <Card
        sx={{
          width: {
            sm: 576,
            md: 768,
            lg: 992,
          },
          boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
        }}
      >
        {mainLoader ? (
          <SearchLoader />
        ) : (
          <>
            <MDBox
              p={2}
              mx={3}
              display="flex"
              justifyContent="center"
              color="white"
              bgColor="white"
            >
              <MDBox
                display="grid"
                justifyContent="center"
                alignItems="center"
                bgColor="white"
                color="white"
                height="4rem"
                borderRadius="lg"
                variant="gradient"
              >
                <MDTypography variant="h2" fontWeight="medium" textTransform="capitalize">
                  {title}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25} color="white" bgColor="white">
              <MDTypography variant="h6" color="text" fontWeight="regular">
                {description}
              </MDTypography>
              <MDBox
                justifyContent="center"
                pb={2}
                px={2}
                my={4}
                lineHeight={1.25}
                color="white"
                bgColor="white"
              >
                <Paper
                  component="form"
                  sx={{
                    p: "0px 0px",
                    display: "flex",
                    alignItems: "center",
                    margin: "auto",
                    width: { xs: "100%", md: "75%" },
                    position: "relative",
                  }}
                >
                  <InputBase
                    sx={{ p: 2, ml: 1, flex: 1, height: { md: 70, xs: 70 } }}
                    placeholder={placeholderText}
                    inputProps={{ "aria-label": "What service do you need?" }}
                    value={getActivity}
                    onChange={setValueInInput}
                  />

                  <MDButton
                    color="error"
                    // onClick={getSignUp}
                    // disabled={!getActivity}
                    onClick={handleSubmitButton}
                    sx={{
                      p: 4,
                      ml: 1,
                      textTransform: "capitalize",
                      height: { md: 70, xs: 70 },
                      width: { md: "25%", xs: "40%" },
                      fontSize: { md: 18, xs: 16 },
                    }}
                  >
                    Submit
                  </MDButton>
                </Paper>

                <MDBox
                  sx={{
                    position: "absolute",
                    zIndex: "1",
                    // marginTop: "-200px",
                    width: "100%",
                    // width: { xs: "60%", md: "41%" },
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: "0rem 0rem 1rem 1rem",
                      marginTop: "-5px",
                      // zIndex: -1,
                      width: { xs: "52%", md: "52.5%" },
                      // width: { xs: "60%", md: "41%" },
                      marginLeft: { xs: "0%", md: "11.7%" },
                      // marginLeft: { xs: "0%", md: "9%" },
                      // marginRight: "auto",
                    }}
                  >
                    {searchLoading ? (
                      <MDBox m="-5rem" height="4rem">
                        <SearchLoader />
                      </MDBox>
                    ) : (
                      searchDropdown && (
                        <div>
                          {searchDropdown.map((x) => (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                            <>
                              {x.name === "N/A" ? (
                                <MDTypography
                                  variant="body2"
                                  sx={{
                                    margin: "5px",
                                    textAlign: "start",
                                    paddingLeft: "2rem",
                                  }}
                                  color={x.name === "N/A" ? "error" : "dark"}
                                >
                                  {x.name}
                                </MDTypography>
                              ) : (
                                <MDBox
                                  bgColor="white"
                                  key={x.id}
                                  onBlur={() => setSearchDropdown("")}
                                  onClick={() => {
                                    setActivity(x.name);
                                    setSearchDropdown("");
                                    // getServicesQuestions(x.id, x.name);
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                    paddingLeft: "2rem",
                                  }}
                                  textAlign="left"
                                >
                                  <MDTypography
                                    variant="body2"
                                    sx={{
                                      margin: "5px",
                                      "&:hover": {
                                        color: "#f44335",
                                      },
                                    }}
                                    color={x.name === "N/A" ? "error" : "dark"}
                                  >
                                    {x.name}
                                  </MDTypography>
                                </MDBox>
                              )}
                              <Divider sx={{ height: 1, m: 0.5 }} orientation="horizontal" />
                            </>
                          ))}
                        </div>
                      )
                    )}
                  </Paper>
                </MDBox>
              </MDBox>
            </MDBox>
            {loader ? (
              <Grid my={10} container direction="row" justifyContent="center" alignItems="center">
                <CircularProgress color="inherit" />
              </Grid>
            ) : (
              // main grid and flex done : UI FIXED
              <MDBox pb={3} bgColor="white " justifyContent="center" textAlign="center">
                <Grid container justifyContent="center" alignItems="flexStart" px={2}>
                  {services &&
                    services?.map((prop) => (
                      <>
                        <Grid item xs={12} md={4} key={prop.id}>
                          <MDBox mx={3} display="flex" justifyContent="center">
                            <MDBox
                              display="grid"
                              justifyContent="center"
                              color="white"
                              width="4rem"
                              height="4rem"
                            >
                              <MDAvatar
                                src={prop.icon ? prop.icon : TimlineImage}
                                alt="Avatar"
                                size="md"
                                variant="square"
                                sx={{ padding: "5%" }}
                              />
                            </MDBox>
                          </MDBox>
                          <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25} bgColor="white">
                            <MDTypography
                              variant="h6"
                              fontWeight="medium"
                              textTransform="capitalize"
                              fontSize="1.3rem"
                            >
                              {prop?.name}
                            </MDTypography>
                            {prop?.services?.map((item, key) => (
                              <MDBox
                                // eslint-disable-next-line react/no-array-index-key
                                key={key}
                                mx={3}
                                display="column"
                                justifyContent="center"
                                bgColor="white"
                                sx={{ cursor: "pointer" }}
                              >
                                <MDTypography
                                  variant="caption"
                                  color="black"
                                  fontWeight="regular"
                                  fontSize="0.9rem"
                                  lineHeight="1.75"
                                  onClick={() => {
                                    // if (userData.role === "expert") {
                                    //   SetCurrentSelectedService(item.id, item.name, false);
                                    // } else {
                                    //   getServicesQuestions(item.id, item.name);
                                    // }
                                    getServicesQuestions(item.id, item.name);
                                  }}
                                  sx={{
                                    "&:hover": {
                                      color: "#f44335",
                                    },
                                  }}
                                >
                                  {item.name}
                                </MDTypography>
                              </MDBox>
                            ))}
                          </MDBox>
                        </Grid>
                      </>
                    ))}
                </Grid>
              </MDBox>
            )}
          </>
        )}
      </Card>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackMessage !== "Service Data Not Found" ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default RequiredServiceCardPublic;

RequiredServiceCardPublic.propTypes = {
  title: PropTypes.string,
  placeholderText: PropTypes.string,
  description: PropTypes.string,
  // getSignUp: PropTypes.func,
  // loader: PropTypes.bool,
  // services: PropTypes.array,
  setCurrentService: PropTypes.object,
  isBackButtonClick: PropTypes.bool,
  setToggle: PropTypes.bool,
  setBackButton: PropTypes.bool,
};
