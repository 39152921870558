import React from "react";
import { useHistory } from "react-router-dom";
import { Divider, Grid, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PropTypes from "prop-types";

const BreadCrumb = ({ childRoute }) => {
  const history = useHistory();

  const handleClick = () => {
    history.goBack();
  };

  return (
    <>
      <Grid>
        <Grid item xs={12} md={11} mt="3" container display="flex" alignItems="center">
          {childRoute && (
            <ArrowBackIosNewIcon
              fontSize="medium"
              onClick={handleClick}
              sx={{ cursor: "pointer" }}
            />
          )}
          <Typography
            onClick={handleClick}
            sx={{
              padding: "35px 14px 35px 8px",
              fontWeight: 600,
              fontSize: "30px",
              lineHeight: "38px",
              color: "#051931",
              cursor: "pointer",
            }}
          >
            Settings
          </Typography>
          {childRoute && (
            <Divider
              sx={{ minHeight: "10vh", width: { md: 3 }, m: { xs: 0, md: "0 1rem" } }}
              orientation="vertical"
            />
          )}
          {childRoute && (
            <Typography
              variant="h4"
              sx={{
                padding: 2,
                fontSize: "18px",
                lineHeight: "23px",
                color: "#051931",
              }}
            >
              {childRoute}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Divider sx={{ height: 2, m: 0.5 }} fullWidth />
    </>
  );
};

export default BreadCrumb;

BreadCrumb.propTypes = {
  childRoute: PropTypes.any,
};
