// import { get as getValue } from "lodash";
import { getRefreshToken } from "hooks/auth/refreshToken";
import { getSignOut } from "hooks/auth/B2CActions";
import { Config } from "../config";

const { default: axios } = require("axios");
// const baseUrl = "https://api-develop.synerge.io/v1/";
const baseUrl = Config.apiGateway.API_URL;

let url;
const dataToQueryParameter = (data) => {
  if (typeof data === "object") {
    if (!Array.isArray(data)) {
      let params = "?";
      const dataArray = Object.entries(data);
      if (dataArray.length > 0) {
        dataArray.forEach((entry, index) => {
          const amp = index < dataArray.length - 1 ? "&" : "";
          params = `${params}${entry[0]}=${entry[1]}${amp}`;
        });
        return params;
      }
    }
  } else if (typeof data === "string") {
    return data;
  }
  return "";
};

// const getErrorText = (errResponse) => {
//   let errorMessage = "some error occurred";

//   if (!errResponse) {
//     errorMessage =
//       "The request failed due to unknown reason. please check your internet connection.";
//   }

//   const errors = getValue(errResponse, "data");

//   if (typeof errors === "string") {
//     errorMessage = errors;
//     // } else if (Array.isArray(errors)) {
//   } else if (typeof errors === "object") {
//     const firstKey = Object.keys(errors)[0];
//     const preFix = `${typeof firstKey === "string" ? `${firstKey} - ` : ""}`;
//     errorMessage = `${preFix}${String(errors[firstKey])}`;
//   }

//   return errorMessage;
// };

const resendRequestAfterTokenRefreshed = async (type, options) => {
  try {
    const res = await getRefreshToken();
    const newtoken = res.data.access_token;
    const { endPoint, isAuth, targetURL } = options;
    let { headers } = options;
    if (isAuth) {
      headers = {
        ...headers,
        Authorization: `Bearer ${newtoken}`,
      };
    }
    if (options?.targetURL) {
      url = `${targetURL}${endPoint}`;
    } else {
      url = `${baseUrl}${endPoint}`;
    }
    // if we have params
    if (options?.params) {
      url = `${url}${dataToQueryParameter(options?.params)}`;
    }
    let response = null;
    // checking type of request
    if (type === "post" || type === "put" || type === "patch") {
      response = await axios[type](url, options?.body, { headers });
    } else {
      response = await axios[type](url, { headers });
    }
    return response;
  } catch (err) {
    let res;
    // && err.response.data.error_description.indexOf(
    //   "AADB2C90088: The provided grant has not been issued for this endpoint."
    // ) > -1
    if (err.response.status === 400) {
      if (err.response.data.error === "invalid_grant") {
        getSignOut("expert_logout");
      }
    } else {
      res = err.response;
    }
    return res;
  }
};
export const request = async (type, options) => {
  try {
    const token = localStorage.getItem("accessToken");
    const { endPoint, isAuth, targetURL } = options;
    let { headers } = options;
    if (isAuth) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
    if (options?.targetURL) {
      url = `${targetURL}${endPoint}`;
    } else {
      url = `${baseUrl}${endPoint}`;
    }
    // if we have params
    if (options?.params) {
      url = `${url}${dataToQueryParameter(options?.params)}`;
    }
    let response = null;
    // checking type of request
    if (type === "post" || type === "put" || type === "patch") {
      response = await axios[type](url, options?.body, { headers });
    } else {
      response = await axios[type](url, { headers });
    }
    return response;
  } catch (err) {
    let res;
    if (err.response.status === 401) {
      const res1 = await resendRequestAfterTokenRefreshed(type, options);
      res = res1;
    } else {
      // console.log("err.response: ", err.response);
      // const message = getErrorText(err.response);
      // console.log("message: ", message);
      res = err.response;
    }
    return res;
  }
};
export const get = (options) => request("get", options);
export const post = (options) => request("post", options);
export const put = (options) => request("put", options);
export const patch = (options) => request("patch", options);
export const del = (options) => request("delete", options);
