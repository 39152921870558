import { ActionTypes } from "redux/constants/action-types";

const val = localStorage.getItem("data");
const val2 = localStorage.getItem("leadProcess");
const initialState = {
  modal: false,
  LeadProcess: JSON.parse(val2),
  data: JSON.parse(val),

  questions: [
    {
      id: "aqweasd",
      title: "not found",
      questionType: "textbox",
      required: true,
      questionoptions: [
        { optiontext: "1 Year", optionvalue: "1" },
        { optiontext: "2 Years", optionvalue: "2" },
        { optiontext: "3 Years", optionvalue: "3" },
      ],
    },
  ],
};

export const setTokenReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_TOKEN:
      return { ...state, data: { ...payload } };
    case ActionTypes.RESET:
      return initialState;
    case ActionTypes.SET_MODAL:
      return { ...state, modal: payload };
    case ActionTypes.SET_LEAD_PROCESS:
      return { ...state, LeadProcess: payload };
    case ActionTypes.SET_SESSION_TYPE:
      return { ...state, setSessionType: payload };
    case ActionTypes.SET_QUESTION:
      if (Array.isArray(payload)) {
        return { ...state, questions: payload };
      }
      return { ...state, questions: state.questions };
    case ActionTypes.SET_PUBLIC_QUESTION:
      if (Array.isArray(payload)) {
        return { ...state, public_questions: payload };
      }
      return { ...state, public_questions: state.public_questions };
    default:
      return state;
  }
};
