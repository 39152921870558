import MDButton from "components/MDButton";
import React from "react";
// import { useSelector } from "react-redux";
// import { leadProcess } from "redux/actions/action";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
// import LaptopIcon from "@mui/icons-material/Laptop";
// import WebhookIcon from "@mui/icons-material/Webhook";
import AllCountries from "assets/imgs/icons/allCountries.png";
import MDAvatar from "components/MDAvatar";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { Divider, Container, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { getCurrentUserData } from "constants/userSettings";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Portal from "@mui/material/Portal";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import ExpertautoCompleteProcess from "./expertComponent/ExpertautoCompleteProcess";
import { useDispatch } from "react-redux";
import { setToken } from "redux/actions/action";
import SearchLoader from "../Common/Loader";
import RequestModal from "../Common/Dashboard/RequestModal";
import { getUserRequests } from "../../constants/userSettings";

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = React.useState([]);
  const [userCountries, setuserCountries] = React.useState([]);
  const [userServices, setuserServices] = React.useState([]);
  const [calculateVerification, setCalculateVerification] = React.useState();
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [createRequest, setCreateRequest] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [myRequestsNumber, setMyRequestsNumber] = React.useState(0);
  // const data = useSelector((state) => state?.setTokenReducer?.data);
  // console.log(data);
  const useStyles = makeStyles(() => ({
    containerStyle: {
      // maxWidth: "80%",
      maxWidth: 1180,
      padding: 20,
    },
    paddingBottom: {
      paddingBottom: 35,
    },
    paddingTop: {
      paddingTop: 25,
    },
    marginBottom: {
      marginBottom: 20,
    },
    marginTop: {
      marginTop: 25,
    },
    top5Percent: {
      marginTop: "2%",
    },
    marginBottom5Percent: {
      marginBottom: "5%",
    },
    buttonProperties: {
      color: "#4285F4",
      textTransform: "capitalize",
      fontSize: 15,
      textDecoration: "none",
      // fontFamily: "hurmeGeometeric-Bold",
      fontFamily: "hurmeGeometeric",
      fontWeight: "normal",
    },
    backGroundGrids: {
      backgroundColor: "white",
      // width: 338,
      borderRadius: 35,
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.06)",
      // boxShadow:
      //   "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
    },
    squaredBackGround: {
      background: "#FFFFFF",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 10%)",
      padding: 2,
      width: 80,
      height: 80,
      borderRadius: 25,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
    },
    roundCircle: {
      padding: 8,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      borderRadius: "50%",
      border: "1px solid #9F9F9F",
      width: 64,
      height: 64,
      boxShadow: "4px 4px 13px 1px rgba(0, 0, 0, 0.2)",
    },
    displayInlineFlex: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    fontSize: {
      fontSize: "40px !important",
      color: "#051931",
    },
    creditBalanceCircle: {
      backgroundColor: "#051931",
      width: 110,
      height: 110,
      borderRadius: "50%",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: 23,
      marginTop: 30,
      marginBottom: 30,
    },
    dynamicNames: {
      fontFamily: "Hurme Geometric Sans 3",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "18px",
      color: " #051931",
    },
  }));

  const verifyProfile = (phoneVer, verificationStatus) => {
    let sum = 10;
    if (verificationStatus) {
      if (verificationStatus.contact_Info_Verification === null) {
        sum = +0;
      } else if (verificationStatus.contact_Info_Verification.status === true) {
        sum += 10;
      }
      if (verificationStatus.identity_Verification === null) {
        sum += 0;
      } else if (verificationStatus.identity_Verification.status === true) {
        sum += 10;
      }
      if (verificationStatus.language_Skills_Verification === null) {
        sum += 0;
      } else if (verificationStatus.language_Skills_Verification.status === true) {
        sum += 15;
      }
      if (verificationStatus.overall_Profile_Verification === null) {
        sum += 0;
      } else if (verificationStatus.overall_Profile_Verification.status === true) {
        sum += 15;
      }
      if (verificationStatus.reference_Verification === null) {
        sum += 0;
      } else if (verificationStatus.reference_Verification.status === true) {
        sum += 15;
      }
      if (verificationStatus.technical_Skills_Verification === null) {
        sum += 0;
      } else if (verificationStatus.technical_Skills_Verification.status === true) {
        sum += 15;
      } else {
        sum = +0;
      }
    }
    if (phoneVer === true) {
      sum += 10;
    } else {
      sum += 0;
    }
    setCalculateVerification(sum);
  };

  const getUserDetails = async () => {
    try {
      let phoneVer = false;
      let verificationStatus = Object();
      setSearchLoading(true);
      const res = await getCurrentUserData();
      if (res) {
        dispatch(setToken(res));
        localStorage.setItem("data", JSON.stringify(res));
        setName(res);
        phoneVer = res.phone_Verified;
        verificationStatus = res.verification_Status;
        verifyProfile(phoneVer, verificationStatus);
        setuserCountries(res.user_Countries);
        setuserServices(res.user_Services);
      }
    } catch (error) {
      console.error("client error", error);
    }
    setSearchLoading(false);
  };

  const classes = useStyles();

  const handleCreateRequestOpen = () => {
    setCreateRequest(true);
  };

  // const handleCreateRequestClose = () => {
  //   setCreateRequest(false);
  // };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackMessage("");
    setOpenSnack(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  React.useEffect(async () => {
    await getUserDetails();
    const res = await getUserRequests();
    setMyRequestsNumber(res.request.length);
  }, []);

  React.useEffect(async () => {
    const res = await getUserRequests();
    setMyRequestsNumber(res.request.length);
  }, [createRequest]);

  return (
    <>
      {/* {!LeadProcess && (
        <MDButton variant="caption" onClick={() => dispatch(leadProcess(true))}>
          Expert Dashboard
        </MDButton>
      )}
      {LeadProcess && <ExpertautoCompleteProcess />} */}
      {searchLoading ? (
        <SearchLoader />
      ) : (
        <div style={{ background: "#f9f9fa" }}>
          <Container className={classes.containerStyle}>
            <MDBox display="flex" paddingTop={3} alignItems="center">
              <MDTypography variant="h2" fontWeight="bold">
                Hello {name.name}!
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" style={{ padding: "20px 0" }} fontWeight="bold">
              Welcome to your Dashboard
            </MDTypography>
            <Divider sx={{ height: 4, m: 2.5 }} fullWidth orientation="horizontal" />
            <Grid
              style={{ paddingTop: 30 }}
              container
              // justifyContent={{ xs: "center", md: "space-between" }}
              spacing={2}
              py={10}
            >
              <Grid item xs={12} md={4}>
                <MDBox
                  className={`${classes.backGroundGrids} ${classes.marginBottom}`}
                  p={5}
                  textAlign="center"
                >
                  <div style={{ height: { lg: "311px" } }}>
                    <MDTypography variant="h5" fontWeight="bold">
                      Profile Verification Status
                    </MDTypography>

                    <MDTypography
                      style={{ fontFamily: "hurmeGeometeric-Semi-Bold" }}
                      className={classes.marginTop}
                      variant="h6"
                      fontWeight="regular"
                    >
                      Your profile is {calculateVerification}% complete
                    </MDTypography>

                    <Box
                      className={classes.marginTop}
                      sx={{ position: "relative", display: "inline-flex" }}
                    >
                      <CircularProgress
                        variant="determinate"
                        value={calculateVerification}
                        size={100}
                        color="error"
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MDTypography variant="h4" component="div" color="text.secondary">
                          {calculateVerification}%
                        </MDTypography>
                      </Box>
                    </Box>
                    <MDTypography
                      className={`${classes.marginBottom5Percent}`}
                      variant="h6"
                      fontWeight="regular"
                    >
                      Completing your profile is a great <br /> way to appeal to customers
                    </MDTypography>
                    {/* <NavLink
                    to="/expert/verify-profile"
                    className={`${classes.buttonProperties} ${classes.marginTop}`}
                  >
                    Verify Profile
                  </NavLink> */}
                    <MDButton
                      className={`${classes.buttonProperties}`}
                      onClick={() => {
                        const path = `/expert/my-bussiness-profile`;
                        history.push(path);
                      }}
                    >
                      View Profile
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox
                  // this temporary heigth until 2nd milstone completed
                  sx={{ height: "200px", display: "grid", alignItems: "center" }}
                  // this temporary heigth above until 2nd milstone completed
                  className={`${classes.backGroundGrids} ${classes.marginBottom}`}
                  p={5}
                  textAlign="center"
                >
                  <MDTypography
                    className={`${classes.marginBottom}`}
                    variant="h5"
                    fontWeight="bold"
                    // textAlign="left"
                  >
                    My Requests
                  </MDTypography>
                  <div style={{ padding: 10 }}>
                    <MDTypography
                      className={`${classes.paddingBottom} ${classes.fontSizeBig} ${classes.squaredBackGround}`}
                      variant="h6"
                    >
                      {myRequestsNumber}
                    </MDTypography>
                    <MDButton
                      className={`${classes.buttonProperties}`}
                      sx={{ marginTop: "1rem" }}
                      onClick={() => handleCreateRequestOpen()}
                    >
                      Submit a New Request
                    </MDButton>
                  </div>
                </MDBox>
                {/* <MDBox
                  // this temporary heigth until 2nd milstone completed
                  sx={{ height: "200px", display: "grid", alignItems: "center" }}
                  // this temporary heigth above until 2nd milstone completed
                  className={`${classes.backGroundGrids} ${classes.marginBottom}`}
                  p={5}
                  textAlign="center"
                >
                  <MDTypography
                    className={`${classes.marginBottom}`}
                    variant="h5"
                    fontWeight="bold"
                    // textAlign="left"
                  >
                    Lead Notifications
                  </MDTypography>
                  <div style={{ padding: 10 }}>
                    <MDTypography variant="h6" fontWeight="regular">
                      Sending lead notifications to <br />
                    </MDTypography>

                    <MDTypography variant="h6" fontWeight="regular">
                      {name.createdBy}
                    </MDTypography>
                  </div>
                </MDBox> */}
                {/* <Grid
                  className={`${classes.backGroundGrids} ${classes.marginBottom}`}
                  p={5}
                  item
                  md={4}
                  
                  textAlign="center"
                >
                  <MDTypography className={classes.marginBottom} variant="h5" fontWeight="bold">
                    Active vs Unread Leads
                  </MDTypography>

                  <MDTypography
                    style={{ fontFamily: "hurmeGeometeric-Semi-Bold" }}
                    className={classes.marginBottom}
                    variant="h6"
                    fontWeight="regular"
                  >
                    Active Leads
                  </MDTypography>

                  <MDBox className={classes.roundCircle}>
                    <MDTypography variant="h4" fontWeight="bold" color="error">
                      202
                    </MDTypography>
                  </MDBox>
                  <Divider sx={{ height: 3, m: 4.5 }} fullWidth orientation="horizontal" />

                  <MDTypography
                    style={{ fontFamily: "hurmeGeometeric-Semi-Bold" }}
                    className={classes.marginBottom}
                    variant="h6"
                    fontWeight="regular"
                  >
                    Unread Leads
                  </MDTypography>

                  <MDBox className={classes.roundCircle}>
                    <MDTypography variant="h4" fontWeight="bold" color="error">
                      10
                    </MDTypography>
                  </MDBox>
                </Grid> */}
              </Grid>
              <Grid item xs={12} md={4}>
                <MDBox
                  // this temporary heigth until 2nd milstone completed
                  sx={{ height: { lg: "611px" } }}
                  // this temporary heigth above until 2nd milstone completed
                  className={`${classes.backGroundGrids} ${classes.marginBottom}`}
                  p={5}
                  textAlign="center"
                >
                  <MDTypography className={classes.marginBottom} variant="h5" fontWeight="bold">
                    Lead Preferences
                  </MDTypography>
                  <MDTypography
                    style={{ fontFamily: "hurmeGeometeric-Semi-Bold", marginBottom: "9px" }}
                    variant="h5"
                    fontWeight="bold"
                    // textAlign="left"
                  >
                    Services
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="regular">
                    You will recieve leads in these categories
                  </MDTypography>
                  <div
                    className={`${classes.displayInlineFlex} ${classes.marginTop}`}
                    style={{ justifyContent: "center" }}
                  >
                    {userServices &&
                      userServices.length > 0 &&
                      userServices.slice(0, 2).map(
                        (x) => (
                          // return (
                          <div style={{ width: "130px", marginBottom: "10px" }}>
                            <MDBox className={classes.squaredBackGround}>
                              {/* <WebhookIcon className={classes.fontSize} /> */}
                              <MDAvatar
                                className={classes.fontSize}
                                src={x.icon}
                                alt="Avatar"
                                size="sm"
                                variant="square"
                              />
                            </MDBox>
                            <MDTypography
                              className={`${classes.marginTop} ${classes.dynamicNames}`}
                            >
                              {x && x.name ? x.name : ""}
                            </MDTypography>
                          </div>
                        )
                        // );)
                      )}
                  </div>
                  <MDButton
                    className={`${classes.buttonProperties}`}
                    onClick={() => {
                      const path = `/expert/my-services`;
                      history.push(path);
                    }}
                  >
                    View All
                  </MDButton>
                  <Divider sx={{ height: 3 }} fullWidth orientation="horizontal" />
                  <MDTypography
                    style={{
                      fontFamily: "hurmeGeometeric-Semi-Bold",
                      marginBottom: "9px",
                      marginTop: "51px",
                    }}
                    variant="h5"
                    fontWeight="bold"
                    // textAlign="left"
                  >
                    Locations
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="regular">
                    You are receiving customers within
                  </MDTypography>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="space-evenly"
                    sx={{ mb: 2 }}
                  >
                    {userCountries &&
                      userCountries.length > 0 &&
                      userCountries.slice(0, 2).map((x) => (
                        <div>
                          <div className={classes.displayInlineFlex} style={{ marginTop: 15 }}>
                            <MDAvatar
                              className={classes.fontSize}
                              // src={x.icon}
                              src={x.id === "any" ? AllCountries : x.icon}
                              alt="Avatar"
                              size="sm"
                              variant="square"
                            />

                            <MDTypography
                              sx={{
                                // fontFamily: "hurmeGeometeric-Bold",
                                marginLeft: 1,
                                marginTop: 1,
                                fontSize: 15,
                              }}
                              className={classes.dynamicNames}
                            >
                              {x && x.name ? x.name : ""}
                            </MDTypography>
                          </div>
                        </div>
                      ))}
                  </MDBox>

                  {/* <NavLink */}
                  <MDButton
                    // to="/expert/location"
                    to="/expert/my-locations"
                    className={`${classes.buttonProperties} ${classes.marginTop}`}
                    sx={{ mt: 3 }}
                  >
                    View All
                    {/* </NavLink> */}
                  </MDButton>
                  {/* <Divider sx={{ height: 3, m: 4.5 }} fullWidth orientation="horizontal" /> */}

                  {/* <MDButton className={`${classes.buttonProperties} ${classes.marginTop}`}>
                  Change
                </MDButton> */}
                </MDBox>
                {/* <Grid
                  className={`${classes.backGroundGrids} ${classes.marginBottom}`}
                  p={5}
                  item
                  md={4}
                  
                  textAlign="center"
                >
                  <MDTypography className={classes.marginBottom} variant="h4" fontWeight="bold">
                    Help
                  </MDTypography>
                  <div style={{ backgroundColor: "rgba(159, 159, 159, 0.1)", padding: 10 }}>
                    <MDTypography className={classes.top5Percent} variant="h6" fontWeight="regular">
                      Visit help centre for tips & advice.
                    </MDTypography>
                    <MDTypography className={classes.top5Percent} variant="h6" fontWeight="regular">
                      Email: team@synerge.io
                    </MDTypography>
                    <MDTypography className={classes.top5Percent} variant="h6" fontWeight="regular">
                      Call: 0331264254
                    </MDTypography>
                    <MDTypography
                      className={classes.top5Percent}
                      variant="h6"
                      // textAlign="right"
                      fontWeight="regular"
                    >
                      Open 24 hours a day, 7 days a week
                    </MDTypography>
                  </div>
                </Grid> */}
              </Grid>
              <Grid item xs={12} md={4}>
                <MDBox
                  className={`${classes.backGroundGrids} ${classes.marginBottom}`}
                  p={5}
                  textAlign="center"
                >
                  <div style={{ height: "311px" }}>
                    <MDTypography variant="h5" fontWeight="bold">
                      Credit Balance
                    </MDTypography>
                    <MDBox className={classes.creditBalanceCircle}>
                      <AllInclusiveIcon />
                    </MDBox>
                    <MDTypography className={classes.marginTop} variant="h3" fontWeight="light">
                      {name.credit_Balance} Credits
                    </MDTypography>
                    <MDButton
                      className={`${classes.buttonProperties}`}
                      style={{ marginTop: 18 }}
                      onClick={() => {
                        const path = `/expert/my-credits`;
                        history.push(path);
                      }}
                    >
                      Buy Credits
                    </MDButton>
                  </div>
                </MDBox>
                <MDBox
                  className={`${classes.backGroundGrids} ${classes.marginBottom}`}
                  p={5}
                  textAlign="center"
                  sx={{ height: "200px", display: "grid", alignItems: "center" }}
                >
                  <MDTypography variant="h5" fontWeight="bold">
                    Help
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="regular">
                    Use the chat widget in the bottom right or Email us at team@synerge.io
                  </MDTypography>
                  {/* <MDTypography className={classes.top5Percent} variant="h6" fontWeight="regular">
                      
                    </MDTypography> */}
                  {/* <MDTypography className={classes.marginBottom} variant="h5" fontWeight="bold">
                    My Active Projects
                  </MDTypography>
                  <MDBox className={classes.squaredBackGround}>
                    <MDTypography variant="h4" fontWeight="bold">
                      0
                    </MDTypography>
                  </MDBox> */}
                  {/* <MDButton className={`${classes.buttonProperties} ${classes.marginTop}`}>
                  See my Active Projects
                </MDButton> */}
                </MDBox>
                {/* <Grid
                  className={`${classes.backGroundGrids} ${classes.marginBottom}`}
                  p={5}
                  item
                  md={4}
                  
                  textAlign="center"
                >
                  <MDTypography className={classes.marginBottom} variant="h5" fontWeight="bold">
                    Active vs Unread Responses
                  </MDTypography>

                  <MDTypography
                    style={{ fontFamily: "hurmeGeometeric-Semi-Bold" }}
                    className={classes.marginBottom}
                    variant="h6"
                    fontWeight="regular"
                  >
                    Active Responses
                  </MDTypography>

                  <MDBox className={classes.roundCircle}>
                    <MDTypography variant="h4" fontWeight="bold" color="error">
                      202
                    </MDTypography>
                  </MDBox>
                  <Divider sx={{ height: 3, m: 4.5 }} fullWidth orientation="horizontal" />

                  <MDTypography
                    style={{ fontFamily: "hurmeGeometeric-Semi-Bold" }}
                    className={classes.marginBottom}
                    variant="h6"
                    fontWeight="regular"
                  >
                    Unread Responses
                  </MDTypography>

                  <MDBox className={classes.roundCircle}>
                    <MDTypography variant="h4" fontWeight="bold" color="error">
                      10
                    </MDTypography>
                  </MDBox>
                </Grid> */}
              </Grid>
            </Grid>
          </Container>
          {createRequest && (
            <RequestModal
              setCreateRequest={setCreateRequest}
              setOpenSnack={setOpenSnack}
              setSnackMessage={setSnackMessage}
            />
          )}
        </div>
      )}
      <Portal>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={
              snackMessage === "Your request has been submitted successfully." ? "success" : "error"
            }
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>{" "}
      </Portal>
    </>
  );
};
export default Dashboard;
