import React from "react";
import { Divider, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useSelector } from "react-redux";
import LeadDetail from "views/Common/LeadDetail";
import LeadDetailStatic from "views/Common/LeadDetailStatic";
import UserInfoCard from "views/Common/UserInfoCard";
// import SimpleBar from "simplebar-react";
import PropTypes from "prop-types";

const UserDetail = ({ setShowModal, leadUpdate }) => {
  const [DummyData, setDummyData] = React.useState(true);
  const data = useSelector((state) => state.setTokenReducer?.data);
  const userLeadDetail = useSelector((state) => state.userLeadDetail.leadDetail);
  const {
    credits,
    experts_responded,
    posted_by,
    city,
    country,
    service_questions_and_answers,
    service,
    budget_type,
    hourly_budget,
    fixed_price_budget,
    project_duration,
    project_type,
    start_time,
    description,
    id,
  } = userLeadDetail;

  const [staticQuestions] = React.useState({
    budgetType: budget_type,
    fixedPriceBudget: fixed_price_budget,
    hourlyBudget: hourly_budget,
    projectDuration: project_duration,
    projectType: project_type,
    startTime: start_time,
  });

  const isEmptyObject = (x) => JSON.stringify(x) === "{}";
  React.useEffect(() => {
    setDummyData(isEmptyObject(userLeadDetail));
  }, []);

  if (DummyData) {
    return (
      <Grid item xs={12} md={6} xl={8} sx={{ textAlign: "center", pt: 15 }}>
        {/* <MDTypography textAlign="center" alignSelf="center">
          <b>Click any lead to see its details.. </b>
        </MDTypography> */}
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12} md={4.5} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Divider orientation="vertical" sx={{ ml: 0, mr: 1 }} />
        <UserInfoCard
          credits={credits}
          experts_responded={experts_responded}
          posted_by={posted_by}
          city={city}
          country={country}
          service={service}
          shadow={false}
          setShowModal={setShowModal}
          leadId={id}
          leadUpdating={leadUpdate}
        />
        <Divider orientation="vertical" sx={{ mx: 0 }} />
      </Grid>
      <Grid item xs={12} md={4} pl={1}>
        <MDBox display="flex" justifyContent="flex-end" alignItems="center" py={4} px={4}>
          <MDTypography variant="h6" fontWeight="medium">
            Your Credits {data?.credit_Balance}
          </MDTypography>
        </MDBox>
        {service_questions_and_answers && staticQuestions ? (
          <MDBox>
            {service_questions_and_answers && (
              <MDBox>
                <LeadDetail
                  title="Lead Details"
                  service_questions_and_answers={service_questions_and_answers}
                  shadow={false}
                  lead_description={description}
                />
              </MDBox>
            )}
            {staticQuestions && <LeadDetailStatic staticQuestions={staticQuestions} />}
          </MDBox>
        ) : (
          <MDTypography variant="h5" fontWeight="light" textAlign="center" sx={{ pt: 2 }}>
            No lead details found
          </MDTypography>
        )}
      </Grid>
    </>
  );
};

export default UserDetail;

UserDetail.propTypes = {
  setShowModal: PropTypes.func,
  leadUpdate: PropTypes.func,
};
