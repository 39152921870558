// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  leadDesc: {
    fontFamily: "Hurme Geometric Sans 3",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "113.5%",
    color: " #4F4F4F",
    paddingLeft: "12px",
  },
  leadQuestion: {
    fontFamily: "Hurme Geometric Sans 3",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#051931",
    marginTop: "25px",
    paddingLeft: "12px",
  },
  leadAnswer: {
    fontFamily: "Hurme Geometric Sans 3",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#9F9F9F",
    marginTop: "5px",
    paddingLeft: "12px",
  },
}));

function LeadDetail({ service_questions_and_answers, shadow, lead_description }) {
  const classes = useStyles();

  return (
    <>
      <Card
        sx={{
          height: "80%",
          boxShadow: !shadow && "none",
          pr: 2,
          overflow: "none",
          position: "relative",
        }}
      >
        <MDBox>
          <MDTypography className={classes.leadDesc}>{lead_description}</MDTypography>
        </MDBox>
        <Divider sx={{ height: 4, m: 0.5, mt: 3, mb: 3 }} orientation="horizontal" />
        {service_questions_and_answers &&
          service_questions_and_answers.length > 0 &&
          service_questions_and_answers.map((item) => (
            <MDBox>
              <MDTypography className={classes.leadQuestion}>{item.question}</MDTypography>
              <MDTypography className={classes.leadAnswer}>{item.answer}</MDTypography>
            </MDBox>
          ))}
      </Card>
    </>
  );
}

// Setting default props for the LeadDetail
LeadDetail.defaultProps = {
  shadow: true,
};

// Typechecking props for the LeadDetail
LeadDetail.propTypes = {
  service_questions_and_answers: PropTypes.array,
  shadow: PropTypes.bool,
  lead_description: PropTypes.string,
};

export default LeadDetail;
