import React from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DisplayInDateMin } from "constants/getTime";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  leadQuestion: {
    fontFamily: "Hurme Geometric Sans 3",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#051931",
    marginTop: "25px",
    paddingLeft: "12px",
  },
  leadAnswer: {
    fontFamily: "Hurme Geometric Sans 3",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#9F9F9F",
    marginTop: "5px",
    paddingLeft: "12px",
  },
}));
function LeadDetailStatic({ staticQuestions }) {
  const classes = useStyles();

  return (
    <MDBox mb={8}>
      <MDBox>
        <MDTypography className={classes.leadQuestion}>Budget Type</MDTypography>
        <MDTypography className={classes.leadAnswer}>{staticQuestions.budgetType}</MDTypography>
      </MDBox>
      {staticQuestions.hourlyBudget && staticQuestions.fixedPriceBudget === 0 ? (
        <MDBox>
          <MDTypography className={classes.leadQuestion}>Hourly Budget</MDTypography>
          <MDTypography className={classes.leadAnswer}>
            Min Rate: ${staticQuestions.hourlyBudget.min_rate} - Max Rate: $ //{" "}
            {staticQuestions.hourlyBudget.max_rate}
          </MDTypography>
        </MDBox>
      ) : (
        <MDBox>
          <MDTypography className={classes.leadQuestion}>Fixed Price</MDTypography>
          <MDTypography className={classes.leadAnswer}>
            ${staticQuestions.fixedPriceBudget}
          </MDTypography>
        </MDBox>
      )}
      <MDBox>
        <MDTypography className={classes.leadQuestion}>Project Duration</MDTypography>
        <MDTypography className={classes.leadAnswer}>
          {staticQuestions.projectDuration}
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography className={classes.leadQuestion}>Project Type</MDTypography>
        <MDTypography className={classes.leadAnswer}>{staticQuestions.projectType}</MDTypography>
      </MDBox>
      {/* <MDBox mb={2} sx={{ paddingBottom: "16px" }}>
            <MDTypography sx={{ mb: 1 }} variant="h6" fontWeight="medium">
              Start Time
            </MDTypography>
            {staticQuestions &&
            staticQuestions.startTime ===
              "After sometime. I need sometime before i initiate the project. Expected start date: Immediately" ? (
              <MDTypography
                sx={{ textTransform: "capitalize" }}
                variant="button"
                color="text"
                fontWeight="medium"
              >
                Immediately
              </MDTypography>
            ) : (
            <MDTypography
              sx={{ textTransform: "capitalize" }}
              variant="button"
              color="text"
              fontWeight="medium"
            >
              {DisplayInDateMin(staticQuestions.startTime)}
            </MDTypography>
            )} 
          </MDBox> */}
      <MDBox>
        <MDTypography className={classes.leadQuestion}>Start Time</MDTypography>
        <MDTypography className={classes.leadAnswer}>
          {DisplayInDateMin(staticQuestions.startTime)}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

LeadDetailStatic.propTypes = {
  staticQuestions: PropTypes.object.isRequired,
};

export default LeadDetailStatic;
