import React from "react";
import { get } from "axios-setup";
// import { setLeadDetail } from "redux/actions/action";
import { Grid, Divider, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Card from "@mui/material/Card";
// import MDNotifications from "components/MDNotification";
import MDTypography from "components/MDTypography";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
// import { Tooltip } from "@material-ui/core";
import creditIcon from "assets/imgs/icons/creditIcon.png";
import locationIcon from "assets/imgs/icons/location.png";
// import settings from "assets/imgs/icons/settings.png";
// import filterIcon from "assets/imgs/icons/filterIcon1.png";
// import TextField from "@mui/material/TextField";
import SimpleBar from "simplebar-react";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { stringIntoMin } from "constants/getTime";
import { useSelector } from "react-redux";
// import { apiParamsFunction } from "constants/apiParamsFunction";
import { makeStyles } from "@mui/styles";
import MDAvatar from "components/MDAvatar";
import Loader from "views/Common/Loader";
import CanadaIcon from "../../assets/imgs/icons/canadaIcon.png";
import UnitedSatesIcon from "../../assets/imgs/icons/unitedSatesIcon.png";
import { filterList } from "../Common/FilterLeadList";
import PurchasedLeadDetails from "./expertComponent/PurchasedLeadDetails";
import { BootstrapDialog, BootstrapDialogTitle } from "../../components/MDDialog";

const MyLeads = () => {
  let startDate = new Date();
  const [updatedAgo, setUpdatedAgo] = React.useState("0 min");

  const [purchasedLead, setPurchasedLead] = React.useState([]);
  // const [purchasedLeadBackup, setPurchasedLeadBackup] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [filter, setShowFilter] = React.useState(false);
  const [miniLoading, setMiniLoading] = React.useState(false);
  const [detailViewLoading, setDetailViewLoading] = React.useState(false);
  const userLeadDetail = useSelector((state) => state.userLeadDetail.leadDetail);
  const userData = useSelector((state) => state.setTokenReducer?.data);
  const servicesListing = userData.user_Services;
  // const [isHiredActive, setIsHiredActive] = React.useState(false);
  // const [isPendingActive, setIsPendingActive] = React.useState(true);
  // const [isArchivedActive, setIsArchivedActive] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  // const [snackMessage, setSnackMessage] = React.useState("");
  const [isId, setId] = React.useState("");
  // const [purchaseStatus, setpurchaseStatus] = React.useState("pending");
  // const [keyword, setKeyword] = React.useState("");
  const [leadSubmittedTimeValue, setLeadSubmittedTimeValue] = React.useState("");
  // const dispatch = useDispatch();

  const [checkStates, setCheckStates] = React.useState();

  const checkingServicesStates = () => {
    if (servicesListing && servicesListing.length > 0) {
      const array1 = [];
      for (let i = 0; i < servicesListing.length; i++) {
        array1.push({
          id: servicesListing[i].id,
          name: servicesListing[i].name,
          isChecked: false,
        });
      }
      setCheckStates(array1);
    }
  };

  const defaultCountryArr = [
    {
      countryName: "USA",
      isChecked: false,
    },
    {
      countryName: "Canada",
      isChecked: false,
    },
    {
      countryName: "any",
      isChecked: false,
    },
  ];

  const getUpdatedTimeAgo = () => {
    setInterval(() => {
      setUpdatedAgo(stringIntoMin(startDate));
    }, 60000);
  };
  const [checkCountries, setCheckCountries] = React.useState(defaultCountryArr);

  // const handleFilterOpen = () => {
  //   setShowFilter(true);
  // };

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  const handleCheckBoxCountriesChange = (event) => {
    const arr1 = checkCountries;
    const index = checkCountries.findIndex((x) => x.countryName === event.target.name);
    arr1[index].isChecked = event.target.checked;
    setCheckCountries(arr1);
  };

  const handleCheckBoxChange = (event) => {
    const arra1 = checkStates;
    const index = checkStates.findIndex((x) => x.name === event.target.name);
    arra1[index].isChecked = event.target.checked;
    setCheckStates(arra1);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  // const handleClickSnack = () => {
  //   setOpenSnack(true);
  // };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  // const timeStamp = new Date();
  // let minutes = 0;
  let renderProfiles = Object();
  const useStyles = makeStyles(() => ({
    buttonProps: {
      textTransform: "capitalize",
      width: "100%",
      height: 70,
      fontSize: 16,
      color: "black",
      borderRadius: 0,
    },
    dash_dash: {
      height: 1,
      backgroundColor: "#cecece",
      width: "100%",
    },
    buttonActiveProps: {
      color: "white",
      backgroundColor: "#df232a",
    },
    active: {
      borderLeft: "5px solid #df232a",
      transition: "0.2s ease all",
      backgroundColor: "#F8F8F8",
    },
    buttonProperties4: {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "lighter",
      borderRadius: "25px",
    },
    root: {
      marginTop: 8,
      marginBottom: 8,
      width: "80%",
      "@media (max-width:780px)": {
        width: "100%",
      },
      "&::before": {
        height: 0,
      },
    },
  }));

  const classes = useStyles();

  const selectUserID = async () => {
    setDetailViewLoading(true);
    try {
      // const selectedLead = purchasedLead.find((x) => x.id === id);
      // if (selectedLead) {
      //   dispatch(setLeadDetail(selectedLead));
      // }
      // const leadResponseselectUserID = await get(
      //   apiParamsFunction(true, "application/json", "leads", "", `/${id}`)
      // );
      // dispatch(setLeadDetail(leadResponseselectUserID.data));
    } catch (err) {
      console.log(err);
    }
    setDetailViewLoading(false);
  };

  const getPurchasedLeadsList = async () => {
    setLoading(true);
    const requestOptions = {
      isAuth: true,
      headers: {
        "Content-Type": "application/json",
      },
      endPoint: `users/me/purchased_leads`,
      params: {
        page_size: 500,
        continuation_token: "",
        purchased_lead_status: "pending",
        active: true,
      },
    };
    // purchaseStatus && purchaseStatus.length > 0 ? purchaseStatus :

    const res = await get(requestOptions);
    try {
      if (res.data.data && res.data.data.length > 0) {
        const leadsArray = res.data.data;
        setPurchasedLead(res.data.data);
        // setPurchasedLeadBackup(res.data.data);
        selectUserID(leadsArray[0].id);
        setId(leadsArray[0].id);
      } else {
        setPurchasedLead([]);
        // setPurchasedLeadBackup([]);
      }
    } catch (error) {
      console.log("client error", error);
      setMiniLoading(false);
    }
    setLoading(false);
  };

  const handleRadioChange = (event) => {
    setLeadSubmittedTimeValue(event.target.value);
  };

  const getFilterLeadList = async () => {
    setMiniLoading(true);
    const response = await filterList(leadSubmittedTimeValue, checkStates, checkCountries);
    if (response && response.data) {
      if (response.data.data) {
        setPurchasedLead(response.data.data);
      } else {
        setPurchasedLead(response.data);
      }
    } else {
      setPurchasedLead("");
      selectUserID("");
      // dispatch(setLeadDetail({}));
    }
    setMiniLoading(false);
    checkingServicesStates();
    setCheckCountries(defaultCountryArr);
  };

  React.useEffect(async () => {
    await getPurchasedLeadsList();
    getUpdatedTimeAgo();
    checkingServicesStates();
  }, [setUpdatedAgo]);

  // purchaseStatus put inside useEffect when you uncomment the buttons

  if (purchasedLead && purchasedLead.length > 0) {
    purchasedLead.sort((a, b) => new Date(b.posted_on) - new Date(a.posted_on));
    renderProfiles = purchasedLead.map((items) => {
      const time = stringIntoMin(items.posted_on);
      return (
        <>
          <MDBox
            className={isId === items.id ? classes.active : ""}
            p={2}
            key={items.id}
            display="flex-column"
            alignItems="center"
            onClick={() => {
              selectUserID(items.id);
              setId(items.id);
            }}
            bgColor={userLeadDetail.id === items.id && "#F8F8F8"}
            sx={{
              cursor: "pointer",
            }}
          >
            <MDBox display="flex" justifyContent="space-between" flexWrap="wrap">
              <MDTypography sx={{ pb: 1, fontWeight: "600", fontSize: "14px", lineHeight: "18px" }}>
                {items.posted_by.name}
              </MDTypography>
              <MDTypography variant="caption" fontWeight="light">
                {time}
              </MDTypography>
            </MDBox>
            <MDTypography sx={{ pb: 1, fontweight: "600", fontSize: "14px", lineHeight: "18px" }}>
              <b>
                {items.service && items.service.name && items.service.name.length > 0
                  ? items.service.name
                  : "Service"}
              </b>
              &nbsp;
              <b fontWeight="900">|</b>
              &nbsp;
              <span>
                {items.description && items.description.length > 40
                  ? `${items.description.substring(0, 40)}...`
                  : items.description}
              </span>
            </MDTypography>
            <MDBox display="flex" justifyContent="flex-start" flexWrap="wrap" sx={{ pb: 1 }}>
              <MDBox
                component="img"
                src={locationIcon}
                alt="Brand"
                color="white"
                mr={2}
                sx={{
                  height: "16px",
                  mr: "6px",
                  alignItems: "center",
                }}
              />
              <MDTypography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "18px" }}>
                {`${items.posted_by.country}, ${items.posted_by.city}` || "N/A"}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="flex-start" flexWrap="wrap">
              <MDBox
                component="img"
                src={creditIcon}
                alt="Brand"
                color="white"
                mr={2}
                sx={{
                  height: "16px",
                  mr: "6px",
                  alignItems: "center",
                }}
              />

              <MDTypography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "18px" }}>
                {items.credits} Credits
              </MDTypography>
            </MDBox>
          </MDBox>
          <Divider sx={{ height: "1px", m: 0 }} orientation="horizontal" />
        </>
      );
    });
  }

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <MDBox mt={0} mb={0}>
        {/* <MDBox>
          <MDNotifications />
        </MDBox> */}

        <Grid container spacing={0}>
          <Grid item xs={12} md={3.5}>
            <Card sx={{ boxShadow: "none" }}>
              <MDBox pt={1} px={2} bgColor="dark" display="flex-column">
                <MDBox display="flex" justifyContent="space-between" bgColor="dark">
                  <MDBox bgColor="dark" display="flex-column">
                    <MDTypography
                      variant="h6"
                      color="white"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {/* showing all {purchasedLead.length ? purchasedLead.length : 0} leads */}
                      {purchasedLead && purchasedLead.length > 0
                        ? purchasedLead.length > 1
                          ? `Showing all ${purchasedLead.length} leads`
                          : `Showing  ${purchasedLead.length} lead`
                        : ""}
                    </MDTypography>
                    <MDTypography variant="caption" color="white" fontWeight="light">
                      Updated {updatedAgo}
                    </MDTypography>

                    <MDButton
                      variant="text"
                      color="info"
                      onClick={async () => {
                        await getPurchasedLeadsList();
                        startDate = new Date();
                      }}
                    >
                      <MDTypography
                        variant="text"
                        fontWeight="medium"
                        color="white"
                        sx={{ textDecoration: "underline" }}
                      >
                        Refresh
                      </MDTypography>
                    </MDButton>
                    {/* <MDButton
                        variant="text"
                        color="info"
                        onClick={async () => {
                          setSnackMessage("Cleared all filters");
                          handleClickSnack();
                          setPurchasedLead(purchasedLeadBackup);
                        }}
                      >
                        <MDTypography variant="text" fontWeight="medium" color="info">
                          Clear All Filters
                        </MDTypography>
                      </MDButton> */}
                  </MDBox>
                  <Snackbar
                    open={openSnack}
                    autoHideDuration={3000}
                    action={action}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={handleCloseSnack}
                  >
                    <Alert onClose={handleCloseSnack} severity="success" sx={{ width: "100%" }}>
                      hello
                    </Alert>
                  </Snackbar>
                  <MDBox bgColor="dark" display="flex" alignItems="center">
                    {/* <MDBox
                      component="img"
                      src={settings}
                      alt="Brand"
                      color="white"
                      bgColor="dark"
                      mr={2}
                    /> */}
                    {/* <MDButton
                      size="small"
                      color="dark"
                      onClick={() => {
                        handleFilterOpen();
                      }}
                    >
                      <MDBox
                        component="img"
                        src={filterIcon}
                        alt="Brand"
                        color="white"
                        bgColor="dark"
                        mr={2}
                      />
                    </MDButton> */}
                    <BootstrapDialog
                      onClose={handleFilterClose}
                      aria-labelledby="customized-dialog-title"
                      open={filter}
                    >
                      <MDBox sx={{ width: "576px" }}>
                        <BootstrapDialogTitle
                          id="customized-dialog-title"
                          onClose={handleFilterClose}
                        >
                          Filters
                        </BootstrapDialogTitle>
                        <DialogContent
                          dividers
                          sx={{
                            borderBottom: "transparent",
                          }}
                        >
                          {/* KEYWORD SEARCH */}
                          {/* <Accordion
              expanded={expanded === "Keyword"}
              onChange={handleChange("Keyword")}
              elevation={0}
              className={classes.root}
              onClick={() => {
                setIsService(false);
                setIsCountry(false);
                setIsKeywordSearch(true);
                setIsLeadSubmittedTime(false);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ width: "fit-content" }}
              >
                <MDTypography variant="h5" sx={{ margin: "0 10px" }}>
                  Keyword Search
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Tooltip title="ex: Tony Stark" placement="right-end">
                  <TextField
                    sx={{ width: "80%" }}
                    label="Name"
                    rows={1}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                  />
                </Tooltip>
              </AccordionDetails>
            </Accordion> */}
                          {/* LEAD SUBMITTED TIME */}
                          <Accordion
                            // expanded={expanded === "Time"}
                            // onChange={handleChange("Time")}
                            elevation={0}
                            className={classes.root}
                            // onClick={() => {
                            //   setIsService(false);
                            //   setIsCountry(false);
                            //   setIsKeywordSearch(false);
                            //   setIsLeadSubmittedTime(true);
                            // }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              sx={{ width: "fit-content" }}
                            >
                              <MDTypography variant="h5" sx={{ margin: "0 10px" }}>
                                Lead Submitted Time
                              </MDTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl>
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  onChange={handleRadioChange}
                                >
                                  <FormControlLabel
                                    value="any_time"
                                    control={<Radio />}
                                    label="Any Time"
                                  />
                                  <FormControlLabel
                                    value="last_hour"
                                    control={<Radio />}
                                    label="Last Hour"
                                  />
                                  <FormControlLabel
                                    value="today"
                                    control={<Radio />}
                                    label="Today"
                                  />
                                  <FormControlLabel
                                    value="yesterday"
                                    control={<Radio />}
                                    label="Yesterday"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                          {/* SERVICE */}
                          <Accordion
                            // expanded={expanded === "service"}
                            // onChange={handleChange("service")}
                            elevation={0}
                            className={classes.root}
                            // onClick={() => {
                            //   setIsService(true);
                            //   setIsCountry(false);
                            //   setIsKeywordSearch(false);
                            //   setIsLeadSubmittedTime(false);
                            // }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              sx={{ width: "fit-content" }}
                            >
                              <MDTypography variant="h5" sx={{ margin: "0 10px" }}>
                                Service
                              </MDTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl>
                                {servicesListing && servicesListing.length > 0 ? (
                                  servicesListing.map((x) => (
                                    <>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name={`${x.name}`}
                                            onChange={(e) => {
                                              handleCheckBoxChange(e);
                                            }}
                                          />
                                        }
                                        label={`${x.name}`}
                                      />
                                    </>
                                  ))
                                ) : (
                                  <MDTypography variant="h6" sx={{ ml: 3 }}>
                                    No services added
                                  </MDTypography>
                                )}
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                          {/* COUNTRY */}
                          <Accordion
                            // expanded={expanded === "country"}
                            // onChange={handleChange("country")}
                            elevation={0}
                            className={classes.root}
                            // onClick={() => {
                            //   setIsService(false);
                            //   setIsCountry(true);
                            //   setIsKeywordSearch(false);
                            //   setIsLeadSubmittedTime(false);
                            // }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              sx={{ width: "fit-content" }}
                            >
                              <MDTypography variant="h5" sx={{ margin: "0 10px" }}>
                                Country
                              </MDTypography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <MDBox display="flex" alignItems="center">
                                <Checkbox
                                  sx={{ mr: 3 }}
                                  onChange={(e) => {
                                    handleCheckBoxCountriesChange(e);
                                  }}
                                  // checked={usa}
                                  name="USA"
                                />
                                <MDAvatar
                                  src={UnitedSatesIcon}
                                  alt="Avatar"
                                  size="sm"
                                  variant="square"
                                />
                                <MDTypography color="dark" varient="body1" fontWeight="bold" ml={2}>
                                  United States
                                </MDTypography>
                              </MDBox>
                              <MDBox display="flex" alignItems="center">
                                <Checkbox
                                  sx={{ mr: 3 }}
                                  onChange={(e) => {
                                    handleCheckBoxCountriesChange(e);
                                  }}
                                  name="Canada"
                                />
                                <MDAvatar
                                  src={CanadaIcon}
                                  alt="Avatar"
                                  size="sm"
                                  variant="square"
                                />
                                <MDTypography color="dark" varient="body1" fontWeight="bold" ml={2}>
                                  Canada
                                </MDTypography>
                              </MDBox>
                              <MDBox display="flex" alignItems="center">
                                <Checkbox
                                  sx={{ mr: 3 }}
                                  onChange={(e) => {
                                    handleCheckBoxCountriesChange(e);
                                  }}
                                  name="any"
                                />
                                <MDTypography color="dark" varient="body1" fontWeight="bold" ml={2}>
                                  All Countries
                                </MDTypography>
                              </MDBox>
                            </AccordionDetails>
                          </Accordion>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: "center", my: 3 }}>
                          <MDButton
                            sx={{ mr: 5 }}
                            className={`${classes.buttonProperties4}`}
                            color="dark"
                            size="large"
                            onClick={() => {
                              getFilterLeadList();
                              handleFilterClose();
                            }}
                          >
                            Apply
                          </MDButton>
                          <MDButton
                            className={`${classes.buttonProperties4}`}
                            color="error"
                            size="large"
                            onClick={() => {
                              handleFilterClose();
                            }}
                          >
                            Close
                          </MDButton>
                        </DialogActions>
                      </MDBox>
                    </BootstrapDialog>
                  </MDBox>
                </MDBox>
              </MDBox>
              {/* <MDBox display="flex" justifyContent="space-evenly" sx={{ mt: 0.1 }}>
                <MDButton
                  color={`${isPendingActive ? "error" : ""}`}
                  className={`${classes.buttonProps} ${
                    isPendingActive ? classes.buttonActiveProps : ""
                  }`}
                  onClick={() => {
                    // setIsHiredActive(false);
                    setIsPendingActive(true);
                    // setIsArchivedActive(false);
                    setpurchaseStatus("pending");
                  }}
                >
                  Pending
                </MDButton>
                <MDButton
                  color={`${isHiredActive ? "error" : ""}`}
                  className={`${classes.buttonProps} ${
                    isHiredActive ? classes.buttonActiveProps : ""
                  }`}
                  onClick={() => {
                    setIsHiredActive(true);
                    setIsPendingActive(false);
                    setIsArchivedActive(false);
                    setpurchaseStatus("pendinghireconfirmation");
                  }}
                >
                  Hired
                </MDButton>
                <MDButton
                  color={`${isArchivedActive ? "error" : ""}`}
                  className={`${classes.buttonProps} ${
                    isArchivedActive ? classes.buttonActiveProps : ""
                  }`}
                  onClick={() => {
                    setIsHiredActive(false);
                    setIsPendingActive(false);
                    setIsArchivedActive(true);
                    setpurchaseStatus("archived");
                  }}
                >
                  Archived
                </MDButton>
              </MDBox> */}
              <span className={classes.dash_dash} />
              <SimpleBar style={{ maxHeight: "80vh" }} className="scroll" autoHide={false}>
                <MDBox
                  sx={{
                    position: "relative",
                    maxHeight: "79vh",
                  }}
                >
                  {purchasedLead && purchasedLead.length > 0 ? (
                    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                      {miniLoading ? <Loader /> : renderProfiles}
                    </MDBox>
                  ) : (
                    <MDTypography variant="h6" sx={{ pt: 5 }} textAlign="center">
                      No results found
                    </MDTypography>
                  )}
                </MDBox>
              </SimpleBar>
            </Card>
          </Grid>
          {detailViewLoading ? (
            <Grid
              item
              xs={12}
              md={6}
              xl={8}
              sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}
            >
              <Loader />
            </Grid>
          ) : (
            <>
              {purchasedLead && purchasedLead.length > 0 && (
                <PurchasedLeadDetails purchased_lead_id={isId && isId.length > 0 ? isId : ""} />
              )}
            </>
          )}

          {/* <PurchasedLeadDetails purchased_lead_id={isId && isId.length > 0 ? isId : ""} /> */}
        </Grid>
      </MDBox>
    </>
  );
};

export default MyLeads;
