import React from "react";
import { useHistory } from "react-router-dom";
import { Card, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import MDAvatar from "components/MDAvatar";
import AllCountries from "assets/imgs/icons/allCountries.png";
// import canadaIcon from "assets/imgs/icons/canadaIcon.png";
// import unitedSatesIcon from "assets/imgs/icons/unitedSatesIcon.png";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// // import Radio from "@mui/material/Radio";
// import Checkbox from "@mui/material/Checkbox";
import SearchLoader from "views/Common/Loader";

const styles = {
  isActive: {
    border: "2px solid red",
    "&:hover": {
      border: "2px solid red",
    },
  },
};
const useStyle = makeStyles(styles);

function SelectLeadLocation({
  setToggle,
  countries,
  title,
  description,
  setSelectedCountries,
  seeLeads,
  selectedCountries,
  onAnswerChange,
  loader,
  error,
  setError,
  isLocation,
}) {
  const classes = useStyle();
  // const [countryColor, setCountryColor] = React.useState([]);
  const history = useHistory();
  // console.log(
  //   "in SelectLeadLocation",
  //   selectedCountries.map((x) => x.name)
  // );

  // const tempColorHandler = (item) => {
  //   if (item && countryColor.length > 0) {
  //     const index = countryColor.findIndex((x) => x.name === item.name);
  //     if (index !== -1) {
  //       setCountryColor(countryColor.splice(index, 1));
  //     } else {
  //       setCountryColor([
  //         ...countryColor,
  //         {
  //           name: item.name,
  //           id: item.id,
  //         },
  //       ]);
  //     }
  //   } else {
  //     setCountryColor([
  //       ...countryColor,
  //       {
  //         name: item.name,
  //         id: item.id,
  //       },
  //     ]);
  //   }
  // };

  const countrySelection = (item) => (
    <>
      <MDBox
        // pb={2}
        // px={2}
        lineHeight={1.25}
        bgColor="white"
        display="flex"
      >
        <MDAvatar
          // src={item.icon}
          src={item.id === "any" ? AllCountries : item.icon}
          alt="Avatar"
          size="sm"
          variant="square"
        />

        <MDTypography color="dark" varient="body1" fontWeight="bold" ml={2}>
          {item.name}
        </MDTypography>
      </MDBox>
    </>
  );

  return (
    <Grid
      my={10}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ textAlign: "center" }}
    >
      <Card
        id="delete-account"
        sx={{
          boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
          borderRadius: "15px",
          width: { xs: "80%", sm: "50%", md: "50%" },
          padding: 5,
          display: "grid",
        }}
      >
        <MDBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor="white"
          color="white"
          // height="4rem"
          borderRadius="lg"
          variant="gradient"
        >
          <MDTypography
            variant="h3"
            sx={{ fontFamily: "hurmeGeometeric-Semi-Bold" }}
            textTransform="capitalize"
          >
            {title}
          </MDTypography>
          <MDTypography
            sx={{
              width: { xs: "100%", sm: "80%", md: "60%" },
              marginRight: "auto",
              marginLeft: "auto",
              paddingBottom: "5%",
            }}
            variant="h6"
            fontWeight="light"
            mt={3}
          >
            {description}
          </MDTypography>
        </MDBox>

        {error && (
          <MDTypography variant="button" color="error" sx={{ p: 1 }}>
            {error}
          </MDTypography>
        )}

        {/* </MDBox> */}
        {loader ? (
          <SearchLoader />
        ) : (
          <MDBox mt={5} mb={10}>
            <>
              {/* <FormControl>
                <FormGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="countries"
                  sx={{ justifyContent: "center" }}
                > */}
              {(countries || [])?.map((item) => (
                <MDButton
                  // size="medium"
                  className={`${
                    selectedCountries &&
                    selectedCountries.length > 0 &&
                    selectedCountries.find((x) => x.name === item.name)
                      ? classes.isActive
                      : ""
                  }`}
                  // className={`${
                  //   selectedCountries.filter((x) => x.name === item.name)
                  //     ? classes.isActive
                  //     : "black"
                  // }`}
                  key={item.id}
                  variant="outlined"
                  // circular
                  color="black"
                  // color={
                  //   selectedCountries &&
                  //   selectedCountries.length > 0 &&
                  //   selectedCountries.find((x) => x.name === item.name)
                  //     ? "error"
                  //     : "black"
                  // }
                  // color={selectedCountries.find((x) => x.name === item.name) ? "error" : "black"}
                  padding={0}
                  sx={{
                    marginBottom: 3,
                    marginRight: { xs: 0, sm: 2, md: 2 },
                    marginLeft: { xs: 0, sm: 2, md: 2 },
                    borderRadius: "10px !important",
                    width: "256px",
                    height: "53px",
                    // display: "flex",
                    justifyContent: "flex-start",

                    // border: selectedCountries.filter((x) =>
                    //   x.name === item.name ? "red" : "2px solid black"
                    // ),
                  }}
                  gradient
                  onClick={() => {
                    // tempColorHandler(item);
                    // setID(item.id);
                    // debugger;
                    onAnswerChange(item.id, item.name);
                  }}
                >
                  <Typography>{countrySelection(item)}</Typography>
                  {/* <FormControlLabel
                        value={item.id}
                        // control={<Radio sx={{ display: "none" }} />}
                        control={
                          <Checkbox
                            checked={item.name === user_selected_country}
                            // onChange={handleChange}
                          />
                        }
                        label={countrySelection(item)}
                        // onChange={() => onAnswerChange(item.id, item.name)}
                      /> */}
                </MDButton>
              ))}
              {/* </FormGroup>
              </FormControl> */}
              {/* <MDBox
                pb={2}
                px={2}
                textAlign="center"
                lineHeight={1.25}
                bgColor="white"
                display="flex"
                justifyContent="space-around"
              >
                {(countries || [])?.map((val) => (
                  <MDButton
                    type="radio"
                    size="large"
                    variant="outlined"
                    color={
                      selectedCountry.length > 0 && selectedCountry.find((x) => x.id === val.id)
                        ? "error"
                        : "black"
                    }
                    key={val.id}
                    onClick={(e) => onAnswerChange(val.id, val.name, e)}
                  >
                    <MDAvatar
                      src={val.name === "USA" ? unitedSatesIcon : canadaIcon}
                      alt="Avatar"
                      size="sm"
                      variant="square"
                    />
                    <MDTypography color="dark" varient="body1" fontWeight="bold" ml={2}>
                      {val.name}
                    </MDTypography>
                  </MDButton>
                ))}
              </MDBox>
              <MDBox mt={1}>
                <MDButton
                  size="large"
                  variant="outlined"
                  color={
                    selectedCountry.length > 0 && selectedCountry.find((x) => x.id === "any")
                      ? "error"
                      : "black"
                  }
                  // key={val.id}
                  key="any"
                  onClick={(e) => onAnswerChange("any", "any", e)}
                >
                  {/* <MDAvatar
                      src={val.name === "USA" ? unitedSatesIcon : canadaIcon}
                      alt="Avatar"
                      size="sm"
                      variant="square"
                    /> */}
              {/* <MDTypography color="dark" varient="body1" fontWeight="bold" ml={2}>
                    All Countries
                  </MDTypography>
                </MDButton>
              </MDBox> */}{" "}
              {/* } */}
            </>
          </MDBox>
        )}
        <MDBox
          display="flex"
          justifyContent="space-around"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <MDButton
            color="dark"
            size="large"
            sx={{
              height: "100%",
              borderRadius: 30,
              textTransform: "capitalize",
              order: { xs: 2, sm: 1, md: 1 },
              marginTop: { xs: "10px", sm: "0px", md: "0px" },
            }}
            onClick={() => {
              if (isLocation) {
                const path = `/expert/dashboard`;
                history.push(path);
              } else {
                setToggle(true);
                setSelectedCountries({});
                setError("");
              }
            }}
          >
            back
          </MDButton>
          {loader ? (
            <MDButton
              color="error"
              size="large"
              sx={{
                height: "100%",
                borderRadius: 30,
                textTransform: "capitalize",
                order: { xs: 1, sm: 2, md: 2 },
              }}
            >
              Loading...
            </MDButton>
          ) : (
            <MDButton
              color="error"
              size="large"
              sx={{
                height: "100%",
                borderRadius: 30,
                textTransform: "capitalize",
                order: { xs: 1, sm: 2, md: 2 },
              }}
              onClick={() => seeLeads()}
            >
              See Leads
            </MDButton>
          )}
        </MDBox>
      </Card>
    </Grid>
  );
}

export default SelectLeadLocation;

SelectLeadLocation.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  setToggle: PropTypes.func,
  countries: PropTypes.array,
  onAnswerChange: PropTypes.func,
  seeLeads: PropTypes.func,
  setSelectedCountries: PropTypes.func,
  loader: PropTypes.bool,
  error: PropTypes.string,
  setError: PropTypes.any,
  isLocation: PropTypes.bool,
  selectedCountries: PropTypes.any,
};
