import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import { useDispatch } from "react-redux";
import { apiParamsFunction } from "constants/apiParamsFunction";
import { setToken, leadProcess, setSessionType } from "redux/actions/action";
import { get, post } from "axios-setup";

const useADB2CustomHook = () => {
  const { search, pathname } = useLocation();
  const { code } = queryString.parse(search);
  const { state } = queryString.parse(search);
  const { error } = queryString.parse(search);
  const { error_description } = queryString.parse(search);
  const [loading, setLoading] = React.useState(true);
  const [errors, setError] = React.useState(null);
  const [data, setData] = React.useState({});
  const dispatch = useDispatch();

  const getUserDataFromLoginAPI = async (roleName) => {
    const requestOptions = {
      targetURL: "https://api.ipdata.co/",
      isAuth: false,
      headers: {
        "Content-Type": "application/json",
      },
      endPoint: `?api-key=554691002b2c810837d4b42ddf84ba6ccbe150a02d0b14ba5a3edec6`,
    };
    const getIP = await get(requestOptions);

    try {
      getIP.data.role = roleName;
      const getRole = await post(
        apiParamsFunction(true, "application/json", "auth/sign_in", getIP.data)
      );
      try {
        setData(getRole.data);
        setLoading(false);
        dispatch(setToken(getRole.data));
        localStorage.setItem("data", JSON.stringify(getRole.data));
      } catch (err) {
        setError(err);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  React.useEffect(() => {
    if (
      error !== null &&
      error_description !== null &&
      error !== undefined &&
      error_description !== undefined
    ) {
      setError(`${error}, ${error_description} `);
    }
  }, []);

  React.useEffect(async () => {
    let actionPolicy = "B2C_1A_DISPLAYCONTROL_SENDGRID_SIGNIN";
    // let actionPolicy = "B2C_1_signin";
    let IsLoginCall = true;
    let role = "client";
    // eslint-disable-next-line eqeqeq
    if (localStorage.getItem("actionKey") == "client_signup") {
      IsLoginCall = false;
      role = "client";
      // actionPolicy = "B2C_1_signup";
      actionPolicy = "B2C_1A_DISPLAYCONTROL_SENDGRID_SIGNIN";
      // eslint-disable-next-line eqeqeq
    } else if (localStorage.getItem("actionKey") == "expert_signup") {
      IsLoginCall = false;
      role = "expert";
      // actionPolicy = "B2C_1_signup";
      actionPolicy = "B2C_1A_DISPLAYCONTROL_SENDGRID_SIGNIN";
    } else if (localStorage.getItem("actionKey") === "client_login") {
      actionPolicy = "B2C_1A_DISPLAYCONTROL_SENDGRID_SIGNIN";
      // let actionPolicy = "B2C_1_signin";
      IsLoginCall = true;
      role = "client";
    } else if (localStorage.getItem("actionKey") === "expert_login") {
      actionPolicy = "B2C_1A_DISPLAYCONTROL_SENDGRID_SIGNIN";
      // let actionPolicy = "B2C_1_signin";
      IsLoginCall = true;
      role = "expert";
    }
    // console.log("actionPolicy", actionPolicy);
    const res = await axios.post(
      `https://synergeplatform.b2clogin.com/synergeplatform.onmicrosoft.com/${actionPolicy}/oauth2/v2.0/token?grant_type=authorization_code&client_id=${process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID}&redirect_uri=https://jwt.ms&code_verifier=3d1NCzH6pAsZ.xIO__AnS5hrL-ax.vPqFD-ONmIge.4dWCfJk~~aIupCSXZjX36up7CC.u1Ru6RROC46Gqw-LZ2_BOCILjNcSXSgAaVSYCPQLS3Acn2j1MAo3eEY3dky&scope=openid profile email https://synergeplatform.onmicrosoft.com/api/access_as_user&code=${code}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    try {
      localStorage.setItem("accessToken", res.data.access_token);
      localStorage.setItem("refreshToken", res.data.refresh_token);
      if (IsLoginCall) {
        // SignIN
        dispatch(setSessionType("SignIn"));
        getUserDataFromLoginAPI(role);
      } else {
        // SignUP
        dispatch(setSessionType("SignUp"));
        let requestData = { role: `${role}` };
        if (localStorage.getItem("flowType") === "PublicLeadFlow") {
          requestData = { role: `${role}`, lead_id: `${state}` };
        } else {
          requestData = { role: `${role}`, lead_id: "" };
        }
        const sendingRole = await post(
          apiParamsFunction(true, "application/json", "auth/sign_up", requestData)
        );
        try {
          if (sendingRole.status === 200) {
            if (requestData.role === "client") {
              // GTM event client-signup
              window.dataLayer.push({
                event: "client-signup",
                eventProps: {
                  category: "auth",
                  action: "signup",
                  label: "client",
                  value: 1,
                },
              });
            } else {
              // GTM event expert-signup
              window.dataLayer.push({
                event: "expert-signup",
                eventProps: {
                  category: "auth",
                  action: "signup",
                  label: "expert",
                  value: 1,
                },
              });
            }
            getUserDataFromLoginAPI();
            localStorage.setItem("leadProcess", true);
            dispatch(leadProcess(true));
          }
        } catch (err) {
          setError(err);
        }
      }

      localStorage.setItem("accessToken", res.data.access_token);
    } catch (err) {
      setError(err);
    }
  }, [pathname]);

  return { loading, errors, data };
};
export default useADB2CustomHook;
