import React from "react";
import { get } from "axios-setup";
import QuestionsForm from "views/Client/clientComponents/QuestionsForm";
import RequiredServiceCard from "./RequiredServiceCard";

const AutoCompleteProcess = () => {
  const [toggle, setToggle] = React.useState(true);
  const [currentService, setCurrentService] = React.useState("");
  const [loader, setLoader] = React.useState(true);
  const [service, setService] = React.useState([]);
  const getServiceList = async () => {
    const requestOptions = {
      isAuth: true,
      headers: {
        "Content-Type": "application/json",
      },
      endPoint: `service_areas`,
      params: {
        page_size: 50,
        continuation_token: "",
        active: true,
      },
    };
    const res = await get(requestOptions);
    try {
      setService(res.data.data);

      setLoader(false);
    } catch (error) {
      console.log("client error", error);
    }
  };

  React.useEffect(() => {
    getServiceList();
  }, []);
  return (
    <>
      {toggle ? (
        <RequiredServiceCard
          title="Required Service"
          description="Choose services that you need."
          placeholderText="What service do you need?"
          loader={loader}
          services={service}
          setCurrentService={setCurrentService}
          setToggle={setToggle}
        />
      ) : (
        <QuestionsForm currentService={currentService} setToggle={setToggle} />
      )}
    </>
  );
};

export default AutoCompleteProcess;
