import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";

import { makeStyles } from "@mui/styles";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
// import OTPInput, { ResendOTP } from "otp-input-react";
import OTPInput from "otp-input-react";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import welcome from "assets/images/welcome.png";
// import OPTVerification from "views/Common/OTPVerification";
// import colors from "assets/theme/base/colors";

// const { error } = colors;

const styles = {
  title: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: " #051931",
    textShadow: "0px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  description: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#051931",
    margin: "13px 0 31px",
  },
  status: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#051931",
    "& span": {
      color: "red",
    },
  },
  btn: {
    textTransform: "capitalize",
    borderRadius: "30px",
    fontSize: " 16px",
    color: "#FFFFFF",
    padding: "14px 74px",
    "@media (min-width:780px)": {
      marginRight: "18px",
    },
  },
};

const useStyles = makeStyles(styles);

const IdentityVerification = () => {
  const classes = useStyles();

  const [isGetCode, setGetCode] = useState(false);

  return (
    <Grid container justifyContent="space-between" mt={3}>
      <Grid item xs={6}>
        {isGetCode === false ? (
          <>
            <Typography className={classes.title}>Request Code</Typography>
            <Typography className={classes.description}>
              We will send you a code on your mobile number.
            </Typography>
            <MDInput label="Enter Mobile Number" sx={{ width: "50%" }} />
            <MDBox sx={{ marginTop: "100px" }}>
              <MDButton color="error" className={classes.btn} onClick={() => setGetCode(true)}>
                Get Code
              </MDButton>
            </MDBox>
          </>
        ) : (
          <>
            <MDBox>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "23px",
                  color: "#051931",
                  textShadow: " 0px 0px 4px rgba(0, 0, 0, 0.05)",
                }}
              >
                Step 1
              </Typography>
              <Typography
                sx={{
                  fontweight: "400",
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#051931",
                  marginTop: "10px",
                }}
              >
                Please upload your identity card*
              </Typography>
            </MDBox>

            <Grid container alignItems="center">
              <Grid item xs={3}>
                <CloudUploadIcon sx={{ fontSize: "64px !important", marginTop: "16px" }} />
                <MDButton
                  sx={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#4285F4",
                    padding: 0,
                    margin: "24px 0 0 -61px",
                    textTransform: "capitalize",
                  }}
                >
                  <input type="file" hidden />
                  Upload
                </MDButton>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="flex-start">
                <MDBox display="flex" flexDirection="column" alignItems="flex-end">
                  <CloseIcon fontSize="medium" />
                  <MDBox
                    component="img"
                    src={welcome}
                    alt="Brand"
                    // width="5rem"
                    color="transparent"
                    bgColor="transparent"
                    // ml="1rem"
                  />
                  <MDBox>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: " #DE1921",
                      }}
                    >
                      Remove
                    </Typography>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={5}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleOutlineIcon fontSize="small" sx={{ color: "#80CC28", mr: "3px" }} />
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: " #80CC28",
                    }}
                  >
                    successfull
                  </Typography>
                </MDBox>
              </Grid>
            </Grid>

            <MDBox>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "23px",
                  color: "#051931",
                  textShadow: " 0px 0px 4px rgba(0, 0, 0, 0.05)",
                  marginTop: "34px",
                }}
              >
                Step 2
              </Typography>
              <Typography
                sx={{
                  fontweight: "400",
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#051931",
                  marginTop: "10px",
                }}
              >
                Please upload a picture of you holding the same govt identity along with the code
                you have been sent*
              </Typography>
            </MDBox>

            <Grid container alignItems="center">
              <Grid item xs={3}>
                <CloudUploadIcon sx={{ fontSize: "64px !important", marginTop: "16px" }} />
                <MDButton
                  sx={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#4285F4",
                    padding: 0,
                    margin: "24px 0 0 -61px",
                    textTransform: "capitalize",
                  }}
                >
                  <input type="file" hidden />
                  Upload
                </MDButton>
              </Grid>
              <Grid item xs={4} display="flex" alignItems="flex-start">
                <MDBox display="flex" flexDirection="column" alignItems="flex-end">
                  <CloseIcon fontSize="medium" />
                  <MDBox
                    component="img"
                    src={welcome}
                    alt="Brand"
                    // width="5rem"
                    color="transparent"
                    bgColor="transparent"
                    // ml="1rem"
                  />
                  <MDBox>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: " #DE1921",
                      }}
                    >
                      Remove
                    </Typography>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={5}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleOutlineIcon fontSize="small" sx={{ color: "#80CC28", mr: "3px" }} />
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "#80CC28",
                    }}
                  >
                    successfull
                  </Typography>
                </MDBox>
              </Grid>
            </Grid>

            {/* <OPTVerification /> */}
            <MDBox sx={{ px: { xs: 0 }, mt: 5 }}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: " #051931",
                }}
              >
                {/* {phoneNumber.slice(2).replace(/.(?=...)/g, "*")} */}
                Enter the OTP sent to +92458******
              </Typography>
              <MDBox mt={4}>
                <OTPInput
                  // value={OTP}
                  // onChange={setOTP}
                  // autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  // secure
                  inputStyles={{
                    borderLeft: "none",
                    borderTop: "none",
                    borderRight: "none",
                    broderBottom: "3px solid #051931",
                    marginRight: "15px",
                    // backgroundColor: "#f9f9fa",
                  }}
                />
                {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
              </MDBox>
              <Typography mt={4} className={classes.resentotp}>
                <MDBox display="flex" flexDrection="row">
                  <MDBox
                    sx={{
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "20px",
                      color: " #051931",
                    }}
                  >
                    Didn’t receive the code?
                  </MDBox>
                  {/* <ResendOTP
                  // renderButton={renderButton}
                  // renderTime={renderTime}
                  // onResendClick={async () => {
                  //   const res = await sendOTPVerificationCode();
                  //   if (res.status === 200) await handleResendTimmer();
                  //   else if (res.status === 500) {
                  //     // setOpenSnack(true);
                  //     setSnackMessage(`${res.data.title} , ${res.data.detail}`);
                  //   }
                  // }}
                  // maxTime={resendTimmer}
                  /> */}
                </MDBox>
              </Typography>
            </MDBox>

            <MDBox sx={{ m: "37px 0" }}>
              <MDButton className={classes.btn} color="dark" onClick={() => setGetCode(false)}>
                Back
              </MDButton>
              <MDButton className={classes.btn} color="error" disabled>
                Submit
              </MDButton>
            </MDBox>
          </>
        )}
      </Grid>
      <Grid item xs={6} textAlign="end">
        <Typography className={classes.status}>
          Status
          <CancelIcon sx={{ margin: "0 5px" }} />
          <span>Not Verified</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IdentityVerification;
