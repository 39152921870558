import MyLeads from "views/Expert/MyLeads";
// import MyProjects from "views/Expert/MyProjects";
import Settings from "views/Expert/Settings";
import Leads from "../views/Expert/Leads";
import Dashboard from "../views/Expert/Dashboard";

export const expertRoutes = [
  // DASHBOARD ROUTES
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/expert",
  },
  {
    path: "/leads",
    name: "Leads",
    component: Leads,
    layout: "/expert",
  },
  {
    path: "/my-leads",
    name: "My Leads",
    component: MyLeads,
    layout: "/expert",
  },
  // {
  //   path: "/my-projects",
  //   name: "My Projects",
  //   component: MyProjects,
  //   layout: "/expert",
  // },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: "/expert",
  },
];
