import { combineReducers } from "redux";
import { setTokenReducer } from "./setTokenReducer";
import { userLeadDetail } from "./userLeadDetail";

const reducers = combineReducers({
  setTokenReducer,
  userLeadDetail,
});

export default reducers;
