import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// THIS FUNCTION WILL CHECK REQUESTED APP LAYOUT AND WILL RETURN ROUTES ACCORDINGLY

const ProtectedRoute = ({ auth, component: Component, ...rest }) => (
  <Route
    {...rest}
    exact
    render={(props) => {
      if (auth) return <Component {...props} />;
    }}
  />
);
export default ProtectedRoute;

ProtectedRoute.propTypes = {
  auth: PropTypes.bool,
  component: PropTypes.func,
  rest: PropTypes.object,
};
