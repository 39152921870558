import React from "react";
// import CreditDetails from "./CreditDetails";
import PropTypes from "prop-types";
import MyCards from "../SettingsComponents/MyCards";
import { getPaymentMethods } from "../../../../constants/userSettings";

const CreditCardAddRemove = ({ setModalFlag }) => {
  const [creditCards, setCreditCards] = React.useState([]);
  const paymentMethods = async () => {
    const response = await getPaymentMethods();
    await setCreditCards(response.data);
  };

  React.useEffect(() => {
    paymentMethods();
  }, []);

  // return <>{creditCards && creditCards.length > 0 ? <MyCards /> : ""}</>;
  return (
    <>
      <MyCards SavedCards={creditCards} setModalFlag={setModalFlag} />
    </>
  );
};

CreditCardAddRemove.propTypes = {
  setModalFlag: PropTypes.func,
};

export default CreditCardAddRemove;
