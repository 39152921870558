import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCredits, paymentMethodChecker } from "redux/actions/action";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import card from "assets/imgs/icons/card.png";
import cardCVC from "assets/imgs/icons/cardCVC.png";
import cardExpiry from "assets/imgs/icons/cardExpiry.png";
import payment from "payment";
import {
  hasCardNumberReachedMaxLength,
  hasCVCReachedMaxLength,
  isHighlighted,
} from "./CreditCardUtils";

const CreditDetails = () => {
  const credit = useSelector((state) => state?.userLeadDetail?.credits);
  const paymentChecker = useSelector((state) => state?.userLeadDetail?.paymentCheck);
  const [creditState, setCreditState] = React.useState({
    cardNumber: "",
    expiry: "",
    cvc: "",
    isDefaultCard: true,
  });

  const dispatch = useDispatch();

  function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
  }

  function formatCreditCardNumber(value) {
    if (!value) {
      return value;
    }
    const issuer = payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;
    switch (issuer) {
      case "amex":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
          10,
          17
        )}`;
        break;
      case "dinersclub":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
          10,
          14
        )}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
          8,
          12
        )} ${clearValue.slice(12, 16)}`;
        break;
    }

    return nextValue.trim();
  }
  function formatExpirationDate(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
  }

  const handleChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    if (e.target.name === "cardNumber") {
      value = formatCreditCardNumber(value);
    }
    if (e.target.name === "expiry") {
      value = formatExpirationDate(value);
    }
    setCreditState({ ...creditState, [name]: value });
    dispatch(setCredits({ ...credit, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCreditState({ ...creditState, [name]: checked });
    dispatch(setCredits({ ...credit, [name]: checked }));
  };
  const checkIsNumeric = (e) => {
    if (!/^\d*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleCardNumberKeyPress = (e) => {
    const { value } = e.target;
    checkIsNumeric(e);
    if (value && !isHighlighted()) {
      const valueLength = value.length;
      if (hasCardNumberReachedMaxLength(value, valueLength)) {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    // if (paymentChecker) {
    // setCreditState({
    //   number: "",
    //   expiry: "",
    //   cvc: "",
    //   isDefaultCard: false,
    // });
    // }
    if (paymentChecker === false) dispatch(paymentMethodChecker());
  }, [paymentChecker]);

  const handleCardCVCKeyPress = (e) => {
    const cardType = payment.fns.cardType(creditState.cardNumber);
    const { value } = e.target;
    checkIsNumeric(e);
    if (value && !isHighlighted()) {
      if (hasCVCReachedMaxLength(cardType, value.length)) {
        e.preventDefault();
      }
    }
  };
  return (
    <Grid container direction="column" sx={{ textAlign: "left" }} rowSpacing={2}>
      <Grid item>
        <MDBox display="flex" justifyContent="flex-start" alignItems="center" sx={{ mb: 1 }}>
          <MDBox component="img" src={card} mr={1} width="1.5rem" />
          <MDTypography sx={{ fontWeight: "800" }}>Card Number</MDTypography>
        </MDBox>
        <TextField
          variant="outlined"
          placeholder="0000 0000 0000 0000"
          sx={{
            fontWeight: "700",
            width: "100%",
            background: `no-repeat right url(
              "http://www.credit-card-logos.com/images/visa_credit-card-logos/visa_mastercard_logo_6.gif"
            )`,
          }}
          onChange={(e) => {
            handleChange(e);
          }}
          name="cardNumber"
          value={creditState.cardNumber}
          onKeyPress={(e) => handleCardNumberKeyPress(e)}
        />
      </Grid>
      <Grid item>
        <MDBox display="flex" justifyContent="flex-start" alignItems="center" sx={{ mb: 1 }}>
          <MDBox component="img" src={cardExpiry} mr={1} width="1.5rem" />
          <MDTypography sx={{ fontWeight: "800" }}>Expiry Date</MDTypography>
        </MDBox>
        <TextField
          variant="outlined"
          placeholder="MM/YY"
          sx={{ fontWeight: "700", width: "100%" }}
          onChange={(e) => handleChange(e)}
          name="expiry"
          value={creditState.expiry}
        />
      </Grid>
      <Grid item>
        <MDBox display="flex" justifyContent="flex-start" alignItems="center" sx={{ mb: 1 }}>
          <MDBox component="img" src={cardCVC} mr={1} width="1.5rem" />
          <MDTypography sx={{ fontWeight: "800" }}>CVC</MDTypography>
        </MDBox>
        <TextField
          variant="outlined"
          placeholder="CVC"
          sx={{ fontWeight: "700", width: "100%" }}
          onChange={(e) => handleChange(e)}
          name="cvc"
          value={creditState.cvc}
          onKeyPress={(e) => handleCardCVCKeyPress(e)}
        />
      </Grid>
      <Grid item>
        {" "}
        <FormControlLabel
          sx={{ display: "flex" }}
          control={<Checkbox />}
          name="isDefaultCard"
          checked={creditState.isDefaultCard}
          onChange={(e) => handleCheckboxChange(e)}
          label={
            <MDTypography fontWeight="bold" sx={{ margin: "0", padding: "0", fontSize: "1rem" }}>
              Save this card info for next purchase
            </MDTypography>
          }
        />
      </Grid>
    </Grid>
  );
};
export default CreditDetails;
