import React, { useState, useEffect } from "react";
// import { get, put } from "axios-setup/index";
// import { apiParamsFunction } from "constants/apiParamsFunction";
import colors from "assets/theme/base/colors";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Container, Grid, Paper, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import BreadCrumb from "views/Common/BreadCrumb";
// import CircularProgres from "./CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "redux/actions/action";
import { getCurrentUserData, updateCurrentUserProfile } from "constants/userSettings";
import Loader from "../../../Common/Loader";
import { stringIntoMin } from "../../../../constants/getTime";

const { dark } = colors;

const styles = {
  mdbox: {
    display: "flex",
    flexDrection: "row",
    // margin: "15px 0",
  },
  inputlabel: {
    fontWeight: "600",
    fontSize: " 16px",
    lineHeight: "20px",
    color: dark.main,
    margin: "20px 0 0",
    "& span": {
      fontSize: "12px",
      lineHeight: " 15px",
      color: "#818182",
    },
  },
  update: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#4285F4",
    alignItems: "center",
  },
  avatar: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "96px",
    // lineHeight: "123px",
    color: "#FFFFFF",
    // bgcolor: "blue",
    // padding: "5rem",
    width: "200px",
    height: "200px",
    textShadow: " 0px 4px 7px rgba(0, 0, 0, 0.25)",
  },
  btn: {
    textTransform: "capitalize",
    background: "#DE1921",
    borderRadius: "30px",
    fontSize: " 16px",
    color: "#FFFFFF",
    padding: "0.8rem 1.625rem",
    marginBottom: "3rem",
    "&:hover": {
      backgroundColor: "#DE0921",
    },
  },
};
const useStyles = makeStyles(styles);

const MyBussinessProfile = () => {
  const classes = useStyles();

  // const isValidE = true;
  const [business, setBusinessName] = useState("");
  // const [emailAddress, setEmailAddress] = useState([]);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [yearInBusiness, setYearInBusiness] = useState("");
  const [updateProfLoader, setUpdateProfLoader] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const userData = useSelector((state) => state.setTokenReducer?.data);
  const dispatch = useDispatch();

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const expression =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi; //eslint-disable-line
  const regex = new RegExp(expression);

  const isValidData = () => {
    let isValid = false;
    if (yearInBusiness <= 0) {
      isValid = false;
    } else if (business && yearInBusiness) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid;
  };

  // const emailValidation = () => {
  //   const pattern = new RegExp(
  //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  //   );
  //   return !(!emailAddress || pattern.test(emailAddress) === false);
  // };

  // const onSubmit = () => {
  //   const isEmailValid = emailValidation();
  //   if (isEmailValid) {
  //     isValidE = isEmailValid;
  //   } else {
  //     isValidE = isEmailValid;
  //   }
  // };

  const getData = async () => {
    try {
      // const requestOptions = {
      //   isAuth: true,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   endPoint: `users/me`,
      //   params: {
      //     page_size: 50,
      //     continuation_token: "",
      //     active: true,
      //   },
      // };
      // const res = await get(requestOptions);

      const res = await getCurrentUserData();

      // setDataGet(res.data);
      // setBusinessName(res.data.business_Name);
      // setWebsiteUrl(res.data.website_Url);
      // setYearInBusiness(res.data.years_in_business);
      // setLastUpdated(stringIntoMin(res.data.last_Updated_On));
      if (res) {
        dispatch(setToken(res));
        localStorage.setItem("data", JSON.stringify(res));
        setBusinessName(res.business_Name);
        setWebsiteUrl(res.website_Url);
        setYearInBusiness(res.years_in_business);
        setLastUpdated(stringIntoMin(res.last_Updated_On));
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  // console.log("data: ", dataGet.name);
  const updateBusinessProfile = async () => {
    // if (isValidE) {
    setUpdateProfLoader(true);
    try {
      if (!isValidData()) {
        if (yearInBusiness < 0) {
          setSnackMessage("Please provide correct detail");
        } else setSnackMessage("please enter required input");
        setUpdateProfLoader(false);
        return;
      }
      if (websiteUrl && !websiteUrl.match(regex)) {
        handleClickSnack();
        setSnackMessage("Please enter valid website url");
        setUpdateProfLoader(false);
        return;
      }
      const request = {
        ...userData,
        business_Name: business,
        website_Url: websiteUrl,
        years_in_business: yearInBusiness,
      };
      const res = await updateCurrentUserProfile(request);
      if (res && res.status && res.status === 200) {
        setSnackMessage("Profile updated successfully");
        getData();
      } else {
        setSnackMessage(res.message);
      }
    } catch (error) {
      console.error("Error: ", error);
      setSnackMessage("Error updating profile");
    }
    // }

    setUpdateProfLoader(false);
    // if (!isValidE) {
    //   setSnackMessage("Please enter correct data");
    // } else if (!isValidE) {
    //   setSnackMessage("Please enter correct data");
    // }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ backgroundColor: "#f9f9fa" }}>
      <Container maxWidth="xxl" sx={{ width: { md: "95%" }, margin: "auto" }}>
        <BreadCrumb childRoute=" My Business Profile" />
        {/* <CircularProgres /> */}
        <MDBox sx={{ px: { xs: 0, md: "5%" } }}>
          <Paper
            sx={{
              padding: { xs: "0.5rem", sm: "2rem" },
              margin: "2.6rem 0 2rem",
              borderRadius: "1rem",
            }}
          >
            {/* <Grid item xs={6}>
            <Typography variant="h5">My Profile</Typography>
          </Grid> */}
            <Grid container mb={4}>
              <Grid item xs={6}>
                <Typography variant="h5">My Business Profile</Typography>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "end" }}>
                <Typography variant="body2" color="#9F9F9F">
                  Updated {lastUpdated}
                </Typography>
              </Grid>
            </Grid>

            <Grid container alignItems="center">
              <Grid item xs={12} md={4} order={{ xs: 2, md: 1 }}>
                {updateProfLoader ? (
                  <Loader />
                ) : (
                  <>
                    <Typography className={classes.inputlabel}>Company Name *</Typography>
                    <MDBox className={classes.mdbox}>
                      <MDInput
                        fullWidth
                        required
                        variant="outlined"
                        color="primary"
                        margin="dense"
                        value={business}
                        onChange={(e) => setBusinessName(e.target.value)}
                      />
                      {/* <Typography
                    sx={{ margin: { md: "18px 0 0 15px", xs: "18px 0 0 8px" } }}
                    className={classes.update}
                  >
                    Update
                  </Typography> */}
                    </MDBox>
                    {/* <Typography className={classes.inputlabel}>Company Email Address *</Typography>
                <MDBox className={classes.mdbox}>
                  <MDInput
                    fullWidth
                    required
                    variant="outlined"
                    color="primary"
                    margin="dense"
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                  <Typography
                    sx={{ margin: { md: "18px 0 0 15px", xs: "18px 0 0 8px" } }}
                    className={classes.update}
                  >
                    Update
                  </Typography>
                </MDBox> */}
                    <Typography className={classes.inputlabel}>
                      Company Website <span color="text">(Optional)</span>
                    </Typography>
                    <MDBox className={classes.mdbox}>
                      <MDInput
                        fullWidth
                        required
                        variant="outlined"
                        color="primary"
                        margin="dense"
                        value={websiteUrl}
                        onChange={(e) => setWebsiteUrl(e.target.value)}
                      />
                      {/* <Typography
                    sx={{ margin: { md: "18px 0 0 15px", xs: "18px 0 0 8px" } }}
                    className={classes.update}
                  >
                    Update
                  </Typography> */}
                    </MDBox>
                    <Typography className={classes.inputlabel}>Years in Business *</Typography>
                    <MDBox className={classes.mdbox}>
                      <TextField
                        type="number"
                        fullWidth
                        required
                        variant="outlined"
                        color="primary"
                        margin="dense"
                        InputProps={{ inputProps: { min: 1 } }}
                        value={yearInBusiness}
                        onChange={(e) => setYearInBusiness(e.target.value)}
                      />
                      {/* <Typography
                    sx={{ margin: { md: "18px 0 0 15px", xs: "18px 0 0 8px" } }}
                    className={classes.update}
                  >
                    Update
                  </Typography> */}
                    </MDBox>
                  </>
                )}
              </Grid>
              {/* <Grid item xs={0} md={1} /> */}
              <Grid
                item
                xs={12}
                md={8}
                color="#9F9F9F"
                sx={{ textAlign: "center" }}
                order={{ xs: 1, md: 2 }}
              >
                <IconButton>
                  <Avatar className={classes.avatar} sx={{ bgcolor: "#DE1921" }}>
                    {business && business.charAt(0).toUpperCase()}
                  </Avatar>
                </IconButton>
                {/* <Typography mt={2} className={classes.update}>
              Updated Profile Picture
            </Typography> */}
              </Grid>
            </Grid>
            <Grid container>
              <MDBox className={classes.mdbox}>
                <Typography mt={4} mb={3} className={classes.update}>
                  {/* Change Password */}
                </Typography>
              </MDBox>
            </Grid>
          </Paper>
          <MDButton
            className={classes.btn}
            color="error"
            onClick={async () => {
              // onSubmit();
              await updateBusinessProfile();
              handleClickSnack();
            }}
          >
            Confirm Changes
          </MDButton>
        </MDBox>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={snackMessage === "Profile updated successfully" ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default MyBussinessProfile;
