import React from "react";
import Footer from "components/Footer";
import ResponsiveAppBar from "components/MDHeaderBar";
import LandingPageCard from "views/Common/LandingPageCard";
import { Divider } from "@mui/material";
import googleIcon from "assets/imgs/icons/googleIcon.png";
import { getSignUp } from "hooks/auth/B2CActions";

const ExpertLandingPage = () => {
  const [val, setVal] = React.useState("");

  const onInputChange = (e) => {
    setVal(e.target.value);
  };
  // localStorage.setItem("actionKey", "expert_login");
  const otherSignUpOptions = [
    {
      logo: googleIcon,
      title: "Sign Up with Google",
      hint: "google.com",
      actionUser: "expert_signup",
    },

    // { logo: microsoftLogo, title: "Sign Up with Microsoft" },
    // { logo: appleLogoIcon, title: "Sign Up with Apple" },
  ];

  return (
    <>
      <ResponsiveAppBar />
      <Divider sx={{ height: 2, m: 0.5 }} orientation="horizontal" />
      <div style={{ minHeight: `calc(100%  - ${312}px)` }}>
        <LandingPageCard
          title="Find work that excites you"
          description="Network with startups from all kinds of industries. Use your potential to the max to expand your services. Increase your income with repeat clients."
          getSignUp={() => getSignUp(val, "expert_signup", "NormalFlow", "")}
          onInputChange={onInputChange}
          otherSignOptions={otherSignUpOptions}
        />
      </div>
      <Footer />
    </>
  );
};

export default ExpertLandingPage;
