import { ActionTypes } from "redux/constants/action-types";

const initialState = {
  leadDetail: {},
  error: "",
  packageID: "",
  credits: { cardNumber: "", expiry: "", cvc: "" },
  paymentMethodID: "",
  packageIdCredits: "",
  packageSelected: "",
  isFilterSelected: false,
  filters: { leadSubmittedTimeValue: "", checkStates: [], selectedCountry: "" },
};

export const userLeadDetail = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_LEAD_DETAIL:
      return { ...state, leadDetail: { ...payload } };
    case ActionTypes.SET_ERROR:
      return { error: { ...payload } };
    case ActionTypes.SET_PACKAGESELECTED:
      return { ...state, packageSelected: payload };
    case ActionTypes.SET_PACKAGEID:
      return { ...state, packageID: payload };
    case ActionTypes.SET_CREDITS:
      return { ...state, credits: { ...payload } };
    case ActionTypes.SET_PAYMENT_CHECKER:
      return { ...state, paymentCheck: payload };
    case ActionTypes.SET_PAYMENT_METHOD_ID:
      return { ...state, paymentMethodID: payload };
    case ActionTypes.SET_PACKAGEID_CREDITS:
      return { ...state, packageIdCredits: payload };
    case ActionTypes.REMOVE_PAYMENT_METHOD_ID:
      return { ...state, paymentMethodID: "" };
    case ActionTypes.REMOVE_PACKAGEID:
      return { ...state, packageID: "" };
    case ActionTypes.REMOVE_CREDITS:
      return { ...state, credits: initialState.credits };
    case ActionTypes.SET_IS_FILTER_SELECTED:
      return { ...state, isFilterSelected: payload };
    case ActionTypes.SET_FILTERS:
      return { ...state, filters: { ...payload } };
    default:
      return state;
  }
};
