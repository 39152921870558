import MDBox from "components/MDBox";
import React, { useState } from "react";
// import { post } from "axios-setup";
import { useSelector, useDispatch } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
// import { apiParamsFunction } from "constants/apiParamsFunction";
import { setToken } from "redux/actions/action";
import MDCustomInputPublic from "components/MDCustomPublicInput";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Paper } from "@mui/material";
import OTPInput, { ResendOTP } from "otp-input-react";
// import OTPInput from "otp-input-react";
// import OTPverification from "views/Common/OTPverification/OTPverification";
// import GetNumber from "views/Common/OTPverification/GetNumber";
import { makeStyles } from "@mui/styles";
import { submitPublicQuestionsForLeadPosting } from "constants/publicApiCalls";
import SearchLoader from "views/Common/Loader";
import { BootstrapDialog, BootstrapDialogTitle } from "../../components/MDDialog/index";
import { getSignUp } from "../../hooks/auth/B2CActions";
import {
  sendOTPVerificationCode,
  submitOTPCode,
  updateCurrentUserProfile,
} from "../../constants/userSettings";

const QuestionFormPublic = ({ currentService, setToggle }) => {
  // let isOTPVerified = false;

  // const questions = useSelector((state) => state.setTokenReducer?.questions);
  const questions = useSelector((state) => state.setTokenReducer?.public_questions);

  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [error, setError] = React.useState("");
  const [answer, onAnswerUpdate] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const [checkbox, setCheckbox] = React.useState([]);
  const dispatch = useDispatch();

  // Static States
  const [projectType, setProjectType] = React.useState("");
  const [budgetType, setBudgetType] = React.useState("");
  const [minRate, setMinRate] = React.useState("");
  const [maxRate, setMaxRate] = React.useState("");
  const [fixedPriceBudget, setFixedPriceBudget] = React.useState("");
  const [projectDuration, setProjectDuration] = React.useState("");
  const [startTime, setStartTime] = React.useState("");
  const [next, setNext] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [leadDescription, setleadDescription] = React.useState("");

  const [isGetCode, setIsGetCode] = React.useState(false);
  const [isUpdateNumber, setIsUpdateNumber] = React.useState(false);
  // const [phoneVerified, setPhoneVerified] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  // const [getOtp, setGetOtp] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [OTP, setOTP] = React.useState("");
  // const [OTPResponse, setOTPResponse] = React.useState("");
  const [resendTimmer, setResendTimmerValue] = useState(60);

  const [userData, setUserData] = React.useState("");
  const [open, setOpen] = React.useState(false);
  // const handleClickOpen = () => {
  //   if (!phoneVerified) setOpen(true);
  // };
  const styles = {
    submitCode: {
      borderRadius: 30,
      textTransform: "capitalize",
      width: "40%",
      marginLeft: "auto",
      marginRight: "auto",
      height: 47,
      display: "block",
    },
    or: {
      fontStyle: "normal",
      fontWeight: "900",
      fontSize: "20px",
      lineHeight: "100%",
      // margin: "1.875rem 0 1rem 0",
      display: "flex",
      justifyContent: "center",
    },
    changeNumber: {
      display: "block",
      margin: "auto",
      marginBottom: "2rem",
      cursor: "pointer",
      color: "#4285F4",
      textTransform: "capitalize",
      fontSize: 16,
    },
    inputPhone: {
      fontFamily: "hurmeGeometeric",
      color: "#495057",
      fontSize: "0.875rem",
      borderRadius: "0.375rem",
      border: "0.01rem solid lightgray",
      height: "1.4375em",
      padding: "0.75rem",
      marginTop: "8px",
      width: "70%",
      margin: "auto",
    },
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const renderTime = () => React.Fragment;
  const renderButton = (buttonProps) => (
    <MDButton {...buttonProps} style={{ color: "#de1921" }}>
      {buttonProps.remainingTime !== 0 ? (
        // ? `Please wait for ${buttonProps.remainingTime} sec for resend`
        <div style={{ color: "red" }}>{buttonProps.remainingTime} sec</div>
      ) : (
        "Resend"
      )}
    </MDButton>
  );
  const handleResendTimmer = () => {
    setResendTimmerValue(parseInt(resendTimmer, 10) + 60);
  };

  const handleClose = () => {
    setIsGetCode(false);
    setOpen(false);
  };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const getAnswerIntoArray = () => Object.entries(answer).map((x) => x[1]);
  const requestBody = {
    service: {
      id: currentService.id,
      name: currentService.name,
    },
    service_questions_and_answers: getAnswerIntoArray(),
    // project_type: "on-going",
    // budget_type: "pay by the hour",
    // hourly_budget: {
    //   min_rate: 10,
    //   max_rate: 15,
    // },
    // fixed_price_budget: 1000,
    // project_duration: "short",
    // start_time: "Immediately",
    // lead_description: "lorem ipsum"
  };
  const submitQuestionaries = async () => {
    // if (!phoneVerified && isOTPVerified === false) {
    // setOpen(true);
    // return;
    // }
    setError("");
    // if (answer[questions[currentQuestion]?.id]?.question_id === "startDate") {
    //   if (!answer[questions[currentQuestion]?.id]?.answer) {
    //     return setError("please provide answer");
    //   }
    // } else if (!answer[questions[currentQuestion]?.id]?.answer?.trim()) {
    //   return setError("please provide answer");
    // }
    setLoader(true);

    if (projectDuration) {
      requestBody.project_duration = projectDuration;
    }
    if (projectType) {
      requestBody.project_type = projectType;
    }
    if (budgetType) {
      requestBody.budget_type = budgetType;
    }
    if (fixedPriceBudget && budgetType === "fixed") {
      requestBody.fixed_price_budget = parseFloat(fixedPriceBudget);
    }
    if (minRate && budgetType === "hourly") {
      if (requestBody.hourly_budget !== undefined) {
        requestBody.hourly_budget.min_rate = minRate;
      } else {
        requestBody.hourly_budget = Object();
        requestBody.hourly_budget.min_rate = minRate;
      }
    }
    if (maxRate && budgetType === "hourly") {
      if (requestBody.hourly_budget !== undefined) {
        requestBody.hourly_budget.max_rate = maxRate;
      } else {
        requestBody.hourly_budget = Object();
        requestBody.hourly_budget.max_rate = maxRate;
      }
    }
    // if (startTime) {
    //   if (startTime === "Immediately") requestBody.start_time = startTime;
    // }
    // if (startDate) {
    //   if (startDate) requestBody.start_time = startDate[0].toString();
    // }
    if (startTime) {
      if (startTime === "Immediately") {
        requestBody.start_time = startTime;
      } else {
        requestBody.start_time = startDate[0].toString();
      }
    }
    if (leadDescription) {
      requestBody.lead_description = leadDescription;
    }

    let trimDataIndex = 0;
    if (requestBody.start_time === "Immediately") {
      trimDataIndex = requestBody.service_questions_and_answers.length - 5;
    } else {
      trimDataIndex = requestBody.service_questions_and_answers.length - 6;
    }
    const requestBodyQuestions = requestBody.service_questions_and_answers;

    if (trimDataIndex) {
      const trimData = [];
      for (let index = 0; index < trimDataIndex; ) {
        const element = requestBodyQuestions[index];
        if (element) trimData.push(element);
        index += 1;
      }
      requestBody.service_questions_and_answers = trimData;
    }
    // console.log(isOTPVerified, phoneVerified);
    // await post(apiParamsFunction(true, "application/json", "leads", requestBody));
    const res = await submitPublicQuestionsForLeadPosting(requestBody);
    if (res.status === 200) {
      try {
        // localStorage.setItem("leadProcess", true);
        setLoader(false);
        setOpen(false);
        localStorage.setItem("publicLeadId", res.data.id);
        getSignUp("test@gmail.com", "client_signup", "PublicLeadFlow", res.data.id);

        // dispatch(setModal(true));
      } catch (err) {
        setSnackMessage("Something went wrong. Please try again.");
        handleClickSnack();
        // console.log("Question Submission Error", err);
      }
    } else {
      setSnackMessage(res.message);
      handleClickSnack();
    }
  };
  const nextButtonClick = () => {
    const nextQuestion = currentQuestion + 1;
    if (
      budgetType === "hourly" &&
      answer[questions[currentQuestion]?.id]?.question_id?.trim() === "budgetStaicID"
    ) {
      if (minRate.indexOf(".") > -1 || maxRate.indexOf(".") > -1) {
        return setError("Value should not be in decimal");
      }
      if (answer[questions[currentQuestion]?.id]?.question_id?.trim() === "budgetStaicID") {
        if (Number(minRate) === 0 && Number(maxRate) === 0) {
          return setError("Please provide values greater than 0");
        }
        if (Number(minRate) === 0) {
          return setError("Please provide min rate greater than 0");
        }
        if (Number(maxRate) === 0) {
          return setError("Please provide max rate greater than 0");
        }
      }
      if (
        answer[questions[currentQuestion]?.id]?.question_id?.trim() === "budgetStaicID" &&
        Number(minRate) < 1
      ) {
        return setError("Value should be greater than 0");
      }
      if (
        answer[questions[currentQuestion]?.id]?.question_id?.trim() === "budgetStaicID" &&
        Number(maxRate) <= Number(minRate)
      ) {
        return setError("Max rate should be greater than min rate");
      }
    }
    if (answer[questions[currentQuestion]?.id]?.question_id === "startDate" && startDate === "") {
      return setError("please provide answer");
    }
    if (
      questions[currentQuestion]?.required &&
      !answer[questions[currentQuestion]?.id]?.answer?.trim()
    ) {
      return setError("Please provide answer");
    }

    if (budgetType === "fixed") {
      if (fixedPriceBudget.indexOf(".") > -1) {
        return setError("Value should not be in decimal");
      }
      if (
        answer[questions[currentQuestion]?.id]?.answer?.trim() &&
        answer[questions[currentQuestion]?.id]?.question_id?.trim() === "budgetStaicID" &&
        fixedPriceBudget < 1
      ) {
        return setError("Bugdet should be greater than 0");
      }
    }

    if (
      answer[questions[currentQuestion]?.id]?.question_id?.trim() === "leadDesc" &&
      answer[questions[currentQuestion]?.id]?.answer.length < 300
    ) {
      return setError("Minimum 300 characters are needed to proceed");
    }

    // if (answer[questions[currentQuestion]?.id]?.question_id === "startDate") {
    //   if (!answer[questions[currentQuestion]?.id]?.answer) {
    //     return setError("please provide answer");
    //   }
    // } else if (!answer[questions[currentQuestion]?.id]?.answer?.trim()) {
    //   return setError("please provide answer");
    // }
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    }
    if (next) {
      return setStartTime(next);
    }
  };

  const backButtonClick = () => {
    const nextQuestion = currentQuestion - 1;
    if (error) {
      setError("");
    }
    if (currentQuestion > 0) {
      setCurrentQuestion(nextQuestion);
    }
    if (startTime) {
      setStartTime("");
    }
    // onAnswerUpdate("");
    // setCheckbox("");
  };

  const onAnswerChange = (questionData, value) => {
    const questionId = questionData.id;
    const questionTitle = questionData.title;
    let prevAnswers = answer;

    if (questionData.id === "projectDuration") {
      setProjectDuration(value);
    }

    if (questionData.id === "projectType") {
      setProjectType(value);
    }

    if (questionData.id === "budgetType") {
      setBudgetType(value);
    }

    // if (questionData.id === "budgetStaicID") {
    //   setFixedPriceBudget(value);
    // }
    if (questionData.id === "budgetStaicID") {
      if (questionData.budgetReference === "min") {
        setMinRate(value);
      } else if (questionData.budgetReference === "max") {
        setMaxRate(value);
      } else {
        setFixedPriceBudget(value);
      }
    }

    if (questionData.id === "startTime") {
      setStartTime(value);
      setNext(value);
    }
    if (questionData.id === "startDate") {
      if (value && value.length !== 0) setStartDate(value);
      else setStartDate("");
    }

    if (questionData.id === "leadDesc") {
      setleadDescription(value);
    }

    prevAnswers = {
      ...prevAnswers,
      [questionId]: {
        // ...prevAnswers[question_id],
        question: questionTitle,
        question_id: questionId,
        answer: value,
      },
    };
    onAnswerUpdate(prevAnswers);
  };
  const changeHandler = (questionData, value) => {
    onAnswerChange(questionData, value);
    if (error) {
      setError("");
    }
  };

  const onhandlerCheckbox = (questionData, e) => {
    const newValues = [...checkbox];

    if (e.target.checked === true) {
      newValues.push(e.target.value);
    } else if (e.target.checked === false) {
      const val = checkbox.indexOf(e.target.value);
      newValues.splice(val, 1);
    }

    setCheckbox(newValues);

    const question_id = questionData.id;
    const questionTitle = questionData.title;
    let prevAnswers = answer;
    prevAnswers = {
      ...prevAnswers,
      [question_id]: {
        ...prevAnswers[question_id],
        question: questionTitle,
        question_id,
        answer: newValues.join(","),
      },
    };
    onAnswerUpdate(prevAnswers);
    if (error) {
      setError("");
    }
  };

  // let isValidPn = true;

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  // const phoneValidation = () => {
  //   const pattern = new RegExp(/^\+(?:[0-9] ?){11,14}[0-9]$/);
  //   return !(!phoneNumber || pattern.test(phoneNumber) === false);
  // };
  // const onSubmit = () => {
  //   const isPhoneValid = phoneValidation();
  //   if (isPhoneValid) {
  //     isValidPn = isPhoneValid;
  //   } else {
  //     isValidPn = isPhoneValid;
  //   }
  // };
  const handleNumberChange = (number) => {
    setPhoneNumber(number);
  };
  const updateProfile = async () => {
    // if (isValidPn) {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      //   setUpdateProfLoader(true);
      try {
        const request = {
          ...userData,
          phone: phoneNumber,
        };
        const res = await updateCurrentUserProfile(request);

        if (res && res.status && res.status === 200) {
          setUserData(res.data);
          // setGetOtp(true);
          setSnackMessage("Phone number updated successfully");
          setIsUpdateNumber(false);
        } else {
          setSnackMessage(res.message);
        }
      } catch (err) {
        console.error(err);
        setSnackMessage("Error updating profile");
      }
      // setIsUpdateNumber(false);
    } else {
      setSnackMessage("Please enter correct Phone number");
    }
    // if (!isValidPn) {
    //   setSnackMessage("Please enter correct Phone number");
    // } else if (!isValidPn) {
    //   setSnackMessage("Please enter correct Phone number");
    // }
  };

  const sendOTPCode = async () => {
    try {
      if (OTP) {
        const response = await submitOTPCode(OTP);
        // setOTPResponse(response.contact_Verification_Code_Verified);
        if (response && response.status === 200) {
          if (response.data.contact_Verification_Code_Verified === true) {
            // setOpenSnack(true);
            // setSnackMessage("OTP Verified");
            // await setPhoneVerified(true);
            // isOTPVerified = true;
            // submitQuestionaries();
          } else {
            setOpenSnack(true);
            setSnackMessage("Internal error! Please contact synerge support team");
          }
        }
        if (response && response.status === 400) {
          setOpenSnack(true);
          setSnackMessage(response.message);
        }
      }
    } catch (err) {
      console.error("Error:", err);
      setOpenSnack(true);
      setSnackMessage("Internal error! Please contact synerge support team");
    }
  };

  // React.useEffect(() => {
  //   // setResendTimmerValue(parseInt(resendTimmer, 10) + 60);
  // }, []);

  return (
    <>
      <Grid container justifyContent="center" my={10}>
        <Grid item xs={11} sm={8} lg={6} mb={3} sx={{ textAlign: "center" }}>
          <Paper
            id="delete-account"
            sx={{
              minHeight: "100%",
              borderRadius: "2rem",
              padding: "1rem",
              textAlign: "center",
              marginBottom: "20px",
            }}
            elevation={5}
          >
            {loader ? (
              <SearchLoader />
            ) : (
              <>
                <MDBox pb={3} lineHeight={1} sx={{ paddingTop: "5%" }}>
                  <MDBox mb={1} justifyContent="center" alignItem="center">
                    <MDTypography variant="h2">{currentService.name}</MDTypography>
                  </MDBox>
                  <MDTypography variant="body2" color="#051931">
                    Please answer the following questions.
                  </MDTypography>
                </MDBox>

                {questions.length > 0 && questions && (
                  <Grid container justifyContent="center">
                    <Grid item xs={12} lg={12}>
                      <MDBox py={3} lineHeight={1}>
                        <MDBox mb={1} justifyContent="center" alignItem="center">
                          <MDTypography variant="h6">
                            {/* startTime === "Immediately" ? */}
                            {/* {questions?.length > 0
                        ? `Question ${currentQuestion + 1}`
                        ? `Question against ${currentService?.name}
                    not found`
                        : `Question ${currentQuestion + 1}`} */}
                            {questions?.length > 0 &&
                            questions[currentQuestion]?.questionType !== "datePicker"
                              ? `Question ${currentQuestion + 1}`
                              : ""}
                          </MDTypography>
                        </MDBox>
                        <MDTypography
                          variant="body2"
                          color="#051931"
                          fontWeight="bold"
                          textAlign="center"
                          // noWrap="true"
                        >
                          {questions && questions[currentQuestion]?.title}
                        </MDTypography>
                        <MDTypography
                          variant="body2"
                          color="#051931"
                          textAlign="center"
                          // noWrap="true"
                        >
                          {questions && questions[currentQuestion]?.titleDescription}
                        </MDTypography>
                      </MDBox>
                      {error && (
                        <MDBox
                          sx={{
                            color: "#DE1921",
                            fontSize: "0.875rem",
                            fontWeight: 300,
                            fontFamily: "hurmeGeometeric",
                            marginBottom: "15px",
                          }}
                        >
                          {/* <MDTypography variant="button" color="error" sx={{ m: 3 }}> */}
                          {error}
                          {/* </MDTypography> */}
                        </MDBox>
                      )}
                      {questions[currentQuestion]?.questionType === "radioStatic" ? (
                        <MDBox
                          display="flex"
                          // justifyContent="space-around"
                          flexDirection={{ xs: "column", md: "row" }}
                          marginBottom={3}
                        >
                          <MDCustomInputPublic
                            inputType={questions[currentQuestion]?.questionType}
                            options={questions[currentQuestion]?.questionoptions}
                            answer={answer}
                            changeHandler={(b, e) => changeHandler(b, e)}
                            onhandlerCheckbox={(a, b, c) => onhandlerCheckbox(a, b, c)}
                            currentQuestion={currentQuestion}
                            checkbox={checkbox}
                            budgetType={budgetType}
                            minRate={minRate}
                            maxRate={maxRate}
                            // fixedPriceBudget={fixedPriceBudget}
                          />
                        </MDBox>
                      ) : (
                        <MDBox display="flex" flexDirection={{ xs: "Column" }} marginBottom={3}>
                          <MDCustomInputPublic
                            inputType={questions[currentQuestion]?.questionType}
                            options={questions[currentQuestion]?.questionoptions}
                            answer={answer}
                            changeHandler={(b, e) => changeHandler(b, e)}
                            onhandlerCheckbox={(a, b, c) => onhandlerCheckbox(a, b, c)}
                            currentQuestion={currentQuestion}
                            checkbox={checkbox}
                            budgetType={budgetType}
                            startTime={startTime}
                            minRate={minRate}
                            maxRate={maxRate}
                            // fixedPriceBudget={fixedPriceBudget}
                            // optionvalue={}
                          />
                          {answer[questions[currentQuestion]?.id]?.question_id?.trim() ===
                            "leadDesc" && (
                            <MDBox>
                              <MDTypography
                                variant="body2"
                                color="#051931"
                                textAlign="right"
                                sx={{
                                  p: "1% 21%",
                                  fontSize: "14px",
                                }}
                                // noWrap="true"
                              >
                                Chars:{" "}
                                <span
                                  style={{
                                    color:
                                      answer[questions[currentQuestion]?.id]?.answer.length > 300
                                        ? ""
                                        : "red",
                                  }}
                                >
                                  {!answer[questions[currentQuestion]?.id]?.answer.length
                                    ? 0
                                    : answer[questions[currentQuestion]?.id]?.answer.length}
                                </span>
                              </MDTypography>
                            </MDBox>
                          )}
                        </MDBox>
                      )}

                      <MDBox
                        display="flex"
                        width={{ xs: "100%", sm: "100%", md: "80%" }}
                        margin={{ md: "auto" }}
                        justifyContent="space-evenly"
                        flexDirection={{ xs: "column", sm: "row" }}
                      >
                        <MDButton
                          color="dark"
                          onClick={() => {
                            // eslint-disable-next-line no-unused-expressions
                            currentQuestion + 1 === 1 ? setToggle(true) : backButtonClick();
                          }}
                          circular
                          size="large"
                          sx={{
                            marginBottom: {
                              xs: "1% !important",
                              sm: "10px",
                              md: "10px",
                              lg: "10px",
                            },
                            margin: { xs: "auto" },
                            width: { xs: "80%", md: "152px" },
                            textTransform: "capitalize",
                            order: { xs: 2, sm: 1, md: 1 },
                          }}
                        >
                          back
                        </MDButton>
                        {
                          // eslint-disable-next-line no-nested-ternary
                          (questions?.length > 0 &&
                            currentQuestion + 1 === questions.length &&
                            startDate !== "") ||
                          (startTime === "Immediately" &&
                            answer[questions[currentQuestion]?.id]?.question_id?.trim() !==
                              "budgetStaicID") ? (
                            loader ? (
                              <MDButton
                                variant="gradient"
                                color="error"
                                sx={{
                                  height: "100%",
                                  margin: { xs: "auto" },
                                  marginBottom: {
                                    xs: "1% !important",
                                    sm: "10px",
                                    md: "10px",
                                    lg: "10px",
                                  },
                                  width: { xs: "80%", md: "152px" },
                                  textTransform: "capitalize",
                                  order: { xs: 1, sm: 2, md: 2 },
                                }}
                                //   onClick={submitQuestionaries}
                                circular
                                size="large"
                              >
                                loading...
                              </MDButton>
                            ) : (
                              <MDButton
                                variant="gradient"
                                color="error"
                                sx={{
                                  height: "100%",
                                  margin: { xs: "auto" },
                                  marginBottom: {
                                    xs: "1% !important",
                                    sm: "10px",
                                    md: "10px",
                                    lg: "10px",
                                  },
                                  width: { xs: "80%", md: "152px" },
                                  textTransform: "capitalize",
                                  order: { xs: 1, sm: 2, md: 2 },
                                }}
                                onClick={() => {
                                  // isOTPVerified = false;
                                  submitQuestionaries();
                                }}
                                // onClick={handleClickOpen}
                                circular
                                size="large"
                              >
                                Finish
                              </MDButton>
                            )
                          ) : (
                            questions?.length > 0 && (
                              <MDButton
                                variant="gradient"
                                color="error"
                                sx={{
                                  height: "100%",
                                  margin: { xs: "auto" },
                                  marginBottom: {
                                    xs: "1% !important",
                                    sm: "10px",
                                    md: "10px",
                                    lg: "10px",
                                  },
                                  width: { xs: "80%", md: "152px" },
                                  textTransform: "capitalize",
                                  order: { xs: 1, sm: 2, md: 2 },
                                }}
                                onClick={nextButtonClick}
                                circular
                                size="large"
                              >
                                next
                              </MDButton>
                            )
                          )
                        }
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* dialog box */}
      {/* {phoneVerified} */}
      {/* <div> */}
      {/* <MDButton variant="outlined">Open alert dialog</MDButton> */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={handleClose}
        // aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ margin: "3rem 5rem 1rem 5rem" }}
        >
          {(!userData.phone || userData.phone === "") && "Please enter phone number"}
          {/* {userData.phone && isGetCode && "Please enter OTP code"} */}
          {userData.phone && !isGetCode && "Get code and verify profile"}
        </BootstrapDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!userData.phone || userData.phone === "" || isUpdateNumber ? (
              <>
                {/* {!isUpdateNumber && ( */}
                {/* <Tooltip title="ex: +447911651780" placement="left-start">
                    <TextField
                      type="phone"
                      fullWidth
                      variant="outlined"
                      label="Phone Number"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      sx={{
                        marginTop: "1rem",
                        width: "80%",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </Tooltip> */}
                <MDBox className={classes.inputPhone}>
                  <PhoneInput
                    international
                    // initialValueFormat="national"
                    countryCallingCodeEditable={false}
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={(e) => handleNumberChange(e)}
                    // onChange={setPhoneNumber}
                    // onChange={setValue}
                    // error={phoneNumber && isValidPhoneNumber(phoneNumber) ? "true" : "false"}
                  />
                </MDBox>
                <MDButton
                  className={classes.submitCode}
                  sx={{ width: "100%", margin: "3rem 0 2rem" }}
                  color="error"
                  onClick={async () => {
                    // onSubmit();
                    await updateProfile();
                    // console.log("OPT sent");
                    // setIsGetCode(true);
                    setIsUpdateNumber(false);
                    // sendOTPVerificationCode();
                    handleClickSnack();
                  }}
                >
                  Add Number
                </MDButton>
                {/* )} */}
              </>
            ) : (
              <>
                {/* <MDButton
                      disabled={isGetCode}
                      // className={classes.submitCode}
                      // sx={
                      //   isGetCode
                      //     ? { width: "100%", margin: "3rem 0 2rem", display: "none !important" }
                      //     : { width: "100%", margin: "3rem 0 2rem" }
                      // }
                      sx={isGetCode ? { display: "none" } : { minHeight: 0, padding: "5px 15px" }}
                      color="dark"
                      onClick={async () => {
                        setIsGetCode(true);
                        // setIsUpdateNumber(true);
                        // sendOTPVerificationCode();
                        // handleClickSnack();
                      }}
                    >
                      Get Code
                    </MDButton> */}
                <MDButton
                  disabled={isGetCode}
                  className={classes.submitCode}
                  sx={
                    isGetCode
                      ? { width: "100%", margin: "3rem 0 2rem", display: "none !important" }
                      : { width: "100%", margin: "1rem 0 2rem" }
                  }
                  color="dark"
                  onClick={async () => {
                    const res = await sendOTPVerificationCode();
                    if (res.status === 200) {
                      setSnackMessage("OTP sent successfully");
                      setIsGetCode(true);
                    } else if (res.status === 400) {
                      setOpenSnack(true);
                      setSnackMessage(res.message);
                    }
                    handleClickSnack();
                  }}
                >
                  Get Code
                </MDButton>

                {isGetCode && userData && userData.phone && (
                  <MDBox sx={{ margin: "0 4rem" }}>
                    <MDTypography variant="h6" color="text">
                      Please verify your number {" **** *** "}
                      {phoneNumber.substr(phoneNumber.length - 3)}
                    </MDTypography>
                    <MDBox mt={4} display="flex" justifyContent="center">
                      <OTPInput
                        value={OTP}
                        onChange={setOTP}
                        // autoFocus
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        // secure
                        inputStyles={{
                          borderLeft: "none",
                          borderTop: "none",
                          borderRight: "none",
                          broderBottom: "3px solid #051931",
                          marginRight: "1rem",
                          marginLeft: "1rem",
                        }}
                      />
                      {/* <ResendOTP onResendClick={() => console.log("Resend clicked")} /> */}
                    </MDBox>
                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        placeContent: "center",
                        paddingTop: "5%",
                      }}
                    >
                      Didn’t receive the code?
                      <ResendOTP
                        renderButton={renderButton}
                        renderTime={renderTime}
                        onResendClick={async () => {
                          const res = await sendOTPVerificationCode();
                          if (res.status === 200) await handleResendTimmer();
                          else if (res.status === 400) {
                            setOpenSnack(true);
                            setSnackMessage(res.message);
                          }
                        }}
                        maxTime={resendTimmer}
                      />
                    </MDBox>
                    <MDButton
                      disabled={!isGetCode}
                      className={classes.submitCode}
                      sx={{ width: "100%", margin: "3rem 0 2rem" }}
                      color="error"
                      onClick={async () => {
                        if (OTP && OTP.length === 4) {
                          await sendOTPCode();
                          // await getData();
                          await submitQuestionaries();
                          await dispatch(setToken(userData));
                        } else {
                          setOpenSnack(true);
                          setSnackMessage("Please enter valid OTP");
                        }
                      }}
                    >
                      Verify
                    </MDButton>
                  </MDBox>
                )}
                <MDTypography className={classes.or}>OR</MDTypography>
                <MDButton
                  className={classes.changeNumber}
                  onClick={() => {
                    setIsUpdateNumber(true);
                    // setPhoneNumber("");
                    setIsGetCode(false);
                  }}
                >
                  Change Number
                </MDButton>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
            <MDButton onClick={handleClose}>aaaaa</MDButton>
            <MDButton onClick={handleClose}>bbbbb</MDButton>
          </DialogActions> */}
        <Snackbar
          open={openSnack}
          autoHideDuration={4000}
          action={action}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={
              snackMessage === "Profile updated successfuly" ||
              snackMessage === "Phone number updated successfully" ||
              snackMessage === "OTP sent successfully"
                ? "success"
                : "error"
            }
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </BootstrapDialog>
      {/* </div> */}
    </>
  );
};

export default QuestionFormPublic;

QuestionFormPublic.propTypes = {
  currentService: PropTypes.object,
  setToggle: PropTypes.func,
};
