import React from "react";
import { AppRoutes } from "routes";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import ResponsiveAppBar from "components/MDHeaderBar";
import { Switch, Redirect, Route } from "react-router-dom";
import { Divider } from "@mui/material";
import GenericClientRoutes from "routes/genericClientRoutes";
import NotFoundPage from "../views/Auth/NotFoundPage";
import { getRoutes } from "../helpers/routesHelpers";
// eslint-disable-next-line arrow-body-style
const Client = () => {
  return (
    <MDBox>
      <ResponsiveAppBar routes={AppRoutes()} />
      <Divider sx={{ height: 2, m: 0.5, marginBottom: 0 }} orientation="horizontal" />
      <div style={{ minHeight: `calc(100vh  - ${295}px)` }}>
        <Switch>
          {getRoutes(AppRoutes())}
          <GenericClientRoutes />
          <Redirect exact from="/client" to="/client/dashboard" />
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </div>

      <Footer />
    </MDBox>
  );
};

export default Client;
