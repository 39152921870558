import React from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import creditIcon from "assets/imgs/icons/creditIcon.png";
import { formatAMPM, displayInDateFormat } from "../../../../constants/getTime";

const PurchasedCreditsHistory = ({ purchaseHistory }) => {
  const date = new Date(purchaseHistory.created);
  return (
    <>
      <MDBox display="flex" justifyContent="space-between" sx={{ mb: 4 }}>
        <MDBox sx={{ borderLeft: "4px solid #051931", paddingLeft: "17px" }}>
          <MDTypography sx={{ fontSize: "14px", lineHeight: "28px", alignItems: "center" }}>
            <img src={creditIcon} alt="credit icon" /> {purchaseHistory.creditsPurchased} Credits
          </MDTypography>
          <MDTypography sx={{ fontSize: "14px", lineHeight: "28px" }}>
            {" "}
            {purchaseHistory.package_Name} Offer
          </MDTypography>
        </MDBox>
        <MDBox display="grid" alignItems="center">
          <MDTypography
            sx={{
              fontSize: "12px",
              lineHeight: "15px",
              color: "#9F9F9F",
              textAlign: "end",
            }}
          >
            {/* {`${date.toString().split(" ")[4].toString().split(":")[0]}:${
              date.toString().split(" ")[4].toString().split(":")[1]
            }`} */}
            {purchaseHistory.card.brand} **** **** **** {purchaseHistory.card.last4}
          </MDTypography>
          <MDTypography sx={{ fontSize: "12px", lineHeight: "15px", color: "#9F9F9F" }}>
            {/* {`${date.toString().split(" ")[0]}, ${
              date.toString().split(" ")[1]
            } ${date.getDate()}, ${date.getFullYear()}`} */}
            {displayInDateFormat(date)} {formatAMPM(date)}
          </MDTypography>
        </MDBox>
      </MDBox>
    </>
  );
};

PurchasedCreditsHistory.propTypes = {
  purchaseHistory: PropTypes.object,
};

export default PurchasedCreditsHistory;
