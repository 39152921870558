import React from "react";
import { get, post } from "axios-setup/index";
import { apiParamsFunction } from "constants/apiParamsFunction";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  setLeadDetail,
  setToken,
  setPackageId,
  setPackageidCreditcs,
  setPackageName,
} from "redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import UserLists from "views/Common/UserLists";
import Loader from "views/Common/Loader";
// import MDNotifications from "components/MDNotification";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import store from "redux/store";
import Snackbar from "@mui/material/Snackbar";
import { getCurrentUserData } from "constants/userSettings";
import { postPurchaseCreditsPackage, getFilteredLeadList } from "constants/getData";
import { validateYourCard, validateCreditCardExpiry } from "constants/helper";
import { filterList } from "views/Common/FilterLeadList";
import ExpertModal from "./expertComponent/ExpertModal";
import UserDetail from "./expertComponent/UserDetail";
import CreditCardAddRemove from "./expertComponent/FourTypeOfModals/CreditCardAddRemove";
import PackageModal from "./expertComponent/FourTypeOfModals/PackageModal";
import ConfirmationModal from "./expertComponent/FourTypeOfModals/ConfirmationModal";
import { getCardTypeByValue } from "./expertComponent/FourTypeOfModals/CreditCardUtils";
import comeBackSoon from "../../assets/images/comeBackSoon.png";

const Leads = () => {
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("Please enter correct Card details");
  const [leadsUpdate, setLeadsUpdate] = React.useState(false);

  const data = useSelector((state) => state?.setTokenReducer?.data);

  const credits = data?.credit_Balance;
  const [lead, setLead] = React.useState([]);
  const history = useHistory();
  const selectLeadData = useSelector((state) => state?.userLeadDetail?.leadDetail);
  // const buyingCredits = useSelector((state) => state?.userLeadDetail?.packageIdCredits);
  const isFilterSelected = useSelector((state) => state?.userLeadDetail?.isFilterSelected);
  const filters = useSelector((state) => state?.userLeadDetail?.filters);

  const [loading, setLoading] = React.useState(true);
  const [detailViewLoading, setDetailViewLoading] = React.useState(false);
  const [modalFlag, setModalFlag] = React.useState("first");
  const [showModal, setShowModal] = React.useState(false);
  const [buttonLoader, setButtonLoader] = React.useState(false);
  const [showLeadDetails, setShowLeadDetails] = React.useState(false);
  const [buyingCredits, setBuyingCredits] = React.useState("");
  const [buyingPackage, setBuyingPackage] = React.useState("");

  /* eslint-disable global-require */
  // const validators = require("credit-card-validate");
  let userServiceIds = [];
  let leadsArray = [];

  const dispatch = useDispatch();

  const getPaymentMethodID = () => {
    const stat = store.getState((state) => state?.userLeadDetail);
    return stat?.userLeadDetail?.paymentMethodID;
  };

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackMessage("");
    setOpenSnack(false);
  };

  // const handleClickSnack = () => {
  //   setOpenSnack(true);
  // };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  // const validateYourCard = (number, expiry, cvc, cardType) => {
  //   let cardValidator = [];
  //   cardValidator = new validators.cards[cardType](
  //     number?.toString(),
  //     new Date(expiry),
  //     cvc?.toString()
  //   );
  //   return cardValidator.isValid();
  // };

  React.useEffect(() => {
    if (modalFlag === "fourth") {
      return setModalFlag("fourth");
    }
    if (modalFlag === "fifth") {
      return setModalFlag("fifth");
    }
    // if (selectLeadData.credits > credits) {
    //   setModalFlag("first");
    // }
    if (selectLeadData.credits > 0 && selectLeadData.credits <= credits) {
      setModalFlag("third");
      // getPaymentMethodID();
    }
  }, [selectLeadData, modalFlag]);

  const getCurrentUserInfo = async () => {
    const res = await getCurrentUserData();
    dispatch(setToken(res));
    localStorage.setItem("data", JSON.stringify(res));
  };

  const creditPurchase = async () => {
    if (selectLeadData.credits > credits) {
      const getPackageId = () => {
        const stat = store.getState((state) => state?.userLeadDetail);
        return stat?.userLeadDetail?.packageID;
      };
      const getCredits = () => {
        const stat = store.getState((state) => state?.userLeadDetail);
        return stat?.userLeadDetail?.credits;
      };
      const packgId = getPackageId();
      const credit = getCredits();
      const paymentMethodID = getPaymentMethodID();
      if (paymentMethodID && paymentMethodID.length > 0) {
        setButtonLoader(true);
        const creditCardDetail = {
          brand: "",
          exp_Year: null,
          exp_month: null,
          number: "",
        };
        try {
          const res = await postPurchaseCreditsPackage(
            packgId,
            paymentMethodID,
            creditCardDetail,
            false
          );
          const { status } = res;
          if (status >= 200 && status <= 299) {
            const res2 = await post(
              apiParamsFunction(
                true,
                "application/json",
                "users/me/purchased_leads",
                {
                  lead_id: selectLeadData.id,
                },
                ""
              )
            );
            if (res2.status === 200) {
              await getCurrentUserInfo();
              setModalFlag("fifth");
            } else {
              setButtonLoader(false);
              setModalFlag("invalid");
              setOpenSnack(true);
              setSnackMessage(`${res2.data.title} , ${res2.data.detail}`);
              setOpenSnack(false);
            }
          } else {
            setButtonLoader(false);
            setModalFlag("invalid");
          }
        } catch (err) {
          console.error("Error: ", err);
          setButtonLoader(false);
          setModalFlag("invalid");
        }
        setButtonLoader(false);
      } else {
        setButtonLoader(true);
        try {
          const monthAndYear = credit.expiry.split("/");
          const cardDetails = {
            expMonth: monthAndYear[0].trim(),
            expYear: monthAndYear[1].trim(),
            Number: credit.cardNumber,
            CVC: credit.cvc,
          };
          // const addNewCard = await createPaymentMethod(
          //   parseInt(monthAndYear[0], 10),
          //   parseInt(monthAndYear[1], 10),
          //   credit.number,
          //   credit.cvc,
          //   credit.isDefaultCard
          // );
          // if (addNewCard.status === 200) {
          const requestOptionsBody = {
            package_id: packgId,
            Card: cardDetails,
            payment_method_id: "",
            savepayment_method: credit.isDefaultCard === undefined ? true : credit.isDefaultCard,
            lead_id: selectLeadData.id,
          };
          const result = await post(
            apiParamsFunction(
              true,
              "application/json",
              "users/me/purchase_package",
              requestOptionsBody
            )
          );
          const { status } = result;
          if (status >= 200 && status <= 299) {
            const res1 = await post(
              apiParamsFunction(
                true,
                "application/json",
                "users/me/purchased_leads",
                {
                  lead_id: requestOptionsBody.lead_id,
                },
                ""
              )
            );
            if (res1.status !== 200) {
              setModalFlag("invalid");
              setButtonLoader(false);
              setOpenSnack(true);
              setSnackMessage(`${res1.data.title} , ${res1.data.detail}`);
              setOpenSnack(false);
            }
          }
          // } else if (addNewCard.status !== 200 && addNewCard.data) {
          //   setSnackMessage(addNewCard.data.detail);
          // }

          await getCurrentUserInfo();
          setModalFlag("fourth");
          setButtonLoader(false);
        } catch (err) {
          console.log("LeadSubmission error", err);
          setModalFlag("invalid");
          setButtonLoader(false);
        }
      }
    } else {
      setButtonLoader(true);
      try {
        const res3 = await post(
          apiParamsFunction(
            true,
            "application/json",
            "users/me/purchased_leads",
            {
              lead_id: selectLeadData.id,
            },
            ""
          )
        );
        if (res3.status === 200) {
          await getCurrentUserInfo();
          setModalFlag("fourth");
          setButtonLoader(false);
        } else {
          setModalFlag("invalid");
          setButtonLoader(false);
          setOpenSnack(true);
          setSnackMessage(`${res3.data.title} , ${res3.data.detail}`);
          setOpenSnack(false);
        }
      } catch (err) {
        console.error("Lead Submission Error:", err);
        setModalFlag("invalid");
        setButtonLoader(false);
      }
    }
  };

  const modalTypes = {
    first: {
      type: "first",
      title: "Not enough credits!",
      description: "You need to buy credits",
      label: "Buy Credits",
      nextRoute: "second",
      errorMessage: "Please Select a Package",
      ThumbIcon: false,
      successfunc: () => {
        const stat = store.getState((state) => state?.userLeadDetail);
        const pkgId = stat?.userLeadDetail?.packageID;
        const creditsSelected = stat?.userLeadDetail?.packageIdCredits;
        const packageSelected = stat?.userLeadDetail?.packageSelected;
        setBuyingCredits(creditsSelected);
        setBuyingPackage(packageSelected);
        if (pkgId) {
          setModalFlag("second");
        } else {
          return null;
        }
      },
    },
    second: {
      type: "second",
      title: `You are buying ${buyingCredits
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} credits`,
      description: " ",
      label: "Buy ",
      nextRoute: "third",
      ThumbIcon: false,
      errorMessage: "Add your credit card",
      successfunc: () => {
        const stat = store.getState((state) => state?.userLeadDetail);
        const paymentMethodID = getPaymentMethodID();
        if (paymentMethodID && paymentMethodID.length > 0) {
          setModalFlag("third");
        } else {
          const creditDetails = stat?.userLeadDetail?.credits;
          const { cardNumber, expiry, cvc } = creditDetails;
          const cardType = getCardTypeByValue(cardNumber);
          if (cardType && cardType.type) {
            // console.log(cardType.type);
            // let cardTypeUsed;
            // if (cardType.type === "amex") {
            //   cardTypeUsed = "AmericanExpress";
            // } else if (cardType.type === "mastercard") {
            //   cardTypeUsed = "MasterCard";
            // } else {
            //   cardTypeUsed = cardType.type.charAt(0).toUpperCase() + cardType.type.slice(1);
            // }
            if (validateCreditCardExpiry(expiry)) {
              const validate = validateYourCard(
                cardNumber.replace(/\s/g, ""),
                expiry.split("/"),
                cvc,
                cardType.type
              );
              if (validate) {
                setModalFlag("third");
              } else {
                // setSnackMessage("Please Provide Correct Card Details");
                // handleClickSnack();
                return null;
              }
            } else {
              // setSnackMessage("Please Provide Correct Card Expiry");
              // handleClickSnack();
              return null;
            }
          } else {
            return null;
          }
          // let cardValidator = [];
          // try {
          //   cardValidator = new validators.cards.Visa(
          //     number?.toString(),
          //     new Date(expiry),
          //     cvc?.toString()
          //   );
          // } catch (err) {
          //   console.error("Error: ", err);
          // }
          // if (cardValidator.isValid()) {
          //   setModalFlag("third");
          // } else {
          //   return null;
          // }
        }
      },
    },
    third: {
      type: "third",
      title: "Please Confirm",
      description: `${selectLeadData?.credits} credits will be used to submit your details to ${selectLeadData?.posted_by?.name}`,
      label: "Unlock",
      secondButton: true,
      ThumbIcon: false,
      secondLabel: "cancel",
      successfunc: () => {
        creditPurchase();
        if (!buyingCredits) {
          setModalFlag("fourth");
        }
      },
    },
    fourth: {
      type: "fourth",
      title: "Successful",
      description: "Lead successfully unlocked. Click below to view client contact details.",
      label: "View unlocked lead",
      ThumbIcon: true,
      successfunc: () => {
        setShowModal(false);
        if (credits) {
          setModalFlag("third");
        } else {
          setModalFlag("first");
        }
        history.push("/expert/my-leads");
      },
    },
    fifth: {
      type: "fifth",
      title: "Purchase Successful",
      description: `You have purchased ${buyingPackage} and unlocked the lead for ${selectLeadData?.credits} credits.`,
      label: "View unlocked lead",
      ThumbIcon: true,
      successfunc: () => {
        setShowModal(false);
        if (credits) {
          setModalFlag("third");
        } else {
          setModalFlag("first");
        }
        dispatch(setPackageidCreditcs(""));
        dispatch(setPackageId(""));
        dispatch(setPackageName(""));
        history.push("/expert/my-leads");
      },
    },
    invalid: {
      type: "invalid",
      title: "Error",
      description: "Card details are not valid please try again.",
      label: "try again",
      secondButton: true,
      secondLabel: "cancel",
      successfunc: () => {
        setModalFlag("second");
      },
    },
  };
  const selectUserID = async (id) => {
    setDetailViewLoading(true);
    try {
      if (id === -1) {
        setShowLeadDetails(false);
      } else {
        setShowLeadDetails(true);
        const leadResponseselectUserID = await get(
          apiParamsFunction(true, "application/json", "leads", "", `/${id}`)
        );
        await dispatch(setLeadDetail(leadResponseselectUserID.data));
      }
      setDetailViewLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const renderModalType = () => {
    switch (modalTypes[modalFlag].type) {
      case "first":
        return <PackageModal credits={credits} selectLeadData={selectLeadData} />;
      case "second":
        return <CreditCardAddRemove setModalFlag={setModalFlag} />;
      case "third":
        return <ConfirmationModal credits={credits} selectLeadData={selectLeadData} />;
      case "fourth":
        return null;
      case "fifth":
        return null;
      case "invalid":
        return null;
      default:
        return <PackageModal />;
    }
  };

  // services: "802464d8-8fa0-43b6-a817-1341295ba025&services=197e2ba3-a004-48f0-bc85-627bf0558f3f",

  const getLeads = async (serviceId) => {
    if (isFilterSelected) {
      setLoading(true);
      if (
        filters.leadSubmittedTimeValue &&
        filters.checkStates.length > 0 &&
        filters.selectedCountry
      ) {
        try {
          const response = await filterList(
            filters.leadSubmittedTimeValue,
            filters.checkStates,
            filters.selectedCountry
          );

          if (response.data) {
            leadsArray = response.data.data;
          } else {
            dispatch(setLeadDetail());
          }
          if (leadsArray && leadsArray.length > 0) {
            setLead(leadsArray);
            selectUserID(leadsArray[leadsArray.length - 1].id);
            setLoading(false);
          } else {
            setLead("");
            selectUserID(-1);
          }
        } catch (err) {
          console.error(err);
        }
      }
    } else if (!isFilterSelected) {
      if (serviceId && serviceId.length > 0) {
        setLoading(true);
        let serviceIDs = "";
        let allServiceIds = "";
        let allCountries = "";
        if (serviceId.length > 1) {
          const service = "&services=";
          const service1 = serviceId[0];
          for (let i = 1; i < serviceId.length; i++) {
            serviceIDs = serviceIDs + service + serviceId[i];
          }
          allServiceIds = service1 + serviceIDs;
        } else {
          for (let i = 0; i < serviceId.length; i++) {
            allServiceIds = serviceId[i];
          }
          // console.log(allServiceIds);
        }
        if (data && data.user_Countries) {
          const countryNames = data.user_Countries;
          for (let i = 0; i < countryNames.length; i++) {
            allCountries = countryNames[i].name;
          }
        }
        try {
          const leadResponse = await getFilteredLeadList(allServiceIds, allCountries, "any_time");
          if (leadResponse.data) {
            leadsArray = leadResponse.data.data;
          } else {
            dispatch(setLeadDetail());
          }
          if (leadsArray && leadsArray.length > 0) {
            setLead(leadsArray);
            selectUserID(leadsArray[leadsArray.length - 1].id);
            setLoading(false);
            setLeadsUpdate(false);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setLead("");
        setLoading(false);
        setLeadsUpdate(false);
      }
    }
    setLoading(false);
  };

  const getUserDetails = async () => {
    // const requestOptions = {
    //   isAuth: true,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   endPoint: `users/me`,
    //   params: {
    //     page_size: 50,
    //     continuation_token: "",
    //     active: true,
    //   },
    // };
    // const res = await get(requestOptions);
    try {
      const userServices = data.user_Services;
      if (userServices && userServices.length > 0 && userServices !== null) {
        userServiceIds = userServices.filter((item) => item.id && item.id !== "");
        getLeads(userServiceIds.map((x) => x.id));
      } else {
        setOpenSnack(true);
        setSnackMessage("Please select service first");
        getLeads([]);
      }
    } catch (error) {
      console.log("client error", error);
    }
  };

  const testUpdate = async () => {
    // getUserDetails();
    setLeadsUpdate(true);
    // setLead(() => {
    //   const indexVal = lead.findIndex((x) => x.id === selectLeadData.id);
    //   if (indexVal !== -1) {
    //     lead.splice(indexVal, 1);
    //   }
    // });
  };

  React.useEffect(() => {
    getUserDetails();
    // dispatch(setLeadDetail());
  }, [leadsUpdate]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <MDBox mt={0} mb={0}>
        {/* <MDBox>
          <MDNotifications />
        </MDBox> */}

        <Grid container spacing={0}>
          <Grid item xs={12} md={3.5}>
            <UserLists
              leadList={lead}
              shadow={false}
              selectUserID={(id) => selectUserID(id)}
              setModalFlag={setModalFlag}
            />
          </Grid>
          {!showLeadDetails && <Divider orientation="vertical" sx={{ mx: 0, height: "40vh" }} />}
          {detailViewLoading ? (
            <Grid
              item
              xs={12}
              md={6}
              xl={8}
              sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}
            >
              <Loader />
            </Grid>
          ) : showLeadDetails ? (
            <UserDetail leadUpdate={testUpdate} setShowModal={setShowModal} />
          ) : (
            <Grid
              container
              xs={12}
              md={8}
              // xl={7}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src={comeBackSoon} style={{ heigt: "220px", width: "220px" }} alt="" />
              <MDTypography
                style={{ fontFamily: "hurmeGeometeric-Semi-Bold" }}
                variant="h5"
                fontWeight="bold"
                // textAlign="left"
              >
                Check back soon to find exciting leads.
              </MDTypography>
            </Grid>
          )}
        </Grid>
      </MDBox>
      {showModal && (
        <ExpertModal
          details={modalTypes[modalFlag]}
          component={renderModalType}
          setModalFlag={setModalFlag}
          setShowModal={setShowModal}
          buttonLoader={buttonLoader}
          ifModalFlag={modalFlag}
          refreshScreen={testUpdate}
        />
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={4000}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackMessage === "Profile updated successfully" ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>{" "}
    </>
  );
};

export default Leads;
