import React from "react";
import useADB2CustomHook from "hooks/auth/useADB2CLogin";
import { Redirect } from "react-router-dom";
import Loader from "views/Common/Loader";

const AuthCallback = () => {
  const { data, loading, errors } = useADB2CustomHook();
  if (errors) {
    // if (errors.indexOf("AADB2C90091") > -1) {
    //   alert("The user has cancelled entering self-asserted information.");
    // }
    // if (errors.indexOf("AADB2C90006") > -1) {
    //   alert("The url is not registered for the client.");
    // }
    return <Redirect to="/" />;
  }
  return <>{loading ? <Loader /> : <Redirect to={`/${data.role}`} />}</>;
};

export default AuthCallback;
