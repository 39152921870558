export const stringIntoMin = (str) => {
  let stringToMin = "";

  const givenTimeInMS = new Date(str).getTime();

  const currentTimeInMS = new Date().getTime();

  const agoTimeMins = Math.floor((currentTimeInMS - givenTimeInMS) / 60000);

  const agoTimeHours = Math.floor(agoTimeMins / 60);

  const agoTimeDays = Math.floor(agoTimeHours / 24);

  // if (agoTimeDays >= 1) {
  //   stringToMin = `${agoTimeDays} days ago`;
  // }
  // if (agoTimeHours >= 1) {
  //   stringToMin = `${agoTimeHours} hours ago`;
  // }

  // return `${agoTimeMins} mins ago`;
  if (agoTimeDays >= 1) {
    if (agoTimeDays === 1) {
      stringToMin = `${agoTimeDays} day ago`;
    } else if (agoTimeDays !== 1 && agoTimeDays > 1) {
      stringToMin = `${agoTimeDays} days ago`;
    }
  } else if (agoTimeHours >= 1) {
    if (agoTimeHours === 1) {
      stringToMin = `${agoTimeHours} hour ago`;
    } else if (agoTimeHours !== 1 && agoTimeHours > 1) {
      stringToMin = `${agoTimeHours} hours ago`;
    }
  } else if (agoTimeMins === 0 || agoTimeMins === 1) {
    stringToMin = `${agoTimeMins} min ago`;
  } else {
    stringToMin = `${agoTimeMins} mins ago`;
  }

  return stringToMin;
};

export const DisplayInDateMin = (str) => {
  let time = "";
  if (str === "Immediately") {
    time = "Immediately";
  } else {
    time = `${str.toString().split("00:00:00")[0]}`;
  }
  return time;
};
// export const formatAMPM = (date) => {
//   var hours = date.getHours();
//   var minutes = date.getMinutes();
//   var ampm = hours >= 12 ? "pm" : "am";
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'
//   minutes = minutes < 10 ? "0" + minutes : minutes;
//   var strTime = hours + ":" + minutes + " " + ampm;
//   return strTime;
// };

export const formatAMPM = (date) =>
  date.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });

// "Friday, Jul 2, 2021"
export const displayInDateFormat = (date) =>
  date.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
