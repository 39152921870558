import { Route } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";

export const getRoutes = (routes) =>
  routes.map((prop, key) => {
    if (
      // prop.layout === "/admin" ||
      prop.layout === "/auth" ||
      prop.layout === "/expert" ||
      prop.layout === "/client"
    ) {
      // eslint-disable-next-line react/no-array-index-key
      return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />;
    }
    return null;
  });

getRoutes.propTypes = {
  routes: PropTypes.any,
};
