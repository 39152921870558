import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
// import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import MDBox from "components/MDBox";
import CancelIcon from "@mui/icons-material/Cancel";
import { makeStyles } from "@mui/styles";
import { BpStaticRadio } from "constants/radioButton";

const styles = {
  title: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: " #051931",
    textShadow: "0px 0px 4px rgba(0, 0, 0, 0.05)",
    marginBottom: "27px",
  },
  description: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#051931",
    margin: "6px 0 0 0",
    "& div": {
      fontStyle: "italic",
      fontSize: "12px",
      color: "gray",
    },
  },
  status: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: "#051931",
    "& span": {
      color: "red",
    },
  },
};

const useStyles = makeStyles(styles);

const LanguageVerification = () => {
  const classes = useStyles();

  const [selectMethod, setMethod] = useState("");

  const handleMethodChange = (event) => {
    setMethod(event.target.value);
  };

  return (
    <Grid container justifyContent="space-between" m={3}>
      <Grid item xs={9}>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            <Typography className={classes.title}>
              Choose any method for Language Verification
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectMethod}
            onChange={handleMethodChange}
          >
            <FormControlLabel
              value="certificationDocument"
              control={<BpStaticRadio />}
              sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}
              label={
                <Typography className={classes.description}>
                  Upload documents verifying any one of the certifications
                  <div>
                    (Duolingo Proficiency Exam in English, Cambridge English: First (FCE) ,
                    Cambridge English: Proficiency (CPE) , Cambridge English: Business Vantage or
                    Higher , IELTS - English Proficiency Exam , Cambridge English: Advanced (CAE) )
                  </div>
                </Typography>
              }
            />

            <FormControlLabel
              value="englishProficiency"
              control={<BpStaticRadio />}
              sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}
              label={
                <Typography className={classes.description}>
                  Take our English Proficiency test
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={3} textAlign="end">
        <Typography className={classes.status}>
          Status
          <CancelIcon sx={{ margin: "0 5px" }} />
          <span>Not Verified</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LanguageVerification;

/* <MDBox display="flex" flexDirection="row" mt={2}>
          <MDBox>
            <MDBox
              sx={{
                width: "20px",
                height: "20px",
                background: " #051931",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
                borderRadius: "50%",
              }}
            />
          </MDBox>
          <MDBox>
            <Typography className={classes.description}>
              Upload documents verifying any one of the certifications
              <div>
                (Duolingo Proficiency Exam in English, Cambridge English: First (FCE) , Cambridge
                English: Proficiency (CPE) , Cambridge English: Business Vantage or Higher , IELTS -
                English Proficiency Exam , Cambridge English: Advanced (CAE) )
              </div>
            </Typography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection="row" mt={2}>
          <MDBox>
            <MDBox
              sx={{
                width: "20px",
                height: "20px",
                background: " #051931",
                boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
                borderRadius: "50%",
              }}
            />
          </MDBox>
          <MDBox>
            <Typography className={classes.description}>
              Take our English Proficiency test
            </Typography>
          </MDBox>
        </MDBox> */
