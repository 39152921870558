// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

function MDNotifications({ message, buttonText, buttonFrom }) {
  const from = buttonFrom === "client" ? "/client/verify-profile" : "/expert/verify-profile";

  const alertContent = () => (
    <>
      <MDTypography color="dark" fontWeight="medium">
        {message}
      </MDTypography>
      <NavLink
        style={{
          textDecoration: "none",
          paddingLeft: "2%",
          fontFamily: "hurmeGeometeric-Semi-Bold",
          fontSize: 14,
          fontWeight: 600,
          lineHeight: 1,
          letterSpacing: "0em",
          textAlign: "left",
        }}
        to={from}
        activeStyle={{
          color: "red",
        }}
      >
        {/* <MDTypography> */}
        {buttonText}
        {/* </MDTypography> */}
      </NavLink>
    </>
  );

  return (
    <MDAlert color="warning" dismissible>
      {alertContent()}
    </MDAlert>
  );
}

MDNotifications.propTypes = {
  message: PropTypes.string,
  buttonText: PropTypes.string,
  buttonFrom: PropTypes.string,
};

export default MDNotifications;
