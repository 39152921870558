export const apiParamsFunction = (isAuth, headers, endPoint, body, params) => {
  const requestOptions = {
    isAuth,
    headers: { "Content-Type": headers },
    endPoint,
    body,
    params,
  };

  return requestOptions;
};
