import React from "react";
import { AppRoutes } from "routes";
import Footer from "components/Footer";
import MDBox from "components/MDBox";
import ResponsiveAppBar from "components/MDHeaderBar";
import { Switch, Redirect, Route } from "react-router-dom";
import { Divider } from "@mui/material";
import GenericExpertRoutes from "routes/genericExpertRoutes";
import { getRoutes } from "../helpers/routesHelpers";
import NotFoundPage from "../views/Auth/NotFoundPage";

// eslint-disable-next-line arrow-body-style
const Expert = () => {
  return (
    <MDBox>
      <ResponsiveAppBar routes={AppRoutes()} />
      <Divider sx={{ height: 2, m: 0, marginBottom: 0 }} orientation="horizontal" />
      <div style={{ minHeight: `calc(100vh  - ${295}px)` }}>
        <Switch>
          {getRoutes(AppRoutes())}
          <GenericExpertRoutes />
          <Redirect exact from="/expert" to="/expert/dashboard" />
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </div>
      <Footer />
    </MDBox>
  );
};

export default Expert;
