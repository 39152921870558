/* eslint-disable react/jsx-boolean-value */
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import ProtectedRoute from "helpers/ProtectedRoute";
import { useSelector } from "react-redux";
import BasicModal from "components/MDModal/BasicModal";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import TagManager from "react-gtm-module";
import AuthLayout from "./layouts/Auth";
import ClientLayout from "./layouts/Client";
import ExpertLayout from "./layouts/Expert";
import "./App.css";

export default function App() {
  const data = useSelector((state) => state.setTokenReducer?.data);
  const modal = useSelector((state) => state.setTokenReducer?.modal);
  const sessionType = useSelector((state) => state.setTokenReducer?.setSessionType);

  const tagManagerArgs = {
    gtmId: "GTM-M6XTFTQ",
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <ThemeProvider theme={theme}>
      <SimpleBar style={{ maxHeight: "100vh" }}>
        <BrowserRouter>
          <Switch>
            <ProtectedRoute
              path="/client"
              component={ClientLayout}
              auth={data?.role === "client"}
            />
            <ProtectedRoute
              path="/expert"
              component={ExpertLayout}
              auth={data?.role === "expert"}
            />
            {data ? (
              sessionType && sessionType.length > 0 && sessionType === "SignUp" ? (
                data.role === "client" ? (
                  // <Redirect to={`/${data.role}/postleads`} />
                  <Redirect to={`/${data.role}/dashboard`} />
                ) : (
                  <Redirect to={`/${data.role}/post-service`} />
                )
              ) : (
                <Redirect to={`/${data.role}/dashboard`} />
              )
            ) : (
              <ProtectedRoute path="/" component={AuthLayout} auth={true} />
            )}
          </Switch>
          {modal && (
            <BasicModal
              title="Success!"
              description="Your project has been posted successfully."
              label="Go To Dashboard"
            />
          )}
        </BrowserRouter>
      </SimpleBar>
    </ThemeProvider>
  );
}
