import React from "react";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import { getPakagesList } from "constants/getData";
import MDTypography from "components/MDTypography";
import creditIcon from "assets/imgs/icons/creditIcon.png";
import MDBox from "components/MDBox";
// import { useDispatch } from "react-redux";
// import { removePackageId } from "redux/actions/action";
import UserInfo from "./UserInfo";

const PackageModal = ({ selectLeadData, credits }) => {
  const [packageList, setPackageList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getPackageLists = async () => {
    setLoading(true);
    // dispatch(removePackageId());
    try {
      const offerList = await getPakagesList();
      setPackageList(offerList);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  // const dispatch = useDispatch();
  React.useEffect(async () => {
    if (packageList.length === 0) {
      getPackageLists();
    }
  }, []);
  return (
    <>
      <MDBox
        display="flex-column"
        justifyContent="space-between"
        alignItems=" flex-start"
        px={2}
        my={1}
        md={6}
        // borderRadius="lg"
        // shadow="lg"
        sx={{
          // border: "0.1rem solid #051931",
          // borderColor: "#051931",
          // borderRadius: 1,
          width: " fit-content",
          padding: "0 2rem",
          margin: "auto",
          border: "1px solid rgba(5, 25, 49, 0.55)",
          boxShadow: " 0px 0px 6px rgba(0, 0, 0, 0.25)",
          borderRadius: "5px",
        }}
      >
        <MDBox display="flex" justifyContent="center" flexWrap="wrap" mb={2} mt={4} my={2}>
          <MDBox
            component="img"
            src={creditIcon}
            alt="Brand"
            color="white"
            bgColor="white"
            mr={1}
          />
          <MDTypography variant="h6">Your total Credits</MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="center" flexWrap="wrap" mb={2} mt={4} my={2}>
          <MDButton variant="gradient" color="error" sx={{ height: "100%" }}>
            <MDTypography variant="text" fontWeight="medium" color="white">
              {`${credits} Credits`}
            </MDTypography>
          </MDButton>
        </MDBox>
        <MDBox display="flex" justifyContent="center" flexWrap="wrap" mb={2} mt={4} my={2}>
          <MDBox
            component="img"
            src={creditIcon}
            alt="Brand"
            color="white"
            bgColor="white"
            mr={1}
          />

          <MDTypography variant="h6">Total credits Required</MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="center" flexWrap="wrap" mb={2} mt={4} my={2}>
          <MDButton color="white" sx={{ height: "100%", border: 1 }}>
            <MDTypography variant="text" fontWeight="medium" color="dark">
              {`${selectLeadData?.credits} Credits`}
            </MDTypography>
          </MDButton>
        </MDBox>
      </MDBox>

      <MDBox mt={3}>
        {/* <MDBox
          variant="gradient"
          sx={{
            width: "60%",
            marginRight: "20%",
            marginLeft: "20%",
            borderRadius: "5px",
            background: "linear-gradient(to right bottom, #051931, #DE1921)",
            color: "#EBEFF4",
          }}
          mt={-4}
        >
          <MDTypography color>GET HIRED GUARANTEE</MDTypography>
        </MDBox> */}
        {/* //child components */}
        <MDBox pt={3}>
          <UserInfo packageList={packageList} loading={loading} />
        </MDBox>
      </MDBox>
    </>
  );
};

export default PackageModal;

PackageModal.propTypes = {
  selectLeadData: PropTypes.any,
  credits: PropTypes.string,
};
