// prop-types is library for typechecking of props
import React from "react";
import { PropTypes } from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import store from "redux/store";
import { setFilters, setIsFilterSelected } from "redux/actions/action";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";
import creditIcon from "assets/imgs/icons/creditIcon.png";
import locationIcon from "assets/imgs/icons/location.png";
import AllCountries from "assets/imgs/icons/allCountries.png";
// import settings from "assets/imgs/icons/settings.png";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import filterIcon from "assets/imgs/icons/filterIcon1.png";
import MDAvatar from "components/MDAvatar";
import Checkbox from "@mui/material/Checkbox";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { makeStyles } from "@mui/styles";
import { stringIntoMin } from "constants/getTime";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Loader from "views/Common/Loader";
import SimpleBar from "simplebar-react";
import { BpStaticRadio } from "constants/radioButton";
import { getCountriesList } from "../../../constants/getData";
import { filterList } from "../FilterLeadList";
// import CanadaIcon from "../../../assets/imgs/icons/canadaIcon.png";
// import UnitedSatesIcon from "../../../assets/imgs/icons/unitedSatesIcon.png";
import "simplebar/dist/simplebar.min.css";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../components/MDDialog";

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function UserLists({ leadList, shadow, selectUserID, setModalFlag }) {
  const useStyles = makeStyles(() => ({
    active: {
      borderLeft: "5px solid red",
      transition: "0.2s ease all",
      backgroundColor: "#F8F8F8",
    },
    buttonProperties4: {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: "lighter",
      borderRadius: "25px",
    },
    root: {
      marginTop: 8,
      marginBottom: 8,
      width: "80%",
      "@media (max-width:780px)": {
        width: "100%",
      },
      "&::before": {
        height: 0,
      },
    },
    filterTitle: {
      fontFamily: "Hurme Geometric Sans 3",
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "31px",
      color: "#051931",
      padding: 0,
      margin: "30px 0 0 39px",
    },
    filterSubtitle: {
      fontFamily: "Hurme Geometric Sans 3",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#051931",
      margin: "0 0 0 39px",
      "@media (max-width:575px)": {
        margin: "0 0 0 3px",
      },
    },
    filterSubtitleDetail: {
      fontFamily: "Hurme Geometric Sans 3",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#9F9F9F",
      padding: 0,
      margin: "0 0 0 39px",
      "@media (max-width:575px)": {
        margin: "0 0 0 3px",
      },
    },
    customReasonButton: {
      marginTop: "5px",
      border: "1px solid black",
      padding: "5px 15px",
      borderRadius: "10px",
      width: "70%",
      cursor: "pointer",
    },
    isActive: {
      border: "0.1rem solid red",
    },
  }));

  const [startTime, setStartTime] = React.useState(0);
  // const [updatedAgo, setUpdatedAgo] = React.useState("0 min");
  const [timerOn, setTimerOn] = React.useState(true);

  const dispatch = useDispatch();
  const classes = useStyles();
  const userData = useSelector((state) => state.setTokenReducer?.data);

  const credits = userData?.credit_Balance;
  const servicesListing = userData.user_Services;
  const userCountryListing = userData.user_Countries;

  const [countries, setCountries] = React.useState([]);
  const [expanded, setExpanded] = React.useState("Time");
  const [isId, setId] = React.useState("");
  const [filter, setShowFilter] = React.useState(false);
  // const [leadListBackup] = React.useState(leadList && leadList.length > 0 ? leadList : []);
  const [miniLoading, setMiniLoading] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  // const [ifRefreshThen, setIfRefreshThen] = React.useState("");
  const [leadListing, setLeadListing] = React.useState(
    leadList && leadList.length > 0 ? leadList : []
  );
  // const [selectedCountry, setSelectedCountry] = React.useState("");
  const [selectedCountry, setSelectedCountry] = React.useState([]);

  const [leadSubmittedTimeValue, setLeadSubmittedTimeValue] = React.useState("any_time");
  let renderProfiles = Object();

  const [checkStates, setCheckStates] = React.useState([]);

  const responseOfStore = store.getState((state) => state?.userLeadDetail?.filters);
  const isFilterSelectedOnLoad = responseOfStore?.userLeadDetail.isFilterSelected;
  const alreadyAddedFilters = responseOfStore?.userLeadDetail.filters;

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const checkingServicesStates = () => {
    const array1 = [];
    if (checkStates && checkStates.length <= 0) {
      if (servicesListing && servicesListing.length > 0) {
        for (let i = 0; i < servicesListing.length; i++) {
          array1.push({
            id: servicesListing[i].id,
            name: servicesListing[i].name,
            isChecked: true,
          });
        }
        setCheckStates(array1);
      }
    }
  };

  const resetAllfilters = async (isClearFilterdCalled) => {
    setMiniLoading(true);
    const responseOfStore1 = store.getState((state) => state?.userLeadDetail?.filters);
    const isFilterSelectedOnLoad1 = responseOfStore1?.userLeadDetail.isFilterSelected;
    if (!isFilterSelectedOnLoad1) {
      setLeadSubmittedTimeValue("any_time");
      const array1 = [];
      if (servicesListing && servicesListing.length > 0) {
        for (let i = 0; i < servicesListing.length; i++) {
          array1.push({
            id: servicesListing[i].id,
            name: servicesListing[i].name,
            isChecked: true,
          });
        }
        setCheckStates(array1);
      }
      // if (
      //   userData.user_Countries[0] &&
      //   userData.user_Countries[0].name &&
      //   userData.user_Countries[0].name.length > 0
      // ) {
      //   setSelectedCountry(userData.user_Countries[0].name);
      // }
      setSelectedCountry(userData.user_Countries);
      dispatch(
        setFilters({
          leadSubmittedTimeValue: "any_time",
          checkStates: array1,
          // selectedCountry: userData.user_Countries[0].name,
          selectedCountry: userData.user_Countries,
        })
      );
      // const response = await filterList("any_time", array1, arr);
      // const response = await filterList("any_time", array1, userData.user_Countries[0].name);
      const response = await filterList("any_time", array1, userData.user_Countries);
      if (response && response.data) {
        if (response.data.data) {
          setLeadListing(response.data.data);
          setId(response.data.data[0].id);
          selectUserID(response.data.data[0].id);
          // if (ifRefreshThen !== "refresh") {
          //   setSnackMessage("Cleared all filters");
          //   handleClickSnack();
          // }
        } else {
          setLeadListing(response.data);
        }
      } else {
        setLeadListing("");
        selectUserID(-1);
        setSnackMessage("No leads to display");
        handleClickSnack();
      }
    } else if (leadListing && leadListing.length > 0) {
      setId(leadListing[0].id);
    }

    setMiniLoading(false);
    if (isClearFilterdCalled) {
      setSnackMessage("Cleared all filters");
      handleClickSnack();
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleFilterOpen = () => {
    setShowFilter(true);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  const handleCheckBoxChange = (event) => {
    const arra1 = checkStates;
    const index = checkStates.findIndex((x) => x.name === event.target.name);
    arra1[index].isChecked = event.target.checked;
    setCheckStates([...arra1]);
  };

  const handleRadioChange = (event) => {
    setLeadSubmittedTimeValue(event.target.value);
  };

  // const getUpdatedTimeAgo = () => {
  //   setInterval(() => {
  //     setUpdatedAgo(stringIntoMin(startDate));
  //   }, 60000);
  // };

  const getFilterLeadList = async () => {
    // const filters = useSelector((state) => state?.userLeadDetail?.filters);
    const resss = store.getState((state) => state?.userLeadDetail?.filters);
    const filters = resss?.userLeadDetail.filters;
    const isFilterSelected = resss?.userLeadDetail.isFilterSelected;
    if (isFilterSelected) {
      const response = await filterList(
        filters.leadSubmittedTimeValue,
        filters.checkStates,
        filters.selectedCountry
      );
      if (response && response.data) {
        if (response.data.data) {
          setLeadListing(response.data.data);
          setId(response.data.data[0].id);
          selectUserID(response.data.data[0].id);
        } else {
          setLeadListing(response.data);
        }
      } else {
        setLeadListing("");
        selectUserID(-1);
        // dispatch(setLeadDetail({}));
      }
    }
    checkingServicesStates();
  };

  const handleSelectedCountry = (country) => {
    let indexVal;
    if (selectedCountry && selectedCountry.length > 0) {
      indexVal = selectedCountry.findIndex((x) => x.id === country.id);
      if (indexVal !== -1) {
        const temp = [...selectedCountry];
        temp.splice(indexVal, 1);
        setSelectedCountry(temp);
      } else {
        setSelectedCountry([
          ...selectedCountry,
          {
            id: country.id,
            name: country.name,
          },
        ]);
      }

      if (country.id === "any") {
        setSelectedCountry([
          {
            id: country.id,
            name: country.name,
          },
        ]);
      } else {
        const allCountryIndex = selectedCountry.findIndex((x) => x.id === "any");
        if (allCountryIndex !== -1) {
          const temp = [
            ...selectedCountry,
            {
              id: country.id,
              name: country.name,
            },
          ];
          temp.splice(allCountryIndex, 1);
          setSelectedCountry(temp);
        }
      }
    } else {
      // await setSelectedCountry([
      setSelectedCountry([
        ...selectedCountry,
        {
          id: country.id,
          name: country.name,
        },
      ]);
    }
  };
  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  React.useEffect(async () => {
    const resposneOfCoutriesList = await getCountriesList();
    // const country = resposneOfCoutriesList.data.data.filter(
    //   (x) => x.name === "Canada" || x.name === "USA"
    // );

    const country = resposneOfCoutriesList.data.data;
    if (country && country.length > 0) {
      country.splice(0, 0, {
        active: true,
        icon: "",
        id: "any",
        name: "All Countries",
      });
    }
    if (!isFilterSelectedOnLoad) {
      // if (userCountryListing && userCountryListing.length > 0 && userCountryListing[0]) {
      if (userCountryListing && userCountryListing.length > 0) {
        // setSelectedCountry(userCountryListing[0].name);
        // const arr = userCountryListing.map((x) => x.name);
        const arr = () => Object.entries(userData.user_Countries).map((x) => x[1]);
        setSelectedCountry(arr);
      }

      if (leadListing && leadListing.length > 0) {
        setId(leadListing[0].id);
      }
      // getUpdatedTimeAgo();
      checkingServicesStates();
    } else {
      resetAllfilters();
      setLeadSubmittedTimeValue(alreadyAddedFilters.leadSubmittedTimeValue);
      setCheckStates(alreadyAddedFilters.checkStates);
      setSelectedCountry(alreadyAddedFilters.selectedCountry);
    }
    setCountries(country);

    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setStartTime((prevTime) => prevTime + 60000);
      }, 60000);
    } else if (!timerOn) {
      clearInterval(interval);
      setStartTime(0);
    }
    return () => clearInterval(interval);
  }, [timerOn]);

  if (leadListing && leadListing.length > 0) {
    leadListing.sort((a, b) => new Date(b.posted_on) - new Date(a.posted_on));
    renderProfiles =
      leadListing &&
      leadListing.map((items) => {
        const time = stringIntoMin(items.posted_on);
        return (
          <>
            <MDBox
              className={isId === items.id ? classes.active : ""}
              p={2}
              key={items.id}
              display="flex-column"
              alignItems="center"
              onClick={async () => {
                await selectUserID(items.id);
                setId(items.id);
                if (items.credits > credits) {
                  setModalFlag("first");
                }
              }}
              sx={{
                cursor: "pointer",
              }}
            >
              <MDBox display="flex" justifyContent="space-between" flexWrap="wrap">
                <MDTypography
                  sx={{ pb: 1, fontWeight: "600", fontSize: "14px", lineHeight: "18px" }}
                >
                  {items.posted_by.name}
                </MDTypography>
                <MDTypography variant="caption" fontWeight="light">
                  {time}
                </MDTypography>
              </MDBox>
              <MDTypography sx={{ pb: 1, fontweight: "600", fontSize: "14px", lineHeight: "18px" }}>
                <b>
                  {items.service.name}
                  {" | "}
                  {items.description && items.description.length > 40
                    ? `${items.description.slice(0, 40)} ...`
                    : items.description}
                </b>
              </MDTypography>
              <MDBox display="flex" justifyContent="flex-start" flexWrap="wrap" sx={{ pb: 1 }}>
                <MDBox
                  component="img"
                  src={locationIcon}
                  alt="Brand"
                  color="white"
                  mr={2}
                  sx={{
                    // width: "1.2rem",
                    height: "16px",
                    // mb: 1,
                    mr: "6px",
                    alignItems: "center",
                  }}
                />
                <MDTypography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "18px" }}>
                  {`${items.city}, ${items.country}` || "N/A"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="flex-start" flexWrap="wrap">
                <MDBox
                  component="img"
                  src={creditIcon}
                  alt="Brand"
                  color="white"
                  mr={2}
                  sx={{
                    height: "16px",
                    mr: "6px",
                    alignItems: "center",
                  }}
                />

                <MDTypography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "18px" }}>
                  {items.credits} Credits
                </MDTypography>
              </MDBox>
            </MDBox>
            <Divider sx={{ height: "1px", m: 0 }} orientation="horizontal" />
          </>
        );
      });
  }

  return (
    <Card sx={{ boxShadow: !shadow && "none" }}>
      <MDBox pt={1} px={2} bgColor="dark" display="flex-column">
        <MDBox display="flex" justifyContent="space-between" bgColor="dark">
          <MDBox bgColor="dark" display="flex-column">
            <MDTypography
              variant="h6"
              color="white"
              fontWeight="medium"
              textTransform="capitalize"
              sx={{ width: "100%" }}
            >
              {leadListing && leadListing.length > 0
                ? leadListing.length > 1
                  ? `Showing all ${leadListing.length} leads`
                  : `Showing ${leadListing.length} lead`
                : ""}
            </MDTypography>

            <MDTypography variant="caption" color="white" fontWeight="light" sx={{ width: "20%" }}>
              Updated{" "}
              {Math.floor((startTime / 60000) % 60)
                .toString()
                .slice(-2)}{" "}
              mins ago
            </MDTypography>

            <MDButton
              variant="text"
              color="info"
              sx={{ width: "18%" }}
              onClick={() => {
                const resss = store.getState((state) => state?.userLeadDetail?.filters);
                const isFilterSelected = resss?.userLeadDetail.isFilterSelected;
                // setIfRefreshThen("refresh");
                if (isFilterSelected) {
                  setMiniLoading(true);
                  getFilterLeadList();
                  setMiniLoading(false);
                } else {
                  setMiniLoading(true);
                  resetAllfilters(false);
                  setMiniLoading(false);
                }
                // setIfRefreshThen("");
                setTimerOn(false);
                setTimeout(() => setTimerOn(true), 1000);
              }}
            >
              <MDTypography
                variant="text"
                fontWeight="medium"
                color="white"
                sx={{ textDecorationLine: "underline" }}
                pl={2}
              >
                Refresh
              </MDTypography>
            </MDButton>
            <MDButton
              variant="text"
              color="info"
              sx={{ width: "40%", padding: "0px" }}
              onClick={async () => {
                dispatch(setIsFilterSelected(false));
                resetAllfilters(true);
                setTimerOn(false);
                setTimeout(() => setTimerOn(true), 1000);
              }}
            >
              <MDTypography
                variant="text"
                fontWeight="medium"
                color="white"
                sx={{ textDecorationLine: "underline" }}
              >
                Clear All Filters
              </MDTypography>
            </MDButton>
          </MDBox>
          <MDBox bgColor="dark" display="flex" alignItems="center" sx={{ width: "10%" }}>
            {/* <MDBox component="img" src={settings} alt="Brand" color="white" bgColor="dark" mr={2} /> */}
            <MDBox
              component="img"
              sx={{ cursor: "pointer" }}
              src={filterIcon}
              alt="Brand"
              color="white"
              bgColor="dark"
              mr={2}
              onClick={() => {
                handleFilterOpen();
              }}
            />
          </MDBox>
        </MDBox>
      </MDBox>
      <BootstrapDialog
        onClose={handleFilterClose}
        aria-labelledby="customized-dialog-title"
        open={filter}
        sx={{ overflowX: "hidden", margin: 0, padding: 0 }}
      >
        <MDBox sx={{ width: { xs: "300px", sm: "576px" }, padding: 0, overflow: "auto" }}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleFilterClose}
            className={classes.filterTitle}
          >
            Filters
          </BootstrapDialogTitle>
          <DialogContent
            // dividers
            sx={{
              // borderBottom: "transparent",
              padding: "0px",
            }}
          >
            {/* KEYWORD SEARCH */}
            {/* <Accordion
              expanded={expanded === "Keyword"}
              onChange={handleChange("Keyword")}
              elevation={0}
              className={classes.root}
              onClick={() => {
                setIsService(false);
                setIsCountry(false);
                setIsKeywordSearch(true);
                setIsLeadSubmittedTime(false);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ width: "fit-content" }}
              >
                <MDTypography variant="h5" sx={{ margin: "0 10px" }}>
                  Keyword Search
                </MDTypography>
              </AccordionSummary>
              <AccordionDetails>
                <Tooltip title="ex: Tony Stark" placement="right-end">
                  <TextField
                    sx={{ width: "80%" }}
                    label="Name"
                    rows={1}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                  />
                </Tooltip>
              </AccordionDetails>
            </Accordion> */}
            {/* LEAD SUBMITTED TIME */}
            <Accordion
              expanded={expanded === "Time"}
              onChange={handleChange("Time")}
              elevation={0}
              className={classes.root}
              // onClick={() => {
              //   setIsService(false);
              //   setIsCountry(false);
              //   setIsKeywordSearch(false);
              //   setIsLeadSubmittedTime(true);
              // }}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon fontSize="large" color="black" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ padding: 0 }}
              >
                <MDTypography className={classes.filterSubtitle}>Lead Submitted Time</MDTypography>
              </AccordionSummary>
              <AccordionDetails className={classes.filterSubtitleDetail}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="any_time"
                      checked={leadSubmittedTimeValue === "any_time"}
                      control={<BpStaticRadio sx={{ color: "black" }} />}
                      label="Any Time"
                    />
                    <FormControlLabel
                      value="last_hour"
                      checked={leadSubmittedTimeValue === "last_hour"}
                      control={<BpStaticRadio />}
                      label="Last Hour"
                    />
                    <FormControlLabel
                      value="today"
                      checked={leadSubmittedTimeValue === "today"}
                      control={<BpStaticRadio />}
                      label="Today"
                    />
                    <FormControlLabel
                      value="yesterday"
                      checked={leadSubmittedTimeValue === "yesterday"}
                      control={<BpStaticRadio />}
                      label="Yesterday"
                    />
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </Accordion>
            {/* SERVICE */}
            <Accordion
              expanded={expanded === "service"}
              onChange={handleChange("service")}
              elevation={0}
              className={classes.root}
              // onClick={() => {
              //   setIsService(true);
              //   setIsCountry(false);
              //   setIsKeywordSearch(false);
              //   setIsLeadSubmittedTime(false);
              // }}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon fontSize="large" color="black" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ padding: 0 }}
              >
                <MDTypography className={classes.filterSubtitle}>Service</MDTypography>
              </AccordionSummary>
              <AccordionDetails className={classes.filterSubtitleDetail}>
                <FormControl>
                  {checkStates && checkStates.length > 0 ? (
                    checkStates.map((x) => (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={`${x.name}`}
                              checked={x.isChecked}
                              onChange={handleCheckBoxChange}
                            />
                          }
                          label={`${x.name}`}
                        />
                      </>
                    ))
                  ) : (
                    <MDTypography variant="h6" sx={{ ml: 3 }}>
                      No services added
                    </MDTypography>
                  )}
                </FormControl>
              </AccordionDetails>
            </Accordion>
            {/* COUNTRY */}
            <Accordion
              expanded={expanded === "country"}
              onChange={handleChange("country")}
              elevation={0}
              className={classes.root}
              // onClick={() => {
              //   setIsService(false);
              //   setIsCountry(true);
              //   setIsKeywordSearch(false);
              //   setIsLeadSubmittedTime(false);
              // }}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon fontSize="large" color="black" />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ padding: 0 }}
              >
                <MDTypography className={classes.filterSubtitle}>Country</MDTypography>
              </AccordionSummary>
              <AccordionDetails className={classes.filterSubtitleDetail}>
                {/* <MDBox display="flex" alignItems="center">
                  <Checkbox
                    sx={{ mr: 3 }}
                    onChange={(e) => {
                      handleCheckBoxCountriesChange(e);
                    }}
                    // checked={usa}
                    name="USA"
                  />
                  <MDAvatar src={UnitedSatesIcon} alt="Avatar" size="sm" variant="square" />
                  <MDTypography color="dark" varient="body1" fontWeight="bold" ml={2}>
                    United States
                  </MDTypography>
                </MDBox> */}

                {/* OLD WORKING CODE */}
                {/* <MDBox
                  display="flex"
                  alignItems="center"
                  // sx={{ ml: "10px" }}
                  className={`${classes.customReasonButton} ${
                    selectedCountry === "USA" ? classes.isActive : ""
                  }`}
                  onClick={() => {
                    setSelectedCountry("United States");
                  }}
                >
                  <MDAvatar src={UnitedSatesIcon} alt="Avatar" size="sm" variant="square" />
                  <MDTypography variant="h6" fontWeight="bold">
                    United States
                  </MDTypography>
                </MDBox>

                <MDBox
                  display="flex"
                  alignItems="center"
                  // sx={{ ml: "10px" }}
                  className={`${classes.customReasonButton} ${
                    selectedCountry === "Canada" ? classes.isActive : ""
                  }`}
                  onClick={() => {
                    setSelectedCountry("Canada");
                  }}
                >
                  <MDAvatar src={CanadaIcon} alt="Avatar" size="sm" variant="square" />
                  <MDTypography variant="h6" fontWeight="bold">
                    Canada
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="center"
                  // sx={{ ml: "10px" }}
                  className={`${classes.customReasonButton} ${
                    selectedCountry === "any" ? classes.isActive : ""
                  }`}
                  onClick={() => {
                    setSelectedCountry("any");
                  }}
                >
                  <MDTypography variant="h6" fontWeight="bold">
                    All Countries
                  </MDTypography>
                </MDBox> */}
                {/* OLD WORKING CODE */}

                {/* <MDBox display="flex" alignItems="center">
                  <Checkbox
                    sx={{ mr: 3 }}
                    onChange={(e) => {
                      handleCheckBoxCountriesChange(e);
                    }}
                    name="Canada"
                  />
                  <MDAvatar src={CanadaIcon} alt="Avatar" size="sm" variant="square" />
                  <MDTypography color="dark" varient="body1" fontWeight="bold" ml={2}>
                    Canada
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <Checkbox
                    sx={{ mr: 3 }}
                    onChange={(e) => {
                      handleCheckBoxCountriesChange(e);
                    }}
                    name="any"
                  />
                  <MDTypography color="dark" varient="body1" fontWeight="bold" ml={2}>
                    All Countries
                  </MDTypography>
                </MDBox> */}

                {countries?.map((country) => (
                  <MDBox
                    display="flex"
                    alignItems="center"
                    // sx={{ ml: "10px" }}
                    // className={`${classes.customReasonButton} ${
                    //   country.name === selectedCountry ? classes.isActive : ""
                    // }`}
                    className={`${classes.customReasonButton} ${
                      selectedCountry &&
                      selectedCountry.length > 0 &&
                      selectedCountry.find((x) => x.name === country.name)
                        ? classes.isActive
                        : ""
                    }`}
                    onClick={() => handleSelectedCountry(country)}
                  >
                    <MDAvatar
                      // src={
                      //   country.name === "USA"
                      //     ? UnitedSatesIcon
                      //     : country.name === "Canada"
                      //     ? CanadaIcon
                      //     : ""
                      // }
                      // src={country.icon}
                      src={country.id === "any" ? AllCountries : country.icon}
                      alt="Avatar"
                      size="sm"
                      variant="square"
                    />
                    <MDTypography variant="h6" fontWeight="bold" sx={{ ml: 1 }}>
                      {country.name}
                    </MDTypography>
                  </MDBox>
                ))}
              </AccordionDetails>
            </Accordion>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              my: 3,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <MDButton
              className={`${classes.buttonProperties4}`}
              color="dark"
              size="large"
              sx={{ mr: { xs: 0, sm: 5 }, mb: { xs: 1, sm: 0 } }}
              onClick={async () => {
                dispatch(setIsFilterSelected(false));
                resetAllfilters();
                handleFilterClose();
              }}
            >
              Clear
            </MDButton>
            <MDButton
              className={`${classes.buttonProperties4}`}
              color="error"
              size="large"
              onClick={async () => {
                await setMiniLoading(true);
                await dispatch(setIsFilterSelected(true));
                await dispatch(
                  setFilters({ leadSubmittedTimeValue, checkStates, selectedCountry })
                );
                getFilterLeadList();
                handleFilterClose();
                await setMiniLoading(false);
                setTimerOn(false);
                setTimeout(() => setTimerOn(true), 1000);
              }}
            >
              Apply
            </MDButton>
          </DialogActions>
        </MDBox>
      </BootstrapDialog>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={
            snackMessage === "Cleared all filters" || snackMessage === "refresh"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
      <SimpleBar style={{ maxHeight: "80vh" }} className="scroll" autoHide={false}>
        <MDBox
          sx={{
            position: "relative",
            maxHeight: "79vh",
          }}
        >
          {leadListing && leadListing.length > 0 ? (
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {miniLoading ? <Loader /> : renderProfiles}
            </MDBox>
          ) : (
            <MDTypography variant="h6" sx={{ pt: 5 }} textAlign="center">
              No Leads Found. Try clearing filters
            </MDTypography>
          )}
        </MDBox>
      </SimpleBar>
    </Card>
  );
}

// Setting default props for the ProfilesList
UserLists.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
UserLists.propTypes = {
  leadList: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
  selectUserID: PropTypes.func,
  setModalFlag: PropTypes.func,
};

export default UserLists;
