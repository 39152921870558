import React from "react";
import PropTypes from "prop-types";
import { post } from "axios-setup";
import { Grid } from "@mui/material";
import { apiParamsFunction } from "constants/apiParamsFunction";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import Alert from "@mui/material/Alert";
import MDButton from "components/MDButton";
import Loader from "views/Common/Loader";

function EmailTemplate({ setShowEmail, purchased_lead_id, name, userid }) {
  const [emailSubject, setEmailSubject] = React.useState("");
  const [emailDescription, setEmailDescription] = React.useState("");
  const [miniloading, setMiniloading] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const postActivity = async () => {
    if (
      emailSubject &&
      emailDescription &&
      emailSubject.length > 0 &&
      emailDescription.length > 0
    ) {
      setMiniloading(true);
      try {
        await post(
          apiParamsFunction(
            true,
            "application/json",
            `users/me/purchased_leads/${purchased_lead_id}/activities`,
            {
              id: purchased_lead_id,
              activity_type: "email",
              email_details: {
                subject: emailSubject,
                body: emailDescription,
              },
              call_details: {
                call_time: "2022-01-01T01:01:01.001Z",
                status: "",
                comments: "",
              },
              created_on: new Date().toISOString(),
              title: `Emailed ${name}`,
              description: "Description",
              lead_id: purchased_lead_id,
              user_id: userid,
            },
            ""
          )
        );
        setMiniloading(false);
        setSnackMessage("Activity submitted successfully");
      } catch (error) {
        console.error(error);
        setSnackMessage("Error submitting activity");
        setMiniloading(false);
      }
    } else {
      setSnackMessage("Please fill both fields.");
    }
  };

  const useStyles = makeStyles(() => ({
    buttonProperties2: {
      textTransform: "capitalize",
      fontSize: 18,
      borderRadius: "25px",
    },
  }));

  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} md={12} xl={10}>
        <MDBox sx={{ p: 8 }}>
          <MDTypography variant="h3" sx={{ mb: 3 }}>
            Compose Email
          </MDTypography>

          {miniloading ? (
            <Loader />
          ) : (
            <div>
              {" "}
              <TextField
                sx={{ width: "50%", mt: 2 }}
                label="Subject"
                rows={1}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
              <TextField
                sx={{ width: "100%", mt: 2 }}
                label="Description"
                multiline
                rows={10}
                onChange={(event) => {
                  setEmailDescription(event.target.value);
                }}
              />
            </div>
          )}

          <MDBox sx={{ display: "flex", justifyContent: "end", mt: 3 }}>
            <MDButton
              className={classes.buttonProperties2}
              color="error"
              size="large"
              onClick={async () => {
                await postActivity();
                handleClickSnack();
              }}
            >
              Send
            </MDButton>
            <MDButton
              sx={{ ml: 3 }}
              className={classes.buttonProperties2}
              color="dark"
              size="large"
              onClick={setShowEmail}
            >
              Cancel
            </MDButton>
          </MDBox>
          <Snackbar
            open={openSnack}
            autoHideDuration={4000}
            action={action}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseSnack}
          >
            <Alert
              onClose={handleCloseSnack}
              severity={snackMessage === "Activity submitted successfully" ? "success" : "error"}
              sx={{ width: "100%" }}
            >
              {snackMessage}
            </Alert>
          </Snackbar>
        </MDBox>
      </Grid>
    </>
  );
}

EmailTemplate.propTypes = {
  setShowEmail: PropTypes.func,
  purchased_lead_id: PropTypes.string,
  name: PropTypes.string,
  userid: PropTypes.string,
};

export default EmailTemplate;
