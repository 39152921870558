import React, { useState } from "react";
import PropTypes from "prop-types";
import { put } from "axios-setup";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "redux/actions/action";
import { getServiceList, getCountriesList } from "constants/getData";
import { apiParamsFunction } from "constants/apiParamsFunction";
import RequiredServiceCard from "views/Common/RequiredServiceCard";
// import AllCountries from "assets/imgs/icons/allCountries.png";
import SelectLeadLocation from "./SelectLeadLocation";

const ExpertautoCompleteProcess = ({ isLocation }) => {
  const [toggle, setToggle] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentService, setCurrentService] = useState("");
  const [loader, setLoader] = useState(true);
  const [service, setService] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  // let selectedCountries = [];
  // console.log("selectedCountries", selectedCountries);
  // const [checkedData, setChecked] = useState([]);
  const [error, setError] = useState("");
  const getAnswerIntoArray = () => Object.entries(selectedCountries).map((x) => x[1]);
  const selectedCountry = getAnswerIntoArray();
  const userInfo = useSelector((state) => state.setTokenReducer?.data);

  const onAnswerChange = async (counntryId, countryName) => {
    // setSelectedCountries([
    //   {
    //     id: counntryId,
    //     name: countryName,
    //   },
    // ]);
    // debugger;
    // let indexVal;
    // console.log(index);
    // setError("");
    // const newValues = [...checkedData];
    // // const { checked } = e.target;
    // if (selectedCountries && selectedCountries.length > 0)
    //   indexVal = selectedCountries.findIndex((x) => x.id === e.target.value);
    // if (indexVal && indexVal >= 0) {
    //   newValues.splice(indexVal, 1, e.target.value);
    // } else {
    //   const ind = newValues.indexOf(name);
    //   newValues.splice(ind, 1);
    // }
    // setChecked(newValues);
    // const CountryId = id;
    // const CountryName = name;
    // let prevAnswers = selectedCountries;
    // prevAnswers = {
    //   ...prevAnswers,
    //   [id]: {
    //     id: CountryId,
    //     name: CountryName,
    //   },
    // };
    // setSelectedCountries(prevAnswers);
    let indexVal;
    if (selectedCountries && selectedCountries.length > 0) {
      indexVal = selectedCountries.findIndex((x) => x.id === counntryId);

      if (indexVal !== -1) {
        // selectedCountries.splice(indexVal, 1);
        const temp = [...selectedCountries];
        temp.splice(indexVal, 1);
        setSelectedCountries(temp);
        // return;
      } else {
        // await setSelectedCountries([
        setSelectedCountries([
          ...selectedCountries,
          {
            id: counntryId,
            name: countryName,
          },
        ]);
        // return;
        // selectedCountries.push({
        //   id: counntryId,
        //   name: countryName,
        // });
      }
      // return;
      // debugger;
      if (counntryId === "any") {
        setSelectedCountries([
          {
            id: counntryId,
            name: countryName,
          },
        ]);
        // setSelectedCountries(arr);
        // console.log("arr", arr);
      } else {
        const allCountryIndex = selectedCountries.findIndex((x) => x.id === "any");
        if (allCountryIndex !== -1) {
          const temp = [
            ...selectedCountries,
            {
              id: counntryId,
              name: countryName,
            },
          ];
          temp.splice(allCountryIndex, 1);
          setSelectedCountries(temp);
        }
      }
    } else {
      // await setSelectedCountries([
      setSelectedCountries([
        ...selectedCountries,
        {
          id: counntryId,
          name: countryName,
        },
      ]);
      // return;
      // selectedCountries.push({
      //   id: counntryId,
      //   name: countryName,
      // });
    }
    // console.log("selectedCountries", selectedCountries);
  };

  // push into selectedCountries array
  // if (userInfo && userInfo.user_Countries) {
  //   if (userInfo && userInfo.user_Countries.length > 0) {
  //     // if (Object.keys(selectedCountries).length <= 0) {

  //     if (selectedCountries.length <= 0) {
  //       // selectedCountries = userInfo.user_Countries;
  //       userInfo.user_Countries.map((x) => {
  //         // setSelectedCountries([
  //         //   {
  //         //     id: x.id,
  //         //     name: x.name,
  //         //   },
  //         // ]);
  //         selectedCountries.push({
  //           id: x.id,
  //           name: x.name,
  //         });
  //       });
  //       // console.log("selectedCountries useEffect", selectedCountries);
  //     }
  //   }
  // }

  const seeLeads = async () => {
    setError("");
    let services = [];
    if (selectedCountry.length === 0) {
      return setError("Please select any country");
    }
    if (userInfo && userInfo.user_Services) {
      services = userInfo.user_Services;
    }
    if (isLocation) {
      try {
        setLoader(true);
        const requestOptionsBody = {
          user_services: services,
          // user_services: userInfo.user_Services,
          user_countries: getAnswerIntoArray(),
        };
        const res = await put(
          apiParamsFunction(true, "application/json", "users/me/services", requestOptionsBody)
        );
        dispatch(setToken(res.data));
        localStorage.setItem("data", JSON.stringify(res.data));
        history.push("/expert/leads");
        setLoader(false);
      } catch (err) {
        console.log("Country update error", err);
      }
    } else {
      try {
        setLoader(true);
        const requestOptionsBody = {
          user_services: [currentService],
          user_countries: getAnswerIntoArray(),
        };
        const res = await put(
          apiParamsFunction(true, "application/json", "users/me/services", requestOptionsBody)
        );
        dispatch(setToken(res.data));
        localStorage.setItem("data", JSON.stringify(res.data));
        history.push("/expert/leads");
        setLoader(false);
      } catch (err) {
        console.log("LeadSubmission error", err);
      }
    }
  };

  const getServices = async () => {
    const resposneOfServiceList = await getServiceList();
    setService(resposneOfServiceList.data.data);
  };

  const getCountries = async () => {
    try {
      const resposneOfCoutriesList = await getCountriesList();
      const country = resposneOfCoutriesList.data.data;
      if (country && country.length > 0) {
        country.splice(0, 0, {
          active: true,
          icon: "",
          id: "any",
          name: "All Countries",
        });
      }
      setCountries(country);

      setLoader(false);

      if (userInfo && userInfo.user_Countries) {
        if (userInfo && userInfo.user_Countries.length > 0) {
          // if (Object.keys(selectedCountries).length <= 0) {

          // if (selectedCountries.length <= 0) {
          // selectedCountries = userInfo.user_Countries;
          const arr = () => Object.entries(userInfo.user_Countries).map((x) => x[1]);
          setSelectedCountries(arr);
          // userInfo.user_Countries.map(
          //   (x) =>
          //     setSelectedCountries([
          //       ...selectedCountries,
          //       {
          //         id: x.id,
          //         name: x.name,
          //       },
          //     ])
          //   // selectedCountries.push({
          //   //   id: x.id,
          //   //   name: x.name,
          //   // });
          // );
          // }
        }
      }
    } catch (err) {
      console.error("client error", err);
    }
  };

  React.useEffect(() => {
    getCountries();
    getServices();
  }, []);

  // React.useEffect(async () => {
  //   try {
  //     const resposneOfServiceList = await getServiceList();
  //     const resposneOfCoutriesList = await getCountriesList();
  //     setService(resposneOfServiceList.data.data);
  //     setLoader(false);
  //     // For all countires
  //     const country = resposneOfCoutriesList.data.data;
  //     // const country = resposneOfCoutriesList.data.data.filter(
  //     //   (x) => x.name === "Canada" || x.name === "USA"
  //     // );
  //     if (country && country.length > 0) {
  //       country.splice(0, 0, {
  //         active: true,
  //         icon: "",
  //         id: "any",
  //         name: "All Countries",
  //       });
  //     }
  //     setCountries(country);

  //     // if (userInfo && userInfo.user_Countries && userInfo.user_Countries[0]) {
  //     if (userInfo && userInfo.user_Countries.length > 0) {
  //       // if (Object.keys(selectedCountries).length <= 0) {

  //       if (selectedCountries.length <= 0) {
  //         selectedCountries = userInfo.user_Countries;
  //         // setSelectedCountries([
  //         //   {
  //         //     id: userInfo.user_Countries[0].id,
  //         //     name: userInfo.user_Countries[0].name,
  //         //   },
  //         // ]);
  //       }
  //     }
  //   } catch (err) {
  //     console.log("client error", err);
  //   }
  // }, []);

  return (
    <>
      {toggle && !isLocation ? (
        <RequiredServiceCard
          title="Your Services"
          description="Choose services that you offer."
          placeholderText="What service do you provide?"
          loader={loader}
          services={service}
          setCurrentService={setCurrentService}
          setToggle={setToggle}
        />
      ) : (
        <SelectLeadLocation
          setToggle={setToggle}
          countries={countries}
          title="Where would you like to see leads from?"
          description="Tell us the area you cover so we can show you leads for your location"
          setSelectedCountries={setSelectedCountries}
          selectedCountries={selectedCountries}
          seeLeads={seeLeads}
          selectedCountry={selectedCountry}
          onAnswerChange={onAnswerChange}
          // checkedData={checkedData}
          loader={loader}
          error={error}
          setError={setError}
          isLocation={isLocation}
        />
      )}
    </>
  );
};

export default ExpertautoCompleteProcess;

ExpertautoCompleteProcess.propTypes = {
  isLocation: PropTypes.bool,
};
