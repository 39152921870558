import { get, put, post, del } from "axios-setup";
import { apiParamsFunction } from "constants/apiParamsFunction";
// import { useDispatcher } from "./dispatcher";

const manageResponse = (response) => {
  const res = {
    status: response.status,
    statusText: response.statusText,
    data: response.data,
    // message: `${response.data.title} , ${response.data.detail}`,
    message: `${response.data.detail}`,
  };
  return res;
};

export const getCurrentUserData = async () => {
  const requestOptions = {
    isAuth: true,
    headers: {
      "Content-Type": "application/json",
    },
    endPoint: `users/me`,
    params: {
      page_size: 50,
      continuation_token: "",
      active: true,
    },
  };
  const res = await get(requestOptions);
  // if (res.data) {
  //   const test = useDispatcher("setToken", "data", res.data);
  //   console.log(test);
  //   // <Dispatcher dispatchAction="setToken" l_storage_name="data" data={res.data} />;
  // }
  return res.data;
};

export const updateCurrentUserProfile = async (userData) => {
  const body = {
    name: userData.name,
    phone: userData.phone,
    emails: userData.emails,
    city: userData.city,
    country: userData.country,
    // phone_Verified: userData.phone_Verified,
    business_Name: userData.business_Name,
    website_Url: userData.website_Url,
    profile_Picture_Url: userData.profile_Picture_Url,
    is_recruiter: userData.is_recruiter,
    source_Type: userData.source_Type,
    business_Email: userData.business_Email,
    years_in_business: userData.years_in_business,
    send_Email_Notifications: userData.send_Email_Notifications,
  };
  const res = await put(apiParamsFunction(true, "application/json", "/users/me", body, ""));
  return manageResponse(res);
};

export const updateCurrentUserServicesAndCountries = async (userServices, userCountries) => {
  const body = {
    user_services: userServices,
    user_countries: userCountries,
  };
  const res = await put(
    apiParamsFunction(true, "application/json", "/users/me/services", body, "")
  );
  return res.data;
};

export const getUserPurchaseHistory = async (pageSize = 50) => {
  const requestOptions = {
    page_size: pageSize,
    continuation_token: "",
    active: true,
  };
  const res = await get(
    apiParamsFunction(true, "application/json", "/users/me/purchase_history", "", requestOptions)
  );
  return res.data;
};

export const sendVerificationCode = () => {
  post(apiParamsFunction(true, "application/json", "/users/me/verifications/identity", "", ""));
};

export const sendOTPVerificationCode = async () => {
  const res = await post(
    apiParamsFunction(true, "application/json", "/users/me/verifications/contact", "", "")
  );
  return manageResponse(res);
};

export const sendVerificationCodeSMS = (phoneNumber) => {
  const body = { identity_verification_code: phoneNumber };
  return post(
    apiParamsFunction(true, "application/json", "/users/me/verifications/identity", body, "")
  );
};

export const submitVerificationCodeAndIdentityDocs = (
  picture_url,
  selfie_url,
  verification_code
) => {
  const body = {
    identity_document_picture_url: picture_url,
    identity_selfie_url: selfie_url,
    identity_verification_code: verification_code,
  };
  return put(
    apiParamsFunction(true, "application/json", "/users/me/verifications/identity", body, "")
  );
};

export const submitSkillDocs = (
  study_proof_document,
  employment_proof_document,
  work_proof_document,
  portfolio
) => {
  const body = {
    study_proof_document_url: study_proof_document,
    employment_proof_document_url: employment_proof_document,
    work_proof_document_url: work_proof_document,
    portfolio_url: portfolio,
  };
  return put(
    apiParamsFunction(true, "application/json", "/users/me/verifications/skills", body, "")
  );
};

export const submitOTPCode = async (verification_code) => {
  const body = {
    contact_verification_code: verification_code,
  };
  const requestOptions = {
    isAuth: true,
    headers: {
      "Content-Type": "application/json",
    },
    endPoint: `users/me/verifications/contact`,
    body,
  };
  const res = await put(requestOptions);

  return manageResponse(res);
};

export const getPaymentMethods = async () => {
  const requestOptions = {
    isAuth: true,
    headers: {
      "Content-Type": "application/json",
    },
    endPoint: `users/me/payment_methods`,
    params: {
      page_size: 50,
      continuation_token: "",
      active: true,
    },
  };
  const res = await get(requestOptions);
  return res.data;
};

export const createPaymentMethod = async (ExpMonth, ExpYear, number, cvc, IsDefault) => {
  let res = [];
  try {
    const body = {
      Card: {
        expMonth: ExpMonth,
        expYear: ExpYear,
        Number: number,
        CVC: cvc,
      },
      isDefault: IsDefault,
    };
    const requestOptions = {
      isAuth: true,
      headers: {
        "Content-Type": "application/json",
      },
      endPoint: `/users/me/payment_methods`,
      body,
    };
    res = await post(requestOptions);
  } catch (err) {
    console.error("error: ", err);
  }
  return manageResponse(res);
};
export const deletePaymentMethod = async (paymentMethodID) => {
  const requestOptions = {
    isAuth: true,
    headers: {
      "Content-Type": "application/json",
    },
    endPoint: `/users/me/payment_methods/${paymentMethodID}`,
  };
  const res = await del(requestOptions);
  return res.data;
};

export const getServicesList = async () => {
  const requestOptions = {
    isAuth: true,
    headers: {
      "Content-Type": "application/json",
    },

    endPoint: `/services`,
    params: {
      page_size: 50,
      continuation_token: "",
      active: true,
    },
  };
  const res = await get(requestOptions);
  return res.data;
};

export const getCountriesList = () => {
  const requestOptions = {
    page_size: 50,
    continuation_token: "",
    active: true,
  };
  const res = get(apiParamsFunction(true, "application/json", "countries", "", requestOptions));
  return res;
};

export const getSASToken = async (doc_Type) => {
  const requestOptions = {
    isAuth: true,
    headers: {
      "Content-Type": "application/json",
    },
    endPoint: `/system/blob_upload_token`,
    params: {
      upload_entity_type: doc_Type,
    },
  };
  const res = await get(requestOptions);
  return res.data;
};

export const createUserRequest = async (formData) => {
  const requestOptions = {
    isAuth: true,
    headers: {
      "Content-Type": "application/json",
    },
    endPoint: `users/me/requests`,
    body: formData,
  };
  const res = await post(requestOptions);
  return manageResponse(res);
};

export const getUserRequests = async () => {
  const requestOptions = {
    isAuth: true,
    headers: {
      "Content-Type": "application/json",
    },
    endPoint: `users/me/requests`,
    params: {
      page_size: 50,
      continuation_token: "",
      request_status: "Pending",
    },
  };
  const res = await get(requestOptions);
  return res.data;
};
