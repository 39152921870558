import { get, post } from "axios-setup";
import { apiParamsFunction } from "constants/apiParamsFunction";

export const getPakagesList = () => {
  const requestOptionsBody = {
    page_size: "50",
    continuation_token: "",
    active: true,
  };
  return get(apiParamsFunction(true, "application/json", "/packages", "", requestOptionsBody));
};

export const getServiceList = () => {
  const requestOptions = {
    page_size: 50,
    continuation_token: "",
    active: true,
  };
  return get(apiParamsFunction(true, "application/json", "/service_areas", "", requestOptions));
};

export const getCountriesList = () => {
  const requestOptions = {
    page_size: 50,
    continuation_token: "",
    active: true,
  };
  const res = get(apiParamsFunction(true, "application/json", "countries", "", requestOptions));
  return res;
};

export const postIgnoredLead = async (leadId, reason) => {
  try {
    await post(
      apiParamsFunction(
        true,
        "application/json",
        `users/me/ignored_leads`,
        {
          lead_id: leadId,
          ignore_reason: reason,
        },
        ""
      )
    );
  } catch (error) {
    console.error(error);
  }
};

export const getFilteredLeadList = async (_services, _countries, getByTime) => {
  let response = [];
  const requestOptions = {
    page_size: 50,
    continuation_token: "",
    active: true,
    lead_status: "pending",
  };
  if (_services && _services.length > 0) {
    requestOptions.services = _services;
  }
  if (_countries !== "any" && _countries !== "All Countries") {
    const newCountries = _countries.replace("USA", "United States");
    requestOptions.countries = newCountries;
  }
  // if (_countries !== "any" && _countries !== "All Countries") {
  //   if (_countries === "USA") {
  //     requestOptions.countries = "United States";
  //   } else {
  //     requestOptions.countries = _countries;
  //   }
  // }
  if (getByTime && getByTime.length > 0) {
    requestOptions.get_by_time = getByTime;
  }
  try {
    response = await get(apiParamsFunction(true, "application/json", "/leads", "", requestOptions));
  } catch (err) {
    console.error("Error: ", err);
  }
  return response;
};

export const postPurchaseCreditsPackage = async (
  packageID,
  paymentMethodID,
  card,
  savePaymentMethod
) => {
  let response = [];
  try {
    const requestOptionsBody = {
      package_id: packageID,
      Card: card,
      payment_method_id: paymentMethodID,
      savepayment_method: savePaymentMethod,
      lead_id: "",
    };

    response = await post(
      apiParamsFunction(true, "application/json", "users/me/purchase_package", requestOptionsBody)
    );
  } catch (err) {
    console.error(err);
  }
  return response;
};
