import React from "react";
import { Route, Switch, Redirect, BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "routes";
import { getRoutes } from "../helpers/routesHelpers";
import NotFoundPage from "../views/Auth/NotFoundPage";

// eslint-disable-next-line arrow-body-style

// styles

const Auth = () => (
  <Router>
    <Switch>
      {getRoutes(AppRoutes())}
      {localStorage.getItem("logoutType") === "expert_logout" ? (
        <Redirect exact from="/" to="/auth/expert-landing-page" />
      ) : (
        <Redirect exact from="/" to="/auth/client-landing-page" />
      )}
      {/* <Redirect exact from="/" to="/auth/expert-landing-page" /> */}
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  </Router>
);

export default Auth;
