import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "assets/theme-dark/base/colors";

const { dark, error } = colors;

const styles = {
  title: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: dark.main,
    textShadow: "0px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  description: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: dark.main,
    margin: "13px 0 31px",
  },
  inputlabel: {
    fontWeight: "600",
    fontSize: " 16px",
    lineHeight: "20px",
    color: dark.main,
    margin: "20px 0 0",
  },
  status: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "23px",
    color: dark.main,
    "& span": {
      color: "red",
    },
  },
  btn: {
    borderRadius: "30px",
    fontSize: " 16px",
    color: "#FFFFFF",
    padding: "13px 73px",
    marginTop: "53px",
  },
};

const useStyles = makeStyles(styles);

const ReferenceCheck = () => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" mt={3}>
      <Grid item xs={6}>
        <MDBox>
          <Typography className={classes.title}>Reference Check 1 </Typography>
          <Typography className={classes.description}>
            Please enter details of reference 1
          </Typography>
          <MDBox>
            <Typography className={classes.inputlabel}>Full Name*</Typography>
            <MDBox>
              <MDInput required label="Enter Full Name" sx={{ width: "50%" }} />
            </MDBox>
            <Typography className={classes.inputlabel}>Email*</Typography>
            <MDBox>
              <MDInput required label="Enter Email" sx={{ width: "50%" }} />
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox sx={{ marginTop: "47px" }}>
          <Typography className={classes.title}>Reference Check 2 </Typography>
          <Typography className={classes.description}>
            Please enter details of reference 2
          </Typography>
          <MDBox>
            <Typography className={classes.inputlabel}>Full Name*</Typography>
            <MDBox>
              <MDInput required label="Enter Full Name" sx={{ width: "50%" }} />
            </MDBox>
            <Typography className={classes.inputlabel}>Email*</Typography>
            <MDBox>
              <MDInput required label="Enter Email" sx={{ width: "50%" }} />
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox>
          <MDButton
            className={classes.btn}
            sx={{
              backgroundColor: dark.main,
              "&:hover": {
                backgroundColor: dark.main,
              },
            }}
          >
            Back
          </MDButton>
          <MDButton
            className={classes.btn}
            sx={{
              marginLeft: "18px",
              backgroundColor: error.main,
              "&:hover": {
                backgroundColor: error.main,
              },
            }}
          >
            Submit
          </MDButton>
        </MDBox>
      </Grid>
      <Grid item xs={6} textAlign="end">
        <Typography className={classes.status}>
          Status
          <CancelIcon sx={{ margin: "0 5px" }} />
          <span>Not Verified</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReferenceCheck;
