import AuthCallback from "../views/Auth/AuthCallback";
import ClientLandingPage from "../views/Auth/ClientLandingPage";
import ExpertLandingPage from "../views/Auth/ExpertLandingPage";

export const authRoutes = [
  {
    path: "/client-landing-page",
    name: " Client Landing Page",
    component: ClientLandingPage,
    layout: "/auth",
  },
  {
    path: "/expert-landing-page",
    name: "Expert Landing Page",
    component: ExpertLandingPage,
    layout: "/auth",
  },
  {
    path: "/callback",
    name: "Call back",
    component: AuthCallback,
    layout: "/auth",
  },
];
