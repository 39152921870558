import React from "react";
import MDButton from "components/MDButton";
import {
  Grid,
  Divider,
  Container,
  // CardActionArea, CardActions
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { useSelector, useDispatch } from "react-redux";
import { setModal, setToken, setSessionType } from "redux/actions/action";
import { get } from "axios-setup";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";
import Portal from "@mui/material/Portal";
import Snackbar from "@mui/material/Snackbar";
import MDBox from "components/MDBox";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import OTPInput, { ResendOTP } from "otp-input-react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import PaidIcon from "@mui/icons-material/Paid";
// import Rating from "@mui/material/Rating";
// import CircularProgress from "@mui/material/CircularProgress";
// import Box from "@mui/material/Box";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { NavLink } from "react-router-dom";
import SearchLoader from "../Common/Loader";
import Notification from "../../components/MDNotification";

import { BootstrapDialog, BootstrapDialogTitle } from "../../components/MDDialog";
import {
  sendOTPVerificationCode,
  submitOTPCode,
  updateCurrentUserProfile,
  getUserRequests,
} from "../../constants/userSettings";
import start from "../../assets/images/start.svg";
import RequestModal from "../Common/Dashboard/RequestModal";

const Dashboard = () => {
  // let isOTPVerified = false;
  const [name, setName] = React.useState([]);
  const [pendingLeadStatus, setPendingLeadStatus] = React.useState([]);
  // const [completedLeadStatus, setCompletedLeadStatus] = React.useState([]);
  // const [inProgressLeadStatus, setInProgressLeadStatus] = React.useState([]);
  // const [userVerification, setuserVerification] = React.useState();
  const [phoneVerification, setPhoneVerification] = React.useState(false);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isGetCode, setIsGetCode] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [isUpdateNumber, setIsUpdateNumber] = React.useState(false);
  const [OTP, setOTP] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");
  const [resendTimmer, setResendTimmerValue] = React.useState(60);
  const [createRequest, setCreateRequest] = React.useState(false);
  const [myRequestsNumber, setMyRequestsNumber] = React.useState(0);

  const sessionType = useSelector((state) => state.setTokenReducer?.setSessionType);
  // const userData = useSelector((state) => state.setTokenReducer?.data);
  const dispatch = useDispatch();

  const useStyles = makeStyles(() => ({
    circle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "auto",
      width: 64,
      color: "#ffffff",
      height: 64,
      left: 254,
      top: 459,
      background: "#051931",
      boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.15)",
      borderRadius: 40,
      marginBottom: 30,
      marginTop: 30,
    },
    fontSize: {
      fontSize: 25,
      fontFamily: "sans-serif",
    },
    fontSizeBig: {
      fontSize: 69,
    },

    paddingBottom: {
      paddingBottom: 35,
    },
    paddingTop: {
      paddingTop: 25,
    },
    buttonProperties: {
      color: "#4285F4",
      textTransform: "capitalize",
      textDecoration: "none",
      fontSize: 15,
      // fontFamily: "hurmeGeometeric-Semi-Bold",
      fontFamily: "hurmeGeometeric",
      fontWeight: "normal",
    },
    displayFlex: {
      display: "flex",
    },
    displayInlineFlex: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    pap: {
      textDecoration: "none",
      fontFamily: "hurmeGeometeric-Semi-Bold",
      color: "white",
      backgroundColor: "#DE1921",
      height: 40,
      borderRadius: 35,
      textTransform: "inherit",
      fontSize: 16,
      width: "65%",
      display: "flex",
      justifyContent: "space-around",
      margin: "auto",
    },
    containerStyle: {
      paddingTop: 0,
      // maxWidth: "80%",
      maxWidth: 1180,
      padding: 20,
    },
    heightMinContent: {
      // height: "min-content",
      height: "300px",
    },
    avatar: {
      width: "150px",
      height: "150px",
    },
    ratingText: {
      marginBottom: 6,
    },
    guideImage: {
      borderRadius: 20,
      marginBottom: 15,
      boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
    },
    backGroundGrids: {
      backgroundColor: "white",
      borderRadius: 35,
      boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.06)",
      // boxShadow:
      //   "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
    },
    squaredBackGround: {
      background: "#FFFFFF",
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 10%)",
      padding: 10,
      width: 80,
      height: 80,
      borderRadius: 25,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
    },
    submitCode: {
      borderRadius: 30,
      textTransform: "capitalize",
      width: "40%",
      marginLeft: "auto",
      marginRight: "auto",
      height: 47,
      display: "block",
    },
    or: {
      fontStyle: "normal",
      fontWeight: "900",
      fontSize: "20px",
      lineHeight: "100%",
      // margin: "1.875rem 0 1rem 0",
      display: "flex",
      marginBottom: "1.5rem",
      justifyContent: "center",
    },
    changeNumber: {
      display: "block",
      margin: "auto",
      marginBottom: "2rem",
      cursor: "pointer",
      color: "#4285F4",
      textTransform: "capitalize",
      fontSize: 16,
    },
    inputPhone: {
      fontFamily: "hurmeGeometeric",
      color: "#495057",
      fontSize: "0.875rem",
      borderRadius: "0.375rem",
      border: "0.01rem solid lightgray",
      height: "1.4375em",
      padding: "0.75rem",
      marginTop: "8px",
      width: "70%",
      margin: "auto",
    },
  }));

  const classes = useStyles();

  const getLeadDetails = async () => {
    // setSearchLoading(true);
    const requestOptions = {
      isAuth: true,
      headers: {
        "Content-Type": "application/json",
      },
      endPoint: `users/me/submitted_leads`,
      params: {
        page_size: 50,
        continuation_token: "",
        lead_status: "pending",
      },
    };
    const res = await get(requestOptions);
    const leadsArray = res.data;
    try {
      if (leadsArray && leadsArray.length > 0) {
        setPendingLeadStatus(leadsArray.filter((status) => status.lead_status === "pending"));
        // setCompletedLeadStatus(leadsArray.filter((status) => status.lead_status === "completed"));
        // setInProgressLeadStatus(
        //   leadsArray.filter((status) => status.lead_status === "in progress")
        // );
      }
    } catch (error) {
      console.error("client error", error);
    }
    // setSearchLoading(false);
  };

  const getUserDetails = async () => {
    setSearchLoading(true);
    const requestOptions = {
      isAuth: true,
      headers: {
        "Content-Type": "application/json",
      },
      endPoint: `users/me`,
      params: {
        page_size: 50,
        continuation_token: "",
        active: true,
      },
    };
    try {
      const res = await get(requestOptions);
      if (res && res.data) {
        dispatch(setToken(res.data));
        localStorage.setItem("data", JSON.stringify(res.data));
        await setName(res.data);
        await setPhoneNumber(res.data.phone);
        // if (res.data.verification_Status) {
        //   setuserVerification(res.data.verification_Status);
        // }
        if (res.data.phone_Verified) {
          setPhoneVerification(res.data.phone_Verified);
        }
        await getLeadDetails();
      }
    } catch (error) {
      console.error("client error", error);
    }
    setSearchLoading(false);
  };

  const updateProfile = async () => {
    // if (isValidPn) {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      //   setUpdateProfLoader(true);
      try {
        const request = {
          ...name,
          phone: phoneNumber,
        };
        const res = await updateCurrentUserProfile(request);
        if (res && res.status && res.status === 200) {
          setName(res.data);
          setSnackMessage("Phone number updated successfully");
          setIsUpdateNumber(false);
        } else {
          setSnackMessage(res.message);
        }
      } catch (err) {
        console.error(err);
        setSnackMessage("Error updating profile");
      }
    } else {
      // if (userData && userData.phone && userData.phone !== "") {
      //   const request = {
      //     ...name,
      //     phone: "",
      //   };
      //   await updateCurrentUserProfile(request);
      // }
      setSnackMessage("Please enter correct Phone number");
    }
  };

  const seeIfSessionTypeIsSignUp = () => {
    if (sessionType === "SignUp") {
      setOpen(true);
    }
  };

  // const getUserRating = async () => {
  //   setSearchLoading(true);
  //   const requestOptions = {
  //     isAuth: true,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     endPoint: `users/:${name.id && name.id.length > 0 ? name.id : ""}/client_rating`,
  //   };
  //   const res = await get(requestOptions);
  //   setSearchLoading(false);
  //   try {
  //     console.log("Response");
  //   } catch (error) {
  //     console.log("client error", error);
  //   }
  // };

  // const checkUserVerification = () => {
  //   setSearchLoading(true);
  //   if (name && name.length > 0) {
  //     if (name.verification_Status) {
  //       setuserVerification(name.verification_Status);
  //     }
  //     if (name.phone_Verified) {
  //       setPhoneVerification(name.phone_Verified);
  //     }
  //   }
  //   setSearchLoading(false);
  // };

  const handleClose = async () => {
    await dispatch(setSessionType("SignIn"));
    setIsGetCode(false);
    setOpen(false);
  };

  const handleNumberChange = (number) => {
    setPhoneNumber(number);
  };

  const handleResendTimmer = () => {
    setResendTimmerValue(parseInt(resendTimmer, 10) + 60);
  };

  const handleCreateRequestOpen = () => {
    setCreateRequest(true);
  };

  const sendOTPCode = async () => {
    try {
      if (OTP) {
        const response = await submitOTPCode(OTP);
        if (response && response.status === 200) {
          if (response.data.contact_Verification_Code_Verified === true) {
            // isOTPVerified = true;
            setOpenSnack(true);
            setSnackMessage("OTP Verified");
            await getUserDetails();
            await dispatch(setToken(name));
            await dispatch(setSessionType("SignIn"));
            dispatch(setModal(true));
            // getUserDetails();
            setOpen(false);
          } else {
            setOpenSnack(true);
            setSnackMessage("Internal error! Please contact synerge support team");
          }
        }
        if (response && response.status === 400) {
          setOpenSnack(true);
          setSnackMessage(response.message);
        }
      }
    } catch (err) {
      console.error("Error:", err);
      setOpenSnack(true);
      setSnackMessage("Internal error! Please contact synerge support team");
    }
  };

  const renderTime = () => React.Fragment;

  const renderButton = (buttonProps) => (
    <MDButton {...buttonProps} style={{ color: "#de1921" }}>
      {buttonProps.remainingTime !== 0 ? (
        // ? `Please wait for ${buttonProps.remainingTime} sec for resend`
        <div style={{ color: "#de1921" }}>{buttonProps.remainingTime} sec</div>
      ) : (
        "Resend"
      )}
    </MDButton>
  );

  const handleCloseSnack = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnack}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  React.useEffect(async () => {
    seeIfSessionTypeIsSignUp();
    await getUserDetails();
    const res = await getUserRequests();
    setMyRequestsNumber(res.request.length);
    // await getLeadDetails();
    // checkUserVerification();
    // getUserRating();
  }, []);

  React.useEffect(async () => {
    const res = await getUserRequests();
    setMyRequestsNumber(res.request.length);
  }, [createRequest]);

  return (
    <>
      {searchLoading ? (
        <SearchLoader />
      ) : (
        <>
          {phoneVerification ? (
            <></>
          ) : (
            <Notification
              message="Phone Number Unverified"
              buttonFrom="client"
              buttonText="Verify your phone number"
            />
          )}
          <div style={{ background: "#f9f9fa" }}>
            <Container className={classes.containerStyle}>
              <MDBox display="flex" paddingTop={3} alignItems="baseline">
                <MDTypography variant="h2" fontWeight="bold">
                  Hello {name.name && name.name.length > 0 ? name.name : name.given_Name}! &nbsp;
                </MDTypography>
                <MDTypography variant="h6" textAlign="center">
                  {phoneVerification === true ? (
                    <div className={classes.displayInlineFlex}>
                      <VerifiedUserIcon fontSize="medium" /> &nbsp;
                      <span style={{ color: "#4285F4" }}>Verified</span>
                    </div>
                  ) : (
                    ""
                  )}
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" style={{ padding: "20px 0" }} fontWeight="bold">
                Welcome to your Dashboard
              </MDTypography>
              <Divider sx={{ height: 4, m: 2.5 }} fullWidth orientation="horizontal" />
              <Grid
                container
                spacing={2}
                py={10}
                alignItems="flex-start"
                justifyContent={{ xs: "center", md: "space-between" }}
              >
                <Grid item xs={12} md={4}>
                  <MDBox
                    // className={classes.backGroundGrids}
                    className={`${classes.heightMinContent} ${classes.backGroundGrids}`}
                    p={5}
                    item
                    md={4}
                    direction="column"
                    textAlign="center"
                  >
                    <MDTypography
                      className={`${classes.paddingBottom}`}
                      style={{ paddingTop: 15 }}
                      variant="h4"
                      fontWeight="bold"
                    >
                      My Projects
                    </MDTypography>

                    <MDTypography variant="h5" fontWeight="regular">
                      Pending
                    </MDTypography>
                    <div className={classes.circle}>
                      <span className={classes.fontSize}>{pendingLeadStatus.length}</span>
                    </div>
                    <NavLink className={classes.buttonProperties} to="/client/my-projects">
                      View Pending Projects
                    </NavLink>

                    {/* <Divider sx={{ height: 3, m: 4.5 }} fullWidth orientation="horizontal" />
                    <Grid sx={{ height: 376 }}>
                      <MDTypography variant="h5" fontWeight="regular">
                        In Progress
                      </MDTypography>
                      <div className={classes.circle}>
                        <span className={classes.fontSize}>{inProgressLeadStatus.length}</span>
                      </div> */}
                    {/* <NavLink className={classes.buttonProperties} to="/client/my-projects">
                  View In Progress Projects
                </NavLink> */}
                    {/* <Divider sx={{ height: 3, m: 4.5 }} fullWidth orientation="horizontal" />

                      <MDTypography variant="h5" fontWeight="regular">
                        Completed
                      </MDTypography>
                      <div className={classes.circle}>
                        <span className={classes.fontSize}>{completedLeadStatus.length}</span>
                      </div> */}
                    {/* <NavLink className={classes.buttonProperties} to="/client/my-projects">
                  View Completed Projects
                </NavLink> */}
                    {/* <Divider sx={{ height: 3, m: 4.5 }} fullWidth orientation="horizontal" /> */}
                    {/* </Grid> */}

                    {/* This MDBox should be remove when above code will be uncomment */}
                    <MDBox sx={{ height: "42px" }} />
                    {/* This MDBox should be remove when above code will be uncomment */}
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDBox
                    className={`${classes.heightMinContent} ${classes.backGroundGrids}`}
                    p={5}
                    item
                    md={4}
                    direction="column"
                    textAlign="center"
                  >
                    <MDTypography
                      className={classes.paddingBottom}
                      style={{ paddingTop: 15 }}
                      variant="h4"
                      fontWeight="bold"
                    >
                      My Requests
                    </MDTypography>
                    <MDTypography
                      style={{ marginBottom: "18px" }}
                      className={`${classes.paddingBottom} ${classes.fontSizeBig} ${classes.squaredBackGround}`}
                      variant="h6"
                    >
                      {myRequestsNumber}
                    </MDTypography>
                    <MDButton
                      className={`${classes.buttonProperties}`}
                      sx={{ marginTop: "1rem" }}
                      onClick={() => handleCreateRequestOpen()}
                    >
                      {" "}
                      Submit a new Request{" "}
                    </MDButton>
                    {/* <Divider sx={{ height: 3, m: 4.5 }} fullWidth orientation="horizontal" /> */}
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MDBox
                    className={`${classes.heightMinContent} ${classes.backGroundGrids}`}
                    p={5}
                    item
                    md={4}
                    direction="column"
                    textAlign="center"
                  >
                    <MDTypography
                      className={classes.paddingBottom}
                      style={{ paddingTop: 15 }}
                      variant="h4"
                      fontWeight="bold"
                    >
                      New Project
                    </MDTypography>
                    <img alt="" src={start} className={`${classes.avatar} `} />
                    <NavLink className={classes.pap} to="/client/postleads">
                      <span style={{ margin: "auto" }}>Post a Project</span>
                    </NavLink>
                    {/* <Divider sx={{ height: 3, m: 4.5 }} fullWidth orientation="horizontal" /> */}
                  </MDBox>
                </Grid>
                {createRequest && (
                  <RequestModal
                    setCreateRequest={setCreateRequest}
                    setOpenSnack={setOpenSnack}
                    setSnackMessage={setSnackMessage}
                  />
                )}
                {/* <Grid
                  className={`${classes.backGroundGrids}`}
                  item
                  md={11}
                  xs={12}
                  direction="column"
                  p={7}
                  textAlign="center"
                  style={{ height: 490 }}
                >
                  <MDTypography className={classes.paddingBottom} variant="h3" fontWeight="bold">
                    Ratings
                  </MDTypography>{" "}
                  <MDBox display="flex" flexWrap="wrap" alignItems="center">
                    <Grid lg={8} md={12} xs={12}>
                      <MDBox
                        className={classes.paddingBottom}
                        pr={4}
                        display="grid"
                        textAlign="start"
                      >
                        <MDTypography className={classes.ratingText} variant="h5" fontWeight="bold">
                          Tehana
                        </MDTypography>
                        <MDTypography
                          className={classes.ratingText}
                          variant="h6"
                          fontWeight="light"
                        >
                          UI/UX | answers of few key questions
                        </MDTypography>
                        <MDTypography
                          className={`${classes.ratingText}`}
                          variant="h6"
                          fontWeight="light"
                        >
                          <LocationOnIcon />
                          &nbsp;New York, United States
                        </MDTypography>
                        <MDTypography
                          className={`${classes.ratingText}`}
                          variant="h6"
                          fontWeight="light"
                        >
                          <PaidIcon />
                          &nbsp;20
                        </MDTypography>
                        <MDTypography
                          className={`${classes.paddingTop} ${classes.displayInlineFlex}`}
                          variant="h6"
                          fontWeight="bold"
                        >
                          Communication &nbsp;
                          <Rating name="read-only" value={5} readOnly />
                          <span>5.0</span>
                        </MDTypography>
                        <MDTypography
                          className={`${classes.paddingTop} ${classes.displayInlineFlex}`}
                          variant="h6"
                          fontWeight="bold"
                        >
                          Responsiveness &nbsp;
                          <Rating name="read-only" value={5} readOnly />
                          <span>5.0</span>
                        </MDTypography>
                        <MDTypography
                          className={`${classes.paddingTop} ${classes.displayInlineFlex}`}
                          variant="h6"
                          fontWeight="bold"
                        >
                          Average Calculated &nbsp;
                          <Rating name="read-only" value={5} readOnly />
                          <span>4.75</span>
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid lg={4} md={12} xs={12} px={2}>
                      <Box sx={{ position: "relative", display: "inline-flex" }}>
                        <CircularProgress
                          variant="determinate"
                          value={90}
                          size={160}
                          color="success"
                        />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <MDTypography variant="h4" component="div" color="text.secondary">
                            90%
                          </MDTypography>
                        </Box>
                      </Box>
                    </Grid>
                  </MDBox>
                </Grid> */}
              </Grid>
              {/* BLOGS */}
              {/* <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <MDTypography variant="h1" fontWeight="bold">
                Guides
              </MDTypography>
              <Grid
                container
                py={6}
                textAlign="center"
                direction="row"
                justifyContent="space-between"
              >
                <Grid md={4} xs={12}>
                  <Card sx={{ maxWidth: 345, boxShadow: "none", margin: "auto" }}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.guideImage}
                        component="img"
                        height="200"
                        image="https://www.linkpicture.com/q/as_5.jpg"
                        alt="picture"
                      />
                      <CardContent>
                        <MDTypography variant="body2" color="text.secondary">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                          veniam.
                        </MDTypography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions style={{ justifyContent: "center" }}>
                      <MDButton className={classes.buttonProperties}>Learn More</MDButton>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid md={4} xs={12}>
                  <Card sx={{ maxWidth: 345, boxShadow: "none", margin: "auto" }}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.guideImage}
                        component="img"
                        height="200"
                        image="https://www.linkpicture.com/q/as_5.jpg"
                        alt="green iguana"
                      />
                      <CardContent>
                        <MDTypography variant="body2" color="text.secondary">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                          veniam.
                        </MDTypography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions style={{ justifyContent: "center" }}>
                      <MDButton className={classes.buttonProperties}>Learn More</MDButton>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid md={4} xs={12}>
                  <Card sx={{ maxWidth: 345, boxShadow: "none", margin: "auto" }}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.guideImage}
                        component="img"
                        height="200"
                        image="https://www.linkpicture.com/q/as_5.jpg"
                        alt="green iguana"
                      />
                      <CardContent>
                        <MDTypography variant="body2" color="text.secondary">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                          veniam.
                        </MDTypography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions style={{ justifyContent: "center" }}>
                      <MDButton className={classes.buttonProperties}>Learn More</MDButton>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid> */}

              {sessionType === "SignUp" && (
                <BootstrapDialog
                  aria-labelledby="customized-dialog-title"
                  open={open}
                  onClose={handleClose}
                  // aria-describedby="alert-dialog-description"
                >
                  <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                    sx={{ margin: "3rem 5rem 1rem 5rem", textAlign: "center" }}
                  >
                    {(!name.phone || name.phone === "") &&
                      "Almost done, please verify your phone so that our experts can reach you."}
                    {/* {name.phone && isGetCode && "Please enter OTP code"} */}
                    {name.phone && !isGetCode && "Get code and verify profile"}
                  </BootstrapDialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {!name.phone || name.phone === "" || isUpdateNumber ? (
                        <>
                          <MDBox className={classes.inputPhone}>
                            <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="US"
                              placeholder="Enter phone number"
                              value={phoneNumber}
                              onChange={(e) => handleNumberChange(e)}
                            />
                          </MDBox>
                          <MDButton
                            className={classes.submitCode}
                            sx={{ width: "100%", margin: "3rem 0 2rem" }}
                            color="error"
                            onClick={async () => {
                              await updateProfile();
                              handleClickSnack();
                            }}
                          >
                            Add Number
                          </MDButton>
                        </>
                      ) : (
                        <>
                          <MDButton
                            disabled={isGetCode}
                            className={classes.submitCode}
                            sx={
                              isGetCode
                                ? {
                                    width: "100%",
                                    margin: "3rem 0 2rem",
                                    display: "none !important",
                                  }
                                : { width: "100%", margin: "1rem 0 2rem" }
                            }
                            color="dark"
                            onClick={async () => {
                              const res = await sendOTPVerificationCode();
                              if (res.status === 200) {
                                setSnackMessage("OTP sent successfully");
                                setIsGetCode(true);
                              } else if (res.status === 400) {
                                setOpenSnack(true);
                                setSnackMessage(res.message);
                              }
                              handleClickSnack();
                            }}
                          >
                            Get Code
                          </MDButton>

                          {isGetCode && name && name.phone && (
                            <MDBox sx={{ margin: "0 4rem", textAlign: "center" }}>
                              <MDTypography variant="h5" color="text">
                                We&apos;ve sent a 4 digit code on your phone
                              </MDTypography>

                              <MDTypography variant="h6" color="text">
                                Please verify your number{" **** *** "}
                                {phoneNumber.substr(phoneNumber.length - 3)}
                              </MDTypography>
                              <MDBox mt={4} display="flex" justifyContent="center">
                                <OTPInput
                                  value={OTP}
                                  onChange={setOTP}
                                  OTPLength={4}
                                  otpType="number"
                                  disabled={false}
                                  inputStyles={{
                                    borderLeft: "none",
                                    borderTop: "none",
                                    borderRight: "none",
                                    broderBottom: "3px solid #051931",
                                    marginRight: "1rem",
                                    marginLeft: "1rem",
                                  }}
                                />
                              </MDBox>
                              <MDBox
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  placeContent: "center",
                                  paddingTop: "5%",
                                }}
                              >
                                Didn’t receive the code?
                                <ResendOTP
                                  renderButton={renderButton}
                                  renderTime={renderTime}
                                  onResendClick={async () => {
                                    const res = await sendOTPVerificationCode();
                                    if (res.status === 200) await handleResendTimmer();
                                    else if (res.status === 400) {
                                      setOpenSnack(true);
                                      setSnackMessage(res.message);
                                    }
                                  }}
                                  maxTime={resendTimmer}
                                />
                              </MDBox>
                              <MDButton
                                disabled={!isGetCode}
                                className={classes.submitCode}
                                sx={{ width: "100%", margin: "3rem 0 2rem 0" }}
                                color="error"
                                onClick={async () => {
                                  if (OTP && OTP.length === 4) {
                                    await sendOTPCode();
                                  } else {
                                    setOpenSnack(true);
                                    setSnackMessage("Please enter valid OTP");
                                  }
                                }}
                              >
                                Verify
                              </MDButton>
                            </MDBox>
                          )}
                          <MDTypography className={classes.or}>OR</MDTypography>
                          <MDButton
                            className={classes.changeNumber}
                            onClick={() => {
                              setIsUpdateNumber(true);
                              setIsGetCode(false);
                            }}
                          >
                            Change Number
                          </MDButton>
                        </>
                      )}
                    </DialogContentText>
                  </DialogContent>
                </BootstrapDialog>
              )}
              <Portal>
                <Snackbar
                  open={openSnack}
                  autoHideDuration={4000}
                  action={action}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  onClose={handleCloseSnack}
                >
                  <Alert
                    onClose={handleCloseSnack}
                    severity={
                      snackMessage === "Profile updated successfuly" ||
                      snackMessage === "Phone number updated successfully" ||
                      snackMessage === "OTP sent successfully" ||
                      snackMessage === "Your request has been submitted successfully."
                        ? "success"
                        : "error"
                    }
                    sx={{ width: "100%" }}
                  >
                    {snackMessage}
                  </Alert>
                </Snackbar>{" "}
              </Portal>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
