import React from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { setPaymentMethodId } from "redux/actions/action";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import VisaCard from "assets/imgs/icons/Visa.png";
import AmericanExpressCard from "assets/imgs/icons/American Express.png";
import DiscoverCard from "assets/imgs/icons/Discover.png";
import MasterCard from "assets/imgs/icons/MasterCard.png";
import JCB from "assets/imgs/icons/jcb.png";
import AllCards from "assets/imgs/icons/allCards.png";
import CreditDetails from "../FourTypeOfModals/CreditDetails";

const styles = {
  isActive: {
    border: "2px solid red",
  },
  buttonProperties4: {
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: "lighter",
    borderRadius: "25px",
  },
};

const useStyle = makeStyles(styles);

const MyCards = ({ SavedCards, setModalFlag }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [ID, setID] = React.useState("");
  const [addCard, setAddCard] = React.useState(false);

  const handleChange = (e) => {
    dispatch(setPaymentMethodId(e));
  };

  const isDefaultCardThen = () => {
    if (addCard) {
      dispatch(setPaymentMethodId());
    } else if (!addCard) {
      if (SavedCards && SavedCards.length > 0) {
        const pmID = SavedCards.find((x) => x.is_Default === true);
        if (pmID) {
          dispatch(setPaymentMethodId(pmID.id));
          setID(pmID.id);
        }
      }
    }
  };

  const getPaymentCardLogoUrl = (cardType) => {
    let brand = VisaCard;
    if (cardType.toLowerCase() === "visa") {
      brand = VisaCard;
    } else if (cardType.toLowerCase() === "mastercard") {
      brand = MasterCard;
    } else if (cardType.toLowerCase() === "discover") {
      brand = DiscoverCard;
    } else if (cardType.toLowerCase() === "american express") {
      brand = AmericanExpressCard;
    } else if (cardType.toLowerCase() === "jcb") {
      brand = JCB;
    } else {
      brand = AllCards;
    }
    return brand;
  };

  React.useEffect(() => {
    isDefaultCardThen();
  }, [addCard]);

  return (
    <>
      <MDBox>
        <ArrowBackIcon
          sx={{
            mr: 1,
            fontSize: "30px !important",
            marginBottom: "15px",
            display: "block",
            cursor: "pointer",
          }}
          onClick={() => {
            if (addCard) {
              setAddCard(!addCard);
            } else if (setModalFlag) {
              setModalFlag("first");
            }
          }}
        />
        <MDBox display="flex" justifyContent={`${!addCard ? "center" : "start"}`}>
          {/* <MDButton
            className={classes.buttonProperties4}
            color={`${!addCard ? "error" : "dark"}`}
            size={`${!addCard ? "medium" : "small"}`}
            sx={{ my: 4, mr: 3, ml: 2 }}
            onClick={() => {
              setAddCard(!addCard);
            }}
          >
            {!addCard ? (
              <MDTypography sx={{ fontSize: "13px", display: "flex" }} color="white">
                <AddIcon sx={{ paddingTop: "1px" }} />
                Add Card
              </MDTypography>
            ) : (
              // <MDTypography sx={{ fontSize: "13px" }} color="white">
              <ArrowBackIcon sx={{ mr: 1 }} />
              //   Back
              // </MDTypography>
            )}
          </MDButton> */}
          {!addCard ? (
            <MDButton
              className={classes.buttonProperties4}
              color={`${!addCard ? "error" : "dark"}`}
              size={`${!addCard ? "medium" : "small"}`}
              sx={{ my: 4, mr: 3, ml: 2 }}
              onClick={() => {
                setAddCard(!addCard);
              }}
            >
              <MDTypography sx={{ fontSize: "13px", display: "flex" }} color="white">
                <AddIcon sx={{ paddingTop: "3px" }} />
                Add Card
              </MDTypography>
            </MDButton>
          ) : (
            <></>
          )}
        </MDBox>
        {!addCard ? (
          <SimpleBar style={{ maxHeight: "25vh" }} className="scroll" autoHide={false}>
            <MDBox>
              {SavedCards &&
              SavedCards.length > 0 &&
              SavedCards.sort((a, b) => new Date(b.created) - new Date(a.created)) ? (
                SavedCards.map((item) => (
                  <Grid
                    container
                    sx={{ mb: 3, width: "80%", marginRight: "auto", marginLeft: "auto" }}
                    justifyContent="center"
                  >
                    <MDBox display="flex" justifyContent="center">
                      <div>
                        <MDButton
                          className={`${item.id === ID ? classes.isActive : ""}`}
                          key={item.id}
                          variant="outlined"
                          color="dark"
                          sx={{ width: "350px", marginBottom: 1, justifyContent: "space-between" }}
                          gradient
                          onClick={() => {
                            handleChange(item.id);
                            setID(item.id);
                          }}
                        >
                          <MDBox className={classes.payment}>
                            <img
                              className={classes.imageSize}
                              src={getPaymentCardLogoUrl(item.card.brand)}
                              alt="icon"
                              width="fit-content"
                              height="fit-content"
                            />
                          </MDBox>
                          <div style={{ display: "flex" }}>
                            <MDTypography sx={{ ml: 2 }} variant="h6" fontWeight="light">
                              **** **** **** {item.card.number}
                            </MDTypography>
                          </div>
                          <div style={{ display: "flex" }}>
                            <MDTypography sx={{ ml: 2 }} variant="h6" fontWeight="light">
                              {item.card.exp_month}/{item.card.exp_Year}
                            </MDTypography>
                          </div>
                        </MDButton>
                      </div>
                    </MDBox>
                  </Grid>
                ))
              ) : (
                <MDTypography textAlign="center" fontWeight="bold" variant="h5">
                  No cards found. Add a card to continue
                </MDTypography>
              )}
            </MDBox>
          </SimpleBar>
        ) : (
          <Grid md={10} margin="auto">
            <CreditDetails />
          </Grid>
        )}
      </MDBox>
    </>
  );
};

MyCards.propTypes = {
  SavedCards: PropTypes.object,
  setModalFlag: PropTypes.func,
};

export default MyCards;
